import {
  Contracts,
  CreateContract,
  DeleteContract,
  DeleteManyContract,
  UpdateContract,
} from "@/graphql/settings/Contracts";
import { useContractGlobalStore } from "@/pinia/collections";
import { setGlobalStoreGraphQL } from "@/utils/graphql.utils";
import { apolloProvider } from "@/vue-apollo";

export const createContract = async (payload: { data: any }) => {
  try {
    return await apolloProvider.defaultClient.mutate({
      mutation: CreateContract,
      update: (store, { data: { createContract } }) => {
        updateStoreAfterCreateContract(store, createContract);
      },
      variables: {
        data: payload.data,
      },
    });
  } catch (error) {
    console.error(error);
  }
};

export const updateContract = async (payload: { data: any; where: any }) => {
  try {
    return await apolloProvider.defaultClient.mutate({
      mutation: UpdateContract,
      update: (store, data) => {
        updateStoreAfterUpdateContract(store, data.data.updateContract);
      },
      variables: {
        data: payload.data,
        where: payload.where,
      },
    });
  } catch (error) {
    console.error(error);
  }
};

export const deleteContract = async (payload: { where: any }) => {
  try {
    return await apolloProvider.defaultClient.mutate({
      mutation: DeleteContract,
      update: (store, data) => {
        updateStoreAfterDeleteContract(store, data.data.deleteContract.id);
      },
      variables: {
        where: payload.where,
      },
    });
  } catch (error) {
    console.error(error);
  }
};

export const deleteManyContract = async (payload: { where: any }) => {
  try {
    return await apolloProvider.defaultClient.mutate({
      mutation: DeleteManyContract,
      variables: {
        where: payload.where,
      },
    });
  } catch (error) {
    console.error(error);
  }
};

const updateStoreAfterCreateContract = (store, createContract) => {
  const data = store.readQuery({
    query: Contracts,
  });
  data.Contracts.push(createContract);
  setGlobalStoreGraphQL(useContractGlobalStore(), data.Contracts);
  store.writeQuery({
    data,
    query: Contracts,
  });
};

const updateStoreAfterDeleteContract = (store, id) => {
  const data = store.readQuery({
    query: Contracts,
  });
  const index = data.Contracts.findIndex((c) => c.id === id);
  if (index !== -1) {
    data.Contracts.splice(index, 1);
    setGlobalStoreGraphQL(useContractGlobalStore(), data.Contracts);
    store.writeQuery({
      data,
      query: Contracts,
    });
  }
};

const updateStoreAfterUpdateContract = (store, updateContract) => {
  const data = store.readQuery({
    query: Contracts,
  });
  const index = data.Contracts.findIndex((c) => c.id === updateContract.id);
  if (index !== -1) {
    data.Contracts.splice(index, 1, updateContract);
    setGlobalStoreGraphQL(useContractGlobalStore(), data.Contracts);
    store.writeQuery({
      data,
      query: Contracts,
    });
  }
};
