<template>
  <div :class="$style.container">
    <v-badge
      :content="Number(counter)"
      :offset-x="25"
      :offset-y="15"
      :value="Number(counter)"
      color="primary"
      overlap
    >
      <v-menu
        v-model="show"
        :class="$style.menu"
        :close-on-content-click="false"
        :content-class="$style.menu"
        left
        offset-y
      >
        <template #activator="{on}">
          <v-btn color="grey_middle" text v-on="on">
            <v-icon>mdi-bell-outline</v-icon>
          </v-btn>
        </template>
        <v-list :class="[$style.list, 'pa-0']">
          <v-tabs fixed-tabs>
            <v-tabs-slider color="primary" />
            <v-tab href="#unread">
              {{ $t("notifications.not_read") }}
            </v-tab>
            <v-tab href="#read">
              {{ $t("notifications.read") }}
            </v-tab>
            <v-tab-item style="height: 300px;" value="unread">
              <v-btn
                v-if="unReadNotifications.length"
                :loading="loadingAllRead"
                block
                class="text-lowercase"
                color="primary"
                depressed
                text
                @click="markAllRead"
              >
                <v-icon :size="16" left>
                  mdi-eye-outline
                </v-icon>
                <div>{{ $t("notifications.mark_read") }}</div>
              </v-btn>
              <div v-if="!unReadNotifications.length" :class="$style.upToDate">
                <p>
                  {{ $t("notifications.all_notifications_read") }}
                </p>
                <div :class="$style.upToDateIcon">
                  <v-icon :size="60" color="grey">
                    mdi-coffee-outline
                  </v-icon>
                </div>
              </div>
              <div
                :ref="
                  unReadNotifications.length && !noMoreUnReadToload
                    ? 'elUnRead'
                    : null
                "
                style="height: 270px; padding-bottom: 20px; overflow-y: scroll;"
              >
                <div
                  v-for="notification in unReadNotifications"
                  :key="notification.id"
                  :class="$style.notificationItem"
                >
                  <NotificationItem
                    :notification="notification"
                    @mark-read="markRead($event)"
                    @notification-click="markRead($event)"
                  />
                </div>
              </div>
              <div style="position: absolute; bottom: 10px; left: 175px;">
                <m-progress-circular
                  v-if="loadingUnRead"
                  color="primary"
                  indeterminate
                />
              </div>
            </v-tab-item>
            <v-tab-item style="height: 300px;" value="read">
              <v-btn
                v-if="readNotifications.length"
                :loading="loadingAllNotifications"
                block
                class="text-lowercase"
                color="primary"
                depressed
                text
                @click="removeAllNotifications"
              >
                <v-icon :size="16" color="primary" left>
                  mdi-delete-sweep-outline
                </v-icon>
                {{ $t("notifications.remove") }}
              </v-btn>
              <div v-if="!readNotifications.length" :class="$style.upToDate">
                <p>{{ $t("notifications.all_notifications_clear") }}</p>
                <div :class="$style.upToDateIcon">
                  <v-icon :size="60" color="grey">
                    mdi-bell-check-outline
                  </v-icon>
                </div>
              </div>
              <div
                :ref="
                  readNotifications.length && !noMoreReadToload
                    ? 'elRead'
                    : null
                "
                style="height: 270px; padding-bottom: 20px; overflow-y: scroll;"
              >
                <div
                  v-for="notification in readNotifications"
                  :key="notification.id"
                  :class="$style.notificationItem"
                >
                  <NotificationItem
                    :notification="notification"
                    :read="true"
                    @notification-click="show = false"
                    @remove-notification="removeNotification($event)"
                  />
                </div>
                <div style="position: absolute; bottom: 10px; left: 175px;">
                  <m-progress-circular
                    v-if="loadingRead"
                    color="primary"
                    indeterminate
                  />
                </div>
              </div>
            </v-tab-item>
          </v-tabs>
        </v-list>
      </v-menu>
    </v-badge>
  </div>
</template>

<script lang="ts">
import { useInfiniteScroll } from "@vueuse/core";
import { filter } from "lodash";
import {
  computed,
  defineComponent,
  onMounted,
  type Ref,
  ref,
  watch,
} from "vue";

import { MProgressCircular } from "@mercateam/apps-module-design-system";

import { useNotificationsStore } from "@/pinia/collections";
import { useAuthentificationStore } from "@/pinia/user";
import ClientHelper from "@/tscript/database/ClientHelper";
import { BaseStatus } from "@/tscript/interfaces";

import NotificationItem from "@/components/global/notifications/NotificationItem.vue";

export default defineComponent({
  name: "NotificationsPopup2",
  components: { MProgressCircular, NotificationItem },
  setup() {
    const notificationsClientHelper = new ClientHelper<Notification>(
      "notifications",
    );
    const elRead = ref<HTMLElement | null>(null);
    const elUnRead = ref<HTMLElement | null>(null);
    const unReadNotifications: Ref<any[]> = ref([]);
    const readNotifications: Ref<any[]> = ref([]);
    const counter: Ref<number | string> = ref(0);
    const show: Ref<boolean> = ref(false);
    const takeReadNotifs = ref(20);
    const takeUnReadNotifs = ref(20);
    const loadingRead = ref(false);
    const noMoreUnReadToload = ref(false);
    const noMoreReadToload = ref(false);
    const loadingUnRead = ref(false);
    const loadingAllRead = ref(false);
    const loadingAllNotifications = ref(false);
    const isContentParameterActivatedOnCurrentSite = computed(() => {
      if (!useAuthentificationStore()) return false;
      return !!useAuthentificationStore().active_site_full
        ?.settings_content_feature;
    });
    const userId = computed(() => {
      return useAuthentificationStore()?.data?.id;
    });
    const client_id = computed(() => {
      return useAuthentificationStore()?.data?.client_id;
    });
    const site_id = computed(() => {
      return useAuthentificationStore()?.data?.site_id;
    });
    const user_id = computed(() => {
      return useAuthentificationStore()?.data?.id;
    });
    const notificationStore = computed(() => {
      return useNotificationsStore();
    });
    const queryNotificationsRead = async (loadAndIncrement = false) => {
      const arrayWhereAll: any[] = [
        {
          fieldName: "client_id",
          filterOp: "==",
          value: client_id.value,
        },
        {
          fieldName: "site_id",
          filterOp: "==",
          value: site_id.value,
        },
        {
          fieldName: "user_id",
          filterOp: "==",
          value: user_id.value,
        },
        { fieldName: "read", filterOp: "==", value: true },
      ];

      if (!isContentParameterActivatedOnCurrentSite.value) {
        arrayWhereAll.push({
          fieldName: "type",
          filterOp: "not-in",
          value: ["content-approbation", "content-consultation"],
        });
      }

      if (userId.value && client_id.value && site_id.value) {
        const resultAll = await notificationsClientHelper.getDocumentsWithWhere(
          arrayWhereAll,
          takeReadNotifs.value,
          "updated_at",
          "desc",
        );
        let readNotificationsFinal: any[] = [];
        if (isContentParameterActivatedOnCurrentSite.value) {
          readNotificationsFinal = filter(resultAll, (notification: any) => {
            return (
              !notification?.status ||
              notification?.status === BaseStatus.active
            );
          });
        } else {
          readNotificationsFinal = filter(resultAll, (notification: any) => {
            return (
              notification.type !== "content-approbation" &&
              notification.type !== "content-consultation" &&
              (!notification?.status ||
                notification?.status === BaseStatus.active)
            );
          });
        }
        if (
          readNotifications.value.length === readNotificationsFinal.length &&
          loadAndIncrement
        ) {
          noMoreReadToload.value = true;
        } else {
          noMoreReadToload.value = false;
        }
        return readNotificationsFinal;
      }
      return [];
    };
    const queryNotificationsUnRead = async () => {
      const arrayWhereArchived: any[] = [
        {
          fieldName: "client_id",
          filterOp: "==",
          value: client_id.value,
        },
        {
          fieldName: "site_id",
          filterOp: "==",
          value: site_id.value,
        },
        {
          fieldName: "user_id",
          filterOp: "==",
          value: user_id.value,
        },
        {
          fieldName: "status",
          filterOp: "==",
          value: BaseStatus.archived,
        },
        { fieldName: "read", filterOp: "==", value: false },
      ];
      const arrayWhereAll: any[] = [
        {
          fieldName: "client_id",
          filterOp: "==",
          value: client_id.value,
        },
        {
          fieldName: "site_id",
          filterOp: "==",
          value: site_id.value,
        },
        {
          fieldName: "user_id",
          filterOp: "==",
          value: user_id.value,
        },
        { fieldName: "read", filterOp: "==", value: false },
      ];

      if (!isContentParameterActivatedOnCurrentSite.value) {
        arrayWhereAll.push({
          fieldName: "type",
          filterOp: "not-in",
          value: ["content-approbation", "content-consultation"],
        });
        arrayWhereArchived.push({
          fieldName: "type",
          filterOp: "not-in",
          value: ["content-approbation", "content-consultation"],
        });
      }

      if (userId.value) {
        const notificationsArchivedWithCount: any =
          await notificationsClientHelper.getDocumentsWithWhereWithCount(
            arrayWhereArchived,
            takeUnReadNotifs.value,
            "status",
          );
        const notificationsAllWithCount: any =
          await notificationsClientHelper.getDocumentsWithWhereWithCount(
            arrayWhereAll,
            takeUnReadNotifs.value,
            "created_at",
            "desc",
          );
        const notificationItemsActives = filter(
          notificationsAllWithCount.items,
          (notification) => {
            if (
              !notification?.status ||
              notification?.status === BaseStatus.active
            )
              return notification;
          },
        );
        return {
          count:
            notificationsAllWithCount?.count -
            notificationsArchivedWithCount.count,
          items: notificationItemsActives,
        };
      }
      return { count: 0, items: [] as any[] };
    };
    const loadMoreRead = async (
      loadAndIncrement = false,
      startValue?: number,
    ) => {
      loadingRead.value = true;
      if (startValue) takeReadNotifs.value = startValue;
      const result = await queryNotificationsRead(loadAndIncrement);
      readNotifications.value = result;
      loadingRead.value = false;
    };
    const loadMoreUnRead = async (
      loadAndIncrement = false,
      startValue?: number,
    ) => {
      loadingUnRead.value = true;
      if (startValue) takeUnReadNotifs.value = startValue;
      const result = await queryNotificationsUnRead();
      let items = result?.items;
      if (!isContentParameterActivatedOnCurrentSite.value) {
        items = filter(items, (notification) => {
          return (
            notification.type !== "content-approbation" &&
            notification.type !== "content-consultation"
          );
        });
      }
      if (
        unReadNotifications.value.length === items.length &&
        loadAndIncrement
      ) {
        noMoreUnReadToload.value = true;
      } else {
        noMoreUnReadToload.value = false;
      }
      unReadNotifications.value = items || [];
      const count = result?.count >= 1000 ? "+999" : result?.count;
      counter.value = count || 0;
      loadingUnRead.value = false;
    };
    const markAllRead = async () => {
      loadingAllRead.value = true;
      const ids = await notificationsClientHelper.getDocumentIdsWithWhere(
        [
          {
            fieldName: "client_id",
            filterOp: "==",
            value: client_id.value,
          },
          {
            fieldName: "site_id",
            filterOp: "==",
            value: site_id.value,
          },
          {
            fieldName: "user_id",
            filterOp: "==",
            value: user_id.value,
          },
          { fieldName: "read", filterOp: "==", value: false },
        ],
        500,
      );
      if (ids.length > 0) {
        for (const notifcations_id of ids) {
          await notificationsClientHelper.set(notifcations_id, { read: true });
        }
        await loadMoreRead();
        await loadMoreUnRead();
      }
      if (ids.length >= 500) {
        await markAllRead();
      }
      loadingAllRead.value = false;
      loadingUnRead.value = false;
    };
    const markRead = async (id: string) => {
      await notificationStore.value.update({ read: true }, id);
      await loadMoreRead();
      await loadMoreUnRead();
    };
    const removeNotification = async (id: string) => {
      await notificationStore.value.delete(id);
      await loadMoreRead();
      await loadMoreUnRead();
    };
    const removeAllNotifications = async () => {
      if (readNotifications.value.length) {
        try {
          loadingAllNotifications.value = true;
          const ids = await notificationsClientHelper.getDocumentIdsWithWhere(
            [
              {
                fieldName: "client_id",
                filterOp: "==",
                value: client_id.value,
              },
              {
                fieldName: "site_id",
                filterOp: "==",
                value: site_id.value,
              },
              {
                fieldName: "user_id",
                filterOp: "==",
                value: user_id.value,
              },
              { fieldName: "read", filterOp: "==", value: true },
            ],
            500,
          );
          await notificationStore.value.deleteMany(ids);
          if (ids.length >= 500) {
            await removeAllNotifications();
          }
          await loadMoreRead();
          await loadMoreUnRead();
        } catch {
          console.error("Error in loading notifications");
        } finally {
          loadingAllNotifications.value = false;
          loadingRead.value = false;
        }
      }
    };
    useInfiniteScroll(
      elRead,
      async () => {
        // load more
        takeReadNotifs.value += 20;
        await loadMoreRead(true);
      },
      { distance: 20 },
    );
    useInfiniteScroll(
      elUnRead,
      async () => {
        // load more
        takeUnReadNotifs.value += 20;
        await loadMoreUnRead(true);
      },
      { distance: 20 },
    );

    onMounted(() => {
      (async () => {
        try {
          await loadMoreUnRead(false, 0);
        } catch (error: any) {
          console.error("Error :", error);
        }
      })();
    });

    watch(show, async () => {
      try {
        await loadMoreRead(false, 0);
        await loadMoreUnRead(false, 0);
      } catch (error: any) {
        console.error("Error :", error);
      }
    });

    return {
      counter,
      elRead,
      elUnRead,
      loadingAllNotifications,
      loadingAllRead,
      loadingRead,
      loadingUnRead,
      loadMoreRead,
      loadMoreUnRead,
      markAllRead,
      markRead,
      noMoreReadToload,
      noMoreUnReadToload,
      readNotifications,
      removeAllNotifications,
      removeNotification,
      show,
      unReadNotifications,
    };
  },
});
</script>

<style lang="scss" module>
@use "../../scss/mixins" as mx;

.container {
  display: flex;
  align-items: center;
}

.menu {
  background-color: white !important;
}

.list {
  width: 380px;
  height: 350px;
  background-color: white !important;
}

[class^="v-menu"]::-webkit-scrollbar-track {
  background-color: white;
}

[class^="v-menu"]::-webkit-scrollbar {
  width: 6px;
  background-color: white;
}

[class^="v-menu"]::-webkit-scrollbar-thumb {
  background-color: rgb(117 117 117 / 0%);
  border-radius: 10px;
}

[class^="v-menu"]:hover::-webkit-scrollbar-thumb {
  background-color: rgb(117 117 117 / 50%);
}

.upToDate {
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  padding: 2rem 1rem;
  text-align: center;
}
</style>
