import { gql } from "graphql-tag";

export const CreateClearance = gql`
  mutation CreateClearance($data: ClearanceCreateInput!) {
    createClearance(data: $data) {
      id
      name
      client_id
      site_id
      site {
        name
      }
      teams {
        id
        team_name
        status
      }
      status
      validity
      minimal_days
      keywords_temp
      internal_code
    }
  }
`;

export const UpdateClearance = gql`
  mutation UpdateClearance(
    $data: ClearanceUpdateInput!
    $where: ClearanceWhereUniqueInput!
  ) {
    updateClearance(data: $data, where: $where) {
      id
      name
      client_id
      site_id
      site {
        name
      }
      teams {
        id
        team_name
        status
      }
      status
      validity
      minimal_days
      keywords_temp
      internal_code
    }
  }
`;

export const DeleteClearance = gql`
  mutation DeleteClearance($where: ClearanceWhereUniqueInput!) {
    deleteClearance(where: $where) {
      id
      client_id
      site_id
      name
      status
      validity
      minimal_days
    }
  }
`;

export const DeleteManyClearance = gql`
  mutation DeleteManyClearance($where: ClearanceWhereInput) {
    deleteManyClearance(where: $where) {
      count
    }
  }
`;

export const Clearances = gql`
  query Clearances {
    Clearances {
      id
      client_id
      site_id
      site {
        name
      }
      teams {
        id
        team_name
        status
      }
      name
      status
      validity
      minimal_days
      keywords_temp
      internal_code
    }
  }
`;
