import type { DataProxy, DocumentNode, FetchResult } from "@apollo/client";
import type { Store } from "pinia";

import { setGlobalStoreGraphQL } from "@/utils/graphql.utils";

export const updateStoreAfterCreation = (
  cache: DataProxy,
  query: DocumentNode,
  queryName: string,
  newItem: FetchResult["data"],
  store: Store,
) => {
  const data = cache.readQuery<FetchResult["data"]>({
    query,
  });
  if (!data) return;

  const datasUpdated = [...data[queryName], newItem];
  setGlobalStoreGraphQL(store, datasUpdated);
  cache.writeQuery({
    data: { [queryName]: datasUpdated },
    query,
  });
};

export const updateStoreAfterDeletion = (
  cache: DataProxy,
  query: DocumentNode,
  queryName: string,
  newItem: { id: string },
  store: Store,
) => {
  const data = cache.readQuery<FetchResult["data"]>({
    query,
  });
  if (!data) return;

  const index = data[queryName].findIndex((c) => c.id === newItem.id);
  if (index !== -1) {
    const datasUpdated = data[queryName].toSpliced(index, 1);
    setGlobalStoreGraphQL(store, datasUpdated);
    cache.writeQuery({
      data: { [queryName]: datasUpdated },
      query,
    });
  }
};

export const updateStoreAfterUpdate = (
  cache: DataProxy,
  query: DocumentNode,
  queryName: string,
  newItem: { id: string },
  store: Store,
) => {
  const data = cache.readQuery<FetchResult["data"]>({
    query,
  });
  if (!data) return;

  const index = data[queryName].findIndex((c) => c.id === newItem.id);
  if (index !== -1) {
    const datasUpdated = data[queryName].toSpliced(index, 1, newItem);
    setGlobalStoreGraphQL(store, datasUpdated);
    cache.writeQuery({
      data: { [queryName]: datasUpdated },
      query,
    });
  }
};
