import { gql } from "graphql-tag";

export const CreateSubposition = gql`
  mutation CreateSubposition($data: SubpositionCreateInput!) {
    createSubposition(data: $data) {
      id
      name
      status
      site {
        name
      }
      clearances {
        id
        name
      }
      skills {
        id
        name
      }
      restrictions {
        id
        name
      }
      position {
        id
        name
        restricted_positions
      }
      teams {
        id
        team_name
        status
      }
      cover
      shift_positions
      restricted_positions
      internal_code
      link_positions_skill(where: {skill: {is: {status: {equals: active}}}}) {
        id
        skill {
          id
          name
          level
        }
        level_min
      }
    }
  }
`;

export const UpdateSubposition = gql`
  mutation UpdateSubposition(
    $data: SubpositionUpdateInput!
    $where: SubpositionWhereUniqueInput!
  ) {
    updateSubposition(data: $data, where: $where) {
      id
      name
      status
      site {
        name
      }
      clearances {
        id
        name
      }
      skills {
        id
        name
      }
      restrictions {
        id
        name
      }
      position {
        id
        name
        restricted_positions
      }
      teams {
        id
        team_name
        status
      }
      cover
      shift_positions
      restricted_positions
      internal_code
      link_positions_skill(where: {skill: {is: {status: {equals: active}}}}) {
        id
        skill {
          id
          name
          level
        }
        level_min
      }
    }
  }
`;

export const DeleteSubposition = gql`
  mutation DeleteSubposition($where: SubpositionWhereUniqueInput!) {
    deleteSubposition(where: $where) {
      id
    }
  }
`;

export const Subposition = gql`
  query Subposition($where: SubpositionWhereUniqueInput!) {
    Subposition(where: $where) {
      id
      name
      status
      site {
        name
      }
      clearances {
        id
        name
      }
      skills {
        id
        name
      }
      restrictions {
        id
        name
      }
      position {
        id
        name
        restricted_positions
        subpositions {
          id
          name
        }
        teams {
          id
          team_name
          status
        }
      }
      teams {
        id
        team_name
        status
      }
      cover
      shift_positions
      restricted_positions
      internal_code
      link_positions_skill(where: {skill: {is: {status: {equals: active}}}}) {
        id
        skill {
          id
          name
          level
        }
        level_min
      }
    }
  }
`;

export const DeleteManySubposition = gql`
  mutation DeleteManySubposition($where: SubpositionWhereInput) {
    deleteManySubposition(where: $where) {
      count
    }
  }
`;

export const Subpositions = gql`
  query Subpositions {
    Subpositions {
      id
      name
      status
      site {
        name
      }
      clearances {
        id
        name
      }
      skills {
        id
        name
      }
      restrictions {
        id
        name
      }
      position {
        id
        name
        restricted_positions
      }
      teams {
        id
        team_name
        status
      }
      cover
      shift_positions
      restricted_positions
      internal_code
      link_positions_skill(where: {skill: {is: {status: {equals: active}}}}) {
        id
        skill {
          id
          name
          level
        }
        level_min
      }
    }
  }
`;
