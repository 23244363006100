import { gql } from "graphql-tag";

export const LPositionCloseds = gql`
  query LPositionCloseds($where: LPositionClosedWhereInput) {
    LPositionCloseds(where: $where) {
      id
      status
      day
      week
      month
      year
      position_id
      subposition_id
      shift_id
      date
      team_id
      shift_id
    }
  }
`;

export const CreateLPositionClosed = gql`
  mutation CreateLPositionClosed($data: LPositionClosedCreateInput!) {
    createLPositionClosed(data: $data) {
      id
      status
      day
      week
      month
      year
      position_id
      subposition_id
      shift_id
      date
      team_id
      shift_id
    }
  }
`;

export const DeleteLPositionClosed = gql`
  mutation DeleteLPositionClosed($where: LPositionClosedWhereUniqueInput!) {
    deleteLPositionClosed(where: $where) {
      id
      status
      day
      week
      month
      year
      position_id
      subposition_id
      shift_id
      date
      team_id
      shift_id
    }
  }
`;

export const UpdateLPositionClosed = gql`
  mutation UpdateLPositionClosed(
    $where: LPositionClosedWhereUniqueInput!
    $data: LPositionClosedUpdateInput!
  ) {
    updateLPositionClosed(where: $where, data: $data) {
      id
      status
      day
      week
      month
      year
      position_id
      subposition_id
      shift_id
      date
      team_id
      shift_id
    }
  }
`;

export const CreateManyLPositionClosed = gql`
  mutation CreateManyLPositionClosed(
    $data: [LPositionClosedCreateManyInput!]!
  ) {
    createManyLPositionClosed(data: $data) {
      count
    }
  }
`;

export const DeleteManyLPositionClosed = gql`
  mutation DeleteManyLPositionClosed($where: LPositionClosedWhereInput) {
    deleteManyLPositionClosed(where: $where) {
      count
    }
  }
`;
