import { EResolverName } from "@/tscript/interfaces";
import { getToken } from "@/vue-apollo";

import { createAbsence } from "../_tables/absence";
import { createCategory } from "../_tables/category";
import {
  createCellAbsence,
  createManyCellAbsence,
} from "../_tables/cellAbsence";
import { createCellResult, createManyCellResult } from "../_tables/cellResult";
import { createCellShift, createManyCellShift } from "../_tables/cellShift";
import { createClearance } from "../_tables/clearances";
import { createClient } from "../_tables/client";
import { createClosure } from "../_tables/closures";
import { createContract } from "../_tables/contract";
import { createEmployee } from "../_tables/employee";
import { createExternalTrainer } from "../_tables/externalTrainers";
import { createFactory } from "../_tables/factories";
import { createFilesAttachment } from "../_tables/filesAttachment";
import { createFolder } from "../_tables/folder";
import { createLClearance } from "../_tables/lclearances";
import {
  createLPositionClosed,
  createManyLPositionClosed,
} from "../_tables/lpositionClosed";
import { createLRestriction } from "../_tables/lrestrictions";
import { createLSkill } from "../_tables/lskills";
import { createLTraining } from "../_tables/ltrainings";
import { createNote } from "../_tables/notes";
import { createPositionOrder } from "../_tables/positionOrder";
import { createPosition } from "../_tables/positions";
import { createRestriction } from "../_tables/restriction";
import { createShift } from "../_tables/shifts";
import { createSite } from "../_tables/sites";
import { createSkillLegend } from "../_tables/skillLegend";
import { createSkill } from "../_tables/skills";
import { createSubposition } from "../_tables/subpositions";
import { createTeam } from "../_tables/teams";
import { createUser } from "../_tables/users";

export const createSwitcher = async (
  resolverName: EResolverName,
  payload: { data: any; password?: string },
) => {
  // Store token into localstorage
  await getToken();

  switch (resolverName) {
    case EResolverName.absence:
      return await createAbsence(payload);
    case EResolverName.category:
      return await createCategory(payload);
    case EResolverName.cellAbsence:
      return await createCellAbsence(payload);
    case EResolverName.cellResult:
      return await createCellResult(payload);
    case EResolverName.cellShift:
      return await createCellShift(payload);
    case EResolverName.clearance:
      return await createClearance(payload);
    case EResolverName.client:
      return await createClient(payload);
    case EResolverName.closure:
      return await createClosure(payload);
    case EResolverName.contract:
      return await createContract(payload);
    case EResolverName.employee:
      return await createEmployee(payload);
    case EResolverName.external_trainer:
      return await createExternalTrainer(payload);
    case EResolverName.factory:
      return await createFactory(payload);
    case EResolverName.filesAttachment:
      return await createFilesAttachment(payload);
    case EResolverName.folder:
      return await createFolder(payload);
    case EResolverName.lclearance:
      return await createLClearance(payload);
    case EResolverName.lposition_closed:
      return await createLPositionClosed(payload);
    case EResolverName.lrestriction:
      return await createLRestriction(payload);
    case EResolverName.lskill:
      return await createLSkill(payload);
    case EResolverName.ltraining:
      return await createLTraining(payload);
    case EResolverName.note:
      return await createNote(payload);
    case EResolverName.position:
      return await createPosition(payload);
    case EResolverName.positionOrder:
      return await createPositionOrder(payload);
    case EResolverName.restriction:
      return await createRestriction(payload);
    case EResolverName.shift:
      return await createShift(payload);
    case EResolverName.site:
      return await createSite(payload);
    case EResolverName.skill:
      return await createSkill(payload);
    case EResolverName.skillLegend:
      return await createSkillLegend(payload);
    case EResolverName.subposition:
      return await createSubposition(payload);
    case EResolverName.team:
      return await createTeam(payload);
    case EResolverName.user:
      return await createUser(payload);
    default:
      break;
  }
};

export const createManySwitcher = async (
  resolverName: EResolverName,
  payload: { data: any; password?: string },
) => {
  // Store token into localstorage
  await getToken();

  try {
    switch (resolverName) {
      case EResolverName.cellAbsence:
        return await createManyCellAbsence(payload);
      case EResolverName.cellResult:
        return await createManyCellResult(payload);
      case EResolverName.cellShift:
        return await createManyCellShift(payload);
      case EResolverName.lposition_closed:
        return await createManyLPositionClosed(payload);
      default:
        break;
    }
  } catch (error) {
    console.error(error);
  }
};
