import type {
  DocumentData,
  DocumentReference,
  DocumentSnapshot,
  OrderByDirection,
  WriteBatch,
} from "firebase/firestore";

import FirestoreHelper, { type ISearchCriteria } from "./FirestoreHelper";

export default class ClientHelper<T> {
  collection_name: string;
  firestoreHelper: FirestoreHelper;

  constructor(collection_name: string) {
    this.firestoreHelper = new FirestoreHelper(collection_name, true);
    this.collection_name = collection_name;
  }

  async add(data): Promise<DocumentReference> {
    const document = await this.firestoreHelper.addData(data);
    return document;
  }

  async addMany(data): Promise<
    | undefined
    | {
      batchArray: WriteBatch[];
      documents: DocumentData[];
    }
  > {
    const documents = await this.firestoreHelper.addDocsWithBatch(data);
    return documents;
  }

  async delete(id: string) {
    await this.firestoreHelper.deleteData(id);
  }

  async deleteMany(ids: string[]): Promise<undefined | WriteBatch[]> {
    const deleteResult = await this.firestoreHelper.deleteDocsWithBatch(ids);
    return deleteResult;
  }

  getCollection() {
    return this.collection_name;
  }

  async getDocument(id: string): Promise<null | T> {
    return await this.firestoreHelper.getDocument<T>(id);
  }

  async getDocumentIdsWithWhere(
    arrayWhere: ISearchCriteria[],
    limit?: number,
    orderBy?: string,
    order?: OrderByDirection,
  ) {
    return this.firestoreHelper.getDocumentIdsWithWhere(
      arrayWhere,
      limit,
      orderBy,
      order,
    );
  }

  async getDocuments(limit?: number, orderBy?: string): Promise<null | T[]> {
    return await this.firestoreHelper.getDocuments<T>(limit, orderBy);
  }

  async getDocumentsWhereWithCount(
    arrayWhere: ISearchCriteria[],
  ): Promise<number> {
    return await this.firestoreHelper.getDocumentsWhereWithCount(arrayWhere);
  }

  async getDocumentsWithStringSearchWhere(
    arrayWhere: ISearchCriteria[],
    fieldString: string,
    searchString: string,
    _limit?: number,
  ) {
    return await this.firestoreHelper.getDocumentsWithStringSearchWhere<T>(
      arrayWhere,
      fieldString,
      searchString,
      _limit,
    );
  }

  async getDocumentsWithWhere(
    arrayWhere: ISearchCriteria[],
    limit?: number,
    orderBy?: string,
    order?: OrderByDirection,
  ): Promise<T[]> {
    return await this.firestoreHelper.getDocumentsWithWhere<T>(
      arrayWhere,
      limit,
      orderBy,
      order,
    );
  }

  async getDocumentsWithWhereWithCount(
    arrayWhere: ISearchCriteria[],
    limit?: number,
    orderBy?: string,
    order?: OrderByDirection,
  ): Promise<{ count: number; items: T[] }> {
    return await this.firestoreHelper.getDocumentsWithWhereWithCount<T>(
      arrayWhere,
      limit,
      orderBy,
      order,
    );
  }

  async getDocumentsWithWhereWithCountWithPagination(
    arrayWhere: ISearchCriteria[],
    orderBy?: string,
    order?: OrderByDirection,
    elementsPerPage?: number,
    startAfterDoc?: DocumentSnapshot,
  ): Promise<{
      count: number;
      items: T[];
      lastDoc: DocumentSnapshot;
    }> {
    return await this.firestoreHelper.getDocumentsWithWhereWithCountWithPagination<T>(
      arrayWhere,
      orderBy,
      order,
      elementsPerPage,
      startAfterDoc,
    );
  }

  getRealTimeWithWhere(
    searchCriteria: ISearchCriteria[],

    callback: any,
    limit?: number,
    orderBy?: string,
  ) {
    return this.firestoreHelper.onSnapshotWhere(
      searchCriteria,
      callback,
      limit,
      orderBy,
    );
  }

  async set(id: string, data): Promise<boolean> {
    await this.firestoreHelper.setData(id, data);
    return true;
  }

  async update(id: string, data: Partial<T>) {
    await this.firestoreHelper.updateData(id, data);
  }

  async updateMany<T extends { id: string }>(
    data: T[],
  ): Promise<undefined | WriteBatch[]> {
    const documents = await this.firestoreHelper.updateDocsWithBatch(data);
    return documents;
  }
}
