var it = Object.defineProperty;
var Ce = (t) => {
  throw TypeError(t);
};
var at = (t, e, r) => e in t ? it(t, e, { enumerable: !0, configurable: !0, writable: !0, value: r }) : t[e] = r;
var F = (t, e, r) => at(t, typeof e != "symbol" ? e + "" : e, r), ge = (t, e, r) => e.has(t) || Ce("Cannot " + r);
var l = (t, e, r) => (ge(t, e, "read from private field"), r ? r.call(t) : e.get(t)), _ = (t, e, r) => e.has(t) ? Ce("Cannot add the same private member more than once") : e instanceof WeakSet ? e.add(t) : e.set(t, r), L = (t, e, r, s) => (ge(t, e, "write to private field"), s ? s.call(t, r) : e.set(t, r), r), w = (t, e, r) => (ge(t, e, "access private method"), r);
import ct, { unref as ut, onMounted as lt, nextTick as Ge, ref as W, readonly as ft, watch as he, getCurrentInstance as Ke, getCurrentScope as ht, onScopeDispose as pt, shallowRef as H, computed as G, isRef as K } from "vue";
import { defineStore as ze } from "pinia";
import { Local as oe, formatDate as Oe, parseDate as dt, noop as V, digest as te, readShield as Fe, dedupe as gt } from "@mercateam/shared-toolkit";
ct.util.warn;
function yt(t) {
  return ht() ? (pt(t), !0) : !1;
}
function Te(t) {
  return typeof t == "function" ? t() : ut(t);
}
const mt = typeof window < "u" && typeof document < "u";
typeof WorkerGlobalScope < "u" && globalThis instanceof WorkerGlobalScope;
const wt = Object.prototype.toString, bt = (t) => wt.call(t) === "[object Object]", vt = () => {
};
function Et(t, e) {
  function r(...s) {
    return new Promise((o, n) => {
      Promise.resolve(t(() => e.apply(this, s), { fn: e, thisArg: this, args: s })).then(o).catch(n);
    });
  }
  return r;
}
const Qe = (t) => t();
function St(t = Qe) {
  const e = W(!0);
  function r() {
    e.value = !1;
  }
  function s() {
    e.value = !0;
  }
  const o = (...n) => {
    e.value && t(...n);
  };
  return { isActive: ft(e), pause: r, resume: s, eventFilter: o };
}
function At(t) {
  return Ke();
}
function Rt(t, e, r = {}) {
  const {
    eventFilter: s = Qe,
    ...o
  } = r;
  return he(
    t,
    Et(
      s,
      e
    ),
    o
  );
}
function Tt(t, e, r = {}) {
  const {
    eventFilter: s,
    ...o
  } = r, { eventFilter: n, pause: a, resume: c, isActive: h } = St(s);
  return { stop: Rt(
    t,
    e,
    {
      ...o,
      eventFilter: n
    }
  ), pause: a, resume: c, isActive: h };
}
function _t(t, e = !0, r) {
  At() ? lt(t, r) : e ? t() : Ge(t);
}
function Ct(t) {
  var e;
  const r = Te(t);
  return (e = r == null ? void 0 : r.$el) != null ? e : r;
}
const pe = mt ? window : void 0;
function Pe(...t) {
  let e, r, s, o;
  if (typeof t[0] == "string" || Array.isArray(t[0]) ? ([r, s, o] = t, e = pe) : [e, r, s, o] = t, !e)
    return vt;
  Array.isArray(r) || (r = [r]), Array.isArray(s) || (s = [s]);
  const n = [], a = () => {
    n.forEach((i) => i()), n.length = 0;
  }, c = (i, f, y, p) => (i.addEventListener(f, y, p), () => i.removeEventListener(f, y, p)), h = he(
    () => [Ct(e), Te(o)],
    ([i, f]) => {
      if (a(), !i)
        return;
      const y = bt(f) ? { ...f } : f;
      n.push(
        ...r.flatMap((p) => s.map((m) => c(i, p, m, y)))
      );
    },
    { immediate: !0, flush: "post" }
  ), u = () => {
    h(), a();
  };
  return yt(u), u;
}
const ne = typeof globalThis < "u" ? globalThis : typeof window < "u" ? window : typeof global < "u" ? global : typeof self < "u" ? self : {}, ie = "__vueuse_ssr_handlers__", Ot = /* @__PURE__ */ Ft();
function Ft() {
  return ie in ne || (ne[ie] = ne[ie] || {}), ne[ie];
}
function Pt(t, e) {
  return Ot[t] || e;
}
function Lt(t) {
  return t == null ? "any" : t instanceof Set ? "set" : t instanceof Map ? "map" : t instanceof Date ? "date" : typeof t == "boolean" ? "boolean" : typeof t == "string" ? "string" : typeof t == "object" ? "object" : Number.isNaN(t) ? "any" : "number";
}
const Dt = {
  boolean: {
    read: (t) => t === "true",
    write: (t) => String(t)
  },
  object: {
    read: (t) => JSON.parse(t),
    write: (t) => JSON.stringify(t)
  },
  number: {
    read: (t) => Number.parseFloat(t),
    write: (t) => String(t)
  },
  any: {
    read: (t) => t,
    write: (t) => String(t)
  },
  string: {
    read: (t) => t,
    write: (t) => String(t)
  },
  map: {
    read: (t) => new Map(JSON.parse(t)),
    write: (t) => JSON.stringify(Array.from(t.entries()))
  },
  set: {
    read: (t) => new Set(JSON.parse(t)),
    write: (t) => JSON.stringify(Array.from(t))
  },
  date: {
    read: (t) => new Date(t),
    write: (t) => t.toISOString()
  }
}, Le = "vueuse-storage";
function $t(t, e, r, s = {}) {
  var o;
  const {
    flush: n = "pre",
    deep: a = !0,
    listenToStorageChanges: c = !0,
    writeDefaults: h = !0,
    mergeDefaults: u = !1,
    shallow: i,
    window: f = pe,
    eventFilter: y,
    onError: p = (E) => {
      console.error(E);
    },
    initOnMounted: m
  } = s, d = (i ? H : W)(e);
  if (!r)
    try {
      r = Pt("getDefaultStorage", () => {
        var E;
        return (E = pe) == null ? void 0 : E.localStorage;
      })();
    } catch (E) {
      p(E);
    }
  if (!r)
    return d;
  const g = Te(e), S = Lt(g), v = (o = s.serializer) != null ? o : Dt[S], { pause: j, resume: N } = Tt(
    d,
    () => re(d.value),
    { flush: n, deep: a, eventFilter: y }
  );
  f && c && _t(() => {
    Pe(f, "storage", se), Pe(f, Le, nt), m && se();
  }), m || se();
  function I(E, R) {
    f && f.dispatchEvent(new CustomEvent(Le, {
      detail: {
        key: t,
        oldValue: E,
        newValue: R,
        storageArea: r
      }
    }));
  }
  function re(E) {
    try {
      const R = r.getItem(t);
      if (E == null)
        I(R, null), r.removeItem(t);
      else {
        const B = v.write(E);
        R !== B && (r.setItem(t, B), I(R, B));
      }
    } catch (R) {
      p(R);
    }
  }
  function ot(E) {
    const R = E ? E.newValue : r.getItem(t);
    if (R == null)
      return h && g != null && r.setItem(t, v.write(g)), g;
    if (!E && u) {
      const B = v.read(R);
      return typeof u == "function" ? u(B, g) : S === "object" && !Array.isArray(B) ? { ...g, ...B } : B;
    } else return typeof R != "string" ? R : v.read(R);
  }
  function se(E) {
    if (!(E && E.storageArea !== r)) {
      if (E && E.key == null) {
        d.value = g;
        return;
      }
      if (!(E && E.key !== t)) {
        j();
        try {
          (E == null ? void 0 : E.newValue) !== v.write(d.value) && (d.value = ot(E));
        } catch (R) {
          p(R);
        } finally {
          E ? Ge(N) : N();
        }
      }
    }
  }
  function nt(E) {
    se(E.detail);
  }
  return d;
}
function jt(t, e, r = {}) {
  const { window: s = pe } = r;
  return $t(t, e, s == null ? void 0 : s.localStorage, r);
}
const Nt = /"(?:_|\\u0{2}5[Ff]){2}(?:p|\\u0{2}70)(?:r|\\u0{2}72)(?:o|\\u0{2}6[Ff])(?:t|\\u0{2}74)(?:o|\\u0{2}6[Ff])(?:_|\\u0{2}5[Ff]){2}"\s*:/, Mt = /"(?:c|\\u0063)(?:o|\\u006[Ff])(?:n|\\u006[Ee])(?:s|\\u0073)(?:t|\\u0074)(?:r|\\u0072)(?:u|\\u0075)(?:c|\\u0063)(?:t|\\u0074)(?:o|\\u006[Ff])(?:r|\\u0072)"\s*:/, It = /^\s*["[{]|^\s*-?\d{1,16}(\.\d{1,17})?([Ee][+-]?\d+)?\s*$/;
function Ut(t, e) {
  if (t === "__proto__" || t === "constructor" && e && typeof e == "object" && "prototype" in e) {
    qt(t);
    return;
  }
  return e;
}
function qt(t) {
  console.warn(`[destr] Dropping "${t}" key to prevent prototype pollution.`);
}
function Wt(t, e = {}) {
  if (typeof t != "string")
    return t;
  if (t[0] === '"' && t[t.length - 1] === '"' && t.indexOf("\\") === -1)
    return t.slice(1, -1);
  const r = t.trim();
  if (r.length <= 9)
    switch (r.toLowerCase()) {
      case "true":
        return !0;
      case "false":
        return !1;
      case "undefined":
        return;
      case "null":
        return null;
      case "nan":
        return Number.NaN;
      case "infinity":
        return Number.POSITIVE_INFINITY;
      case "-infinity":
        return Number.NEGATIVE_INFINITY;
    }
  if (!It.test(t)) {
    if (e.strict)
      throw new SyntaxError("[destr] Invalid JSON");
    return t;
  }
  try {
    if (Nt.test(t) || Mt.test(t)) {
      if (e.strict)
        throw new Error("[destr] Possible prototype pollution");
      return JSON.parse(t, Ut);
    }
    return JSON.parse(t);
  } catch (s) {
    if (e.strict)
      throw s;
    return t;
  }
}
const Bt = /#/g, Jt = /&/g, Ht = /\//g, Vt = /=/g, _e = /\+/g, Gt = /%5e/gi, Kt = /%60/gi, zt = /%7c/gi, Qt = /%20/gi;
function Xt(t) {
  return encodeURI("" + t).replace(zt, "|");
}
function be(t) {
  return Xt(typeof t == "string" ? t : JSON.stringify(t)).replace(_e, "%2B").replace(Qt, "+").replace(Bt, "%23").replace(Jt, "%26").replace(Kt, "`").replace(Gt, "^").replace(Ht, "%2F");
}
function ye(t) {
  return be(t).replace(Vt, "%3D");
}
function Xe(t = "") {
  try {
    return decodeURIComponent("" + t);
  } catch {
    return "" + t;
  }
}
function Yt(t) {
  return Xe(t.replace(_e, " "));
}
function Zt(t) {
  return Xe(t.replace(_e, " "));
}
function xt(t = "") {
  const e = /* @__PURE__ */ Object.create(null);
  t[0] === "?" && (t = t.slice(1));
  for (const r of t.split("&")) {
    const s = r.match(/([^=]+)=?(.*)/) || [];
    if (s.length < 2)
      continue;
    const o = Yt(s[1]);
    if (o === "__proto__" || o === "constructor")
      continue;
    const n = Zt(s[2] || "");
    e[o] === void 0 ? e[o] = n : Array.isArray(e[o]) ? e[o].push(n) : e[o] = [e[o], n];
  }
  return e;
}
function kt(t, e) {
  return (typeof e == "number" || typeof e == "boolean") && (e = String(e)), e ? Array.isArray(e) ? e.map(
    (r) => `${ye(t)}=${be(r)}`
  ).join("&") : `${ye(t)}=${be(e)}` : ye(t);
}
function er(t) {
  return Object.keys(t).filter((e) => t[e] !== void 0).map((e) => kt(e, t[e])).filter(Boolean).join("&");
}
const tr = /^[\s\w\0+.-]{2,}:([/\\]{1,2})/, rr = /^[\s\w\0+.-]{2,}:([/\\]{2})?/, sr = /^([/\\]\s*){2,}[^/\\]/, or = /^\.?\//;
function Ye(t, e = {}) {
  return typeof e == "boolean" && (e = { acceptRelative: e }), e.strict ? tr.test(t) : rr.test(t) || (e.acceptRelative ? sr.test(t) : !1);
}
function nr(t = "", e) {
  return t.endsWith("/");
}
function ir(t = "", e) {
  return (nr(t) ? t.slice(0, -1) : t) || "/";
}
function ar(t = "", e) {
  return t.endsWith("/") ? t : t + "/";
}
function cr(t, e) {
  if (lr(e) || Ye(t))
    return t;
  const r = ir(e);
  return t.startsWith(r) ? t : hr(r, t);
}
function ur(t, e) {
  const r = pr(t), s = { ...xt(r.search), ...e };
  return r.search = er(s), dr(r);
}
function lr(t) {
  return !t || t === "/";
}
function fr(t) {
  return t && t !== "/";
}
function hr(t, ...e) {
  let r = t || "";
  for (const s of e.filter((o) => fr(o)))
    if (r) {
      const o = s.replace(or, "");
      r = ar(r) + o;
    } else
      r = s;
  return r;
}
const Ze = Symbol.for("ufo:protocolRelative");
function pr(t = "", e) {
  const r = t.match(
    /^[\s\0]*(blob:|data:|javascript:|vbscript:)(.*)/i
  );
  if (r) {
    const [, f, y = ""] = r;
    return {
      protocol: f.toLowerCase(),
      pathname: y,
      href: f + y,
      auth: "",
      host: "",
      search: "",
      hash: ""
    };
  }
  if (!Ye(t, { acceptRelative: !0 }))
    return De(t);
  const [, s = "", o, n = ""] = t.replace(/\\/g, "/").match(/^[\s\0]*([\w+.-]{2,}:)?\/\/([^/@]+@)?(.*)/) || [];
  let [, a = "", c = ""] = n.match(/([^#/?]*)(.*)?/) || [];
  s === "file:" && (c = c.replace(/\/(?=[A-Za-z]:)/, ""));
  const { pathname: h, search: u, hash: i } = De(c);
  return {
    protocol: s.toLowerCase(),
    auth: o ? o.slice(0, Math.max(0, o.length - 1)) : "",
    host: a,
    pathname: h,
    search: u,
    hash: i,
    [Ze]: !s
  };
}
function De(t = "") {
  const [e = "", r = "", s = ""] = (t.match(/([^#?]*)(\?[^#]*)?(#.*)?/) || []).splice(1);
  return {
    pathname: e,
    search: r,
    hash: s
  };
}
function dr(t) {
  const e = t.pathname || "", r = t.search ? (t.search.startsWith("?") ? "" : "?") + t.search : "", s = t.hash || "", o = t.auth ? t.auth + "@" : "", n = t.host || "";
  return (t.protocol || t[Ze] ? (t.protocol || "") + "//" : "") + o + n + e + r + s;
}
class gr extends Error {
  constructor(e, r) {
    super(e, r), this.name = "FetchError", r != null && r.cause && !this.cause && (this.cause = r.cause);
  }
}
function yr(t) {
  var h, u, i, f, y;
  const e = ((h = t.error) == null ? void 0 : h.message) || ((u = t.error) == null ? void 0 : u.toString()) || "", r = ((i = t.request) == null ? void 0 : i.method) || ((f = t.options) == null ? void 0 : f.method) || "GET", s = ((y = t.request) == null ? void 0 : y.url) || String(t.request) || "/", o = `[${r}] ${JSON.stringify(s)}`, n = t.response ? `${t.response.status} ${t.response.statusText}` : "<no response>", a = `${o}: ${n}${e ? ` ${e}` : ""}`, c = new gr(
    a,
    t.error ? { cause: t.error } : void 0
  );
  for (const p of ["request", "options", "response"])
    Object.defineProperty(c, p, {
      get() {
        return t[p];
      }
    });
  for (const [p, m] of [
    ["data", "_data"],
    ["status", "status"],
    ["statusCode", "status"],
    ["statusText", "statusText"],
    ["statusMessage", "statusText"]
  ])
    Object.defineProperty(c, p, {
      get() {
        return t.response && t.response[m];
      }
    });
  return c;
}
const mr = new Set(
  Object.freeze(["PATCH", "POST", "PUT", "DELETE"])
);
function $e(t = "GET") {
  return mr.has(t.toUpperCase());
}
function wr(t) {
  if (t === void 0)
    return !1;
  const e = typeof t;
  return e === "string" || e === "number" || e === "boolean" || e === null ? !0 : e !== "object" ? !1 : Array.isArray(t) ? !0 : t.buffer ? !1 : t.constructor && t.constructor.name === "Object" || typeof t.toJSON == "function";
}
const br = /* @__PURE__ */ new Set([
  "image/svg",
  "application/xml",
  "application/xhtml",
  "application/html"
]), vr = /^application\/(?:[\w!#$%&*.^`~-]*\+)?json(;.+)?$/i;
function Er(t = "") {
  if (!t)
    return "json";
  const e = t.split(";").shift() || "";
  return vr.test(e) ? "json" : br.has(e) || e.startsWith("text/") ? "text" : "blob";
}
function Sr(t, e, r, s) {
  const o = Ar(
    (e == null ? void 0 : e.headers) ?? (t == null ? void 0 : t.headers),
    r == null ? void 0 : r.headers,
    s
  );
  let n;
  return (r != null && r.query || r != null && r.params || e != null && e.params || e != null && e.query) && (n = {
    ...r == null ? void 0 : r.params,
    ...r == null ? void 0 : r.query,
    ...e == null ? void 0 : e.params,
    ...e == null ? void 0 : e.query
  }), {
    ...r,
    ...e,
    query: n,
    params: n,
    headers: o
  };
}
function Ar(t, e, r) {
  if (!e)
    return new r(t);
  const s = new r(e);
  if (t)
    for (const [o, n] of Symbol.iterator in t || Array.isArray(t) ? t : new r(t))
      s.set(o, n);
  return s;
}
async function ae(t, e) {
  if (e)
    if (Array.isArray(e))
      for (const r of e)
        await r(t);
    else
      await e(t);
}
const Rr = /* @__PURE__ */ new Set([
  408,
  // Request Timeout
  409,
  // Conflict
  425,
  // Too Early (Experimental)
  429,
  // Too Many Requests
  500,
  // Internal Server Error
  502,
  // Bad Gateway
  503,
  // Service Unavailable
  504
  // Gateway Timeout
]), Tr = /* @__PURE__ */ new Set([101, 204, 205, 304]);
function xe(t = {}) {
  const {
    fetch: e = globalThis.fetch,
    Headers: r = globalThis.Headers,
    AbortController: s = globalThis.AbortController
  } = t;
  async function o(c) {
    const h = c.error && c.error.name === "AbortError" && !c.options.timeout || !1;
    if (c.options.retry !== !1 && !h) {
      let i;
      typeof c.options.retry == "number" ? i = c.options.retry : i = $e(c.options.method) ? 0 : 1;
      const f = c.response && c.response.status || 500;
      if (i > 0 && (Array.isArray(c.options.retryStatusCodes) ? c.options.retryStatusCodes.includes(f) : Rr.has(f))) {
        const y = typeof c.options.retryDelay == "function" ? c.options.retryDelay(c) : c.options.retryDelay || 0;
        return y > 0 && await new Promise((p) => setTimeout(p, y)), n(c.request, {
          ...c.options,
          retry: i - 1
        });
      }
    }
    const u = yr(c);
    throw Error.captureStackTrace && Error.captureStackTrace(u, n), u;
  }
  const n = async function(h, u = {}) {
    const i = {
      request: h,
      options: Sr(
        h,
        u,
        t.defaults,
        r
      ),
      response: void 0,
      error: void 0
    };
    i.options.method && (i.options.method = i.options.method.toUpperCase()), i.options.onRequest && await ae(i, i.options.onRequest), typeof i.request == "string" && (i.options.baseURL && (i.request = cr(i.request, i.options.baseURL)), i.options.query && (i.request = ur(i.request, i.options.query), delete i.options.query), "query" in i.options && delete i.options.query, "params" in i.options && delete i.options.params), i.options.body && $e(i.options.method) && (wr(i.options.body) ? (i.options.body = typeof i.options.body == "string" ? i.options.body : JSON.stringify(i.options.body), i.options.headers = new r(i.options.headers || {}), i.options.headers.has("content-type") || i.options.headers.set("content-type", "application/json"), i.options.headers.has("accept") || i.options.headers.set("accept", "application/json")) : (
      // ReadableStream Body
      ("pipeTo" in i.options.body && typeof i.options.body.pipeTo == "function" || // Node.js Stream Body
      typeof i.options.body.pipe == "function") && ("duplex" in i.options || (i.options.duplex = "half"))
    ));
    let f;
    if (!i.options.signal && i.options.timeout) {
      const p = new s();
      f = setTimeout(() => {
        const m = new Error(
          "[TimeoutError]: The operation was aborted due to timeout"
        );
        m.name = "TimeoutError", m.code = 23, p.abort(m);
      }, i.options.timeout), i.options.signal = p.signal;
    }
    try {
      i.response = await e(
        i.request,
        i.options
      );
    } catch (p) {
      return i.error = p, i.options.onRequestError && await ae(
        i,
        i.options.onRequestError
      ), await o(i);
    } finally {
      f && clearTimeout(f);
    }
    if ((i.response.body || // https://github.com/unjs/ofetch/issues/324
    // https://github.com/unjs/ofetch/issues/294
    // https://github.com/JakeChampion/fetch/issues/1454
    i.response._bodyInit) && !Tr.has(i.response.status) && i.options.method !== "HEAD") {
      const p = (i.options.parseResponse ? "json" : i.options.responseType) || Er(i.response.headers.get("content-type") || "");
      switch (p) {
        case "json": {
          const m = await i.response.text(), d = i.options.parseResponse || Wt;
          i.response._data = d(m);
          break;
        }
        case "stream": {
          i.response._data = i.response.body || i.response._bodyInit;
          break;
        }
        default:
          i.response._data = await i.response[p]();
      }
    }
    return i.options.onResponse && await ae(
      i,
      i.options.onResponse
    ), !i.options.ignoreResponseError && i.response.status >= 400 && i.response.status < 600 ? (i.options.onResponseError && await ae(
      i,
      i.options.onResponseError
    ), await o(i)) : i.response;
  }, a = async function(h, u) {
    return (await n(h, u))._data;
  };
  return a.raw = n, a.native = (...c) => e(...c), a.create = (c = {}, h = {}) => xe({
    ...t,
    ...h,
    defaults: {
      ...t.defaults,
      ...h.defaults,
      ...c
    }
  }), a;
}
const de = function() {
  if (typeof globalThis < "u")
    return globalThis;
  if (typeof self < "u")
    return self;
  if (typeof window < "u")
    return window;
  if (typeof global < "u")
    return global;
  throw new Error("unable to locate global object");
}(), _r = de.fetch ? (...t) => de.fetch(...t) : () => Promise.reject(new Error("[ofetch] global.fetch is not supported!")), Cr = de.Headers, Or = de.AbortController, Fr = xe({ fetch: _r, Headers: Cr, AbortController: Or }), Pr = "apollo-token", je = "VITE_", Lr = ze("env", () => {
  const t = W({});
  return { $reset: () => {
    t.value = {};
  }, getVariable: (n) => {
    if (!t.value[n])
      throw new Error(`Environment variable ${n} not found`);
    return t.value[n];
  }, setEnv: (n) => {
    t.value = Object.entries(n).filter(([a, c]) => a.startsWith(je)).reduce((a, [c, h]) => {
      const u = c.replace(je, "");
      return a[u] = h, a;
    }, {});
  }, setMockData: ({ env: n }) => {
    t.value = n;
  } };
}), ke = () => {
  const t = jt(Pr, null), { getVariable: e } = Lr(), r = e("BACKEND_URL"), s = (h) => `${r}${h}`, o = G(() => ({
    Accept: "application/json",
    Authorization: `Bearer ${t.value}`,
    "Content-Type": "application/json"
  })), n = Fr.create({
    baseURL: r,
    mode: "cors"
  });
  return {
    buildUrl: s,
    fetchGetRequest: ({
      parameters: h = {},
      url: u
    }) => {
      const i = new AbortController(), f = i.signal;
      return Object.assign(
        n(u, {
          headers: o.value,
          method: "GET",
          query: h ?? {},
          signal: f
        }).then(({ data: y }) => y),
        { abort: i.abort }
      );
    },
    fetchPostRequest: ({
      parameters: h = {},
      url: u
    }) => {
      const i = new AbortController(), f = i.signal;
      return Object.assign(
        n(u, {
          body: h,
          headers: o.value,
          method: "POST",
          signal: f
        }).then(({ data: y }) => y),
        { abort: i.abort }
      );
    }
  };
}, Dr = async () => await ke().fetchGetRequest({
  url: "/user-settings"
}), $r = ze("locale", () => {
  const t = W(navigator.language), e = W(oe.dateFormats["dd/MM/yyyy"]), r = W(oe.timeFormats["HH:mm"]);
  return (async () => {
    const o = await Dr();
    e.value = o.dateFormat, t.value = o.language, ["en", "en-US"].includes(t.value) ? r.value = oe.timeFormats["h:mm aaa"] : r.value = oe.timeFormats["HH:mm"];
  })(), { dateFormat: e, locale: t, timeFormat: r };
}), Qr = () => {
  const t = $r(), e = G(() => t.locale), r = G(() => t.dateFormat), s = G(() => t.timeFormat), o = (h, u) => Oe({
    format: u ?? t.dateFormat,
    locale: t.locale
  })(h), n = Oe({
    format: t.timeFormat,
    locale: t.locale
  }), a = (h, u) => u(o(h), n(h)), c = dt({
    format: t.dateFormat,
    locale: t.locale
  });
  return {
    currentDateFormat: r,
    currentLocale: e,
    currentTimeFormat: s,
    formatDate: o,
    formatDateTime: a,
    formatTime: n,
    parseDate: c
  };
}, Xr = (t = "") => {
  const e = Ke();
  if (!(e != null && e.proxy))
    throw new Error(
      "[i18n] useI18n must be called within a component setup function"
    );
  const r = e.proxy.$i18n;
  if (!r)
    throw new Error("[i18n] vue-i18n instance not found");
  const s = G(() => r.locale), o = (n) => t ? `${t}.${n}` : n;
  return {
    locale: s,
    t: (n, a) => r.t(o(n), a),
    tc: (n, a, c) => r.tc(o(n), a, c)
  };
}, Yr = (t) => {
  const { fetchGetRequest: e } = ke(), r = W(!1), s = W(void 0), o = W(null), n = (h) => {
    if (h.size > 1e7) throw new Error("Limit size exceed");
    return h;
  }, a = async (h) => {
    try {
      r.value = !0;
      const u = h;
      if (!u) return;
      o.value = URL.createObjectURL(u), await c(u);
    } finally {
      r.value = !1;
    }
  }, c = async (h) => {
    try {
      const u = n(h), { signedUrl: i, temporaryUrl: f } = await e({
        parameters: {
          fileName: t ? `${t}/${u.name}` : u.name
        },
        url: "/authentication/uploader"
      });
      if (!(await fetch(i, {
        body: u,
        headers: {
          "Content-Type": "application/octet-stream"
        },
        method: "PUT"
      })).ok)
        throw Error("Erreur lors de l’upload sur GCS");
      return s.value = f, f;
    } catch (u) {
      throw new Error(u);
    }
  };
  return {
    handleFileChangeAndUpload: a,
    loading: G(() => r.value),
    tempImage: G(() => o.value),
    tempURL: G(() => s.value),
    uploadFile: c
  };
};
class jr {
  constructor(e) {
    F(this, "code", "ERR");
    F(this, "detail", "Error");
    F(this, "origin");
    e && typeof e == "object" && ("code" in e && (this.code = String(e.code)), "detail" in e && (this.detail = String(e.detail))), e instanceof Error && (this.detail = e.message, Object.defineProperty(this, "stack", {
      value: e.stack
    }), this.origin = e);
  }
}
function J(t, e) {
  var o, n, a, c, h, u, i, f, y;
  const r = (p) => {
    var m;
    (m = p[Symbol.dispose]) == null || m.call(p);
  }, s = (p) => {
    p(t), r(p);
  };
  switch (t.status.value) {
    case "initial":
      break;
    case "loading": {
      (o = e.loading) == null || o.forEach(s);
      break;
    }
    case "success": {
      (n = e.succeed) == null || n.forEach(s), (a = e.finished) == null || a.forEach(s), (c = e.failed) == null || c.forEach(r);
      break;
    }
    case "error": {
      (h = e.succeed) == null || h.forEach(s), (u = e.finished) == null || u.forEach(s), (i = e.succeed) == null || i.forEach(r);
      break;
    }
    case "disposed": {
      (f = e.disposed) == null || f.forEach(s);
      for (const p in e)
        (y = e[p]) == null || y.forEach(r);
      break;
    }
    default:
      return t.status.value;
  }
}
var T, D, C, P;
class ce {
  constructor(e, r) {
    _(this, C);
    _(this, T, {
      succeed: [],
      finished: [],
      loading: [],
      failed: [],
      disposed: []
    });
    _(this, D);
    F(this, "abort", V);
    F(this, "data", H(null));
    F(this, "errors", H([]));
    F(this, "isError", H(!1));
    F(this, "isLoading", H(!0));
    F(this, "isSuccess", H(!1));
    F(this, "meta", H({}));
    F(this, "status", H("initial"));
    e != null && this.toggleSuccess(e), r && L(this, D, r);
  }
  once(e, r) {
    return w(this, C, P).call(this, !0), new Promise((s, o) => {
      let n;
      r ? n = (a) => {
        let c;
        try {
          c = r(a);
        } catch (h) {
          o(h);
          return;
        }
        s(c);
      } : n = (a) => {
        s(a);
      }, Object.defineProperty(n, Symbol.dispose, {
        value: () => {
          l(this, T)[e] = l(this, T)[e].filter(
            (a) => a !== n
          );
        }
      }), l(this, T)[e].push(n), J(this, { [e]: [n] });
    });
  }
  on(e, r) {
    w(this, C, P).call(this, !0);
    const s = (a) => {
      r.call(n, a);
    };
    l(this, T)[e].push(s);
    const o = () => {
      l(this, T)[e] = l(this, T)[e].filter((a) => a !== s);
    }, n = { [Symbol.dispose]: o };
    return J(this, { [e]: [s] }), n;
  }
  toPromise() {
    return w(this, C, P).call(this, !0), new Promise((e, r) => {
      this.on("finished", function(s) {
        var o;
        this[Symbol.dispose](), s.status.value === "success" ? e(s.data.value) : r(new Error((o = s.errors.value[0]) == null ? void 0 : o.detail));
      });
    });
  }
  setMeta(e) {
    return w(this, C, P).call(this, !0), this.meta.value = e, this;
  }
  toggleInitial() {
    return w(this, C, P).call(this) && this.status.value !== "initial" && (this.status.value = "initial", this.isLoading.value = !1, this.isSuccess.value = !1, this.isError.value = !1, this.data.value = null, J(this, l(this, T))), this;
  }
  toggleLoading(e) {
    return w(this, C, P).call(this) && (this.abort = () => {
      this.toggleInitial(), e && "abort" in e && typeof e.abort == "function" && e.abort();
    }, this.status.value !== "loading" && (this.isLoading.value = !0, this.isSuccess.value = !1, this.isError.value = !1, this.errors.value = [], this.data.value = null, this.status.value = "loading", J(this, l(this, T)))), this;
  }
  toggleSuccess(e) {
    return w(this, C, P).call(this) && (this.status.value !== "success" ? (this.status.value === "initial" && this.toggleLoading(), this.abort = V, this.isLoading.value = !1, this.isSuccess.value = !0, this.isError.value = !1, this.errors.value = [], this.status.value = "success", this.data.value = e, J(this, l(this, T))) : this.data.value !== e && (this.data.value = e, J(this, l(this, T)))), this;
  }
  toggleError(e) {
    return e && this.pushError(e), w(this, C, P).call(this) && this.status.value !== "error" && (this.status.value === "initial" && this.toggleLoading(), this.abort = V, this.isLoading.value = !1, this.isSuccess.value = !1, this.isError.value = !0, this.data.value = null, this.status.value = "error", J(this, l(this, T))), this;
  }
  pushError(e) {
    return w(this, C, P).call(this) && this.errors.value.push(new jr(e)), this;
  }
  dispose() {
    var e, r;
    this.status.value !== "disposed" && (this.status.value = "disposed", this.abort = V, this.isLoading.value = !1, this.isSuccess.value = !1, this.isError.value = !1, (r = (e = l(this, D)) == null ? void 0 : e.dispose) == null || r.call(e), this.data.value = null, J(this, l(this, T)));
  }
  rebuild() {
    var e;
    w(this, C, P).call(this, !0), (e = l(this, D)) != null && e.rebuild && (this.toggleInitial(), l(this, D).rebuild());
  }
  refresh() {
    var e, r;
    w(this, C, P).call(this, !0), (e = l(this, D)) != null && e.release && ((r = l(this, D)) != null && r.rebuild) && (l(this, D).release(), this.toggleInitial(), l(this, D).rebuild());
  }
}
T = new WeakMap(), D = new WeakMap(), C = new WeakSet(), P = function(e = !1) {
  if (this.status.value === "disposed") {
    if (e)
      throw new Error("Resource disposed");
    return !1;
  }
  return !0;
};
var X, Y, M;
class x {
  constructor(e, r, s) {
    _(this, X);
    _(this, Y);
    _(this, M);
    L(this, X, e), L(this, M, s ?? []), L(this, Y, r);
  }
  get entity() {
    return l(this, X);
  }
  addLink(e) {
    l(this, M).includes(e) || l(this, M).push(e);
  }
  isValid() {
    return Date.now() < l(this, Y);
  }
  unlink(e) {
    for (let r = 0; r < l(this, M).length; r += 1)
      if (l(this, M)[r] === e) {
        l(this, M).splice(r, 1);
        break;
      }
    return l(this, M).length;
  }
  update(e, r) {
    return L(this, X, e), L(this, Y, r), this;
  }
}
X = new WeakMap(), Y = new WeakMap(), M = new WeakMap();
const le = (t) => JSON.stringify((K(t) ? t.value : t) ?? null), $ = (t, e) => `${t}:${te(e.map(le))}`, et = (t, e) => {
  if (typeof e == "string")
    return t.indexOf(e);
  e: for (let r = 0; r < t.length; r += 1) {
    const s = t[r];
    if (typeof s != "string" && s[0] === e[0]) {
      for (let o = 0; o < s[1].length; o += 1)
        if (s[1][o] !== e[1][o]) continue e;
      return r;
    }
  }
  return -1;
}, Ne = (t, e) => {
  et(t, e) === -1 && t.push(e);
}, Nr = (t, e) => {
  const r = et(t, e);
  r !== -1 && t.splice(r, 1);
}, Me = (t, e) => {
  for (let r = 0; r < t.length; ) {
    const s = t[r];
    (typeof s == "string" ? s : $(...s)) === e ? r += 1 : t.splice(r, 1);
  }
};
var A, Z, U, O, z, q, b, ve, k, Ee, tt, fe, rt, ee, Se, Ae;
class Ie {
  constructor(e) {
    _(this, b);
    _(this, A);
    _(this, Z);
    _(this, U, []);
    _(this, O, /* @__PURE__ */ new Map());
    _(this, z, /* @__PURE__ */ new WeakMap());
    _(this, q, /* @__PURE__ */ new Map());
    L(this, A, { ...e ?? {} }), l(this, A).ttl > 0 || (l(this, A).ttl = 0), l(this, A).setTimeout || (l(this, A).setTimeout = setTimeout.bind(globalThis)), l(this, A).clearTimeout || (l(this, A).clearTimeout = clearTimeout.bind(globalThis)), l(this, A).dateProvider || (l(this, A).dateProvider = () => Date.now());
  }
  has(e) {
    return l(this, O).has(
      typeof e == "string" ? e : $(...e)
    );
  }
  get(e, r) {
    if (typeof e == "string") {
      if (!l(this, q).has(e)) {
        const a = r();
        return l(this, q).set(e, a), a.status.value === "success" ? (w(this, b, k).call(this, e, e, a.data.value), a) : (w(this, b, ve).call(this, e, e, a), a);
      }
      return l(this, q).get(e);
    }
    const { created: s, resource: o } = w(this, b, fe).call(this, e[1], r), n = $(...e);
    return s && o.status.value === "success" ? (w(this, b, k).call(this, e, n, o.data.value), o) : (w(this, b, ve).call(this, e, n, o), o);
  }
  set(e, r) {
    if (typeof e == "string")
      return w(this, b, k).call(this, e, e, r), this;
    const s = $(...e);
    w(this, b, k).call(this, e, s, r);
    const o = w(this, b, tt).call(this, e);
    return o && o !== s && w(this, b, rt).call(this, o, e), this;
  }
  unset(e) {
    const r = typeof e == "string" ? e : $(...e), s = l(this, O).get(r);
    if (s) {
      l(this, O).delete(r);
      const o = (n) => {
        n.rebuild();
      };
      Me(s.refs, r);
      for (const n of s.refs)
        w(this, b, Ee).call(this, n, o);
      return !0;
    }
    return !1;
  }
  delete(e) {
    typeof e == "string" ? (l(this, q).delete(e), w(this, b, ee).call(this, e, e)) : w(this, b, ee).call(this, $(...e), e);
  }
  clear() {
    L(this, z, /* @__PURE__ */ new WeakMap()), l(this, q).clear(), l(this, O).clear();
  }
}
A = new WeakMap(), Z = new WeakMap(), U = new WeakMap(), O = new WeakMap(), z = new WeakMap(), q = new WeakMap(), b = new WeakSet(), ve = function(e, r, s) {
  const o = l(this, O).get(r);
  o ? (Ne(o.refs, e), "value" in o && s.toggleSuccess(o.value)) : l(this, O).set(r, { refs: [e] });
}, k = function(e, r, s) {
  if (l(this, O).has(r)) {
    const o = l(this, O).get(r);
    o.value = s, Ne(o.refs, e);
    const n = (a) => {
      a.toggleSuccess(s);
    };
    Me(o.refs, r);
    for (const a of o.refs)
      w(this, b, Ee).call(this, a, n);
  } else
    l(this, O).set(r, { refs: [e], value: s });
}, Ee = function(e, r) {
  if (typeof e == "string") {
    const s = l(this, q).get(e);
    s && r(s);
  } else {
    let s = { next: l(this, z) };
    for (const o of e[1]) {
      if (s != null && s.next) {
        s = s.next.get(o);
        continue;
      }
      break;
    }
    s != null && s.resource && r(s == null ? void 0 : s.resource);
  }
}, tt = function(e) {
  if (typeof e == "string") return e;
  const r = e[1].reduce(
    ([s, o], n) => {
      if (!s) return [null, null];
      const a = s.get(n);
      if (!a) return [null, null];
      const c = a.value;
      return a.value = le(n.value), [a.next ?? null, [...o || [], c]];
    },
    [l(this, z), null]
  )[1];
  return r === null ? null : `${e[0]}:${te(r)}`;
}, fe = function(e, r, s = l(this, z)) {
  const o = s.get(e[0]);
  if (!o) {
    if (e.length === 1) {
      const a = r();
      return s.set(e[0], { resource: a, value: le(e[0]) }), { created: !0, resource: a };
    }
    const n = /* @__PURE__ */ new WeakMap();
    return s.set(e[0], { next: n, value: le(e[0]) }), w(this, b, fe).call(this, e.slice(1), r, n);
  }
  return e.length === 1 ? (o.resource || (o.resource = r()), { created: !1, resource: o.resource }) : (o.next || (o.next = /* @__PURE__ */ new WeakMap()), w(this, b, fe).call(this, e.slice(1), r, o.next));
}, rt = function(e, r) {
  if (l(this, A).ttl === 0) {
    w(this, b, ee).call(this, e, r);
    return;
  }
  const s = l(this, A).dateProvider() + l(this, A).ttl;
  l(this, U).push([s, r, e]), w(this, b, Se).call(this);
}, ee = function(e, r) {
  var o, n;
  const s = l(this, O).get(e);
  s && (Nr(s.refs, r), s.refs.length === 0 && (l(this, O).delete(e), "value" in s && ((n = (o = l(this, A)).dispose) == null || n.call(o, s.value, r, "expire"))));
}, Se = function() {
  var e;
  if (!l(this, Z) && l(this, A).ttl && l(this, U).length > 0) {
    const r = l(this, A).dateProvider(), s = (((e = l(this, U)[0]) == null ? void 0 : e[0]) ?? r) - r;
    s > 0 ? L(this, Z, l(this, A).setTimeout(() => {
      w(this, b, Ae).call(this);
    }, s)) : w(this, b, Ae).call(this);
  }
}, Ae = function() {
  L(this, Z, void 0);
  const e = l(this, A).dateProvider();
  for (; l(this, U).length > 0; ) {
    const [r, s, o] = l(this, U)[0];
    if (e < r) break;
    l(this, U).shift(), w(this, b, ee).call(this, o, s);
  }
  w(this, b, Se).call(this);
};
const me = (t) => Object.prototype.toString.call(t) === "[object Object]", Ue = (t) => t.reduce((e, r) => K(r) && r.value === void 0 ? !0 : e, !1), ue = (t, e) => {
  const r = t.status.value;
  if (r === "success") return t;
  if (r !== "loading" && e) {
    const s = e((o) => (t.toggleLoading(o), o));
    s && s.catch((o) => t.toggleError(o));
  }
  return t;
}, qe = (t, e, r, s = !1) => (...o) => t(...o).then((n) => (n && (s ? e.set(
  n.id,
  new x(n, r, [`id:${n.id}`])
) : e.set(n.id, new x(n, r))), n)), Mr = {
  has: () => !1,
  get: V,
  set: V,
  delete: V,
  clear: V
}, Ir = (t, e) => ({
  has: (r) => t.has(r),
  get: (r) => {
    var s, o;
    if (t.has(r))
      return new x(
        Q((s = t.get(r)) == null ? void 0 : s.entity, (o = e.get(r)) == null ? void 0 : o.entity),
        0
      );
  },
  set: (r, s) => t.set(r, s),
  delete: (r) => {
    t.delete(r), e.delete(r);
  },
  clear: () => {
    t.clear(), e.clear();
  }
}), Q = (t, e) => {
  if (!t || typeof t != "object") return e;
  if (me(e)) {
    const r = { ...t };
    for (const s in e) {
      const o = s;
      if (!(o in t))
        r[s] = e[s];
      else if (!me(e[s]))
        t[o] === e[s] ? r[s] = e[s] : Array.isArray(t[o]) && Array.isArray(e[s]) && (r[s] = Q(t[o], e[s]));
      else if (s in t) {
        const n = t[o];
        me(n) ? r[s] = Q(n, e[s]) : r[s] = e[s];
      }
    }
    return r;
  }
  if (Array.isArray(t) && Array.isArray(e)) {
    if (t === e)
      return t;
    const r = Math.max(t.length, e.length), s = Array.from({ length: r });
    for (let o = 0; o < r; o += 1)
      s[o] = Q(t[o], e[o]);
    return s;
  }
  return t;
}, Ur = (t, e) => {
  const r = [];
  for (let s = t; s != null && (r.unshift(s), s !== e); s = s.parent)
    ;
  return r;
}, qr = async (t, e) => {
  let r = t;
  for (; e.length > 0; ) {
    const s = e.shift();
    s && r && (r = await s.apply(r), s.validator && (r = s.validator.parse(r)));
  }
  return r;
}, we = async (t, e, r) => {
  const s = Ur(e, r), o = [], n = t.map((a, c) => {
    const h = e.entities.get(a.id);
    return h ? h.entity : (o.push(
      qr(a, s.slice()).then((u) => {
        n[c] = u;
      })
    ), null);
  });
  return o.length === 0 || await Promise.all(o), n;
}, Wr = (t, e) => async (r) => {
  const [s, o] = await Promise.all([t(r), e(r)]);
  return Q(s, o);
}, We = (t, e, r) => {
  const s = (o) => {
    const n = e(o);
    return "toPromise" in n ? n.toPromise() : n;
  };
  return async (o) => {
    const n = t(o);
    if (n === void 0) return o;
    const c = await (Array.isArray(n) ? Promise.all(n.map((u) => s(u))) : s(n)).then(
      (u) => u && r(u, o)
    );
    return Q(o, c);
  };
}, Be = (t, e, r, s, o) => {
  for (let n = 0; n < e.length; n += 1) {
    const a = e[n];
    if (!a) continue;
    const c = t.get(a.id);
    if (c)
      if (c.isValid()) {
        if (e[n] = c.entity, o && te(c.entity) !== te(a))
          throw new Error("please dont update store without knowing it");
        c.addLink(r);
      } else
        c.update(e[n], s).addLink(r);
    else
      t.set(a.id, new x(a, s, [r]));
  }
}, Je = (t) => (e, r, s) => {
  Re(t, e, r);
}, Re = (t, e, r) => {
  if (!e) return;
  const s = Array.isArray(e) ? e : e ? [e] : [], o = typeof r == "string" ? r : $(...r);
  for (const n of s)
    st(t, n.id, o);
}, st = (t, e, r) => {
  const s = t.entities.get(e);
  if (!s) return;
  s.unlink(r) === 0 && t.entities.delete(e), t.parent && st(t.parent, e, `id:${e}`);
}, He = (t) => t.map(
  (e) => JSON.parse(JSON.stringify(K(e) ? e.value : e) ?? "null")
), Zr = (t) => Br(t), Br = (t) => {
  const e = [], r = t.ttl ?? 1e3, s = t.safeMode ?? !1, o = () => Date.now() + r, n = () => {
    for (const u of e)
      u.clear();
  }, a = (u, i) => ({
    addDependencies(f) {
      const [y, p] = f({
        from: (g) => ({
          resolve: (S) => ({
            mergeBy: (v) => [g, S, v]
          })
        })
      }).reduceRight(
        ([g, S], [v, j, N]) => {
          const I = /* @__PURE__ */ new Map();
          return e.push(I), [
            Ir(I, g),
            Wr(
              qe(
                We(v, j, N),
                I,
                o()
              ),
              S
            )
          ];
        },
        [Mr, () => Promise.resolve(null)]
      ), m = new Ie({
        dispose: (g, S, v) => {
          Je(d)(g, S, v);
        },
        ttl: r
      }), d = {
        parent: u,
        entities: y,
        resources: m,
        apply: p
      };
      return e.push(m), a(d, {
        ...i
      });
    },
    addCaseDependency(f, y) {
      throw new Error("Not yet implemented");
    },
    addByIds(f) {
      if (Object.keys(i).length > 0)
        throw new Error("Must be first method");
      const y = Fe(
        (m) => f(m.map(({ id: d }) => d)),
        { cache: 0 }
      ), p = qe(
        We(
          (m) => ({ id: m.id }),
          y,
          (m) => m
        ),
        u.entities,
        o(),
        !0
      );
      return a(
        { ...u, apply: p },
        {
          getById: function m(d) {
            const g = `id:${d}`;
            return ue(
              this.resources.get(
                g,
                () => {
                  var S;
                  return new ce(
                    ((S = this.entities.get(d)) == null ? void 0 : S.entity) ?? null,
                    {
                      rebuild: () => m.call(this, d),
                      dispose: () => {
                        this.resources.delete(g);
                      }
                    }
                  );
                }
              ),
              (S) => {
                const v = this.entities.get(d);
                return v ? (v.addLink(g), Promise.resolve(v.entity)) : S(we([{ id: d }], this, null)).then(
                  (j) => {
                    const N = j[0];
                    return this.resources.set(g, N), N;
                  }
                );
              }
            );
          }
        }
      );
    },
    addSeeker(f, y) {
      if (f in i)
        throw new Error("Can not override an existing method");
      const p = gt(y);
      return a(u, {
        ...i,
        [f]: function m(...d) {
          const g = K(d[0]) ? [f, d] : $(f, d);
          return ue(
            this.resources.get(g, () => {
              const S = new ce(null, {
                rebuild: () => m.apply(this, d),
                release: () => {
                  Re(this, S.data.value, g), this.resources.unset(g);
                },
                dispose: () => {
                  this.resources.delete(g);
                }
              }), v = d.filter(K);
              if (v.length > 0) {
                const j = he(v, () => {
                  S.rebuild();
                });
                S.once("disposed", () => {
                  j();
                });
              }
              return S;
            }),
            (S) => {
              if (!Ue(d))
                return S(
                  p.apply(this, He(d))
                ).then((v) => {
                  const j = Array.isArray(v), N = j ? v : [v];
                  return Be(
                    u.entities,
                    N,
                    typeof g == "string" ? g : $(...g),
                    o(),
                    s
                  ), we(N, this, u).then(
                    (I) => {
                      const re = j ? I : I[0];
                      return this.resources.set(g, re), re;
                    }
                  );
                });
            }
          );
        }
      });
    },
    addFetcher(f, y) {
      if (f in i)
        throw new Error("Can not override an existing method");
      const p = Fe(y.resolve, {
        cache: 0,
        partitionBy: (m) => te(y.groupBy(m)),
        unwrap: y.unwrap
      });
      return a(u, {
        ...i,
        [f]: function m(d) {
          const g = K(d) ? [f, [d]] : $(f, [d]);
          return ue(
            this.resources.get(g, () => {
              const S = new ce(null, {
                rebuild: () => m.call(this, d),
                release: () => {
                  Re(this, S.data.value, g);
                },
                dispose: () => {
                  this.resources.delete(g);
                }
              });
              if (K(d)) {
                const v = he(d, () => {
                  S.rebuild();
                });
                S.once("disposed", v);
              }
              return S;
            }),
            (S) => {
              if (!Ue([d]))
                return S(p(He([d])[0])).then((v) => (v && Be(
                  u.entities,
                  v,
                  typeof g == "string" ? g : $(...g),
                  o(),
                  s
                ), we(v ?? [], this, u))).then((v) => (this.resources.set(g, v), v));
            }
          );
        }
      });
    },
    build() {
      var y;
      const f = {
        context: u,
        clear: n,
        prefillIfAbsent: (p) => {
          let m = 0;
          for (const d of p) {
            const g = `id:${d.id}`;
            ue(
              u.resources.get(g, () => (m += 1, u.entities.set(
                d.id,
                new x(d, o(), [g])
              ), new ce(d, {
                rebuild: () => {
                  "getById" in f && typeof f.getById == "function" && f.getById(d.id);
                },
                dispose: () => {
                  u.resources.delete(g);
                }
              })))
            );
          }
          return m;
        }
      };
      for (const p in i)
        f[p] = (y = i[p]) == null ? void 0 : y.bind(u);
      return f;
    }
  }), c = new Jr(t.cache), h = new Ie({
    dispose: (u, i, f) => {
      Je({ entities: c, parent: null })(
        u,
        i,
        f
      );
    },
    ttl: r
  });
  return e.push(c, h), a(
    {
      parent: null,
      entities: c,
      resources: h,
      apply: (u) => (c.get(u.id) || c.set(u.id, new x(u, o())), Promise.resolve(u))
    },
    {}
  );
};
class Jr extends Map {
  constructor(r) {
    super();
    F(this, "exposedMap");
    r && (this.exposedMap = r);
  }
  clear() {
    var r;
    (r = this.exposedMap) == null || r.clear(), super.clear();
  }
  delete(r) {
    var s;
    return (s = this.exposedMap) == null || s.delete(r), super.delete(r);
  }
  set(r, s) {
    var o;
    return (o = this.exposedMap) == null || o.set(r, s.entity), super.set(r, s);
  }
}
const xr = (t, e, r, s = {}) => {
  const { overwrite: o = !1, warnOnExisting: n = !0 } = s;
  for (const a of Object.keys(r)) {
    const c = t.getLocaleMessage(a)[e];
    if (c && n && console.warn(
      `[i18n] Messages already exist for namespace "${e}" in locale "${a}". ${o ? "Overwriting" : "Skipping"} registration.`
    ), !c || o) {
      const h = {
        [e]: r[a]
      };
      h && t.mergeLocaleMessage(a, h);
    }
  }
}, Hr = (t) => t.replace(/_([a-z])/g, (e, r) => r.toUpperCase()), Ve = (t) => Array.isArray(t) ? t.map((e) => e !== null && typeof e == "object" ? Ve(e) : e) : t === null || typeof t != "object" ? t : Object.entries(t).reduce(
  (e, [r, s]) => {
    const o = Hr(r);
    return s !== null && typeof s == "object" ? e[o] = Ve(s) : e[o] = s, e;
  },
  {}
), kr = (t, e) => t.length > e ? `${t.slice(0, e)}…` : t, es = (t, e) => t.length > e ? t.slice(0, e) : t, ts = (t) => t.normalize("NFD").replace(/[\u0300-\u036f]/g, ""), rs = (t) => {
  let e = 0, r;
  for (r = 0; r < (t == null ? void 0 : t.length); r += 1)
    e = t.charCodeAt(r) + ((e << 5) - e);
  let s = "#";
  for (r = 0; r < 3; r += 1) {
    const o = e >> r * 8 & 255;
    s += `00${o.toString(16)}`.slice(-2);
  }
  return s;
};
export {
  ce as DynamicResource,
  jr as ResourceError,
  je as VITE_PREFIX,
  Zr as buildDynamicStore,
  Q as dynamicMerge,
  me as isRecord,
  xr as registerPackageTranslations,
  ts as removeAccents,
  rs as stringToColor,
  es as textCut,
  kr as textEllipsis,
  Ve as toClientDomain,
  ke as useClient,
  Qr as useDateFormatter,
  Lr as useEnvStore,
  Xr as useI18n,
  $r as useLocaleStore,
  Yr as useUploader
};
