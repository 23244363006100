import { z } from "zod";

export const ReviewResponse = z.object({
  steps: z.array(
    z.object({
      blocks: z.array(
        z.discriminatedUnion("type", [
          z.object({
            type: z.literal("information"),
          }),
          z.object({
            response: z.string(),
            type: z.literal("open-question"),
          }),
          z.object({
            rows: z.array(z.object({ selected_index: z.number().optional() })),
            type: z.literal("grid"),
          }),
          z.object({
            rows: z.array(
              z.object({
                cols: z.array(
                  z.object({
                    text: z.string(),
                  }),
                ),
              }),
            ),
            type: z.literal("table"),
          }),
          z.object({
            comment: z.string().nullable().default(null),
            score: z.number().min(0).max(10).nullable().default(null),
            type: z.literal("rating"),
          }),
        ]),
      ),
    }),
  ),
});

export type ReviewResponse = z.infer<typeof ReviewResponse>;

export const NewGoals = z
  .array(
    z.object({
      description: z.string(),
      priority: z.enum(["critical", "high", "normal", "low"]),
      title: z.string(),
    }),
  )
  .nullable()
  .default(null);

export type NewGoals = z.infer<typeof NewGoals>;

export const GlobalAssessment = z.object({
  comment: z.string().nullable().default(null),
  criteria: z.array(
    z.object({
      selected: z.boolean(),
      text: z.string().min(1),
    }),
  ),
});
export type GlobalAssessment = z.infer<typeof GlobalAssessment>;
