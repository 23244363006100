import Axios, { type CreateAxiosDefaults } from "axios";
import { getAuth } from "firebase/auth";
import qs from "qs";

import { config } from "../config";

const axiosConfig = {
  baseURL: config.backend.url,
  paramsSerializer: (params: unknown) =>
    qs.stringify(params, { arrayFormat: "repeat" }),
  returnRejectedPromiseOnError: true,
  timeout: 30000,
};

export const createAxiosClient = (config?: CreateAxiosDefaults) =>
  Axios.create(config ?? axiosConfig);

const getToken = async () => await getAuth().currentUser?.getIdToken();

export const axiosClient = createAxiosClient();

axiosClient.interceptors.request.use(async (config) => {
  const token = await getToken();

  if (token) {
    config.headers.Authorization = `Bearer ${token}`;
  }

  return config;
});
