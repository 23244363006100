import { omit } from "lodash";

import {
  CreateEmployee,
  DeleteEmployee,
  DeleteManyEmployee,
  UpdateEmployee,
} from "@/graphql/_Employees";
import { useEmployeeGlobalStore } from "@/pinia/collections";
import { apolloProvider } from "@/vue-apollo";

const refetch = async () => useEmployeeGlobalStore().refetch("no-cache", false);

export const createEmployee = async (payload: { data: unknown }) => {
  try {
    const result = await apolloProvider.defaultClient.mutate({
      mutation: CreateEmployee,
      variables: {
        data: payload.data,
      },
    });
    await refetch();
    return result;
  } catch (error: unknown) {
    console.error("GRAPHQL ERROR", error);
  }
};

export const updateEmployee = async (payload: { data: any; where: any }) => {
  try {
    const result = await apolloProvider.defaultClient.mutate({
      mutation: UpdateEmployee,
      variables: {
        data: omit(payload.data, ["site_id", "client_id"]),
        where: payload.where,
      },
    });
    await refetch();
    return result;
  } catch (error: unknown) {
    console.error("GRAPHQL ERROR", error);
  }
};

export const deleteEmployee = async (payload: { where: any }) => {
  try {
    const result = await apolloProvider.defaultClient.mutate({
      mutation: DeleteEmployee,
      variables: {
        where: payload.where,
      },
    });
    await refetch();
    return result;
  } catch (error: any) {
    console.error("GRAPHQL ERROR", error);
  }
};

export const archiveEmployee = async (payload: { data: any; where: any }) => {
  try {
    const result = await apolloProvider.defaultClient.mutate({
      mutation: UpdateEmployee,
      variables: {
        data: payload.data,
        where: payload.where,
      },
    });
    await refetch();
    return result;
  } catch (error: any) {
    console.error("GRAPHQL ERROR", error);
  }
};

export const deleteManyEmployee = async (payload: { where: any }) => {
  try {
    const result = await apolloProvider.defaultClient.mutate({
      mutation: DeleteManyEmployee,
      variables: {
        where: payload.where,
      },
    });
    await refetch();
    return result;
  } catch (error: any) {
    console.error("GRAPHQL ERROR", error);
  }
};
