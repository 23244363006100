<template>
  <svg
    fill="none"
    height="24"
    viewBox="0 0 24 24"
    width="24"
    xmlns="http://www.w3.org/2000/svg"
  >
    <path
      :fill="selection"
      d="M6 5H8.5C8.5 3.34 9.84 2 11.5 2C13.16 2 14.5 3.34 14.5 5H17C18.66 5 20 6.34 20 8V19C20 20.66 18.66 22 17 22H6C4.34 22 3 20.66 3 19V8C3 6.34 4.34 5 6 5ZM6 6C4.9 6 4 6.9 4 8V19C4 20.1 4.9 21 6 21H17C18.1 21 19 20.1 19 19V8C19 6.9 18.1 6 17 6H16V9H7V6H6ZM8 8H15V6H8V8ZM11.5 3C10.4 3 9.5 3.9 9.5 5H13.5C13.5 3.9 12.6 3 11.5 3ZM6 11H17V12H6V11ZM6 14H17V15H6V14ZM6 17H15V18H6V17Z"
    />
  </svg>
</template>

<script lang="ts">
import Vue from "vue";

export default Vue.extend({
  name: "PerformanceReview",
  props: ["selection"],
});
</script>
