import { gql } from "graphql-tag";

export const CreateShift = gql`
  mutation CreateShift($data: ShiftCreateInput!) {
    createShift(data: $data) {
      id
      name
      client_id
      site_id
      color_text
      color_bg
      teams {
        id
      }
      start
      end
      custom_shifts {
        id
        day_name
        day_nb
        end
        start
      }
      is_custom_days_hours
      status
    }
  }
`;

export const UpdateShift = gql`
  mutation UpdateShift(
    $data: ShiftUpdateInput!
    $where: ShiftWhereUniqueInput!
  ) {
    updateShift(data: $data, where: $where) {
      id
      name
      client_id
      site_id
      color_text
      color_bg
      teams {
        id
      }
      start
      end
      custom_shifts {
        id
        day_name
        day_nb
        end
        start
      }
      is_custom_days_hours
      status
    }
  }
`;

export const DeleteShift = gql`
  mutation DeleteShift($where: ShiftWhereUniqueInput!) {
    deleteShift(where: $where) {
      id
    }
  }
`;

export const Shifts = gql`
  query Shifts {
    Shifts(orderBy: [{name: asc}]) {
      id
      name
      client_id
      site_id
      color_text
      color_bg
      teams {
        id
      }
      start
      end
      custom_shifts {
        id
        day_name
        day_nb
        end
        start
      }
      is_custom_days_hours
      status
    }
  }
`;
