<template>
  <svg
    height="24"
    version="1.1"
    viewBox="0 0 24 24"
    width="24"
    xmlns="http://www.w3.org/2000/svg"
    xmlns:xlink="http://www.w3.org/1999/xlink"
  >
    <path
      :fill="selection"
      :stroke="selection"
      d="M11,2.5L20,7V9H2V7L11,2.5M15,10H19V18H15V10M2,22V19H20V22H2M9,10H13V18H9V10M3,10H7V18H3V10M3,20V21H19V20H3M4,11V17H6V11H4M10,11V17H12V11H10M16,11V17H18V11H16M3,8H19V7.6L11,3.58L3,7.6V8Z"
      stroke-width="0.2"
    />
  </svg>
</template>

<script lang="ts">
import Vue from "vue";

export default Vue.extend({
  name: "Administration",
  props: ["selection"],
});
</script>
