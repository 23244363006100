import { z } from "zod";

export const Id = z
  .string()
  .regex(/^[\w-]+$/)
  .brand("Id");
export type Id = z.infer<typeof Id>;

export const NonEmptyString = z.string().trim().min(1).brand("NonEmptyString");
export type NonEmptyString = z.infer<typeof NonEmptyString>;

export const PositiveInteger = z.number().int().gte(0).brand("PositiveInteger");
export type PositiveInteger = z.infer<typeof PositiveInteger>;
