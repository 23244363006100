import { gql } from "graphql-tag";

export const CellAbsence = gql`
  query CellAbsence($where: LAbsenceWhereUniqueInput) {
    CellAbsence(where: $where) {
      id
      status
      employee_id
      day_nb
      day
      timeForAbsences {
        startTimeNumber
        endTimeNumber
        totalTimeNumber
        totalTimeNumberInMinutes
        startTime
        endTime
        totalTime
        isFullDay
      }
      isAbsence
      reason
      comment
    }
  }
`;

export const CellAbsences = gql`
  query CellAbsences($where: LAbsenceWhereInput) {
    CellAbsences(where: $where) {
      id
      employee_id
      day_nb
      reason
      timeForAbsences {
        startTimeNumber
        endTimeNumber
        totalTimeNumber
        totalTimeNumberInMinutes
        startTime
        endTime
        totalTime
        isFullDay
      }
      isAbsence
      day
      status
      comment
    }
  }
`;

export const CellAbsenceCreate = gql`
  mutation CreateCellAbsence($data: LAbsenceCreateInput!) {
    createCellabsence(data: $data) {
      id
      employee_id
      day_nb
      reason
      timeForAbsences {
        startTimeNumber
        endTimeNumber
        totalTimeNumber
        totalTimeNumberInMinutes
        startTime
        endTime
        totalTime
        isFullDay
      }
      isAbsence
      day
      status
      comment
    }
  }
`;

export const CellAbsenceDelete = gql`
  mutation DeleteCellAbsence($where: LAbsenceWhereUniqueInput!) {
    deleteCellabsence(where: $where) {
      id
      employee_id
      day_nb
      reason
      timeForAbsences {
        startTimeNumber
        endTimeNumber
        totalTimeNumber
        totalTimeNumberInMinutes
        startTime
        endTime
        totalTime
        isFullDay
      }
      isAbsence
      day
      status
      comment
    }
  }
`;

export const CellAbsenceUpdate = gql`
  mutation UpdateCellAbsence(
    $where: LAbsenceWhereUniqueInput!
    $data: LAbsenceUpdateInput!
  ) {
    updateCellabsence(where: $where, data: $data) {
      id
      employee_id
      day_nb
      reason
      timeForAbsences {
        startTimeNumber
        endTimeNumber
        totalTimeNumber
        totalTimeNumberInMinutes
        startTime
        endTime
        totalTime
        isFullDay
      }
      isAbsence
      day
      status
      comment
    }
  }
`;

export const CellAbsenceCreateMany = gql`
  mutation CreateManyLAbsence($data: [LAbsenceCreateManyInput!]!) {
    createManyLAbsence(data: $data) {
      count
    }
  }
`;

export const CellAbsenceUpdateMany = gql`
  mutation UpdateManyLAbsence(
    $data: LAbsenceUpdateManyMutationInput!
    $where: LAbsenceWhereInput
  ) {
    updateManyLAbsence(data: $data, where: $where) {
      count
    }
  }
`;
