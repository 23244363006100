import { defineStore } from "pinia";

import * as baseCollection from "@/pinia/collections";

import { useAuthentificationStore } from "../user";

const hasBeenLoaded = <
  T extends { hasRealtime: boolean; hasSubcribed: boolean },
>(
  store: T,
): boolean => {
  return store.hasRealtime && store.hasSubcribed;
};

// Only concern global store loaded at the initialization of the app.
//   UserGlobalStore
//   FactoryGlobalStore
//   ContentGlobalStore
//   TeamGlobalStore
//   EmployeeGlobalStore
//   EventGlobalStore
//   EmployeeSkillStore
//   EmployeeClearanceStore
//   EmployeeRestrictionStore
//   EmployeeTrainingStore
//   SkillGlobalStore
//   ClearanceGlobalStore
//   QuestionnaireModelStore
//   QuestionnaireStore

export const useLoadingStore = defineStore("useLoading", {
  getters: {
    // users(): boolean {
    //   return hasBeenLoaded(baseCollection.useUserGlobalStore());
    // },
    contents(): boolean {
      return hasBeenLoaded(baseCollection.useContentGlobalStore());
    },
    // teams(): boolean {
    //   return hasBeenLoaded(baseCollection.useTeamGlobalStore());
    // },
    employees(): boolean {
      return hasBeenLoaded(baseCollection.useEmployeeGlobalStore());
    },
    // skills(): boolean {
    //   return hasBeenLoaded(baseCollection.useSkillGlobalStore());
    // },
    // clearances(): boolean {
    //   return hasBeenLoaded(baseCollection.useClearanceGlobalStore());
    // },
    factories(): boolean {
      return hasBeenLoaded(baseCollection.useFactoryGlobalStore());
    },
    // Group
    globalStores(): boolean {
      const user = useAuthentificationStore();

      if (user.data && user?.data?.role !== "CUSTOMER_SUCCESS") {
        return !!this.factories && !!this.employees && !!this.contents;
      }
      return true;
    },
    goals(): boolean {
      return hasBeenLoaded(baseCollection.useGoalsStore());
    },
    goalsHistory(): boolean {
      return hasBeenLoaded(baseCollection.useGoalsHistoryStore());
    },
    // TO move from global
    questionnaires(): boolean {
      return hasBeenLoaded(baseCollection.useQuestionnaireStore());
    },

    questionnairesModels(): boolean {
      return hasBeenLoaded(baseCollection.useQuestionnaireModelStore());
    },
  },
  state: () => ({
    collections: [] as string[],
  }),
});
