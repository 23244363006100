import { type Dictionary, orderBy, uniq } from "lodash";

import { usePlanningDisplayStore } from "@/pinia/features/planning-display";

import type {
  AbsenceResult,
  CellResult,
  IAlingCell,
  ShiftResult,
} from "./planningBuilder";

export type IAbsenceDataDictionary = Record<
  string,
  Record<number, AbsenceResult[]>
>;

export type IPlanningDataDictionary = Record<
  string,
  Record<number, (CellResult | IAlingCell)[]>
>;

export type IShiftDataDictionary = Record<
  string,
  Record<number, ShiftResult[]>
>;

type IEmployeeWeekPlanning = Record<
  string,
  {
    weekEmployeeShifts: IWeekEmployeeShifts;
  }
>;
type IPositionsWeekPlanning = Record<
  string,
  {
    weekPositionShifts: IWeekPositionShifts;
  }
>;

type IWeekEmployeeShifts = Record<
  string,
  {
    name: string;
    number: number;
    positionsData: Record<
      string,
      {
        position_id: string;
        shift_id: string;
      }
    >;
    positionsIds: string[];
  }
>;

type IWeekPositionShifts = Record<
  string,
  {
    employeesData: Record<
      string,
      {
        firstName: string;
        start: string;
      }
    >;
    employeesIds: string[];
    name: string;
    number: number;
  }
>;

export const planningAligment = (
  cells: CellResult[],
  absences: AbsenceResult[],
  shifts: ShiftResult[],
  removeDoublonShift: boolean,
): {
  allDataByEmloyeeAbsence: IAbsenceDataDictionary;
  allDataByEmployee: IPlanningDataDictionary;
  allDataByPosition: IPlanningDataDictionary;
  allShiftByEmployee: IShiftDataDictionary;
  compactPlanningByEmployeeLine: Dictionary<number>;
  compactPlanningByPosAndSubpos: Dictionary<any>;
  compactPlanningByPositionLine: Dictionary<number>;
  compactPlanningRow: Dictionary<number>;
  // statPlanningByEmployeeLine: Dictionary<number>;
  statPlanningByEmployeeRow: Dictionary<number>;
} => {
  const allDataByPosition: IPlanningDataDictionary = {};
  const allDataByEmployee: IPlanningDataDictionary = {};

  const allShiftByEmployee: IShiftDataDictionary = {};

  const positionsWeekPlanning: IPositionsWeekPlanning = {};
  const employeeWeekPlanning: IEmployeeWeekPlanning = {};

  // const statPlanningByEmployeeLine: Dictionary<number> = {};
  const statPlanningByEmployeeRow: Dictionary<number> = {};

  const compactPlanningByEmployeeLine: Dictionary<number> = {};
  const compactPlanningRow: Dictionary<number> = {};

  const compactPlanningByPositionLine: Dictionary<number> = {};
  const compactPlanningByPosAndSubpos: Dictionary<any> = {};

  const AlignementCellRules = () => {
    const PlanningDisplayStore = usePlanningDisplayStore();
    if (PlanningDisplayStore.isAlignHours) {
      return ["time.startTime", "shift_id", "employee.first_name"];
    }
    if (PlanningDisplayStore.isAlignEmployees) {
      return ["employee.first_name"];
    }
    if (PlanningDisplayStore.isAlignShifts) {
      return ["shift_name"];
    }
  };

  for (let cell of cells) {
    cell = {
      ...cell,
      shift_name: cell.shift.name.toLocaleLowerCase(),
    };

    if (statPlanningByEmployeeRow[cell.day_nb]) {
      statPlanningByEmployeeRow[cell.day_nb] +=
        cell.time.totalTimeNumberInMinutes;
    } else {
      statPlanningByEmployeeRow[cell.day_nb] =
        cell.time.totalTimeNumberInMinutes;
    }

    if (cell.isTomorrow) continue;

    if (compactPlanningByEmployeeLine[cell.employee_id]) {
      compactPlanningByEmployeeLine[cell.employee_id] +=
        cell.time.totalTimeNumberInMinutes;
    } else {
      compactPlanningByEmployeeLine[cell.employee_id] =
        cell.time.totalTimeNumberInMinutes;
    }

    if (compactPlanningRow[cell.day_nb]) {
      compactPlanningRow[cell.day_nb] += cell.time.totalTimeNumberInMinutes;
    } else {
      compactPlanningRow[cell.day_nb] = cell.time.totalTimeNumberInMinutes;
    }

    if (cell.subposition_id) {
      if (compactPlanningByPosAndSubpos[cell.position_id]) {
        if (
          compactPlanningByPosAndSubpos[cell.position_id][cell.subposition_id]
        ) {
          compactPlanningByPosAndSubpos[cell.position_id][
            cell.subposition_id
          ]++;
        } else {
          compactPlanningByPosAndSubpos[cell.position_id][cell.subposition_id] =
            1;
        }
      } else {
        compactPlanningByPosAndSubpos[cell.position_id] = {
          [cell.subposition_id]: 1,
        };
      }
    } else if (!compactPlanningByPosAndSubpos[cell.position_id]) {
      compactPlanningByPosAndSubpos[cell.position_id] = {};
    }

    const positionOrSubpositionID = cell.subposition_id
      ? cell.subposition_id
      : cell.position_id;

    if (compactPlanningByPositionLine[cell.position_id]) {
      compactPlanningByPositionLine[cell.position_id] +=
        cell.time.totalTimeNumberInMinutes;
    } else {
      compactPlanningByPositionLine[cell.position_id] =
        cell.time.totalTimeNumberInMinutes;
    }

    const shift_name = cell.shift.name.toLocaleLowerCase();

    if (employeeWeekPlanning[cell.employee_id]) {
      if (
        employeeWeekPlanning[cell.employee_id].weekEmployeeShifts[shift_name]
      ) {
        employeeWeekPlanning[cell.employee_id].weekEmployeeShifts[
          shift_name
        ].positionsIds.push(cell.position_id);
        employeeWeekPlanning[cell.employee_id].weekEmployeeShifts[
          shift_name
        ].positionsData[cell.position_id] = {
          position_id: cell.position_id,
          shift_id: cell.shift_id,
        };
        employeeWeekPlanning[cell.employee_id].weekEmployeeShifts[shift_name]
          .number++;
      } else {
        employeeWeekPlanning[cell.employee_id].weekEmployeeShifts[shift_name] =
          {
            name: shift_name,
            number: 1,
            positionsData: {
              [cell.position_id]: {
                position_id: cell.position_id,
                shift_id: cell.shift_id,
              },
            },
            positionsIds: [cell.position_id],
          };
      }
    } else {
      employeeWeekPlanning[cell.employee_id] = {
        weekEmployeeShifts: {
          [shift_name]: {
            name: shift_name,
            number: 1,
            positionsData: {
              [cell.position_id]: {
                position_id: cell.position_id,
                shift_id: cell.shift_id,
              },
            },
            positionsIds: [cell.position_id],
          },
        },
      };
    }

    if (allDataByEmployee[cell.employee_id]) {
      if (allDataByEmployee[cell.employee_id][cell.day_nb]) {
        if (employeeWeekPlanning[cell.employee_id][cell.day_nb][shift_name]) {
          if (removeDoublonShift) {
            continue;
          }
          const dayShift =
            employeeWeekPlanning[cell.employee_id][cell.day_nb][shift_name];
          dayShift.number++;

          if (dayShift.positionsIds) {
            dayShift.positionsIds.push(cell.position_id);
            dayShift.positionsData[cell.position_id] = {
              position_id: cell.position_id,
              shift_id: cell.shift_id,
            };
          } else {
            dayShift.positionsIds = [cell.position_id];
            dayShift.positionsData = {
              [cell.position_id]: {
                position_id: cell.position_id,
                shift_id: cell.shift_id,
              },
            };
          }
        } else {
          employeeWeekPlanning[cell.employee_id][cell.day_nb][shift_name] = {
            name: shift_name,
            number: 1,
            positionsData: {
              [cell.position_id]: {
                position_id: cell.position_id,
                shift_id: cell.shift_id,
              },
            },
            positionsIds: [cell.position_id],
          };
        }

        allDataByEmployee[cell.employee_id][cell.day_nb].push(cell);
      } else {
        employeeWeekPlanning[cell.employee_id][cell.day_nb] = {
          [shift_name]: {
            name: shift_name,
            number: 1,
            positionsData: {
              [cell.position_id]: {
                position_id: cell.position_id,
                shift_id: cell.shift_id,
              },
            },

            positionsIds: [cell.position_id],
          },
        };
        allDataByEmployee[cell.employee_id][cell.day_nb] = [cell];
      }
    } else {
      employeeWeekPlanning[cell.employee_id] = {
        [cell.day_nb]: {
          [shift_name]: {
            name: shift_name,
            number: 1,
            positionsData: {
              [cell.position_id]: {
                position_id: cell.position_id,
                shift_id: cell.shift_id,
              },
            },
            positionsIds: [cell.position_id],
          },
        },
        weekEmployeeShifts: {
          [shift_name]: {
            name: shift_name,
            number: 1,
            positionsData: {
              [cell.position_id]: {
                position_id: cell.position_id,
                shift_id: cell.shift_id,
              },
            },
            positionsIds: [cell.position_id],
          },
        },
      };
      allDataByEmployee[cell.employee_id] = {
        [cell.day_nb]: [cell],
      };
    }
    // END ALIGNEMENT EMPLOYEE VIEW

    // ALIGNEMENT POSITION VIEW
    if (positionsWeekPlanning[positionOrSubpositionID]) {
      if (
        positionsWeekPlanning[positionOrSubpositionID].weekPositionShifts[
          shift_name
        ]
      ) {
        positionsWeekPlanning[positionOrSubpositionID].weekPositionShifts[
          shift_name
        ].employeesIds.push(cell.employee_id);
        positionsWeekPlanning[positionOrSubpositionID].weekPositionShifts[
          shift_name
        ].employeesData[cell.employee_id] = {
          firstName: cell.employee.first_name,
          shift_id: cell.shift_id,
          start: cell.time.startTime,
        };
        positionsWeekPlanning[positionOrSubpositionID].weekPositionShifts[
          shift_name
        ].number++;
      } else {
        positionsWeekPlanning[positionOrSubpositionID].weekPositionShifts[
          shift_name
        ] = {
          employeesData: {
            [cell.employee_id]: {
              firstName: cell.employee.first_name,
              shift_id: cell.shift_id,
              start: cell.time.startTime,
            },
          },
          employeesIds: [cell.employee_id],
          name: shift_name,
          number: 1,
        };
      }
    } else {
      positionsWeekPlanning[positionOrSubpositionID] = {
        weekPositionShifts: {
          [shift_name]: {
            employeesData: {
              [cell.employee_id]: {
                firstName: cell.employee.first_name,
                shift_id: cell.shift_id,
                start: cell.time.startTime,
              },
            },
            employeesIds: [cell.employee_id],
            name: shift_name,
            number: 1,
          },
        },
      };
    }

    if (allDataByPosition[positionOrSubpositionID]) {
      if (allDataByPosition[positionOrSubpositionID][cell.day_nb]) {
        if (
          positionsWeekPlanning[positionOrSubpositionID][cell.day_nb][
            shift_name
          ]
        ) {
          const dayShift =
            positionsWeekPlanning[positionOrSubpositionID][cell.day_nb][
              shift_name
            ];
          dayShift.number++;

          if (dayShift.employeesIds) {
            dayShift.employeesIds.push(cell.employee_id);
            dayShift.employeesData[cell.employee_id] = {
              firstName: cell.employee.first_name,
              shift_id: cell.shift_id,
              start: cell.time.startTime,
            };
          } else {
            dayShift.employeesIds = [cell.employee_id];
            dayShift.employeesData = {
              [cell.employee_id]: {
                firstName: cell.employee.first_name,
                shift_id: cell.shift_id,
                start: cell.time.startTime,
              },
            };
          }
        } else {
          positionsWeekPlanning[positionOrSubpositionID][cell.day_nb][
            shift_name
          ] = {
            employeesData: {
              [cell.employee_id]: {
                firstName: cell.employee.first_name,
                shift_id: cell.shift_id,
                start: cell.time.startTime,
              },
            },
            employeesIds: [cell.employee_id],
            name: shift_name,
            number: 1,
          };
        }

        allDataByPosition[positionOrSubpositionID][cell.day_nb].push(cell);
      } else {
        positionsWeekPlanning[positionOrSubpositionID][cell.day_nb] = {
          [shift_name]: {
            employeesData: {
              [cell.employee_id]: {
                firstName: cell.employee.first_name,
                shift_id: cell.shift_id,
                start: cell.time.startTime,
              },
            },
            employeesIds: [cell.employee_id],
            name: shift_name,

            number: 1,
          },
        };
        allDataByPosition[positionOrSubpositionID][cell.day_nb] = [cell];
      }
    } else {
      positionsWeekPlanning[positionOrSubpositionID] = {
        [cell.day_nb]: {
          [shift_name]: {
            employeesData: {
              [cell.employee_id]: {
                firstName: cell.employee.first_name,
                shift_id: cell.shift_id,
                start: cell.time.startTime,
              },
            },
            employeesIds: [cell.employee_id],
            name: shift_name,
            number: 1,
          },
        },
        weekPositionShifts: {
          [shift_name]: {
            employeesData: {
              [cell.employee_id]: {
                firstName: cell.employee.first_name,
                shift_id: cell.shift_id,
                start: cell.time.startTime,
              },
            },
            employeesIds: [cell.employee_id],
            name: shift_name,
            number: 1,
          },
        },
      };
      allDataByPosition[positionOrSubpositionID] = {
        [cell.day_nb]: [cell],
      };
    }
    // END ALIGNEMENT POSITION VIEW
  }

  // Loop through the employeess dictionary
  for (const employeeID in employeeWeekPlanning) {
    const employeeIDShifts: IEmployeeWeekPlanning =
      employeeWeekPlanning[employeeID];

    // For each shift data of the employee
    for (const shiftKey in employeeIDShifts) {
      // If shiftKey correspond to a day
      if (shiftKey !== "weekEmployeeShifts") {
        const shiftDay = shiftKey;
        const shiftDataAlreadyExistingForTheDay = employeeIDShifts[shiftDay];
        // Get the needed data for the day and loop through each needed shift
        const weekEmployeeShiftsDay = employeeIDShifts.weekEmployeeShifts;
        for (const neededShift in weekEmployeeShiftsDay) {
          const shiftDayData = employeeIDShifts.weekEmployeeShifts[neededShift];
          if (!shiftDataAlreadyExistingForTheDay[neededShift]) {
            const positionIdsTocheck: string[] = uniq(
              shiftDayData.positionsIds,
            );
            positionIdsTocheck.forEach((position_id) => {
              allDataByEmployee[employeeID][shiftDay].push({
                alignementCell: true,
                position_id: position_id,
                shift: shiftDayData.name,
                shift_id: shiftDayData.positionsData[position_id].shift_id,
                shift_name: shiftDayData.name,
                start: null,
              });
            });
          } else if (
            shiftDataAlreadyExistingForTheDay[neededShift].number !==
            shiftDayData.number
          ) {
            const positionIdsTocheck: string[] = uniq(
              shiftDayData.positionsIds,
            );
            positionIdsTocheck.forEach((position_id) => {
              if (
                !shiftDataAlreadyExistingForTheDay[
                  neededShift
                ].positionsIds.includes(position_id)
              ) {
                allDataByEmployee[employeeID][shiftDay].push({
                  alignementCell: true,
                  position_id: position_id,
                  shift: shiftDayData.name,
                  shift_id: shiftDayData.positionsData[position_id].shift_id,
                  shift_name: shiftDayData.name,
                  start: null,
                });
              }
            });
          }
        }
      }
    }
  }

  for (const employeeID in allDataByEmployee) {
    const dataByEmployee = allDataByEmployee[employeeID];
    for (const dayOfTheWeek in dataByEmployee) {
      const dataByEmployeeAndDay = dataByEmployee[dayOfTheWeek];
      allDataByEmployee[employeeID][dayOfTheWeek] = orderBy(
        dataByEmployeeAndDay,
        ["time.startTime", "shift_id", "position_id"],
      );
    }
  }
  // END Loop through the employeess dictionary

  // Loop through the positions dictionary
  for (const position_id in positionsWeekPlanning) {
    const positionIDShifts = positionsWeekPlanning[position_id];

    // For each shift data of the position
    for (const shiftKey in positionIDShifts) {
      // If shiftKey correspond to a day
      if (shiftKey !== "weekPositionShifts") {
        const shiftDay = shiftKey;
        const shiftDataAlreadyExistingForTheDay = positionIDShifts[shiftDay];

        // Get the needed data for the day and loop through each needed shift
        const weekPositionShiftsDay = positionIDShifts.weekPositionShifts;
        for (const neededShift in weekPositionShiftsDay) {
          const shiftDayData = positionIDShifts.weekPositionShifts[neededShift];

          if (!shiftDataAlreadyExistingForTheDay[neededShift]) {
            const employeeIdsTocheck: string[] = uniq(
              shiftDayData.employeesIds,
            );
            employeeIdsTocheck.forEach((employeeID) => {
              allDataByPosition[position_id][shiftDay].push({
                alignementCell: true,
                employee: {
                  first_name: shiftDayData.employeesData[employeeID].firstName,
                },
                employeesId: employeeID,
                firstName: shiftDayData.employeesData[employeeID].firstName,
                shift: shiftDayData.name,
                shift_id: shiftDayData.employeesData[employeeID].shift_id,
                shift_name: shiftDayData.name,
                start: shiftDayData.employeesData[employeeID].start,
                time: {
                  startTime: shiftDayData.employeesData[employeeID].start,
                },
              });
            });
          } else if (
            shiftDataAlreadyExistingForTheDay[neededShift].number !==
            shiftDayData.number
          ) {
            const employeeIdsTocheck: string[] = uniq(
              shiftDayData.employeesIds,
            );
            employeeIdsTocheck.forEach((employeeID) => {
              if (
                !shiftDataAlreadyExistingForTheDay[
                  neededShift
                ].employeesIds.includes(employeeID)
              ) {
                allDataByPosition[position_id][shiftDay].push({
                  alignementCell: true,
                  employee: {
                    first_name:
                      shiftDayData.employeesData[employeeID].firstName,
                  },
                  employeesId: employeeID,
                  firstName: shiftDayData.employeesData[employeeID].firstName,
                  shift: shiftDayData.name,
                  shift_id: shiftDayData.employeesData[employeeID].shift_id,
                  shift_name: shiftDayData.name,
                  start: shiftDayData.employeesData[employeeID].start,
                  time: {
                    startTime: shiftDayData.employeesData[employeeID].start,
                  },
                });
              }
            });
          }
        }
      }
    }
  }
  for (const positionID in allDataByPosition) {
    const dataByPosition = allDataByPosition[positionID];
    for (const dayOfTheWeek in dataByPosition) {
      const dataByPositionAndDay = dataByPosition[dayOfTheWeek];
      allDataByPosition[positionID][dayOfTheWeek] = orderBy(
        dataByPositionAndDay,
        AlignementCellRules(),
      );
    }
  }
  // END Loop through the positions dictionary

  const allDataByEmloyeeAbsence: IAbsenceDataDictionary = {};

  for (const absence of absences) {
    if (allDataByEmloyeeAbsence[absence.employee_id]) {
      if (allDataByEmloyeeAbsence[absence.employee_id][absence.day_nb]) {
        allDataByEmloyeeAbsence[absence.employee_id][absence.day_nb].push(
          absence,
        );
      } else {
        allDataByEmloyeeAbsence[absence.employee_id][absence.day_nb] = [];
        allDataByEmloyeeAbsence[absence.employee_id][absence.day_nb].push(
          absence,
        );
      }
    } else {
      allDataByEmloyeeAbsence[absence.employee_id] = {};
      allDataByEmloyeeAbsence[absence.employee_id][absence.day_nb] = [];
      allDataByEmloyeeAbsence[absence.employee_id][absence.day_nb].push(
        absence,
      );
    }
  }

  for (const shift of shifts) {
    if (allShiftByEmployee[shift.employee_id]) {
      if (allShiftByEmployee[shift.employee_id][shift.day_nb]) {
        allShiftByEmployee[shift.employee_id][shift.day_nb].push(shift);
      } else {
        allShiftByEmployee[shift.employee_id][shift.day_nb] = [];
        allShiftByEmployee[shift.employee_id][shift.day_nb].push(shift);
      }
    } else {
      allShiftByEmployee[shift.employee_id] = {};
      allShiftByEmployee[shift.employee_id][shift.day_nb] = [];
      allShiftByEmployee[shift.employee_id][shift.day_nb].push(shift);
    }
  }

  return {
    allDataByEmloyeeAbsence,
    allDataByEmployee,
    allDataByPosition,
    allShiftByEmployee,
    compactPlanningByEmployeeLine,
    compactPlanningByPosAndSubpos,
    compactPlanningByPositionLine,
    compactPlanningRow,
    // statPlanningByEmployeeLine,
    statPlanningByEmployeeRow,
  };
};
