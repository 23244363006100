import {
  CreateRestriction,
  DeleteRestriction,
  Restrictions,
  UpdateRestriction,
} from "@/graphql/settings/Restrictions";
import { useParameterRestrictionStore } from "@/pinia/collections";
import { apolloProvider } from "@/vue-apollo";

export const createRestriction = async (payload: { data: any }) => {
  try {
    return await apolloProvider.defaultClient.mutate({
      mutation: CreateRestriction,
      update: (store, { data: { createRestriction } }) => {
        updateStoreAfterCreateRestriction(store, createRestriction);
      },
      variables: {
        data: payload.data,
      },
    });
  } catch (error) {
    console.error(error);
  }
};

export const updateRestriction = async (payload: { data: any; where: any }) => {
  try {
    return await apolloProvider.defaultClient.mutate({
      mutation: UpdateRestriction,
      update: (store, data) => {
        updateStoreAfterUpdateRestriction(store, data.data.updateRestriction);
      },
      variables: {
        data: payload.data,
        where: payload.where,
      },
    });
  } catch (error) {
    console.error(error);
  }
};

export const deleteRestriction = async (payload: { where: any }) => {
  try {
    return await apolloProvider.defaultClient.mutate({
      mutation: DeleteRestriction,
      update: (store, data) => {
        updateStoreAfterDeleteRestriction(
          store,
          data.data.deleteRestriction.id,
        );
      },
      variables: {
        where: payload.where,
      },
    });
  } catch (error) {
    console.error(error);
  }
};

const updateStoreAfterCreateRestriction = (store, createRestriction) => {
  const data = store.readQuery({
    query: Restrictions,
  });
  data.Restrictions.push(createRestriction);
  useParameterRestrictionStore().actives.push(createRestriction);
  store.writeQuery({
    data,
    query: Restrictions,
  });
};

const updateStoreAfterDeleteRestriction = (store, id) => {
  const data = store.readQuery({
    query: Restrictions,
  });
  const index = data.Restrictions.findIndex((c) => c.id === id);
  if (index !== -1) {
    // Mutate cache result
    data.Restrictions.splice(index, 1);
    useParameterRestrictionStore().activesScoped = data.Restrictions.filter(
      (restriction) => (restriction.status = "active"),
    );
    useParameterRestrictionStore().deleted.push(data.Restrictions[index]);
    // Write back to the cache
    store.writeQuery({
      data,
      query: Restrictions,
    });
  }
};

const updateStoreAfterUpdateRestriction = (store, updateRestriction) => {
  const data = store.readQuery({
    query: Restrictions,
  });
  const index = data.Restrictions.findIndex(
    (c) => c.id === updateRestriction.id,
  );
  if (index !== -1) {
    // Mutate cache result
    data.Restrictions.splice(index, 1, updateRestriction);
    useParameterRestrictionStore().activesScoped = data.Restrictions.filter(
      (restriction) => (restriction.status = "active"),
    );
    // Write back to the cache
    store.writeQuery({
      data,
      query: Restrictions,
    });
  }
};
