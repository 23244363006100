const childrenInformations = [
  {
    component: () => import("@/components/team/TeamInformations.vue"),
    name: "team-informations",
    path: "/",
  },
  {
    component: () => import("@/components/team/Absences.vue"),
    name: "InformationsAbsences",
    path: "absences",
  },
  {
    component: () =>
      import("@/modules/mini-dashboard/views/MiniDashboardTrainings.vue"),
    name: "InformationsCurrentTraining",
    path: "current-training",
  },
  {
    component: () =>
      import("@/modules/mini-dashboard/views/MiniDashboardContracts.vue"),
    name: "InformationsEndingContracts",
    path: "ending-contracts",
  },
  {
    component: () =>
      import("@/modules/mini-dashboard/views/MiniDashboardClearances.vue"),
    name: "InformationsExpiredClearances",
    path: "expired-clearances",
  },
  {
    component: () =>
      import("@/modules/mini-dashboard/views/MiniDashboardSkills.vue"),
    name: "InformationsExpiredSkills",
    path: "expired-skills",
  },
  {
    component: () => import("@/modules/goals/views/GoalMain.vue"),
    name: "InformationsGoals",
    path: "goals",
  },
  {
    component: () => import("@/modules/notes/views/team-notes.vue"),
    name: "InformationsNotes",
    path: "notes",
  },
  {
    component: () => import("@/components/team/SkillsToRecycle.vue"),
    name: "InformationsSkillsToRecycle",
    path: "skills-to-recycle",
  },
];

export default childrenInformations;
