const childrenDashboard = [
  {
    component: () => import("@/components/team/ExpiredTeamSkills.vue"),
    name: "ExpiredSkills",
    path: "expired-skills",
  },
  {
    component: () => {
      // empty
    },
    name: "contentToView",
    path: "content-to-view",
  },
  {
    component: () => import("@/components/team/ExpiredTeamClearances.vue"),
    name: "cxpiredClearances",
    path: "expired-clearances",
  },
  {
    component: () => import("@/components/team/EndingContracts.vue"),
    name: "EndingContracts",
    path: "ending-contracts",
  },
  {
    component: () => import("@/components/team/Goals.vue"),
    name: "WidgetGoals",
    path: "goals",
  },
  {
    component: () => import("@/components/team/SkillsToRecycle.vue"),
    name: "SkillsToRecycle",
    path: "skills-to-recycle",
  },
  {
    component: () => import("@/components/team/Absences.vue"),
    name: "WidgetAbsences",
    path: "absences",
  },
];

export default childrenDashboard;
