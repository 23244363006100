import { z } from "zod";

import { Id } from "@/shared/domain";

import { Employee } from "./employeeItem";

export const CampaignEdition = z.object({
  dueDate: z.string().optional(),
  name: z.string(),
  selectedEmployees: Employee.array(),
  selectedTemplateId: Id.optional(),
  startDate: z.string(),
  templateId: z.string().optional(),
});

export type CampaignEdition = z.infer<typeof CampaignEdition>;

export const CampaignCreateData = z.object({
  applicants: z.array(z.object({ employee_id: z.string() })),
  due_date: z
    .string()
    .regex(/^\d{4}-\d{2}-\d{2}$/, "Must be a date YYYY-MM-DD")
    .optional(),
  name: z.string(),
  site_id: z.string(),
  start_date: z
    .string()
    .regex(/^\d{4}-\d{2}-\d{2}$/, "Must be a date YYYY-MM-DD"),
  template_id: z.string(),
});

export type CampaignCreateData = z.infer<typeof CampaignCreateData>;
