import "regenerator-runtime/runtime";

import { DefaultApolloClient } from "@vue/apollo-composable";
import { createPinia, PiniaVuePlugin } from "pinia";
import PortalVue from "portal-vue";
import VTooltip from "v-tooltip";
import Vue, {
  type Component,
  provide,
  type VNode,
  type VNodeChildren,
  type VNodeData,
} from "vue";
import VueApexCharts from "vue-apexcharts";
import VueSignaturePad from "vue-signature-pad";
import VueVirtualScroller from "vue-virtual-scroller";

import { vuetifyPlugin as vuetify } from "@mercateam/apps-module-design-system";
import "@mercateam/apps-module-design-system/main.css";
import { module as SchedulingModule } from "@mercateam/apps-module-scheduling";
import "@mercateam/apps-module-scheduling/main.css";
import { useEnvStore } from "@mercateam/apps-module-shared";

import i18n from "@/i18n";
import router from "@/router";

import unwrapDirectives from "./directives";
import { apolloProvider, setupApolloClient } from "./vue-apollo";

import App from "@/App.vue";

type VueCreateElement = (
  tag: Component | string,
  data?: VNodeData,
  children?: VNodeChildren,
) => VNode;

Vue.use(VTooltip);

Vue.config.productionTip = false;

Vue.use(VueApexCharts);

Vue.component("Apexchart", VueApexCharts);
Vue.use(VueVirtualScroller);
Vue.use(PortalVue);
Vue.use(SchedulingModule, { i18n });
Vue.use(VueSignaturePad);
Vue.use(PiniaVuePlugin);
const pinia = createPinia();

const app = {
  apolloProvider: apolloProvider,
  i18n,
  pinia,
  render: (h: VueCreateElement) => h(App),
  router,
  setup() {
    provide(DefaultApolloClient, setupApolloClient);
    const { setEnv } = useEnvStore();
    setEnv(import.meta.env);
  },
  vuetify,
  watch: {
    $route() {
      if ((window as any).Intercom) {
        (window as any).Intercom("update");
      }
    },
  },
};

new Vue(app).$mount("#app");

unwrapDirectives(Vue);
