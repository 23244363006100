import { ESectionName } from "../rights/types";

const teamContentRoutes = [
  {
    children: [
      {
        component: () =>
          import("@/modules/contents/views/ContentsHome/AllContents.vue"),
        meta: {
          requiresAuth: true,
        },
        name: "AllTeamContents",
        path: "contents/:category",
      },
      {
        component: () =>
          import(
            "@/modules/contents/views/ContentsHome/RefineByDocumentType.vue"
          ),
        meta: {
          requiresAuth: true,
        },
        name: "TeamContentByDocumentTypes",
        path: "contents/:category/document-types",
      },
      {
        component: () =>
          import(
            "@/modules/contents/views/ContentsHome/ContentByDocumentType.vue"
          ),
        meta: {
          requiresAuth: true,
        },
        name: "TeamContentByDocumentType",
        path: "contents/:category/document-types/:documentTypeId",
      },
      {
        component: () =>
          import("@/modules/contents/views/ContentsHome/RefineByPosition.vue"),
        meta: {
          requiresAuth: true,
        },
        name: "TeamContentByPositions",
        path: "contents/:category/teams/positions",
      },
      {
        component: () =>
          import("@/modules/contents/views/ContentsHome/ContentByPosition.vue"),
        meta: {
          requiresAuth: true,
        },
        name: "TeamContentByPosition",
        path: "contents/:category/teams/positions/:positionId",
      },
      {
        component: () =>
          import("@/modules/contents/views/ContentsHome/RefineBySkill.vue"),
        meta: {
          requiresAuth: true,
        },
        name: "TeamContentBySkills",
        path: "contents/:category/teams/skills",
      },
      {
        component: () =>
          import("@/modules/contents/views/ContentsHome/ContentBySkill.vue"),
        meta: {
          requiresAuth: true,
        },
        name: "TeamContentBySkill",
        path: "contents/:category/teams/skills/:skillId",
      },
      {
        component: () =>
          import("@/modules/contents/views/ContentsHome/RefineByClearance.vue"),
        meta: {
          requiresAuth: true,
        },
        name: "TeamContentByClearances",
        path: "contents/:category/teams/clearances",
      },
      {
        component: () =>
          import(
            "@/modules/contents/views/ContentsHome/ContentByClearance.vue"
          ),
        meta: {
          requiresAuth: true,
        },
        name: "TeamContentByClearance",
        path: "contents/:category/teams/clearances/:clearanceId",
      },
    ],
    component: () => import("@/modules/contents/views/ContentsHome.vue"),
    meta: {
      requiresAuth: true,
      userRightsSection: ESectionName.Enum.team_contents,
    },
    name: "TeamContentV2",
    path: "contentv2",
    redirect: "contentv2/contents/instruction/teams/positions",
  },
];

export default teamContentRoutes;
