import { childrenAnalysis } from "./analysis/routes";
import {
  childrenFunctionSheet,
  childrenFunctionSheetDisplay,
  childrenFunctionSheetMatching,
} from "./function-sheet/routes";

const childrenWorkforcePlanning = [
  {
    children: [
      {
        children: childrenFunctionSheet,
        // name: "workforce-planning-function-sheet",
        component: () =>
          import(
            "@/modules/workforce-planning/function-sheet/views/FunctionSheetMain.vue",
          ),
        meta: {
          requiresAuth: true,
          userRightsSection: "workforce_planning",
        },
        path: "function-sheet",
      },
      // {
      //   path: "scenarios",
      //   name: "workforce-planning-scenarios",
      //   component: () => import("@/modules/workforce-planning/"),
      //   meta: {
      //     requiresAuth: true,
      // userRightsSection: "workforce_planning",
      //   },
      // },
      // {
      //   path: "action-plans",
      //   name: "workforce-planning-action-plans",
      //   component: () => import("@/modules/workforce-planning/"),
      //   meta: {
      //     requiresAuth: true,
      // userRightsSection: "workforce_planning",
      //   },
      // },
      {
        children: childrenAnalysis,
        // name: "workforce-planning-analysis",
        component: () =>
          import(
            "@/modules/workforce-planning/analysis/views/AnalysisMain.vue",
          ),
        meta: {
          requiresAuth: true,
          userRightsSection: "workforce_planning",
        },
        path: "analysis",
      },
    ],
    component: () =>
      import("@/modules/workforce-planning/views/WorkforcePlanningMain.vue"),
    meta: {
      requiresAuth: true,
      userRightsSection: "workforce_planning",
    },
    name: "workforce-planning",
    path: "",
    redirect: "function-sheet",
  },
  {
    component: () =>
      import(
        "@/modules/workforce-planning/function-sheet/views/FunctionSheetForm.vue",
      ),
    meta: {
      requiresAuth: true,
      userRightsSection: "workforce_planning",
    },
    name: "function-sheet-form",
    path: "function-sheet-form/:id?",
  },
  {
    children: childrenFunctionSheetDisplay,
    component: () =>
      import(
        "@/modules/workforce-planning/function-sheet/views/FunctionSheetDisplayMain.vue",
      ),
    meta: {
      requiresAuth: true,
      userRightsSection: "workforce_planning",
    },
    path: "function-sheet-display/:id?",
  },
  {
    children: childrenFunctionSheetMatching,
    component: () =>
      import(
        "@/modules/workforce-planning/function-sheet/views/FunctionSheetMatchList.vue",
      ),
    meta: {
      requiresAuth: true,
      userRightsSection: "workforce_planning",
    },
    name: "function-sheet-matching",
    path: "function-sheet-matching",
  },
  {
    component: () =>
      import(
        "@/modules/workforce-planning/function-sheet/views/FunctionSheetAddCollab.vue",
      ),
    meta: {
      requiresAuth: true,
    },
    name: "function-sheet-add-collab",
    path: "function-sheet-add-collab/:id?",
  },
  {
    component: () =>
      import(
        "@/modules/workforce-planning/function-sheet/views/FunctionSheetUpdateCategory.vue",
      ),
    meta: {
      requiresAuth: true,
    },
    name: "function-sheet-update-category",
    path: "function-sheet-update-category",
  },
  {
    component: () =>
      import(
        "@/modules/workforce-planning/function-sheet/views/FunctionSheetAssignUIMMScore.vue",
      ),
    meta: {
      requiresAuth: true,
      userRightsSection: "workforce_planning",
    },
    name: "assign-uimm-score",
    path: "assign-uimm-score/:id?",
  },
];

export default childrenWorkforcePlanning;
