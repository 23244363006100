import childrenGoals from "@/modules/goals/routes";
import { ESectionName } from "@/modules/rights/types";

const childrenMonitoring = [
  {
    alias: "",
    component: () =>
      import("@/components/monitoring/MonitoringProcessesList.vue"),
    meta: {
      requiresAuth: true,
      userRightsSection: ESectionName.Enum.monitoring_cartography,
    },
    name: "MonitoringProcessesList",
    path: "processes",
  },
  {
    children: childrenGoals,
    component: () => import("@/modules/goals/views/GoalRouter.vue"),
    meta: {
      requiresAuth: true,
      userRightsSection: ESectionName.Enum.monitoring_goals,
    },
    path: "goals",
  },
  {
    component: () => import("@/modules/jobs/views/JobList.vue"),
    meta: {
      requiresAuth: true,
      userRightsSection: ESectionName.Enum.monitoring_jobs,
    },
    name: "JobsList",
    path: "jobs/list",
  },
  {
    component: () => import("@/modules/jobs/views/JobForm.vue"),
    meta: {
      requiresAuth: true,
      userRightsSection: ESectionName.Enum.monitoring_jobs,
    },
    name: "monitoring-job",
    path: "job/:id?",
  },
];

export default childrenMonitoring;
