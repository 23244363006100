<template>
  <div @paste="onPaste">
    <quill-editor
      :content="content"
      :options="editorOption"
      @change="onEditorChange($event)"
    />
  </div>
</template>

<script lang="ts">
import { v4 as uuidv4 } from "uuid";
import Vue from "vue";
import { quillEditor } from "vue-quill-editor";

import "@/assets/quill/quill.bubble.css";
import "@/assets/quill/quill.core.css";
import "@/assets/quill/quill.snow.css";
import useStorage from "@/composables/use-storage";

export default Vue.extend({
  components: {
    quillEditor,
  },
  data() {
    return {
      editorOption: {
        modules: {
          toolbar: [
            ["bold", "italic", "underline", "strike"],
            [{ color: [] }, { background: [] }],
            [{ header: [] }, "blockquote"],
            [
              { list: "ordered" },
              { list: "bullet" },
              { indent: "-1" },
              { indent: "+1" },
            ],
            ["direction"],
            [
              { align: "" },
              { align: "center" },
              { align: "right" },
              { align: "justify" },
            ],
            ["link"],
            ["image"],
          ],
        },
        // eslint-disable-next-line @typescript-eslint/no-base-to-string
        placeholder: `(1) ${this.$t("content.wysiwyg_placeholder")}`,
      },
      selectionRangeIndex: 0,
    };
  },
  methods: {
    async onEditorChange(event) {
      const { base64toFile, saveFileIntoFirebaseStorage } = useStorage();
      const delta = await event.quill.getContents();
      if (delta?.ops?.length > 0) {
        for (const op of delta.ops) {
          if (op?.insert?.image) {
            if (!op.insert.image.startsWith("data:")) continue;
            const file = base64toFile(op.insert.image, `${uuidv4()}.png`);
            const fileUrl = await saveFileIntoFirebaseStorage(file);
            op.insert.image = fileUrl.url;
            const selectionRangeResize = event.quill.getSelection();
            await event.quill.setContents(delta, "silent");
            this.selectionRangeIndex = selectionRangeResize.index;
            const selectionRange = event.quill.getSelection();
            await event.quill.setSelection(
              selectionRange.index || this.selectionRangeIndex,
              selectionRange.length,
            );
          }
        }
      }

      this.$emit("step-data-update", {
        html: event.quill.root.innerHTML,
        text: event.text,
      });
    },
    onPaste($event): void {
      const path = $event.composedPath();
      for (const element of path) {
        if (element.className?.includes("ql-editor")) {
          setTimeout(() => {
            this.scrollToElementBottom(element, 300);
          }, 100);
          break;
        }
      }
    },
    scrollToElementBottom(element: HTMLElement, offset = 0) {
      const elementRect = element.getBoundingClientRect();
      const scrollTop = window.scrollY || document.documentElement.scrollTop;
      const elementBottomPosition = elementRect.bottom + scrollTop;
      window.scrollTo(0, elementBottomPosition - offset);
    },
  },
  name: "QuillWysiwyg",
  props: {
    content: {
      type: String,
    },
  },
});
</script>
