import { gql } from "graphql-tag";

export const CreateFolder = gql`
  mutation CreateFolder($data: FolderCreateInput!) {
    createFolder(data: $data) {
      id
      name
      client_id
      site_id
      status
      published
    }
  }
`;
export const UpdateFolder = gql`
  mutation UpdateFolder(
    $data: FolderUpdateInput!
    $where: FolderWhereUniqueInput!
  ) {
    updateFolder(data: $data, where: $where) {
      id
      name
      client_id
      site_id
      status
      published
    }
  }
`;
export const DeleteFolder = gql`
  mutation DeleteFolder($where: FolderWhereUniqueInput!) {
    deleteFolder(where: $where) {
      id
    }
  }
`;

export const Folders = gql`
  query Folders {
    Folders {
      id
      name
      client_id
      site_id
      status
      published
    }
  }
`;
