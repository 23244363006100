import { gql } from "graphql-tag";

export const CreatePositionOrder = gql`
  mutation CreatePositionOrder($data: PositionOrderCreateInput!) {
    createPositionOrder(data: $data) {
      id
      createdAt
      updatedAt
      status
      published
      order
      client_id
      site_id
      team_id
      position_id
      team {
        id
      }
      position {
        id
      }
    }
  }
`;

export const PositionOrder = gql`
  query PositionOrder($where: PositionOrderWhereUniqueInput!) {
    PositionOrder(where: $where) {
      id
      createdAt
      updatedAt
      status
      published
      order
      client_id
      site_id
      team_id
      position_id
      team {
        id
      }
      position {
        id
      }
    }
  }
`;

export const PositionOrders = gql`
  query PositionOrders($where: PositionOrderWhereUniqueInput!) {
    PositionOrders(where: $where) {
      id
      createdAt
      updatedAt
      status
      published
      order
      client_id
      site_id
      team_id
      position_id
      team {
        id
      }
      position {
        id
      }
    }
  }
`;
