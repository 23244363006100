import {
  datadogRum,
  type RumEvent,
  type RumEventDomainContext,
} from "@datadog/browser-rum";

import {
  DATADOG_GRAPHQL_CUSTOM_HEADER_PREFIX,
  DATADOG_GRAPHQL_OPERATION_NAME_HEADER,
  DATADOG_GRAPHQL_OPERATION_TYPE_HEADER,
} from "./graphql";

export const getDatadogGraphQLContext = (
  event: RumEvent,
  context: RumEventDomainContext,
) => {
  if (
    event.type === "resource" &&
    event.resource.type === "fetch" &&
    event.resource.url.endsWith("/graphql")
  ) {
    const headers = Object.fromEntries(
      context.requestInit.headers.filter(([key]) =>
        key.startsWith(DATADOG_GRAPHQL_CUSTOM_HEADER_PREFIX),
      ),
    );
    const operationName = headers[DATADOG_GRAPHQL_OPERATION_NAME_HEADER];
    const operationType = headers[DATADOG_GRAPHQL_OPERATION_TYPE_HEADER];

    return {
      operationName,
      operationType,
    };
  }

  return {};
};
