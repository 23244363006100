import { ApolloLink, type Operation } from "@apollo/client";
import type { DefinitionNode, OperationDefinitionNode } from "graphql";

import {
  DATADOG_GRAPHQL_OPERATION_NAME_HEADER,
  DATADOG_GRAPHQL_OPERATION_TYPE_HEADER,
} from "./graphql";

/**
 * Logic below is a copy/paste of what is provided by Datadog for React Native interaction with GraphQL
 * (see: https://github.com/DataDog/dd-sdk-reactnative/blob/d984be1de35994a4e17c083d40ac012718a89c52/packages/react-native-apollo-client/src/DatadogLink.ts)
 */
const getOperationName = (operation: Operation): null | string => {
  if (operation.operationName) {
    return operation.operationName;
  }
  return null;
};

const getOperationDefinitionNode = (
  definition: DefinitionNode,
): definition is OperationDefinitionNode => {
  return definition.kind === "OperationDefinition" && !!definition.operation;
};

const getOperationType = (
  operation: Operation,
): "mutation" | "query" | "subscription" | null => {
  try {
    return (
      operation.query.definitions
        .filter(getOperationDefinitionNode)
        .map((operationDefinitionNode) => {
          return operationDefinitionNode.operation;
        })[0] || null
    );
  } catch (e) {
    // TODO RUM-1206: telemetry
    return null;
  }
};

export class DatadogLink extends ApolloLink {
  constructor() {
    super((operation, forward) => {
      const operationName = getOperationName(operation);
      const operationType = getOperationType(operation);

      operation.setContext(({ headers = {} }) => {
        const newHeaders: Record<string, null | string> = {
          ...headers,
        };

        newHeaders[DATADOG_GRAPHQL_OPERATION_TYPE_HEADER] = operationType;
        newHeaders[DATADOG_GRAPHQL_OPERATION_NAME_HEADER] = operationName;

        return {
          headers: newHeaders,
        };
      });

      return forward(operation);
    });
  }
}
