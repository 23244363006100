const childrenTeamContent = [
  {
    component: () => import("@/components/team/TeamContentOverview.vue"),
    name: "team-content-overview",
    path: "overview",
  },
  {
    component: () => import("@/components/content/ContentListGlobal.vue"),
    name: "team-content-list",
    path: "list",
  },
];

export default childrenTeamContent;
