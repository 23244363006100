import { useQuery } from "@vue/apollo-composable";
import { computed } from "vue";

import {
  GET_PENDING_APPROVALS,
  type GetPendingApprovals,
} from "../graphql/approvals/getPendingApprovals.gql";
import { PendingApproval } from "../types/entities/pending-approval.entity";

type Variables = Parameters<
  typeof useQuery<unknown, { skip: number; take: number }>
>[1];

export const useGetPendingApprovalsQuery = (variables: Variables) => {
  const { result, ...rest } = useQuery<GetPendingApprovals>(
    GET_PENDING_APPROVALS,
    variables,
    {
      fetchPolicy: "cache-and-network",
    },
  );

  const maybeParsedResult = computed(() =>
    result.value
      ? {
          pendingReviews: {
            ...result.value.pendingReviews,
            data: result.value.pendingReviews.data.map((f) =>
              PendingApproval.parse(f),
            ),
          },
        }
      : undefined,
  );

  return {
    ...rest,
    result: maybeParsedResult,
  };
};
