import { datadogRum } from "@datadog/browser-rum";
import {
  getAuth,
  SAMLAuthProvider,
  signInWithPopup,
  type UserCredential,
} from "firebase/auth";
import { endsWith } from "lodash";

export const signInWithProvider = async (
  email: string,
): Promise<null | UserCredential> => {
  try {
    let provider: null | SAMLAuthProvider = null;
    // Logic get user from email and return client_id.
    if (email.includes("andros.fr") || email === "nayir@merca.team") {
      provider = new SAMLAuthProvider("saml.andros");
    } else if (
      email.includes("savencia.com") ||
      email.includes("revillonchocolatier.fr")
    ) {
      provider = new SAMLAuthProvider("saml.valrhona");
    } else if (email.includes("shiseido")) {
      provider = new SAMLAuthProvider("saml.shiseido");
    } else if (email.includes("vaisala")) {
      provider = new SAMLAuthProvider("saml.vaisala");
    } else if (email.includes("redoute.")) {
      provider = new SAMLAuthProvider("saml.laredoute");
    } else if (email.includes("aubertduval")) {
      provider = new SAMLAuthProvider("saml.aubert-et-duval");
    } else if (email.includes("unither-pharma")) {
      provider = new SAMLAuthProvider("saml.unither-pharma");
    } else if (email.includes("grandvision")) {
      provider = new SAMLAuthProvider("saml.grand-vision");
    } else if (email.includes("@audemarspiguet.com")) {
      provider = new SAMLAuthProvider("saml.audemars");
    } else if (email.includes("@lisi-group.com")) {
      provider = new SAMLAuthProvider("saml.lisi");
    } else if (email.includes("@sigvaris.com")) {
      provider = new SAMLAuthProvider("saml.sigvaris");
    } else if (email.includes("@bonduelle.com")) {
      provider = new SAMLAuthProvider("saml.bonduelle");
    } else if (email.includes("@diormail.com")) {
      provider = new SAMLAuthProvider("saml.dior");
    } else if (email.includes("@lisi-aerospace.com")) {
      provider = new SAMLAuthProvider("saml.lisi");
    } else if (email.includes("@lisi-medical.com")) {
      provider = new SAMLAuthProvider("saml.lisi");
    } else if (email.includes("@lisi-automotive.com")) {
      provider = new SAMLAuthProvider("saml.lisi");
    } else if (email.includes("@nutriset.fr")) {
      provider = new SAMLAuthProvider("saml.nutriset");
    } else if (email.includes("@onyx-dev.fr")) {
      provider = new SAMLAuthProvider("saml.nutriset");
    } else if (endsWith(email, "@yposkesi.com")) {
      provider = new SAMLAuthProvider("saml.yposkesi");
    } else if (
      endsWith(email, "@saint-gobain.com") ||
      endsWith(email, "@ext.saint-gobain.com")
    ) {
      provider = new SAMLAuthProvider("saml.saintgobain");
    } else if (endsWith(email, "@groupeseb.com")) {
      provider = new SAMLAuthProvider("saml.seb");
    } else if (endsWith(email, "@europesnacks.com")) {
      provider = new SAMLAuthProvider("saml.europesnacks");
    } else if (endsWith(email, "@upsa-ph.com")) {
      provider = new SAMLAuthProvider("saml.upsa");
    } else if (endsWith(email, "@haleon.com")) {
      provider = new SAMLAuthProvider("haleon.upsa");
    } else {
      const error = new Error("Unknown SAML identifier");
      datadogRum.addError(error, {
        email,
        feature: "sso",
      });
      throw error;
    }
    const auth = getAuth();
    const user = await signInWithPopup(auth, provider);
    return user;
  } catch (error) {
    datadogRum.addError(error, {
      email,
      feature: "sso",
    });
    console.error(error);
    return null;
  }
};
