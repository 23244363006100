import type { DataProxy } from "@apollo/client";

import {
  CreateFactory,
  DeleteFactory,
  DeleteManyFactory,
  Factories,
  UpdateFactory,
} from "@/graphql/admin/Factories";
import { useFactoryGlobalStore } from "@/pinia/collections";
import { apolloProvider } from "@/vue-apollo";

import {
  updateStoreAfterCreation,
  updateStoreAfterDeletion,
  updateStoreAfterUpdate,
} from "./utils";

const refetch = async () => useFactoryGlobalStore().refetch("no-cache", false);

export const createFactory = async (payload: { data: any }) => {
  try {
    const result = await apolloProvider.defaultClient.mutate({
      mutation: CreateFactory,
      update: (cache, data) => {
        updateStoreAfterCreation(
          cache as DataProxy,
          Factories,
          "Factories",
          data.data.createFactory,
          useFactoryGlobalStore(),
        );
      },
      variables: {
        data: payload.data,
      },
    });
    await refetch();
    return result;
  } catch (error: any) {
    console.error("GRAPHQL ERROR", error);
  }
};

export const updateFactory = async (payload: { data: any; where: any }) => {
  try {
    const result = await apolloProvider.defaultClient.mutate({
      mutation: UpdateFactory,
      update: (cache, data) => {
        updateStoreAfterUpdate(
          cache as DataProxy,
          Factories,
          "Factories",
          data.data.updateFactory,
          useFactoryGlobalStore(),
        );
      },
      variables: {
        data: payload.data,
        where: payload.where,
      },
    });
    await refetch();
    return result;
  } catch (error: any) {
    console.error("GRAPHQL ERROR", error);
  }
};

export const deleteFactory = async (payload: { where: any }) => {
  try {
    const result = await apolloProvider.defaultClient.mutate({
      mutation: DeleteFactory,
      update: (cache, data) => {
        updateStoreAfterDeletion(
          cache as DataProxy,
          Factories,
          "Factories",
          data.data.delete_Factory,
          useFactoryGlobalStore(),
        );
      },
      variables: {
        where: payload.where,
      },
    });
    await refetch();
    return result;
  } catch (error: any) {
    console.error("GRAPHQL ERROR", error);
  }
};

export const deleteManyFactory = async (payload: { where: any }) => {
  try {
    const result = await apolloProvider.defaultClient.mutate({
      mutation: DeleteManyFactory,
      variables: {
        where: payload.where,
      },
    });
    await refetch();
    return result;
  } catch (error: any) {
    console.error("GRAPHQL ERROR", error);
  }
};
