import {
  CreateFolder,
  DeleteFolder,
  Folders,
  UpdateFolder,
} from "@/graphql/settings/Folders";
import { useParameterFolderStore } from "@/pinia/collections";
import { setGlobalStoreGraphQL } from "@/utils/graphql.utils";
import { apolloProvider } from "@/vue-apollo";

export const createFolder = async (payload: { data: any }) => {
  try {
    return await apolloProvider.defaultClient.mutate({
      mutation: CreateFolder,
      update: (store, { data: { createFolder } }) => {
        updateStoreAfterCreateFolder(store, createFolder);
      },
      variables: {
        data: payload.data,
      },
    });
  } catch (error) {
    console.error(error);
  }
};

export const updateFolder = async (payload: { data: any; where: any }) => {
  try {
    return await apolloProvider.defaultClient.mutate({
      mutation: UpdateFolder,
      update: (store, data) => {
        updateStoreAfterUpdateFolder(store, data.data.updateFolder);
      },
      variables: {
        data: payload.data,
        where: payload.where,
      },
    });
  } catch (error) {
    console.error(error);
  }
};

export const deleteFolder = async (payload: { where: any }) => {
  try {
    return await apolloProvider.defaultClient.mutate({
      mutation: DeleteFolder,
      update: (store, data) => {
        updateStoreAfterDeleteFolder(store, data.data.deleteFolder.id);
      },
      variables: {
        where: payload.where,
      },
    });
  } catch (error) {
    console.error(error);
  }
};

const updateStoreAfterCreateFolder = (store, createFolder) => {
  const data = store.readQuery({
    query: Folders,
  });
  data.Folders.push(createFolder);
  setGlobalStoreGraphQL(useParameterFolderStore(), data.Folders);
  store.writeQuery({
    data,
    query: Folders,
  });
};

const updateStoreAfterDeleteFolder = (store, id) => {
  const data = store.readQuery({
    query: Folders,
  });
  const index = data.Folders.findIndex((c) => c.id === id);
  if (index !== -1) {
    data.Folders.splice(index, 1);
    setGlobalStoreGraphQL(useParameterFolderStore(), data.Folders);
    store.writeQuery({
      data,
      query: Folders,
    });
  }
};

const updateStoreAfterUpdateFolder = (store, updateFolder) => {
  const data = store.readQuery({
    query: Folders,
  });
  const index = data.Folders.findIndex((c) => c.id === updateFolder.id);
  if (index !== -1) {
    data.Folders.splice(index, 1, updateFolder);
    setGlobalStoreGraphQL(useParameterFolderStore(), data.Folders);
    store.writeQuery({
      data,
      query: Folders,
    });
  }
};
