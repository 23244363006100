import type { RumInitConfiguration } from "@datadog/browser-rum";

import { version } from "../../../package.json";
import { config } from "../../config";

const rawSampling = Number(config.datadog.sampling);
const sampling = Number.isNaN(rawSampling) ? 100 : rawSampling;

export const DATADOG_CONFIG: RumInitConfiguration = {
  allowedTracingUrls: [config.backend.url],
  applicationId: "0a1c9ea9-c37c-405d-a28e-76da6e0871af",
  clientToken: "pub510e65142f98e62029c5dfef2387d382",
  compressIntakeRequests: true,
  defaultPrivacyLevel: "allow",
  env: config.mode,
  service: "apps-core",
  sessionReplaySampleRate: sampling,
  sessionSampleRate: sampling,
  site: "datadoghq.eu",
  version,
};
