export const config = {
  backend: {
    url: import.meta.env.VITE_BACKEND_URL,
  },
  datadog: {
    isEnabled: import.meta.env.VITE_DATADOG_ENABLED === "true",
    sampling: import.meta.env.VITE_DATADOG_SAMPLING,
  },
  firebase: {
    serviceAccount: import.meta.env.VITE_FIREBASE_SERVICE_ACCOUNT,
  },
  gcp: {
    errorReporting: {
      isEnabled: import.meta.env.VITE_GCP_ERROR_REPORTING_ENABLED === "true",
      key: import.meta.env.VITE_GCP_ERROR_REPORTING_KEY,
      projectId: import.meta.env.VITE_GCP_ERROR_REPORTING_PROJECT_ID,
      service: import.meta.env.VITE_GCP_ERROR_REPORTING_SERVICE,
    },
  },
  // Enables access to various hidden features when working locally or in staging
  hasAccessToDevFeatures:
    import.meta.env.VITE_HAS_ACCESS_TO_DEV_FEATURES === "true",
  importExcelFilesUrl: {
    employees: import.meta.env.VITE_IMPORT_EXCEL_FILES_EMPLOYEE_URL,
    holidays: import.meta.env.VITE_IMPORT_EXCEL_FILES_HOLIDAYS_URL,
    shifts: import.meta.env.VITE_IMPORT_EXCEL_FILES_SHIFTS_URL,
  },
  mode: import.meta.env.MODE,
};
