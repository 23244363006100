import { find } from "lodash";

import { useAuthentificationStore } from "@/pinia/user";

import { ESectionName, type InputLSectionValues } from "../types";

export const complementaryModules: ESectionName[] = [
  "performance_reviews",
  "workforce_planning",
  "workforce_planning_uimm",
];

export const buildUserRightsInputFromPricing = (
  userRightsInputLSections: any,
  pricingSections: any,
  userLSections?: any,
  customValues?: InputLSectionValues,
): void => {
  const { data: userData } = useAuthentificationStore();
  for (const pricingSection of pricingSections) {
    let userLSection: any = null;

    let readonlyValue = pricingSection.default_value_readonly;
    let editValue = pricingSection.default_value_edit;

    if (customValues) {
      readonlyValue = customValues.readonly;
      editValue = customValues.edit;
    } else if (userLSections?.length) {
      userLSection = find(
        userLSections,
        (userLSection) => userLSection.section.id === pricingSection.id,
      );
      if (userLSection) {
        readonlyValue = userLSection.values.readonly;
        editValue = userLSection.values.edit;
      }
    }

    // TODO (existing debt ticket) Implement the setting_feature check, cleaner and more generic
    if (
      !userData?.site?.settings_kpis &&
      pricingSection.section_name === ESectionName.Enum.kpis
    ) {
      continue;
    }

    userRightsInputLSections.push({
      section: {
        available_options_edit: pricingSection.available_options_edit,
        available_options_readonly: pricingSection.available_options_readonly,
        id: pricingSection.id,
        section_name: pricingSection.section_name,
        section_translate_name: pricingSection.section_translate_name,
      },
      values: {
        edit: editValue,
        readonly: readonlyValue,
      },
    });
  }
};
