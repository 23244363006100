import type { VueConstructor } from "vue";

import vClosable from "./closable.directive";
import vTooltip from "./tooltip.directive";

const directives = [vTooltip, vClosable];

const unwrapDirectives = (appInstance: VueConstructor) => {
  directives.forEach((directive) => {
    directive.applyTo(appInstance);
  });
};

export default unwrapDirectives;
