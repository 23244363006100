import { computed } from "vue";

import usePermissions from "@/modules/rights/composables/use-permissions";
import {
  EPricingPlan,
  ESectionName,
  ESectionRightValue,
} from "@/modules/rights/types";
import { useAuthentificationStore } from "@/pinia/user";

import { useDefaultPlanningRoute } from "./useDefaultPlanningRoute";

const getOrderedAuthorizedTeamRoutes = (teamId: string) => {
  const userRightTeamInformations = usePermissions(
    ESectionName.Enum.team_informations,
  );
  const userRightTeamEmployees = usePermissions(
    ESectionName.Enum.team_employees,
  );
  const userRightTeamOverview = usePermissions(ESectionName.Enum.team_overview);
  const userRightTeamVersatility = usePermissions(
    ESectionName.Enum.team_versatility,
  );
  const userRightTeamPlanningMangement = usePermissions(
    ESectionName.Enum.team_planning_management,
  );
  const userRightTeamAbsences = usePermissions(ESectionName.Enum.team_absences);
  const userRightTeamContents = usePermissions(ESectionName.Enum.team_contents);
  const userRightsEmployeeSkillsAndClearances = usePermissions(
    ESectionName.Enum.employee_skills_and_clearances,
  );

  const { active_site_full, data: userData } = useAuthentificationStore();

  const isTeamLeader = computed(() => {
    const teamsAsLeaderID = userData?.teams_as_leader.map(({ id }) => id);
    if (!teamsAsLeaderID) return false;
    return Boolean(teamsAsLeaderID.find((id) => id === teamId));
  });

  const hasAccessToContentV2 = computed(() => {
    return (
      active_site_full?.pricing.name === EPricingPlan.Enum.SkillMaster ||
      active_site_full?.pricing.name === EPricingPlan.Enum.SkillTrainer
    );
  });

  const planningRoute = useDefaultPlanningRoute();

  return [
    {
      path: "informations",
      right: userRightTeamInformations.readAllowed.value,
    },
    { path: "employees", right: userRightTeamEmployees.readAllowed.value },
    {
      path: "overview2/skills",
      right:
        userRightTeamOverview.readAllowed.value &&
        userRightsEmployeeSkillsAndClearances.readAllowed.value,
    },
    { path: "versatility", right: userRightTeamVersatility.readAllowed.value },
    {
      path: planningRoute,
      right:
        userRightTeamPlanningMangement.readAllowed.value ||
        (userRightTeamPlanningMangement.rights.value.readonly ===
          ESectionRightValue.Enum.limited_access &&
          isTeamLeader),
    },
    {
      path: "absences/table",
      right:
        userRightTeamAbsences.readAllowed.value ||
        (userRightTeamAbsences.rights.value.readonly ===
          ESectionRightValue.Enum.limited_access &&
          isTeamLeader),
    },
    {
      path: "content/overview",
      right:
        userRightTeamContents.readAllowed.value &&
        active_site_full?.settings_content_feature,
    },
    {
      path: "contentv2/",
      right: userRightTeamContents.readAllowed.value && hasAccessToContentV2,
    },
  ];
};

export const useDefaultTeamRoute = (teamId: string) => {
  const orderedTeamRoutes = getOrderedAuthorizedTeamRoutes(teamId);

  const path = orderedTeamRoutes.find((route) => route.right)?.path;
  return (!!path && `/team/${teamId}/${path}`) || "/access-denied";
};
