import gql from "graphql-tag";

import { apolloProvider } from "@/vue-apollo";

export default function useDocumentation() {
  async function getDocumentations(routeName: string): Promise<any[]> {
    const result = await apolloProvider.defaultClient.query({
      fetchPolicy: "no-cache",
      query: gql`
        query Documentations($where: DocumentationWhereInput) {
          Documentations(where: $where) {
            id
            routeName
            productDescription
            techDescription
          }
        }
      `,
      variables: {
        where: {
          routeName: {
            equals: routeName,
          },
        },
      },
    });

    return result?.data?.Documentations;
  }

  async function createDocumentation(data: {
    productDescription: string;
    routeName: string;
    techDescription: string;
  }): Promise<any> {
    const result = await apolloProvider.defaultClient.mutate({
      mutation: gql`
        mutation CreateDocumentation($data: DocumentationCreateInput!) {
          createDocumentation(data: $data) {
            id
            routeName
            productDescription
            techDescription
          }
        }
      `,
      variables: {
        data,
      },
    });

    return result?.data?.createDocumentation;
  }

  async function updateDocumentation(
    id: string,
    data: {
      productDescription?: string;
      routeName: string;
      techDescription?: string;
    },
  ): Promise<any> {
    const result = await apolloProvider.defaultClient.mutate({
      mutation: gql`
        mutation UpdateDocumentation(
          $data: DocumentationUpdateInput!
          $where: DocumentationWhereUniqueInput!
        ) {
          updateDocumentation(data: $data, where: $where) {
            id
            routeName
            productDescription
            techDescription
          }
        }
      `,
      variables: {
        data,
        where: {
          id,
        },
      },
    });

    return result?.data?.updateDocumentation;
  }

  return { createDocumentation, getDocumentations, updateDocumentation };
}
