import { z } from "zod";

import {
  GlobalAssessment,
  NewGoals,
  ReviewResponse,
} from "./entities/review-response";

export const SaveReview = z.object({
  campaign_id: z.string(),
  employee_id: z.string(),
  global_assessment: GlobalAssessment,
  goals: NewGoals,
  responses: ReviewResponse,
});

export type SaveReview = z.infer<typeof SaveReview>;
