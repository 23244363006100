import CustomDirective from "./class/CustomDirective.class";

// This variable will hold the reference to
// document's click handler
let handleOutsideClick: (e: Event) => void;
const vClosable = new CustomDirective("closable", {
  bind(el, binding, vnode) {
    // Here's the click/touchstart handler
    // (it is registered below)
    handleOutsideClick = (e: Event) => {
      e.stopPropagation();
      // Get the handler method name and the exclude array
      // from the object used in v-closable
      const { exclude, handler } = binding.value;
      // This variable indicates if the clicked element is excluded
      let clickedOnExcludedEl = false;
      exclude.forEach((refName) => {
        // We only run this code if we haven't detected
        // any excluded element yet
        if (!clickedOnExcludedEl) {
          // Get the element using the reference name

          const excludedEl: any = vnode?.context
            ? vnode.context.$refs[refName]
            : "null";
          // See if this excluded element
          // is the same element the user just clicked on
          clickedOnExcludedEl = excludedEl
            ? excludedEl.contains(e.target)
            : null;
        }
      });
      // We check to see if the clicked element is not
      // the dialog element and not excluded
      if (!el.contains(e.target as Node) && !clickedOnExcludedEl) {
        // If the clicked element is outside the dialog
        // and not the button, then call the outside-click handler
        // from the same component this directive is used in
        if (vnode?.context) {
          const myContext = (vnode = vnode.context[handler]);
          myContext();
        }
      }
    };
    // Register click/touchstart event listeners on the whole page
    document.addEventListener("click", handleOutsideClick);
    document.addEventListener("touchstart", handleOutsideClick);
  },
  unbind() {
    document.removeEventListener("click", handleOutsideClick);
    document.removeEventListener("touchstart", handleOutsideClick);
  },
});

export default vClosable;
