<template>
  <v-layout row wrap>
    <v-list-item class="text-left" style="overflow: hidden">
      <v-badge
        :color="badgeColor"
        :value="realBadgeValue"
        bordered
        bottom
        class="print-disabled"
        dot
        offset-x="19"
        offset-y="15"
      >
        {{ employeeId }}

        <AvatarPicture
          :first-name="firstName"
          :id="id"
          :last-name="lastName"
          :picture-url="pictureUrl"
          :size="size"
        />
      </v-badge>
      <v-list-item-content v-if="!inlineName">
        <div class="d-flex align-start text-left">
          <span v-if="data.firstname_parameter">
            {{ lastName }} {{ firstName }}</span
          >
          <span v-else> {{ firstName }} {{ lastName }} </span>
        </div>
        <v-list-item-subtitle v-if="job">{{ job }}</v-list-item-subtitle>
        <v-list-item-subtitle>
          <v-chip
            :color="contract.color_bg"
            :text-color="contract.color_text"
            class="mr-2 pointer"
            label
            small
            v-if="
              contract &&
              contract.contract_name &&
              contract.color_bg &&
              contract.color_text
            "
          >
            <span style="font-size: 10px">{{ contract.contract_name }}</span>
          </v-chip>
          <span style="font-size: 10px" v-if="teamName">{{ teamName }}</span>
        </v-list-item-subtitle>
        <v-list-item-subtitle
          class="font-weight-light body-3 light_grey--text"
          v-if="employeeId"
          >{{ $t("global.employee_id") }}{{ employeeId }}</v-list-item-subtitle
        >
        <v-list-item-subtitle v-if="seniorityYears"
          >{{ seniorityYears }} {{ $t("global.years") }}
        </v-list-item-subtitle>
        <v-list-item-subtitle v-if="birthday">{{
          birthday
        }}</v-list-item-subtitle>
        <v-list-item-subtitle v-if="startDate">{{
          startDate
        }}</v-list-item-subtitle>
      </v-list-item-content>
      <div v-if="dropdown">
        <v-icon
          :class="{[$style.dropdownClosed]: dropdownOpen === 'false'}"
          color="grey_dark"
          small
          >mdi-triangle</v-icon
        >
      </div>
    </v-list-item>
    <div v-if="inlineName">{{ firstName }} {{ lastName }}</div>
  </v-layout>
</template>

<script lang="ts">
import { mapState } from "pinia";
import Vue from "vue";

import AvatarPicture from "@/components/m-avatar-picture.vue";
import { useAuthentificationStore } from "@/pinia/user";

export default Vue.extend({
  components: {
    AvatarPicture,
  },
  computed: {
    ...mapState(useAuthentificationStore, {
      data: "data",
    }),
    realBadgeValue: function (): any {
      if (this.badgeValue) return this.badgeValue;
      return 0;
    },
  },
  name: "AvatarComp",
  props: [
    "id",
    "firstName",
    "lastName",
    "pictureUrl",
    "size",
    "job",
    "inlineName",
    "teamName",
    "employeeId",
    "seniorityYears",
    "birthday",
    "startDate",
    "badgeValue",
    "badgeColor",
    "contract",
    "dropdown",
    "dropdownOpen",
    "defaultWidth",
  ],
});
</script>

<style lang="scss" module>
.dropdownClosed {
  transform: rotateX(180deg);
}
</style>
