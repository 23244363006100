import type { DataProxy } from "@apollo/client";

import {
  CreateShift,
  DeleteShift,
  Shifts,
  UpdateShift,
} from "@/graphql/settings/planning/Shift";
import { useParameterPlanningStore } from "@/pinia/collections";
import { apolloProvider } from "@/vue-apollo";

import {
  updateStoreAfterCreation,
  updateStoreAfterDeletion,
  updateStoreAfterUpdate,
} from "./utils";

export const createShift = async (payload: { data: any }) => {
  try {
    return await apolloProvider.defaultClient.mutate({
      mutation: CreateShift,
      update: (cache, data) => {
        updateStoreAfterCreation(
          cache as DataProxy,
          Shifts,
          "Shifts",
          data.data.createShift,
          useParameterPlanningStore(),
        );
      },
      variables: {
        data: payload.data,
      },
    });
  } catch (error) {
    console.error(error);
  }
};

export const updateShift = async (payload: { data: any; where: any }) => {
  try {
    return await apolloProvider.defaultClient.mutate({
      mutation: UpdateShift,
      update: (cache, data) => {
        updateStoreAfterUpdate(
          cache as DataProxy,
          Shifts,
          "Shifts",
          data.data.updateShift,
          useParameterPlanningStore(),
        );
      },
      variables: {
        data: payload.data,
        where: payload.where,
      },
    });
  } catch (error) {
    console.error(error);
  }
};

export const deleteShift = async (payload: { where: any }) => {
  try {
    return await apolloProvider.defaultClient.mutate({
      mutation: DeleteShift,
      update: (cache, data) => {
        updateStoreAfterDeletion(
          cache as DataProxy,
          Shifts,
          "Shifts",
          data.data.deleteShift,
          useParameterPlanningStore(),
        );
      },
      variables: {
        where: payload.where,
      },
    });
  } catch (error) {
    console.error(error);
  }
};
