const childrenPlanningPosition = [
  {
    component: () => import("@/views/planning/position/PositionDay.vue"),
    name: "PlanningDayPosition",
    path: "day",
  },
  {
    component: () => import("@/views/planning/position/PositionWeekOpti.vue"),
    name: "PlanningWeekPositionv3",
    path: "weekv3",
  },
  {
    component: () => import("@/views/planning/position/PositionWeek.vue"),
    name: "PlanningWeekPosition",
    path: "week",
  },
  {
    component: () => import("@/views/planning/position/PositionMonth.vue"),
    name: "PlanningMonthPosition",
    path: "month",
  },
];

export default childrenPlanningPosition;
