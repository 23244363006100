import { gql } from "graphql-tag";

export const CreatePosition = gql`
  mutation CreatePosition($data: PositionCreateInput!) {
    createPosition(data: $data) {
      id
      name
      status
      site {
        name
      }
      subpositions {
        id
        name
        teams {
          id
        }
      }
      clearances {
        id
        name
        status
      }
      restrictions {
        id
        name
        status
      }
      teams {
        id
        team_name
        status
      }
      cover
      shift_positions
      restricted_positions
      internal_code
      link_positions_skill(where: {skill: {is: {status: {equals: active}}}}) {
        id
        skill {
          id
          status
          name
          level
        }
        level_min
      }
    }
  }
`;

export const UpdatePosition = gql`
  mutation UpdatePosition(
    $data: PositionUpdateInput!
    $where: PositionWhereUniqueInput!
  ) {
    updatePosition(data: $data, where: $where) {
      id
      name
      status
      site {
        name
      }
      subpositions {
        id
        name
        teams {
          id
        }
      }
      clearances {
        id
        name
        status
      }
      restrictions {
        id
        name
        status
      }
      teams {
        id
        team_name
        status
      }
      cover
      shift_positions
      restricted_positions
      internal_code
      link_positions_skill(where: {skill: {is: {status: {equals: active}}}}) {
        id
        skill {
          id
          status
          name
          level
        }
        level_min
      }
    }
  }
`;

export const DeletePosition = gql`
  mutation DeletePosition($where: PositionWhereUniqueInput!) {
    deletePosition(where: $where) {
      id
    }
  }
`;

export const Position = gql`
  query position($where: PositionWhereUniqueInput!) {
    Position(where: $where) {
      id
      name
      status
      site {
        name
      }
      subpositions(where: {status: {equals: active}}) {
        id
        name
        teams {
          id
        }
      }
      clearances {
        id
        name
        status
      }
      restrictions {
        id
        name
        status
      }
      teams {
        id
        team_name
        status
      }
      cover
      shift_positions
      restricted_positions
      internal_code
      link_positions_skill(where: {skill: {is: {status: {equals: active}}}}) {
        id
        skill {
          id
          status
          name
          level
        }
        level_min
      }
    }
  }
`;

export const DeleteManyPosition = gql`
  mutation DeleteManyPosition($where: PositionWhereInput) {
    deleteManyPosition(where: $where) {
      count
    }
  }
`;

export const Positions = gql`
  query Positions($where: PositionWhereInput) {
    Positions(where: $where) {
      id
      name
      status
      site {
        name
      }
      subpositions {
        id
        name
        teams {
          id
        }
      }
      clearances {
        id
        name
        status
      }
      restrictions {
        id
        name
        status
      }
      teams {
        id
        team_name
        status
      }
      cover
      shift_positions
      restricted_positions
      internal_code
      link_positions_skill(where: {skill: {is: {status: {equals: active}}}}) {
        id
        skill {
          id
          status
          name
          level
        }
        level_min
      }
    }
  }
`;
