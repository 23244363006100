import { ESectionName } from "@/modules/rights/types";

const childrenGoals = [
  {
    path: "",
    redirect: "overview",
  },
  {
    component: () => import("@/modules/goals/views/GoalMain.vue"),
    meta: {
      requiresAuth: true,
      userRightsSection: ESectionName.Enum.monitoring_goals,
    },
    name: "goal-groups",
    path: "overview",
  },
  {
    component: () => import("@/modules/goals/views/GoalOverview.vue"),
    meta: {
      requiresAuth: true,
    },
    name: "goal-overview",
    path: "display/:id",
  },
  {
    component: () => import("@/modules/goals/views/GoalCreateStepOne.vue"),
    meta: {
      requiresAuth: true,
    },
    name: "goal-create-one",
    path: "create-one",
  },
  {
    component: () => import("@/modules/goals/views/GoalCreateStepTwo.vue"),
    meta: {
      requiresAuth: true,
    },
    name: "goal-create-two",
    path: "create-two/:id",
  },
  {
    component: () => import("@/modules/goals/views/GoalEditStepOne.vue"),
    meta: {
      requiresAuth: true,
    },
    name: "goal-edit-one",
    path: "edit-one/:id",
  },
];

export default childrenGoals;
