import { z } from "zod";

import { Id, NonEmptyString } from "@/shared/domain";

const Versatility = z.object({
  avoid: z.number().int().gte(1).lte(Number.MAX_SAFE_INTEGER),
  strictness: z.union([
    z.literal(0),
    z.literal(0.2),
    z.literal(0.4),
    z.literal(0.6),
    z.literal(0.8),
    z.literal(1),
  ]),
});

const Expertise = z.object({
  prefer: z.number().int().gte(1).lte(Number.MAX_SAFE_INTEGER),
  strictness: z.union([
    z.literal(0),
    z.literal(0.2),
    z.literal(0.4),
    z.literal(0.6),
    z.literal(0.8),
    z.literal(1),
  ]),
});

export const TemplateSchema = z
  .object({
    id: Id,
    includeTeams: Id.array().nonempty().optional(),
    maxScore: z.number().safe().optional(),
    onlyPositions: Id.array().nonempty().optional(),
    owner: Id,
    positionsOrder: z
      .record(Id, z.number().gte(0).lte(Number.MAX_SAFE_INTEGER))
      .optional(),
    shiftStrategy: z.union([
      z.literal("employee"),
      z.literal("position"),
      z.literal("positionShiftHours"),
    ]),
    title: NonEmptyString,
    versatilityAndExpertise: z.union([Versatility, Expertise]).optional(),
  })
  .brand("Template");
export type Template = z.infer<typeof TemplateSchema>;

export const TemplatesSchema = TemplateSchema.unwrap()
  .pick({ id: true, title: true })
  .array();
export type Templates = z.infer<typeof TemplatesSchema>;
