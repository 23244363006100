import { gql } from "graphql-tag";

export const CreateCategory = gql`
  mutation CreateCategory($data: CategoryCreateInput!) {
    createCategory(data: $data) {
      id
      status
      name
      color_bg
      color_text
    }
  }
`;

export const UpdateCategory = gql`
  mutation UpdateCategory(
    $where: CategoryWhereUniqueInput!
    $data: CategoryUpdateInput!
  ) {
    updateCategory(where: $where, data: $data) {
      id
      status
      name
      color_bg
      color_text
    }
  }
`;

export const DeleteCategory = gql`
  mutation DeleteCategory($where: CategoryWhereUniqueInput!) {
    deleteCategory(where: $where) {
      id
      status
      name
      color_bg
      color_text
    }
  }
`;

export const Categories = gql`
  query Categories($where: CategoryWhereInput) {
    Categories(where: $where) {
      id
      status
      name
      color_bg
      color_text
    }
  }
`;
