import { EResolverName } from "@/tscript/interfaces";
import { getToken } from "@/vue-apollo";

import { unarchiveUser } from "../_tables/users";

export const unarchiveSwitcher = async (
  resolverName: EResolverName,
  payload: any,
) => {
  // Store token into localstorage
  await getToken();

  switch (resolverName) {
    case EResolverName.user:
      await unarchiveUser(payload);
      break;
    default:
      break;
  }
};
