import { gql } from "graphql-tag";

export const Questionnaires_query = gql`
  query Questionnaires($where: QuestionnaireWhereInput) {
    Questionnaires(where: $where) {
      id
      qid
      title
      needApprobation
      createdAt
      updatedAt
      status
      published
      revisionNumber
      levels
      isLastRevision
      approbators {
        id
        approbators {
          id
          first_name
          last_name
          avatar_url
        }
      }
      approbations {
        id
        approbatorUser_id
        isApproved
      }
      updated_by {
        id
        avatar_url
        first_name
        last_name
      }
      updated_by_id
      hasBeenApproved
    }
  }
`;
