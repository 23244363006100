import {
  Categories,
  CreateCategory,
  DeleteCategory,
  UpdateCategory,
} from "@/graphql/settings/Categories";
import { useParameterEmployeeCategoryStore } from "@/pinia/collections";
import { apolloProvider } from "@/vue-apollo";

export const createCategory = async (payload: { data: any }) => {
  try {
    return await apolloProvider.defaultClient.mutate({
      mutation: CreateCategory,
      update: (store, { data: { createCategory } }) => {
        updateStoreAfterCreateCategory(store, createCategory);
      },
      variables: {
        data: payload.data,
      },
    });
  } catch (error) {
    console.error(error);
  }
};

export const updateCategory = async (payload: { data: any; where: any }) => {
  try {
    return await apolloProvider.defaultClient.mutate({
      mutation: UpdateCategory,
      update: (store, data) => {
        updateStoreAfterUpdateCategory(store, data.data.updateCategory);
      },
      variables: {
        data: payload.data,
        where: payload.where,
      },
    });
  } catch (error) {
    console.error(error);
  }
};

export const deleteCategory = async (payload: { where: any }) => {
  try {
    return await apolloProvider.defaultClient.mutate({
      mutation: DeleteCategory,
      update: (store, data) => {
        updateStoreAfterDeleteCategory(store, data.data.deleteCategory.id);
      },
      variables: {
        where: payload.where,
      },
    });
  } catch (error) {
    console.error(error);
  }
};

const updateStoreAfterCreateCategory = (store, createCategory) => {
  const data = store.readQuery({
    query: Categories,
  });
  data.Categories.push(createCategory);
  useParameterEmployeeCategoryStore().actives.push(createCategory);
  store.writeQuery({
    data,
    query: Categories,
  });
};

const updateStoreAfterDeleteCategory = (store, id) => {
  const data = store.readQuery({
    query: Categories,
  });
  const index = data.Categories.findIndex((c) => c.id === id);
  if (index !== -1) {
    data.Categories.splice(index, 1);
    useParameterEmployeeCategoryStore().activesScoped = data.Categories.filter(
      (contract) => (contract.status = "active"),
    );
    store.writeQuery({
      data,
      query: Categories,
    });
  }
};

const updateStoreAfterUpdateCategory = (store, updateCategory) => {
  const data = store.readQuery({
    query: Categories,
  });
  const index = data.Categories.findIndex((c) => c.id === updateCategory.id);
  if (index !== -1) {
    data.Categories.splice(index, 1, updateCategory);
    useParameterEmployeeCategoryStore().activesScoped = data.Categories.filter(
      (contract) => (contract.status = "active"),
    );
    store.writeQuery({
      data,
      query: Categories,
    });
  }
};
