import { gql } from "graphql-tag";

export const CreateLTraining = gql`
  mutation CreateLTraining($data: LTrainingCreateInput!) {
    createLTraining(data: $data) {
      id
      client_id
      site_id
      status
      commentary
      training_level
      validated
      is_ready_for_signature
      validation_date
      start_date
      end_date
      duration
      employee_id
      skill_id
      clearance_id
      skill {
        id
        name
        level
      }
      clearance {
        id
        name
      }
      external_trainer_id
      trainer_users {
        id
        first_name
        last_name
        fullname
      }
      trainer_employees {
        id
        first_name
        last_name
        fullname
      }
      manager_users {
        id
        first_name
        last_name
        fullname
      }
      manager_employees {
        id
        first_name
        last_name
        fullname
      }
      evaluator_users {
        id
        first_name
        last_name
        fullname
      }
      evaluator_employees {
        id
        first_name
        last_name
        fullname
      }
      signatures {
        id
        date
        path
        employee_id
        user_id
      }
      questionnaireResponses {
        questionnaire_id
        score
        signatureWrappers {
          id
          answers {
            checklistResponse_id
            id
            isGoodAnswer
            isValidated
            openResponse
            overridePoints
            question_id
            response_id
          }
          signatures {
            id
            date
            path
            employee_id
            user_id
          }
        }
        questionnaire {
          id
          createdAt
          title
          templateType
          questions {
            id
            questionText
            description
          }
          revisionNumber
          skills {
            id
          }
          clearances {
            id
          }
          minimumScore
        }
      }
    }
  }
`;

export const UpdateLTraining = gql`
  mutation UpdateLTraining(
    $where: LTrainingWhereUniqueInput!
    $data: LTrainingUpdateInput!
  ) {
    updateLTraining(where: $where, data: $data) {
      id
      client_id
      site_id
      status
      commentary
      comments {
        id
        created_by_id
        training_id
        comment
      }
      training_level
      validated
      is_ready_for_signature
      validation_date
      start_date
      end_date
      duration
      employee_id
      skill_id
      clearance_id
      skill {
        id
        name
        level
      }
      clearance {
        id
        name
      }
      external_trainer_id
      trainer_users {
        id
        first_name
        last_name
        fullname
      }
      trainer_employees {
        id
        first_name
        last_name
        fullname
      }
      manager_users {
        id
        first_name
        last_name
        fullname
      }
      manager_employees {
        id
        first_name
        last_name
        fullname
      }
      evaluator_users {
        id
        first_name
        last_name
        fullname
      }
      evaluator_employees {
        id
        first_name
        last_name
        fullname
      }
      signatures {
        id
        date
        path
        employee_id
        user_id
      }
    }
  }
`;

export const DeleteLTraining = gql`
  mutation DeleteLTraining($where: LTrainingWhereUniqueInput!) {
    deleteLTraining(where: $where) {
      id
    }
  }
`;

export const LTrainings = gql`
  query LTrainings {
    LTrainings {
      id
      client_id
      site_id
      status
      commentary
      training_level
      validated
      is_ready_for_signature
      validation_date
      start_date
      end_date
      duration
      employee_id
      skill_id
      clearance_id
      skill {
        id
        name
        level
      }
      clearance {
        id
        name
      }
      external_trainer_id
      trainer_users {
        id
        first_name
        last_name
        fullname
      }
      trainer_employees {
        id
        first_name
        last_name
        fullname
      }
      manager_users {
        id
        first_name
        last_name
        fullname
      }
      manager_employees {
        id
        first_name
        last_name
        fullname
      }
      evaluator_users {
        id
        first_name
        last_name
        fullname
      }
      evaluator_employees {
        id
        first_name
        last_name
        fullname
      }
      signatures {
        id
        date
        path
        employee_id
        user_id
      }
      questionnaireResponses {
        questionnaire_id
        score
        signatureWrappers {
          id
          answers {
            checklistResponse_id
            id
            isGoodAnswer
            isValidated
            openResponse
            overridePoints
            question_id
            response_id
          }
          signatures {
            id
            date
            path
            employee_id
            user_id
          }
        }
        questionnaire {
          id
          createdAt
          title
          templateType
          questions {
            id
            questionText
            description
          }
          revisionNumber
          skills {
            id
          }
          clearances {
            id
          }
          minimumScore
        }
      }
    }
  }
`;
