import { EResolverName } from "@/tscript/interfaces";
import { getToken } from "@/vue-apollo";

import { archiveEmployee } from "../_tables/employee";
import { archiveLClearance } from "../_tables/lclearances";
import { archiveLSkill } from "../_tables/lskills";
import { archiveLTraining } from "../_tables/ltrainings";
import { archivePosition } from "../_tables/positions";
import { archiveSubposition } from "../_tables/subpositions";
import { archiveTeam } from "../_tables/teams";
import { archiveUser } from "../_tables/users";

export const archiveSwitcher = async (
  resolverName: EResolverName,
  payload: any,
) => {
  // Store token into localstorage
  await getToken();

  switch (resolverName) {
    case EResolverName.employee:
      await archiveEmployee(payload);
      break;
    case EResolverName.lclearance:
      await archiveLClearance(payload);
      break;
    case EResolverName.lskill:
      await archiveLSkill(payload);
      break;
    case EResolverName.ltraining:
      await archiveLTraining(payload);
      break;
    case EResolverName.position:
      await archivePosition(payload);
      break;
    case EResolverName.subposition:
      await archiveSubposition(payload);
      break;
    case EResolverName.team:
      await archiveTeam(payload);
      break;
    case EResolverName.user:
      await archiveUser(payload);
      break;
    default:
      break;
  }
};
