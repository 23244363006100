import { z } from "zod";

import { Id } from "@/shared/domain";

export const EmployeeReview = z.object({
  campaignId: Id,
  campaignName: z.string(),
  creatorId: z.string(),
  goals: z.array(
    z.object({
      description: z.string(),
      priority: z.enum(["critical", "high", "normal", "low"]),
      status: z.enum(["achieved", "in-progress", "not-achieved"]).nullable(),
      title: z.string(),
    }),
  ),
  reviewValidatedAt: z.string().nullable(),
  status: z.union([
    z.literal("to-be-started"),
    z.literal("on-going"),
    z.literal("completed"),
  ]),
  stepCompleted: z.number(),
  stepCount: z.number(),
  templateName: z.string(),
});

export type EmployeeReview = z.infer<typeof EmployeeReview>;
