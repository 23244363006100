import { gql } from "graphql-tag";

export const CreateLSkill = gql`
  mutation CreateLSkill($data: LSkillCreateInput!) {
    createLSkill(data: $data) {
      id
      client_id
      site_id
      status
      start_date
      end_date
      skill_name
      level
      employee_id
      skill_id
      skill {
        name
        keywords_temp
        validity_months
      }
      will_recyclage
      need_recyclage
      will_expire
      expired
      trainer
      evaluator
      keywords_temp
    }
  }
`;

export const UpdateLSkill = gql`
  mutation UpdateLSkill(
    $where: LSkillWhereUniqueInput!
    $data: LSkillUpdateInput!
  ) {
    updateLSkill(where: $where, data: $data) {
      id
      client_id
      site_id
      status
      start_date
      end_date
      skill_name
      level
      employee_id
      skill_id
      skill {
        name
        keywords_temp
        validity_months
      }
      will_recyclage
      need_recyclage
      will_expire
      expired
      trainer
      evaluator
      keywords_temp
    }
  }
`;

export const DeleteLSkill = gql`
  mutation DeleteLSkill($where: LSkillWhereUniqueInput!) {
    deleteLSkill(where: $where) {
      id
    }
  }
`;

export const DeleteManyLSkill = gql`
  mutation DeleteManyLSkill($where: LSkillWhereInput) {
    deleteManyLSkill(where: $where) {
      count
    }
  }
`;

export const LSkills = gql`
  query LSkills {
    LSkills(
      where: {employee: {is: {status: {equals: active}}}, level: {gte: 1}}
    ) {
      id
      client_id
      site_id
      status
      start_date
      end_date
      skill_name
      level
      employee_id
      skill_id
      skill {
        name
        keywords_temp
        validity_months
      }
      will_recyclage
      need_recyclage
      will_expire
      expired
      trainer
      evaluator
      keywords_temp
    }
  }
`;
