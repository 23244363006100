import { z } from "zod";

import { Id } from "@/shared/domain";

import { Block } from "./block.entity";

export const Step = z.object({
  blocks: Block.array().default([]),
  id: Id.optional(),
  mustFillEmployee: z.boolean().default(false),
  mustFillManager: z.boolean().default(false),
  title: z.string().default(""),
});

export type Step = z.infer<typeof Step>;
