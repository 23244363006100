import {
  CellShift,
  CellShiftCreate,
  CellShiftCreateMany,
  CellShiftDelete,
  CellShifts,
  CellShiftUpdate,
  CellShiftUpdateMany,
} from "@/graphql/planning/CellShifts";
import { usePlanningStore } from "@/pinia/features/planning";
import { apolloProvider } from "@/vue-apollo";

export const deleteCellShift = async (payload: { where: any }) => {
  try {
    await apolloProvider.defaultClient.mutate({
      mutation: CellShiftDelete,
      update: (store, { data: { deleteCellshift } }) => {
        updateStoreAfterDeleteCellShift(store, deleteCellshift.id);
      },
      variables: {
        where: payload.where,
      },
    });
  } catch (error) {
    console.error(error);
  }
};

export const updateCellShift = async (payload: { data: any; where: any }) => {
  try {
    await apolloProvider.defaultClient.mutate({
      mutation: CellShiftUpdate,
      update: (store, { data: { updateCellshift } }) => {
        updateStoreAfterUpdateCellShift(store, updateCellshift);
      },
      variables: {
        data: payload.data,
        where: payload.where,
      },
    });
  } catch (error) {
    console.error(error);
  }
};

export const createCellShift = async (payload: { data: any }) => {
  try {
    return await apolloProvider.defaultClient.mutate({
      mutation: CellShiftCreate,
      update: (store, { data: { createCellshift } }) => {
        updateStoreAfterCreateCellShift(store, createCellshift);
      },
      variables: {
        data: payload.data,
      },
    });
  } catch (error) {
    console.error(error);
  }
};

export const createManyCellShift = async (payload: { data: any }) => {
  try {
    return await apolloProvider.defaultClient.mutate({
      mutation: CellShiftCreateMany,
      variables: {
        data: payload.data,
      },
    });
  } catch (error) {
    console.error(error);
  }
};

export const updateManyCellShift = async (payload: {
  data: any;
  where: any;
}) => {
  try {
    return await apolloProvider.defaultClient.mutate({
      mutation: CellShiftUpdateMany,
      variables: {
        data: payload.data,
        where: payload.where,
      },
    });
  } catch (error) {
    console.error(error);
  }
};

export const readCellShift = async (payload: { where: any }) => {
  try {
    return await apolloProvider.defaultClient.query({
      query: CellShift,
      variables: {
        where: payload.where,
      },
    });
  } catch (error) {
    console.error(error);
  }
};

const updateStoreAfterCreateCellShift = (store, createCellShift) => {
  const data = store.readQuery({
    query: CellShifts,
    variables: usePlanningStore().apolloCacheVariables.cellShifts,
  });
  data.CellShifts.push(createCellShift);
  store.writeQuery({
    data,
    query: CellShifts,
  });
};

const updateStoreAfterDeleteCellShift = (store, id) => {
  const planningStore = usePlanningStore();
  const data = store.readQuery({
    query: CellShifts,
    variables: planningStore.apolloCacheVariables.cellShifts,
  });
  const index = data.CellShifts.findIndex((c) => c.id === id);
  if (index !== -1) {
    // Mutate cache result
    data.CellShifts.splice(index, 1);
    // Write back to the cache
    store.writeQuery({
      data,
      query: CellShifts,
    });
  }
};

const updateStoreAfterUpdateCellShift = (store, updateCellShift) => {
  const data = store.readQuery({
    query: CellShifts,
    variables: usePlanningStore().apolloCacheVariables.cellShifts,
  });
  const index = data.CellShifts.findIndex((c) => c.id === updateCellShift.id);
  if (index !== -1) {
    // Mutate cache result
    if (updateCellShift.status === "active") {
      data.CellShifts.splice(index, 1, updateCellShift);
    } else {
      data.CellShifts.splice(index, 1);
    }
    // Write back to the cache
    store.writeQuery({
      data,
      query: CellShifts,
    });
  }
};
