import type { DataProxy } from "@apollo/client";

import {
  CreateSkill,
  DeleteManySkill,
  DeleteSkill,
  Skills,
  UpdateSkill,
} from "@/graphql/library/Skills";
import { useSkillGlobalStore } from "@/pinia/collections";
import { apolloProvider } from "@/vue-apollo";

import {
  updateStoreAfterCreation,
  updateStoreAfterDeletion,
  updateStoreAfterUpdate,
} from "./utils";

export const createSkill = async (payload: { data: any }) => {
  try {
    return await apolloProvider.defaultClient.mutate({
      mutation: CreateSkill,
      update: (cache, data) => {
        updateStoreAfterCreation(
          cache as DataProxy,
          Skills,
          "Skills",
          data.data.createSkill,
          useSkillGlobalStore(),
        );
      },
      variables: {
        data: payload.data,
      },
    });
  } catch (error) {
    console.error(error);
  }
};

export const updateSkill = async (payload: { data: any; where: any }) => {
  try {
    return await apolloProvider.defaultClient.mutate({
      mutation: UpdateSkill,
      update: (cache, data) => {
        updateStoreAfterUpdate(
          cache as DataProxy,
          Skills,
          "Skills",
          data.data.updateSkill,
          useSkillGlobalStore(),
        );
      },
      variables: {
        data: payload.data,
        where: payload.where,
      },
    });
  } catch (error) {
    console.error(error);
  }
};

export const deleteSkill = async (payload: { where: any }) => {
  try {
    return await apolloProvider.defaultClient.mutate({
      mutation: DeleteSkill,
      update: (cache, data) => {
        updateStoreAfterDeletion(
          cache as DataProxy,
          Skills,
          "Skills",
          data.data.deleteSkill,
          useSkillGlobalStore(),
        );
      },
      variables: {
        where: payload.where,
      },
    });
  } catch (error) {
    console.error(error);
  }
};

export const readSkill = async (payload: { where: any }) => {
  try {
    return await apolloProvider.defaultClient.query({
      query: Skills,
      variables: {
        where: payload.where,
      },
    });
  } catch (error) {
    console.error(error);
  }
};

export const deleteManySkill = async (payload: { where: any }) => {
  try {
    return await apolloProvider.defaultClient.mutate({
      mutation: DeleteManySkill,
      variables: {
        where: payload.where,
      },
    });
  } catch (error) {
    console.error(error);
  }
};
