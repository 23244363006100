import { useAuthentificationStore } from "@/pinia/user";
import type { PlanningParamsType } from "@/tscript/interfaces";
import { checkAndParsePlanningParams } from "@/utils/json.utils";

export const useDefaultPlanningRoute = () => {
  const { data: userData } = useAuthentificationStore();

  const planningParams: string | undefined = userData?.planning_parameter;

  const planningParamsObject: PlanningParamsType =
    checkAndParsePlanningParams(planningParams);
  let time = "week";
  if (planningParamsObject.time === "month") {
    time = "monthv3";
  } else if (planningParamsObject.time === "day") {
    time = "dayv3";
  }
  return `planning-v3/position/${time}`;
};
