import {
  CreatePositionOrder,
  PositionOrder,
  PositionOrders,
} from "@/graphql/library/PositionOrder";
import { useTeamDataStore } from "@/pinia/team";
import { apolloProvider } from "@/vue-apollo";

export const createPositionOrder = async (payload: { data: any }) => {
  try {
    return await apolloProvider.defaultClient.mutate({
      mutation: CreatePositionOrder,
      update: (_store, { data: { createPositionOrder } }) => {
        const teamDataStore = useTeamDataStore();
        updateStoreAfterCreatePositionOrder(teamDataStore, createPositionOrder);
      },
      variables: {
        data: payload.data,
      },
    });
  } catch (error) {
    console.error(error);
  }
};

const updateStoreAfterCreatePositionOrder = (store, createPositionOrder) => {
  const data = store.readQuery({
    query: PositionOrder,
  });
  data.PositionOrders.push(createPositionOrder);
  useTeamDataStore().positionOrders = createPositionOrder;
  store.writeQuery({
    data,
    query: PositionOrders,
  });
};
