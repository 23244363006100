import { isEmpty, map, some } from "lodash";

import {
  ERole,
  ESectionAction,
  ESectionName,
  ESectionRightValue,
  type ESectionScope,
  type LSectionValues,
} from "@/modules/rights/types";
import { useAuthentificationStore } from "@/pinia/user";

const isLimitedAccessByScope = (scopeInfos?: ESectionScope): boolean => {
  const { data: userData } = useAuthentificationStore();
  if (!userData) return false;
  if (!isEmpty(scopeInfos)) {
    if (scopeInfos.team_id) {
      const teamIdsAsTeamLeader = map(
        userData.teams_as_leader as { id: string; team_name: string }[],
        (team_as_leader) => team_as_leader.id,
      );
      const limitedAccessTeamIds = map(
        userData.limited_access_teams as { id: string; team_name: string }[],
        (team) => team.id,
      );
      return (
        teamIdsAsTeamLeader.includes(scopeInfos.team_id) ||
        limitedAccessTeamIds.includes(scopeInfos.team_id)
      );
    }

    if (scopeInfos.team_ids) {
      return some([
        ...map(userData.teams_as_leader, (team_as_leader) =>
          scopeInfos.team_ids?.includes(team_as_leader.id),
        ),
        ...map(userData.limited_access_teams, (team) =>
          scopeInfos.team_ids?.includes(team.id),
        ),
      ]);
    }
    return true;
  }
  return true;
};

export const isAllowed = (
  sectionName: ESectionName,
  action: ESectionAction,
  scopeInfos?: ESectionScope,
): boolean => {
  const { data: userData, permissions } = useAuthentificationStore();
  if (!userData) return false;

  if (
    userData.role === ERole.Enum.ADMIN ||
    userData.role === ERole.Enum.CUSTOMER_SUCCESS
  ) {
    return true;
  }

  const activeRightsFromUser = userData.current_right || null;
  if (!activeRightsFromUser) {
    console.error("warning: field 'rights' from user is not defined");
    return false;
  }

  const lSectionValues = permissions[sectionName]?.values || null;

  if (!lSectionValues) return false;

  const isLimitedAccess = isLimitedAccessByScope(scopeInfos);

  switch (action) {
    case ESectionAction.Enum.anyAction:
      return editCondition(lSectionValues, isLimitedAccess);

    case ESectionAction.Enum.archive:
      return editCondition(lSectionValues, isLimitedAccess);

    case ESectionAction.Enum.create:
      return editCondition(lSectionValues, isLimitedAccess);

    case ESectionAction.Enum.delete:
      return editCondition(lSectionValues, isLimitedAccess);

    case ESectionAction.Enum.read:
      return readonlyCondition(lSectionValues, isLimitedAccess);

    case ESectionAction.Enum.save:
      return editCondition(lSectionValues, isLimitedAccess);

    case ESectionAction.Enum.update:
      return editCondition(lSectionValues, isLimitedAccess);

    default:
      break;
  }

  return false;
};

const readonlyCondition = (
  lSectionValues: LSectionValues,
  isLimitedAccess: boolean,
): boolean => {
  if (lSectionValues.readonly === ESectionRightValue.Enum.total_access)
    return true;
  if (lSectionValues.readonly === ESectionRightValue.Enum.limited_access)
    return isLimitedAccess;
  return false;
};

const editCondition = (
  lSectionValues: LSectionValues,
  isLimitedAccess: boolean,
): boolean => {
  if (lSectionValues.edit === ESectionRightValue.Enum.total_access) return true;
  if (lSectionValues.edit === ESectionRightValue.Enum.limited_access)
    return isLimitedAccess;
  return false;
};

export const allowedSites = (): string[] => {
  const userData = useAuthentificationStore().data;
  const siteIds = new Set("");

  for (const right of userData?.rights || []) {
    let administationIsAllowed = false;
    let user_profile_informationsIsAllowed = false;

    for (const lsection of right?.link_sections || []) {
      if (
        lsection.section.section_name === ESectionName.Enum.administration &&
        lsection.values.edit === ESectionRightValue.Enum.total_access
      ) {
        administationIsAllowed = true;
      }

      if (
        lsection.section.section_name ===
        ESectionName.Enum.user_profile_informations &&
        lsection.values.edit === ESectionRightValue.Enum.total_access
      ) {
        user_profile_informationsIsAllowed = true;
      }
    }

    if (administationIsAllowed && user_profile_informationsIsAllowed) {
      siteIds.add(right.site_id);
    }
  }

  return [...siteIds];
};
