<template>
  <svg
    fill="none"
    height="24"
    viewBox="0 0 24 24"
    width="24"
    xmlns="http://www.w3.org/2000/svg"
  >
    <path
      :fill="selection"
      d="M7 22C7 19.79 8.79 18 11 18V17C8.58 17 6.56 15.28 6.1 13H5C3.34 13 2 11.66 2 10V5H4C4.77 5 5.47 5.29 6 5.76V3H17V5.76C17.53 5.29 18.23 5 19 5H21V10C21 11.66 19.66 13 18 13H16.9C16.44 15.28 14.42 17 12 17V18C14.21 18 16 19.79 16 22H7ZM12 19H11C9.69 19 8.58 19.83 8.17 21H14.83C14.42 19.83 13.31 19 12 19ZM16 4H7V12C7 14.21 8.79 16 11 16H12C14.21 16 16 14.21 16 12V4ZM20 10V6H19C17.9 6 17 6.9 17 8V12H18C19.1 12 20 11.1 20 10ZM3 10C3 11.1 3.9 12 5 12H6V8C6 6.9 5.1 6 4 6H3V10Z"
    />
  </svg>
</template>

<script lang="ts">
import Vue from "vue";

export default Vue.extend({
  name: "Trainings",
  props: ["selection"],
});
</script>
