import { z } from "zod";

import { Id } from "./shared";

const RevisionAuthor = z.object({
  avatarUrl: z.string().nullish(),
  firstName: z.string(),
  lastName: z.string(),
});

export const PendingApproval = z.object({
  otherReviewersCount: z.number(),
  revisionAuthor: RevisionAuthor,
  revisionDate: z.coerce.date(),
  revisionId: Id,
  revisionTitle: z.string(),
  revisionVersion: z.number(),
});
export type PendingApproval = z.infer<typeof PendingApproval>;
