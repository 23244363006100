import type { Dictionary } from "lodash";

import type { Document } from "../interfaces";

export const createDictionary = <T extends Document>(
  items: T[],
  key?: string,
): Dictionary<T | undefined> => {
  const dictionary = {};
  for (const item of items || []) {
    dictionary[item[key ? key : "id"]] = item;
  }
  return dictionary;
};

export const createDictionaryKeyNested = <T extends Document>(
  items: T[],
  parentKey: string,
  childrenKey: string,
): Dictionary<T | undefined> => {
  const dictionary = {};
  for (const item of items || []) {
    dictionary[item[parentKey][childrenKey]] = item;
  }
  return dictionary;
};
