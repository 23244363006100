import { gql } from "graphql-tag";

import type { PendingApproval } from "@/modules/contents/types/entities/pending-approval.entity";

export interface GetPendingApprovals {
  pendingReviews: {
    data: PendingApproval[];
    skip?: number;
    take?: number;
    total?: number;
  };
}

export const GET_PENDING_APPROVALS = gql`
  query PendingApprovals($skip: Float!, $take: Float!) {
    pendingReviews(skip: $skip, take: $take) {
      data {
        otherReviewersCount
        revisionAuthor {
          avatarUrl
          firstName
          lastName
        }
        revisionDate
        revisionId
        revisionTitle
        revisionVersion
      }
      skip
      take
      total
    }
  }
`;
