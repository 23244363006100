import { z } from "zod";

import { Id } from "@/shared/domain";

import { ResourceType } from "./resource-type.enum";

export const Resource = z.object({
  id: Id.optional(),
  type: z.nativeEnum(ResourceType),
});
type Resource = z.infer<typeof Resource>;

export const TextResource = Resource.extend({
  text: z.string().default(""),
  type: z.literal(ResourceType.information).default(ResourceType.information),
});
export type TextResource = z.infer<typeof TextResource>;

export const OpenQuestionResource = Resource.extend({
  expectedResponse: z.string().nullable().default(null),
  question: z.string().default(""),
  type: z.literal(ResourceType.openQuestion).default(ResourceType.openQuestion),
});
export type OpenQuestionResource = z.infer<typeof OpenQuestionResource>;

export const BlockGridRows = z.array(
  z.object({
    description: z.string().optional(),
    label: z.string().default(""),
  }),
);

export const BlockGridCols = z.array(
  z.object({
    choice: z.string().default(""),
    description: z.string().optional(),
    score: z.number().optional(),
  }),
);

export const GridQuestionResource = Resource.extend({
  cols: BlockGridCols.default([
    { choice: "", description: "" },
    { choice: "", description: "" },
  ]),
  minScore: z.number().nullable().default(null),
  rows: BlockGridRows.default([{ description: "", label: "" }]),
  title: z.string().default(""),
  type: z.literal(ResourceType.grid).default(ResourceType.grid),
});
export type GridQuestionResource = z.infer<typeof GridQuestionResource>;

export const RatingQuestionResource = z.object({
  question: z.string().default(""),
  type: z.literal(ResourceType.rating).default(ResourceType.rating),
});
export type RatingQuestionResource = z.infer<typeof RatingQuestionResource>;

export const TableQuestionResource = Resource.extend({
  rows: z
    .array(
      z.object({
        cols: z
          .array(
            z.object({
              text: z.string(),
            }),
          )
          .default([]),
      }),
    )
    .default([{ cols: [{ text: "" }, { text: "" }] }]),
  title: z.string().default(""),
  type: z.literal(ResourceType.table).default(ResourceType.table),
});
export type TableQuestionResource = z.infer<typeof TableQuestionResource>;

export const OpenQuestionResponseResource = Resource.extend({
  question: z.string(),
  response: z.string().default(""),
  type: z.literal(ResourceType.openQuestion).default(ResourceType.openQuestion),
});
export type OpenQuestionResponseResource = z.infer<
  typeof OpenQuestionResponseResource
>;

export const GridQuestionResponseResource = z.object({
  cols: z.array(
    z.object({
      choice: z.string(),
      description: z.string().nullable(),
      score: z.number().default(0),
    }),
  ),
  minScore: z.number().nullable(),
  rows: z.array(
    z.object({
      description: z.string().nullable(),
      label: z.string(),
      selectedIndex: z.number().nullable(),
    }),
  ),
  title: z.string().nullable(),
  type: z.literal(ResourceType.grid).default(ResourceType.grid),
});
export type GridQuestionResponseResource = z.infer<
  typeof GridQuestionResponseResource
>;

export const TableResponseResource = z.object({
  rows: z
    .array(
      z.object({
        cols: z
          .array(
            z.object({
              frozen: z.boolean().default(false),
              nodelete: z.boolean().default(false),
              text: z.string(),
            }),
          )
          .default([]),
        nodelete: z.boolean().default(false),
      }),
    )
    .default([]),
  title: z.string(),
  type: z.literal(ResourceType.table).default(ResourceType.table),
});
export type TableResponseResource = z.infer<typeof TableResponseResource>;

export const RatingResponseResource = z.object({
  comment: z.string().nullable().default(null),
  question: z.string(),
  score: z.number().min(0).max(10).nullable().default(null),
  type: z.literal(ResourceType.rating).default(ResourceType.rating),
});

export type RatingResponseResource = z.infer<typeof RatingResponseResource>;
