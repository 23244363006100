<template>
  <svg
    height="24"
    version="1.1"
    viewBox="0 0 24 24"
    width="24"
    xmlns="http://www.w3.org/2000/svg"
    xmlns:xlink="http://www.w3.org/1999/xlink"
  >
    <path
      :fill="selection"
      :stroke="selection"
      d="M8,3H17C18.66,3 20,4.34 20,6V19C20,20.66 18.66,22 17,22H8C6.34,22 5,20.66 5,19V6C5,4.34 6.34,3 8,3M8,4C6.9,4 6,4.9 6,6V19C6,20.1 6.9,21 8,21H17C18.1,21 19,20.1 19,19V6C19,4.9 18.1,4 17,4H14V10.7L11,8.6L8,10.7V4M13,4H9V8.78L11,7.38L13,8.78V4M8,24C5.24,24 3,21.76 3,19V7H4V19C4,21.21 5.79,23 8,23H16V24H8Z"
      stroke-width="0.3"
    />
  </svg>
</template>

<script lang="ts">
import Vue from "vue";

export default Vue.extend({
  name: "Portfolio",
  props: ["selection"],
});
</script>
