import StackdriverErrorReporter from "stackdriver-errors-js";

import { config } from "../../config";

const loggerHelper = new StackdriverErrorReporter();
loggerHelper.start({
  disabled: !config.gcp.errorReporting.isEnabled,
  key: config.gcp.errorReporting.key,
  projectId: config.gcp.errorReporting.projectId,
  service: config.gcp.errorReporting.service,
});
export default loggerHelper;
