import { connectAuthEmulator, getAuth } from "firebase/auth";
import { connectFirestoreEmulator, getFirestore } from "firebase/firestore";

import { config } from "@/config";
import fb from "@/firebase/init";

const db = getFirestore(fb);
const auth = getAuth();

if (config.useLocalFirestore) {
  connectFirestoreEmulator(db, "localhost", config.portLocalFirestore);

  if (config.useLocalAuthentification) {
    connectAuthEmulator(auth, `${config.portLocalAuthentification}`);
  }
}

export default db;
