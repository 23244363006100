import { gql } from "graphql-tag";

export const CellShift = gql`
  query CellShift($where: LShiftWhereUniqueInput) {
    CellShift(where: $where) {
      id
      day_nb
      day
      team_id
      dateRegular
      shift_id
      status
      employee_id
      weekBeginsOnYear
      isShowing
      shift {
        color {
          text
          bg
        }
        name
      }
    }
  }
`;

export const CellShifts = gql`
  query CellShifts($where: LShiftWhereInput) {
    CellShifts(where: $where) {
      id
      day_nb
      day
      team_id
      dateRegular
      shift_id
      employee_id
      weekBeginsOnYear
      isShowing
      shift {
        color {
          bg
          text
        }
        name
      }
      status
    }
  }
`;

export const CellShiftCreate = gql`
  mutation CreateCellShift($data: LShiftCreateInput!) {
    createCellshift(data: $data) {
      id
      day_nb
      day
      status
      team_id
      dateRegular
      shift_id
      employee_id
      weekBeginsOnYear
      isShowing
      shift {
        color {
          text
          bg
        }
        name
      }
    }
  }
`;

export const CellShiftDelete = gql`
  mutation DeleteCellShift($where: LShiftWhereUniqueInput!) {
    deleteCellshift(where: $where) {
      id
    }
  }
`;

export const CellShiftUpdate = gql`
  mutation UpdateCellShift(
    $where: LShiftWhereUniqueInput!
    $data: LShiftUpdateInput!
  ) {
    updateCellshift(where: $where, data: $data) {
      id
      day_nb
      day
      team_id
      dateRegular
      shift_id
      employee_id
      weekBeginsOnYear
      isShowing
      status
      shift {
        color {
          text
          bg
        }
        name
      }
    }
  }
`;

export const CellShiftCreateMany = gql`
  mutation CellShiftCreateMany($data: [LShiftCreateManyInput!]!) {
    createManyLShift(data: $data) {
      count
    }
  }
`;

export const CellShiftUpdateMany = gql`
  mutation CellShiftUpdateMany(
    $data: LShiftUpdateManyMutationInput!
    $where: LShiftWhereInput
  ) {
    updateManyLShift(data: $data, where: $where) {
      count
    }
  }
`;
