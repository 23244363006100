import { datadogRum } from "@datadog/browser-rum";
import {
  getAuth,
  SAMLAuthProvider,
  signInWithPopup,
  type UserCredential,
} from "firebase/auth";
import { ref } from "vue";

import { useSettingsClient } from "@mercateam/apps-module-auth";

import { useAuthentificationStore } from "@/pinia/user";
import { snackbarHelper } from "@/tscript/utils/snackbar";
import { getSubdomain } from "@/utils/location.utils";

const isShowingSSOEmailDialog = ref(false);

export default function useSSO() {
  const authStore = useAuthentificationStore();
  const { fetchSettings } = useSettingsClient();

  const showSSOEmailDialog = () => {
    isShowingSSOEmailDialog.value = true;
  };

  const hideSSOEmailDialog = () => {
    isShowingSSOEmailDialog.value = false;
  };

  const getSettings = async (email?: string) => {
    const subdomain = getSubdomain();
    const settings = await fetchSettings({
      email,
      subdomain,
    });
    return settings;
  };

  const connectWithCredentials = async (
    userCredential: null | UserCredential,
  ) => {
    if (!userCredential) {
      snackbarHelper().error("SSO Error");
      return "sso_failed";
    }
    await authStore.connect(userCredential.user);
  };

  const autoRun = async (): Promise<
    "autologin_not_allowed" | "sso_failed" | UserCredential
  > => {
    try {
      const auth = getAuth();
      const settings = await getSettings();
      if (!settings.length) return "autologin_not_allowed";
      const provider = new SAMLAuthProvider(settings[0].data.provider_id);
      const userCredential = await signInWithPopup(auth, provider);
      await connectWithCredentials(userCredential);
      return userCredential;
    } catch (error) {
      datadogRum.addError(error, {
        feature: "sso",
        origin,
        subfeature: "autologin",
      });
      return "sso_failed";
    }
  };

  const manualRun = async (
    email?: string,
  ): Promise<"sso_failed" | UserCredential> => {
    try {
      if (!email) throw new Error("Email is required");
      const auth = getAuth();
      const settings = await getSettings(email);
      if (!settings.length) return "sso_failed";
      const provider = new SAMLAuthProvider(settings[0].data.provider_id);
      const userCredential = await signInWithPopup(auth, provider);
      await connectWithCredentials(userCredential);
      return userCredential;
    } catch (error) {
      datadogRum.addError(error, {
        feature: "sso",
        origin,
        subfeature: "manuallogin",
      });
      return "sso_failed";
    }
  };

  return {
    autoRun,
    hideSSOEmailDialog,
    isShowingSSOEmailDialog,
    manualRun,
    showSSOEmailDialog,
  };
}
