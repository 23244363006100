import { ESectionName } from "../rights/types";

const childrenPerformanceReview = [
  {
    children: [
      {
        name: "performance-review-main",
        path: "",
        redirect: "items",
      },
      {
        component: () =>
          import(
            "@/modules/performance-review/views/PerformanceReviewItems.vue",
          ),
        meta: {
          requiresAuth: true,
          userRightsSection: ESectionName.Enum.performance_reviews_models,
        },
        name: "performance-review-items",
        path: "items",
      },
      {
        component: () =>
          import(
            "@/modules/performance-review/views/PerformanceReviewCampaigns.vue",
          ),
        meta: {
          requiresAuth: true,
        },
        name: "performance-review-campaigns",
        path: "campaigns",
        userRightsSection: "performance_reviews_campaign",
      },
      {
        component: () =>
          import(
            "@/modules/performance-review/views/PerformanceReviewAnalysis.vue",
          ),
        meta: {
          requiresAuth: true,
          userRightsSection: ESectionName.Enum.performance_reviews_analysis,
        },
        name: "performance-review-analysis",
        path: "analysis",
      },
      {
        component: () =>
          import(
            "@/modules/performance-review/views/PerformanceReviewTrainingRequested.vue",
          ),
        meta: {
          requiresAuth: true,
        },
        name: "performance-review-training-requested",
        path: "training-requested",
      },
    ],
    component: () =>
      import("@/modules/performance-review/views/PerformanceReviewMain.vue"),
    meta: {
      requiresAuth: true,
    },
    path: "",
  },
  {
    component: () =>
      import(
        "@/modules/performance-review/views/PerformanceReviewItemCreate.vue",
      ),
    meta: {
      requiresAuth: true,
      userRightsSection: ESectionName.Enum.performance_reviews_models,
    },
    name: "performance-review-items-create",
    path: "items/create/:id",
  },
  {
    component: () =>
      import(
        "@/modules/performance-review/views/PerformanceReviewCampaignCreate.vue",
      ),
    meta: {
      requiresAuth: true,
      userRightsSection: ESectionName.Enum.performance_reviews_campaign,
    },
    name: "performance-review-campaigns-create",
    path: "campaigns/create",
  },
  {
    component: () =>
      import(
        "@/modules/performance-review/views/PerformanceReviewCampaignEdit.vue",
      ),
    meta: {
      requiresAuth: true,
      userRightsSection: ESectionName.Enum.performance_reviews_campaign,
    },
    name: "performance-review-campaigns-edit",
    path: "campaigns/edit/:id",
  },
  {
    component: () =>
      import(
        "@/modules/performance-review/views/PerformanceReviewCampaignEdit.vue",
      ),
    meta: {
      requiresAuth: true,
      userRightsSection: ESectionName.Enum.performance_reviews_campaign,
      view: true,
    },
    name: "performance-review-campaigns-read",
    path: "campaigns/read/:id",
  },
  {
    component: () =>
      import(
        "@/modules/performance-review/views/PerformanceReviewResponse.vue",
      ),
    meta: {
      requiresAuth: true,
      userRightsSection: ESectionName.Enum.employee_performance_reviews,
    },
    name: "performance-review-response",
    path: "response/:id",
  },
  {
    component: () =>
      import("@/modules/performance-review/views/LaunchPerformanceReview.vue"),
    meta: {
      requiresAuth: true,
    },
    name: "launch-performance-review",
    // Should we delete this view ?
    path: "/performance-review/launch/:employee_id",
  },
];

export default childrenPerformanceReview;
