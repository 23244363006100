const childrenTrainingSummary = [
  {
    component: () =>
      import("@/views/trainingv3/trainingSummaryChildren/TrainingEvaluation"),
    meta: {
      requiresAuth: true,
    },
    name: "training_evaluation_v3",
    path: "evaluation",
  },
  {
    component: () =>
      import(
        "@/views/trainingv3/trainingSummaryChildren/TrainingResources.vue",
      ),
    meta: {
      requiresAuth: true,
    },
    name: "training_resources_v3",
    path: "resources",
  },
];

export default childrenTrainingSummary;
