import { filter, includes, map } from "lodash";

import type { ISearchCriteria } from "@/tscript/database/FirestoreHelper";

import * as baseCollection from "@/pinia/collections";
import { useAuthentificationStore } from "@/pinia/user";
import router from "@/router";
import { segmentTriggerEvent } from "@/tscript/analytics";
import { delay } from "@/tscript/utils/helpers";
import { filterActives, filterArchived } from "@/utils/graphql.utils";

import { useKeywordStore } from "./features/keyword";
import { usePlanningStore } from "./features/planning";
import { useTeamDataStore } from "./team";

export const _appDataReset = (): void => {
  const PlanningTemplateGlobalStore =
    baseCollection.usePlanningTemplateGlobalStore();
  const PositionGlobalStore = baseCollection.usePositionGlobalStore();
  const PositionLevelGlobalStore = baseCollection.usePositionLevelGlobalStore();
  const EventGlobalStore = baseCollection.useEventGlobalStore();
  const PositionShiftStore = baseCollection.usePositionShiftStore();
  const SearchFilterStore = baseCollection.useSearchFilterStore();

  const QuestionnaireModelStore = baseCollection.useQuestionnaireModelStore();
  const QuestionnaireStore = baseCollection.useQuestionnaireStore();
  const EmployeeRestrictionStore = baseCollection.useEmployeeRestrictionStore();
  const EmployeeContentStore = baseCollection.useEmployeeContentStore();
  const NotificationStore = baseCollection.useNotificationsStore();

  PlanningTemplateGlobalStore.reset();
  EmployeeContentStore.reset();
  EventGlobalStore.reset();
  PositionShiftStore.reset();
  SearchFilterStore.reset();
  EmployeeRestrictionStore.reset();
  PositionGlobalStore.reset();
  PositionLevelGlobalStore.reset();
  QuestionnaireModelStore.reset();
  QuestionnaireStore.reset();
  NotificationStore.reset();
};

export const appData = async (realtime?: boolean) => {
  const startAppData = performance.now();
  const user = useAuthentificationStore();
  const ContentGlobalStore = baseCollection.useContentGlobalStore();
  const ContentApprobationGlobalStore =
    baseCollection.useContentApprobationGlobalStore();
  const ContentDocumentTypeGlobalStore =
    baseCollection.useContentDocumentTypeGlobalStore();
  const PositionShiftStore = baseCollection.usePositionShiftStore();
  const SearchFilterStore = baseCollection.useSearchFilterStore();
  const ParameterAssignationTagsStore =
    baseCollection.useParameterAssignationTagsStore();
  const QuestionnaireModelStore = baseCollection.useQuestionnaireModelStore();
  const QuestionnaireStore = baseCollection.useQuestionnaireStore();
  const EmployeeContentStore = baseCollection.useEmployeeContentStore();
  const GoalsStore = baseCollection.useGoalsStore();
  // * Notifications
  const NotificationStore = baseCollection.useNotificationsStore();

  // * Keyword
  const KeywordStore = useKeywordStore();
  try {
    if (!user.data) throw new Error("user data is not ready yet");
    const siteScopeSearchCriterias: ISearchCriteria[] = [
      {
        fieldName: "client_id",
        filterOp: "==",
        value: user.data.client_id,
      },
      {
        fieldName: "site_id",
        filterOp: "==",
        value: user.data.site_id,
      },
    ];
    const notificationCriterias: ISearchCriteria[] = [
      {
        fieldName: "client_id",
        filterOp: "==",
        value: user.data.client_id,
      },
      {
        fieldName: "user_id",
        filterOp: "==",
        value: user.data.id,
      },
    ];
    if (user.data.role.startsWith("SITE") && user.data.site_id) {
      notificationCriterias.push({
        fieldName: "site_id",
        filterOp: "==",
        value: user.data.site_id,
      });
    }
    _appDataReset();
    if (realtime) {
      await ContentGlobalStore.getDocumentsWithWhere(siteScopeSearchCriterias);
      ContentApprobationGlobalStore.unsubscribe();
      ContentDocumentTypeGlobalStore.unsubscribe();
      ParameterAssignationTagsStore.unsubscribe();
      EmployeeContentStore.unsubscribe();
      PositionShiftStore.unsubscribe();
      SearchFilterStore.unsubscribe();
      await delay(5);
      QuestionnaireModelStore.unsubscribe();
      QuestionnaireStore.unsubscribe();
      GoalsStore.unsubscribe();
      ContentApprobationGlobalStore.subscribe(siteScopeSearchCriterias);
      ContentDocumentTypeGlobalStore.subscribe(siteScopeSearchCriterias);
      ParameterAssignationTagsStore.subscribe(siteScopeSearchCriterias);
      await delay(5);
      EmployeeContentStore.subscribe(siteScopeSearchCriterias);
      PositionShiftStore.subscribe(siteScopeSearchCriterias);
      SearchFilterStore.subscribe(siteScopeSearchCriterias);
      QuestionnaireModelStore.subscribe(siteScopeSearchCriterias);
      QuestionnaireStore.subscribe(siteScopeSearchCriterias);
      GoalsStore.subscribe(siteScopeSearchCriterias);
      NotificationStore.subscribe(notificationCriterias, 20);
      await KeywordStore.loadKeyword();
    } else {
      await Promise.all([
        ContentApprobationGlobalStore.getDocumentsWithWhere(
          siteScopeSearchCriterias,
        ),
        ContentDocumentTypeGlobalStore.getDocumentsWithWhere(
          siteScopeSearchCriterias,
        ),
        QuestionnaireModelStore.getDocumentsWithWhere(siteScopeSearchCriterias),
        QuestionnaireStore.getDocumentsWithWhere(siteScopeSearchCriterias),
        EmployeeContentStore.getDocumentsWithWhere(siteScopeSearchCriterias),
        GoalsStore.getDocumentsWithWhere(siteScopeSearchCriterias),
        NotificationStore.getDocumentsWithWhere(notificationCriterias, 20),
        KeywordStore.loadKeyword(),
      ]);
    }
    const endAppData = performance.now();
    segmentTriggerEvent("appPerformance", {
      performance: endAppData - startAppData,
    });
  } catch (error) {
    console.error(error);
  }
};

export const teamData = (team) => {
  const teamId = team.id;
  const user = useAuthentificationStore();
  const TeamDataStore = useTeamDataStore();
  const PlanningStore = usePlanningStore();
  const PositionStore = baseCollection.usePositionStore();
  const SubpositionStore = baseCollection.useSubpositionStore();
  if (!user.data) return;

  TeamDataStore.team.id = team.id;
  TeamDataStore.team.document = team;

  if (!router.currentRoute.path.includes("planning-v2")) {
    PlanningStore.reset();
  }

  const positionFilteredByTeam = filter(
    baseCollection.usePositionGlobalStore().documents,
    (p: any) => {
      const team_ids = map(p.teams, (t) => t.id);
      return includes(team_ids, teamId);
    },
  );

  const subpositionFilteredByTeam = filter(
    baseCollection.usePositionLevelGlobalStore().documents,
    (p: any) => {
      const team_ids = map(p.teams, (t) => t.id);
      return includes(team_ids, teamId);
    },
  );

  PositionStore.documentsScoped = positionFilteredByTeam;
  PositionStore.documentsRealTimeScoped = positionFilteredByTeam;
  PositionStore.activesScoped =
    positionFilteredByTeam && filterActives(positionFilteredByTeam);
  PositionStore.archivedScoped =
    positionFilteredByTeam && filterArchived(positionFilteredByTeam);
  PositionStore.hasRealtime = true;
  PositionStore.hasSubcribed = true;

  SubpositionStore.documentsScoped = subpositionFilteredByTeam;
  SubpositionStore.documentsRealTimeScoped = subpositionFilteredByTeam;
  (SubpositionStore as any).activesScoped =
    subpositionFilteredByTeam && filterActives(subpositionFilteredByTeam);
  (SubpositionStore as any).archivedScoped =
    subpositionFilteredByTeam && filterArchived(subpositionFilteredByTeam);
  SubpositionStore.hasRealtime = true;
  SubpositionStore.hasSubcribed = true;
};

export const evaluationData = async (realtime?: boolean) => {
  const user = useAuthentificationStore();

  // const EmployeeTrainingStore = baseCollection.useEmployeeTrainingStore();
  const QuestionnaireModelStore = baseCollection.useQuestionnaireModelStore();
  const QuestionnaireStore = baseCollection.useQuestionnaireStore();
  // Load
  if (!user.data) return;

  // retrieve team object

  const baseSearchCriterias: ISearchCriteria[] = [
    {
      fieldName: "client_id",
      filterOp: "==",
      value: user.data.client_id,
    },
    {
      fieldName: "site_id",
      filterOp: "==",
      value: user.data.site_id,
    },
  ];

  QuestionnaireModelStore.reset();
  QuestionnaireStore.reset();
  // EmployeeTrainingStore.reset();

  if (realtime) {
    // 1) Unsubscribe
    QuestionnaireModelStore.unsubscribe();
    QuestionnaireStore.unsubscribe();
    // EmployeeTrainingStore.unsubscribe();

    // 2) Subscribe
    if (!QuestionnaireModelStore.queryFetched) {
      QuestionnaireModelStore.subscribe(baseSearchCriterias);
    }
    QuestionnaireStore.subscribe(baseSearchCriterias);
    // EmployeeTrainingStore.subscribe(baseSearchCriterias);
  } else {
    await Promise.all([
      QuestionnaireModelStore.getDocumentsWithWhere(baseSearchCriterias),
      QuestionnaireStore.getDocumentsWithWhere(baseSearchCriterias),
      // EmployeeTrainingStore.getDocumentsWithWhere(baseSearchCriterias),
    ]);
  }
};

export const pilotingData = async (realtime?: boolean) => {
  const user = useAuthentificationStore();

  const GoalsStore = baseCollection.useGoalsStore();
  const GoalsHistoryStore = baseCollection.useGoalsHistoryStore();
  // Load
  if (!user.data) return;

  const baseSearchCriterias: ISearchCriteria[] = [
    {
      fieldName: "client_id",
      filterOp: "==",
      value: user.data.client_id,
    },
    {
      fieldName: "site_id",
      filterOp: "==",
      value: user.data.site_id,
    },
  ];

  // GoalsStore.reset();
  GoalsHistoryStore.reset();

  if (realtime) {
    // 1) Unsubscribe
    GoalsStore.unsubscribe();
    GoalsHistoryStore.unsubscribe();
    // 2) Subscribe
    GoalsStore.subscribe(baseSearchCriterias);
    GoalsHistoryStore.subscribe(baseSearchCriterias);
  } else {
    await Promise.all([
      GoalsStore.getDocumentsWithWhere(baseSearchCriterias),
      GoalsHistoryStore.getDocumentsWithWhere(baseSearchCriterias),
    ]);
  }
};

export const eventData = async (realtime?: boolean) => {
  const user = useAuthentificationStore();

  const EventGlobalStore = baseCollection.useEventGlobalStore();
  // Load
  if (!user.data) return;

  // retrieve team object

  const baseSearchCriterias: ISearchCriteria[] = [
    {
      fieldName: "client_id",
      filterOp: "==",
      value: user.data.client_id,
    },
    {
      fieldName: "site_id",
      filterOp: "==",
      value: user.data.site_id,
    },
  ];

  EventGlobalStore.reset();

  if (realtime) {
    // 1) Unsubscribe
    EventGlobalStore.unsubscribe();

    // 2) Subscribe
    EventGlobalStore.subscribe(baseSearchCriterias);
  } else {
    await Promise.all([
      EventGlobalStore.getDocumentsWithWhere(baseSearchCriterias),
    ]);
  }
};

export const cleanEventData = () => {
  const EventGlobalStore = baseCollection.useEventGlobalStore();
  EventGlobalStore.reset();
};
