import {
  CreateLRestriction,
  DeleteLRestriction,
  LRestrictions,
  UpdateLRestriction,
} from "@/graphql/employees/LRestriction";
import { useEmployeeRestrictionStore } from "@/pinia/collections";
import { setGlobalStoreGraphQL } from "@/utils/graphql.utils";
import { apolloProvider } from "@/vue-apollo";

export const createLRestriction = async (payload: { data: any }) => {
  try {
    return await apolloProvider.defaultClient.mutate({
      mutation: CreateLRestriction,
      update: (store, { data: { createLRestriction } }) => {
        updateStoreAfterCreateLRestriction(store, createLRestriction);
      },
      variables: {
        data: payload.data,
      },
    });
  } catch (error) {
    console.error(error);
  }
};

export const updateLRestriction = async (payload: {
  data: any;
  where: any;
}) => {
  try {
    return await apolloProvider.defaultClient.mutate({
      mutation: UpdateLRestriction,
      update: (store, data) => {
        updateStoreAfterUpdateLRestriction(store, data.data.updateLRestriction);
      },
      variables: {
        data: payload.data,
        where: payload.where,
      },
    });
  } catch (error) {
    console.error(error);
  }
};

export const deleteLRestriction = async (payload: { where: any }) => {
  try {
    return await apolloProvider.defaultClient.mutate({
      mutation: DeleteLRestriction,
      update: (store, data) => {
        updateStoreAfterDeleteLRestriction(
          store,
          data.data.deleteLRestriction.id,
        );
      },
      variables: {
        where: payload.where,
      },
    });
  } catch (error) {
    console.error(error);
  }
};

const updateStoreAfterCreateLRestriction = (store, createLRestriction) => {
  const data = store.readQuery({
    query: LRestrictions,
  });
  data.LRestrictions.push(createLRestriction);
  setGlobalStoreGraphQL(useEmployeeRestrictionStore(), data.LRestrictions);
  store.writeQuery({
    data,
    query: LRestrictions,
  });
};

const updateStoreAfterDeleteLRestriction = (store, id) => {
  const data = store.readQuery({
    query: LRestrictions,
  });
  const index = data.LRestrictions.findIndex((c) => c.id === id);
  if (index !== -1) {
    data.LRestrictions.splice(index, 1);
    setGlobalStoreGraphQL(useEmployeeRestrictionStore(), data.LRestrictions);
    store.writeQuery({
      data,
      query: LRestrictions,
    });
  }
};

const updateStoreAfterUpdateLRestriction = (store, updateLRestriction) => {
  const data = store.readQuery({
    query: LRestrictions,
  });
  const index = data.LRestrictions.findIndex(
    (c) => c.id === updateLRestriction.id,
  );
  if (index !== -1) {
    data.LRestrictions.splice(index, 1, updateLRestriction);
    setGlobalStoreGraphQL(useEmployeeRestrictionStore(), data.LRestrictions);
    store.writeQuery({
      data,
      query: LRestrictions,
    });
  }
};
