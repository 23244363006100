import { zonedTimeToUtc } from "date-fns-tz";
import * as _ from "lodash";

import DBHelper from "../dbHelper";

export default class EmployeeHelper {
  clearancesList: any;
  contractsList: any;
  db: any;
  dbHelper: DBHelper;
  positionsList: any;
  skillsList: any;
  subPositionsList: any;
  teamsList: any;

  constructor(db: any) {
    this.db = db;
    this.dbHelper = new DBHelper(db);
  }

  async cacheEmployeeCard(employeeId: string) {
    const employeeCard: any = await this.getEmployeeCard(employeeId);
    if (employeeCard) {
      employeeCard.updated_at = new Date();
      await this.dbHelper.setDataToCollection(
        "employeesCards",
        employeeId,
        employeeCard,
      );
    }
    return employeeCard;
  }

  async getAllEmployees() {
    return await this.dbHelper.getAllDataFromCollection("employees");
  }

  async getCacheEmployeeCard(employeeId: string) {
    return this.dbHelper.getDocFromCollection("employeesCards", employeeId);
  }

  async getCachedEmployeesCardsbyTeam(teamId: string) {
    const employees = await this.getEmployeeCardsFromTeamId(teamId);
    return employees;
  }

  async getEmployeeCard(employeeId: string) {
    let employee: any = null;
    const employees = await this.getEmployeesCards("", employeeId);

    if (employees.length === 1) {
      employee = employees[0];
    }

    return employee;
  }

  async getEmployeeCardsFromTeamId(teamId: string) {
    return await this.dbHelper.getAllDataFromCollectionWithWhere(
      "employeesCards",
      "team_id",
      teamId,
    );
  }

  async getEmployeeClearances(employeeId: string) {
    const clearances = await this.dbHelper.getAllDataFromCollectionWithWhere(
      "link_employee_clearances",
      "employee_id",
      employeeId,
    );
    for (const clearance of clearances) {
      const clearanceInfo = _.find(this.clearancesList, {
        id: clearance.id,
      });
      if (clearanceInfo) {
        clearance.clearance_name = clearanceInfo.name;
      }
    }
    return clearances;
  }

  async getEmployeeKeywords(employeeId: string) {
    const keywords = await this.dbHelper.getAllDataFromCollectionWithWhere(
      "link_keywords",
      "employee_id",
      employeeId,
    );

    return keywords;
  }

  getEmployeePositions(
    _teamId: string,
    skillsList: any,
    clearancesList: any,
    positionsList: any,
  ) {
    const positionsFiltered = positionsList.filter((p: any) => {
      const checkTeam = true;
      let checkClerances = true;
      let checkSkills = true;
      const clearancesListId = _.map(clearancesList, (c) => c?.clearance_id);
      const skillsListId = _.map(skillsList, (s) => s?.skill_id);

      // if (p?.team_ids && p?.team_ids?.length) {
      //   checkTeam = p.team_ids.includes(teamId);
      // }

      if (p?.clearances?.length) {
        checkClerances = p.clearances.every((c: any) =>
          clearancesListId.includes(c),
        );
      }

      if (p?.skills?.length) {
        checkSkills = p.skills.every((s: any) => skillsListId.includes(s));
      }

      return checkTeam && checkClerances && checkSkills;
    });
    const positionsMapped = _.map(positionsFiltered, (p) => {
      const result = {
        id: p?.id,
        level: p?.level === 0 ? p?.level + 1 : p?.level ? p?.level + 1 : 0,
        name: p?.name,
      };
      return result;
    });
    return positionsMapped;
  }

  async getEmployeeSkills(employeeId: string) {
    const skills = await this.dbHelper.getAllDataFromCollectionWithWhere(
      "link_employee_skills",
      "employee_id",
      employeeId,
    );
    const skillsFilterd = [] as any[];
    for (const skill of skills) {
      const skillInfo = _.find(this.skillsList, { id: skill.id });
      if (skillInfo?.name) {
        skill.skill_name = skillInfo.name;
      }
      skill.level_img = `/assets/svg/lvl${skill.level}.svg`;
      if (skill.trainer) {
        skill.trainer_img = "/assets/svg/lvlt.svg";
      }
      if (skill.level > 0) {
        skillsFilterd.push(skill);
      }
    }
    return skillsFilterd;
  }

  async getEmployeeTraining(employeeId: string) {
    const trainings = await this.dbHelper.getAllDataFromCollectionWithWhere(
      "link_trainings_employee",
      "employee_id",
      employeeId,
    );
    let idx = 0;
    for (const training of trainings) {
      const skillInfo = _.find(this.skillsList, { id: training.skill_id });
      if (skillInfo?.name) {
        training.skill_name = skillInfo.name;
      }
      training.level_img = "/assets/svg/t.svg";
      trainings[idx] = training;
      idx++;
    }
    return trainings;
  }

  async getEmployeesCards(teamId: string, employeeId: string) {
    let employees = [] as any[];

    if (teamId) {
      employees = await this.getEmployeesFromTeamId(teamId);
    } else {
      const employeeResult: any = await this.dbHelper.getDocFromCollection(
        "employees",
        employeeId,
      );

      employees.push(employeeResult);
    }
    if (employees?.length > 0) {
      if (employees[0]?.client_id) {
        const arrayWhere: any = {
          client_id: employees[0]?.client_id,
          site_id: employees[0]?.site_id,
        };
        this.teamsList =
          await this.dbHelper.getAllDataFromCollectionWithWhereArray(
            "teams",
            arrayWhere,
          );
        this.skillsList =
          await this.dbHelper.getAllDataFromCollectionWithWhereArray(
            "skills",
            arrayWhere,
          );

        this.clearancesList =
          await this.dbHelper.getAllDataFromCollectionWithWhereArray(
            "clearances",
            arrayWhere,
          );

        this.contractsList =
          await this.dbHelper.getAllDataFromCollectionWithWhereArray(
            "parameters_contract",
            arrayWhere,
          );
        this.positionsList =
          await this.dbHelper.getAllDataFromCollectionWithWhereArray(
            "positions",
            arrayWhere,
          );
        this.subPositionsList =
          await this.dbHelper.getAllDataFromCollectionWithWhereArray(
            "positions_level",
            arrayWhere,
          );
      }
    }

    let idx = 0;
    for (const employee of employees) {
      if (employee?.id) {
        if (employee.first_name && employee.last_name) {
          employee.name = `${employee.first_name} ${employee.last_name}`;
        }

        if (employee.birthday_date) {
          // TODO get timezone from SITE
          const timezone = "Europe/Paris";
          const birthdayDate = zonedTimeToUtc(
            employee.birthday_date._seconds,
            timezone,
          );
          employee.birth_day = birthdayDate.getDate();
          employee.birth_month = birthdayDate.getMonth() + 1;
          employee.birth_year = birthdayDate.getFullYear();
        }

        if (employee.employee_id) {
          employee.employee_company_id = employee.employee_id;
        }

        if (employee.team_id) {
          const teamInfo = _.find(this.teamsList, { id: employee?.team_id });
          if (teamInfo) {
            // Add teamInfo
            employee.team_name = teamInfo.team_name;
            employee.client_id = teamInfo.client_id;
            employee.site_id = teamInfo.site_id;
            employee.factory_id = teamInfo.factory_id;
          }
        }

        const employeeKeywordsArray = await this.getEmployeeKeywords(
          employee.id,
        );
        employee.employeeKeywords = employeeKeywordsArray;
        if (employeeKeywordsArray.length > 0) {
          employee.employeeHasKeywords = true;
        }
        const employeeClearancesArray = await this.getEmployeeClearances(
          employee.id,
        );
        employee.employeeClearances = employeeClearancesArray;
        if (employeeClearancesArray.length > 0) {
          employee.employeeHasClearances = true;
        }
        const employeeSkillsResultArray = await this.getEmployeeSkills(
          employee.id,
        );
        employee.employeeSkills = employeeSkillsResultArray;
        if (employeeSkillsResultArray.length > 0) {
          employee.employeeHasSkills = true;
        }
        const employeeTrainingArray = await this.getEmployeeTraining(
          employee.id,
        );
        employee.employeeTrainings = employeeTrainingArray;
        if (employeeTrainingArray.length > 0) {
          employee.employeeHasTrainings = true;
        }

        // position
        const employeePositionArray = this.getEmployeePositions(
          employee.team_id,
          employeeSkillsResultArray,
          employeeClearancesArray,
          this.positionsList,
        );

        // Subposition
        const employeeSubPositionArray = this.getEmployeePositions(
          employee.team_id,
          employeeSkillsResultArray,
          employeeClearancesArray,
          this.subPositionsList,
        );
        employee.employeePosition = employeePositionArray.concat(
          employeeSubPositionArray,
        );
        if (employeePositionArray.length > 0) {
          employee.employeeHasPosition = true;
        }

        employees[idx] = employee;
        idx++;
      }
    }

    return employees;
  }

  async getEmployeesCardsbyTeam(teamId: string) {
    const employees = await this.getEmployeesCards(teamId, "");
    return employees;
  }

  async getEmployeesFromTeamId(teamId: string) {
    return await this.dbHelper.getAllDataFromCollectionWithWhere(
      "employees",
      "team_id",
      teamId,
    );
  }

  async getEmployeesTeamHistory(employeeId: string) {
    return await this.dbHelper.getAllDataFromCollectionWithWhere(
      "employees_team_history",
      "employee_id",
      employeeId,
    );
  }

  async getSkillsFromSiteId(siteId: string) {
    return await this.dbHelper.getAllDataFromCollectionWithWhere(
      "skills",
      "site_id",
      siteId,
    );
  }

  async getTeamsFromSiteId(siteId: string) {
    return await this.dbHelper.getAllDataFromCollectionWithWhere(
      "teams",
      "site_id",
      siteId,
    );
  }

  async logEmployeeTeamHistory(
    employeeId: string,
    newTeamId: string,
    oldTeamId?: string,
  ) {
    const teamHistory = {
      employee_id: employeeId,
      new_team_id: newTeamId,
      old_team_id: oldTeamId,
      timestamp: new Date(),
    };
    await this.dbHelper.addDataToCollection(
      "employees_team_history",
      teamHistory,
    );
  }
}
