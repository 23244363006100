import { z } from "zod";

export const UpdateApplicantsData = z.object({
  applicants: z.array(
    z.object({
      employee_id: z.string(),
    }),
  ),
  campaign_id: z.string(),
});
export type UpdateApplicantsData = z.infer<typeof UpdateApplicantsData>;
