import { gql } from "graphql-tag";

export const CreateExternalTrainer = gql`
  mutation CreateExternalTrainer($data: ExternalTrainerCreateInput!) {
    createExternalTrainer(data: $data) {
      id
      client_id
      site_id
      status
      published
      name
    }
  }
`;
export const UpdateExternalTrainer = gql`
  mutation UpdateExternalTrainer(
    $data: ExternalTrainerUpdateInput!
    $where: ExternalTrainerWhereUniqueInput!
  ) {
    updateExternalTrainer(data: $data, where: $where) {
      id
      client_id
      site_id
      status
      published
      name
    }
  }
`;
export const DeleteExternalTrainer = gql`
  mutation DeleteExternalTrainer($where: ExternalTrainerWhereUniqueInput!) {
    deleteExternalTrainer(where: $where) {
      id
    }
  }
`;

export const ExternalTrainers = gql`
  query ExternalTrainers {
    ExternalTrainers {
      id
      client_id
      site_id
      status
      published
      name
    }
  }
`;
