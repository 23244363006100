import { gql } from "graphql-tag";

export const CreateSite = gql`
  mutation CreateSite($data: SiteCreateInput!) {
    createSite(data: $data) {
      client_id
      client {
        id
        name
        logo_url
      }
      teams {
        team_name
        id
      }
      id
      settings_skills_end_date
      settings_versatility_user_calendar
      settings_versatilty_feature
      settings_only_trainer
      settings_only_evaluator
      settings_only_trainer
      settings_only_evaluator
      settings_enable_imports
      settings_training_time
      settings_hide_download_content
      settings_workload
      settings_planning_beta
      settings_monitoring_main_goal
      settings_monitoring_v1
      settings_show_score
      timezone
      team_id_for_new_employee
      external_id
      status
      published
      name
      pricing {
        id
        createdAt
        updatedAt
        name
        status
        sections {
          id
          section_name
          section_translate_name
          default_value_readonly
          default_value_edit
        }
      }
      rights_admins {
        id
      }
      complementaryRightsSections {
        id
        section_name
        is_complementary
        section_translate_name
        section_order
      }
    }
  }
`;

export const UpdateSite = gql`
  mutation UpdateSite($data: SiteUpdateInput!, $where: SiteWhereUniqueInput!) {
    updateSite(data: $data, where: $where) {
      client_id
      client {
        id
        name
        logo_url
      }
      teams {
        team_name
        id
      }
      id
      settings_skills_end_date
      settings_versatility_user_calendar
      settings_versatilty_feature
      settings_only_trainer
      settings_only_evaluator
      settings_enable_imports
      settings_training_time
      settings_hide_download_content
      settings_workload
      settings_planning_beta
      settings_monitoring_main_goal
      settings_monitoring_v1
      settings_show_score
      settings_force_approbation
      timezone
      team_id_for_new_employee
      external_id
      status
      published
      name
      pricing {
        id
        createdAt
        updatedAt
        name
        status
        sections {
          id
          section_name
          section_translate_name
          default_value_readonly
          default_value_edit
        }
      }
      rights_admins {
        id
      }
      complementaryRightsSections {
        id
        section_name
        is_complementary
        section_translate_name
        section_order
      }
    }
  }
`;

export const DeleteSite = gql`
  mutation DeleteSite($where: SiteWhereUniqueInput!) {
    deleteSite(where: $where) {
      id
    }
  }
`;

export const Sites = gql`
  query Sites($where: SiteWhereInput) {
    Sites(where: $where) {
      client_id
      client {
        id
        name
        logo_url
      }
      teams {
        team_name
        id
      }
      id
      settings_skills_end_date
      settings_versatility_user_calendar
      settings_versatilty_feature
      settings_only_trainer
      settings_only_evaluator
      settings_enable_imports
      settings_training_time
      settings_hide_download_content
      settings_workload
      settings_planning_beta
      settings_monitoring_main_goal
      settings_monitoring_v1
      settings_show_score
      settings_force_approbation
      timezone
      team_id_for_new_employee
      external_id
      status
      published
      name
      pricing {
        id
        createdAt
        updatedAt
        name
        status
        sections {
          id
          section_name
          section_translate_name
          default_value_readonly
          default_value_edit
        }
      }
      rights_admins {
        id
      }
      complementaryRightsSections {
        id
        section_name
        is_complementary
        section_translate_name
        section_order
      }
    }
  }
`;
