import { z } from "zod";

import { Id } from "@/shared/domain";

export const CampaignGeneralInfoUpdateData = z.object({
  due_date: z.string().nullable(),
  id: Id,
  name: z.string().min(1),
  start_date: z.string(),
});
export type CampaignGeneralInfoUpdateData = z.infer<
  typeof CampaignGeneralInfoUpdateData
>;
