import {
  CreateExternalTrainer,
  DeleteExternalTrainer,
  ExternalTrainers,
  UpdateExternalTrainer,
} from "@/graphql/settings/ExternalTrainers";
import { useParameterExternalTrainerStore } from "@/pinia/collections";
import { setGlobalStoreGraphQL } from "@/utils/graphql.utils";
import { apolloProvider } from "@/vue-apollo";

export const createExternalTrainer = async (payload: { data: any }) => {
  try {
    return await apolloProvider.defaultClient.mutate({
      mutation: CreateExternalTrainer,
      update: (store, { data: { createExternalTrainer } }) => {
        updateStoreAfterCreateExternalTrainer(store, createExternalTrainer);
      },
      variables: {
        data: payload.data,
      },
    });
  } catch (error) {
    console.error(error);
  }
};

export const updateExternalTrainer = async (payload: {
  data: any;
  where: any;
}) => {
  try {
    return await apolloProvider.defaultClient.mutate({
      mutation: UpdateExternalTrainer,
      update: (store, data) => {
        updateStoreAfterUpdateExternalTrainer(
          store,
          data.data.updateExternalTrainer,
        );
      },
      variables: {
        data: payload.data,
        where: payload.where,
      },
    });
  } catch (error) {
    console.error(error);
  }
};

export const deleteExternalTrainer = async (payload: { where: any }) => {
  try {
    return await apolloProvider.defaultClient.mutate({
      mutation: DeleteExternalTrainer,
      update: (store, data) => {
        updateStoreAfterDeleteExternalTrainer(
          store,
          data.data.deleteExternalTrainer.id,
        );
      },
      variables: {
        where: payload.where,
      },
    });
  } catch (error) {
    console.error(error);
  }
};

const updateStoreAfterCreateExternalTrainer = (
  store,
  createExternalTrainer,
) => {
  const data = store.readQuery({
    query: ExternalTrainers,
  });
  data.ExternalTrainers.push(createExternalTrainer);
  setGlobalStoreGraphQL(
    useParameterExternalTrainerStore(),
    data.ExternalTrainers,
  );
  store.writeQuery({
    data,
    query: ExternalTrainers,
  });
};

const updateStoreAfterDeleteExternalTrainer = (store, id) => {
  const data = store.readQuery({
    query: ExternalTrainers,
  });
  const index = data.ExternalTrainers.findIndex((c) => c.id === id);
  if (index !== -1) {
    data.ExternalTrainers.splice(index, 1);
    setGlobalStoreGraphQL(
      useParameterExternalTrainerStore(),
      data.ExternalTrainers,
    );
    store.writeQuery({
      data,
      query: ExternalTrainers,
    });
  }
};

const updateStoreAfterUpdateExternalTrainer = (
  store,
  updateExternalTrainer,
) => {
  const data = store.readQuery({
    query: ExternalTrainers,
  });
  const index = data.ExternalTrainers.findIndex(
    (c) => c.id === updateExternalTrainer.id,
  );
  if (index !== -1) {
    data.ExternalTrainers.splice(index, 1, updateExternalTrainer);
    setGlobalStoreGraphQL(
      useParameterExternalTrainerStore(),
      data.ExternalTrainers,
    );
    store.writeQuery({
      data,
      query: ExternalTrainers,
    });
  }
};
