import type { DataProxy } from "@apollo/client";

import {
  CreateSubposition,
  DeleteManySubposition,
  DeleteSubposition,
  Subpositions,
  UpdateSubposition,
} from "@/graphql/library/Subpositions";
import { usePositionLevelGlobalStore } from "@/pinia/collections";
import { apolloProvider } from "@/vue-apollo";

import {
  updateStoreAfterCreation,
  updateStoreAfterDeletion,
  updateStoreAfterUpdate,
} from "./utils";

export const createSubposition = async (payload: { data: any }) => {
  try {
    return await apolloProvider.defaultClient.mutate({
      mutation: CreateSubposition,
      update: (cache, data) => {
        updateStoreAfterCreation(
          cache as DataProxy,
          Subpositions,
          "Subpositions",
          data.data.createSubposition,
          usePositionLevelGlobalStore(),
        );
      },
      variables: {
        data: payload.data,
      },
    });
  } catch (error) {
    console.error(error);
  }
};

export const updateSubposition = async (payload: { data: any; where: any }) => {
  try {
    await apolloProvider.defaultClient.mutate({
      mutation: UpdateSubposition,
      update: (cache, data) => {
        updateStoreAfterUpdate(
          cache as DataProxy,
          Subpositions,
          "Subpositions",
          data.data.updateSubposition,
          usePositionLevelGlobalStore(),
        );
      },
      variables: {
        data: payload.data,
        where: payload.where,
      },
    });
  } catch (error) {
    console.error(error);
  }
};

export const deleteSubposition = async (payload: { where: any }) => {
  try {
    await apolloProvider.defaultClient.mutate({
      mutation: DeleteSubposition,
      update: (cache, data) => {
        updateStoreAfterDeletion(
          cache as DataProxy,
          Subpositions,
          "Subpositions",
          data.data.deleteSubposition,
          usePositionLevelGlobalStore(),
        );
      },
      variables: {
        where: payload.where,
      },
    });
  } catch (error) {
    console.error(error);
  }
};

export const archiveSubposition = async (payload: {
  data: any;
  where: any;
}) => {
  try {
    return await apolloProvider.defaultClient.mutate({
      mutation: UpdateSubposition,
      update: (cache, data) => {
        updateStoreAfterUpdate(
          cache as DataProxy,
          Subpositions,
          "Subpositions",
          data.data.updateSubposition,
          usePositionLevelGlobalStore(),
        );
      },
      variables: {
        data: payload.data,
        where: payload.where,
      },
    });
  } catch (error) {
    console.error(error);
  }
};

export const readSubposition = async (payload: { where: any }) => {
  try {
    return await apolloProvider.defaultClient.query({
      query: Subpositions,
      variables: {
        where: payload.where,
      },
    });
  } catch (error) {
    console.error(error);
  }
};

export const deleteManySubposition = async (payload: { where: any }) => {
  try {
    return await apolloProvider.defaultClient.mutate({
      mutation: DeleteManySubposition,
      variables: {
        where: payload.where,
      },
    });
  } catch (error) {
    console.error(error);
  }
};
