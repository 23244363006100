<template>
  <svg
    fill="none"
    height="24"
    viewBox="0 0 24 24"
    width="24"
    xmlns="http://www.w3.org/2000/svg"
  >
    <path
      :fill="selection"
      d="M13 6H14V5C14 4.73478 13.8946 4.48043 13.7071 4.29289C13.5196 4.10536 13.2652 4 13 4H10C9.73478 4 9.48043 4.10536 9.29289 4.29289C9.10536 4.48043 9 4.73478 9 5V6H10V8.072L4.285 17.97C4.10265 18.2735 4.00414 18.62 3.99955 18.974C3.99495 19.3281 4.08443 19.677 4.25884 19.9852C4.43325 20.2933 4.68634 20.5496 4.99225 20.7279C5.29816 20.9062 5.64592 21.0001 6 21H17C17.3541 21.0001 17.7018 20.9062 18.0077 20.7279C18.3137 20.5496 18.5667 20.2933 18.7412 19.9852C18.9156 19.677 19.005 19.3281 19.0005 18.974C18.9959 18.62 18.8974 18.2735 18.715 17.97L13 8.072V6ZM6 22C5.4599 22.0001 4.92978 21.8544 4.4656 21.5783C4.00141 21.3022 3.62038 20.9059 3.36271 20.4312C3.10505 19.9565 2.9803 19.4211 3.00165 18.8814C3.023 18.3417 3.18965 17.8178 3.484 17.365L9 7.811V7C8.73478 7 8.48043 6.89464 8.29289 6.70711C8.10536 6.51957 8 6.26522 8 6V5C8 4.46957 8.21071 3.96086 8.58579 3.58579C8.96086 3.21071 9.46957 3 10 3H13C13.5304 3 14.0391 3.21071 14.4142 3.58579C14.7893 3.96086 15 4.46957 15 5V6C15 6.26522 14.8946 6.51957 14.7071 6.70711C14.5196 6.89464 14.2652 7 14 7V7.811L19.516 17.365C19.8104 17.8178 19.977 18.3417 19.9983 18.8814C20.0197 19.4211 19.895 19.9565 19.6373 20.4312C19.3796 20.9059 18.9986 21.3022 18.5344 21.5783C18.0702 21.8544 17.5401 22.0001 17 22H6ZM12.294 15.292L13.92 13.666L17 19H6L9.66 12.66L12.294 15.292ZM12.294 16.706L9.875 14.288L7.732 18H15.268L13.706 15.294L12.294 16.706ZM12 10C12.2652 10 12.5196 10.1054 12.7071 10.2929C12.8946 10.4804 13 10.7348 13 11C13 11.2652 12.8946 11.5196 12.7071 11.7071C12.5196 11.8946 12.2652 12 12 12C11.7348 12 11.4804 11.8946 11.2929 11.7071C11.1054 11.5196 11 11.2652 11 11C11 10.7348 11.1054 10.4804 11.2929 10.2929C11.4804 10.1054 11.7348 10 12 10Z"
    />
  </svg>
</template>

<script lang="ts">
import Vue from "vue";

export default Vue.extend({
  name: "Process",
  props: ["selection"],
});
</script>
