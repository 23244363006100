import { isEmpty, orderBy } from "lodash";

import type {
  ContentApprobationID,
  ContentID,
  LinkEmployeeContentID,
  UserID,
} from "@/tscript/mercateam";

import APICloudRunClient from "@/api/cloudrun";
import usePermissions from "@/modules/rights/composables/use-permissions";
import { ESectionName } from "@/modules/rights/types";
import {
  useContentApprobationGlobalStore,
  useContentGlobalStore,
  useEmployeeContentStore,
  useUserGlobalStore,
} from "@/pinia/collections";
import { useAuthentificationStore } from "@/pinia/user";
import ClientHelper from "@/tscript/database/ClientHelper";
import {
  BasePublish,
  BaseStatus,
  EApprovedOrRefused,
  type IContent,
  type IContentApprobation,
  type IContentUsersListToApprove,
} from "@/tscript/interfaces";

const contentApprobationClientHelper = new ClientHelper<IContentApprobation>(
  "content_approbation",
);
const contentClientHelper = new ClientHelper<IContent>("content");
const cloudRunClient = new APICloudRunClient();
const linkEmployeeContentBindedToCurrentContent = (
  currentContentID: string,
): LinkEmployeeContentID[] => {
  const linkEmployeeContentActives = useEmployeeContentStore().actives;
  const linkEmployeeContentToDelete: LinkEmployeeContentID[] = [];
  for (const linkEmployeeContent of linkEmployeeContentActives) {
    if (linkEmployeeContent.content_id === currentContentID)
      linkEmployeeContentToDelete.push(linkEmployeeContent.id);
  }
  return linkEmployeeContentToDelete;
};

const getAllAlreadyPublishedContentsByContentSlug = (
  currentContentSlug: string,
): ContentID[] => {
  const contentActives = useContentGlobalStore().actives;
  const contentActivesToUpdate: ContentID[] = [];
  for (const content of contentActives) {
    if (
      content.contentSlug === currentContentSlug &&
      content.published === BasePublish.published
    )
      contentActivesToUpdate.push(content.id);
  }
  return contentActivesToUpdate;
};

export const updateUsersListToApproveInContent = async (
  contentApprobationID: ContentApprobationID,
  approvedOrRefused: EApprovedOrRefused,
  _isEdit: boolean,
  editApprobationComment = "",
) => {
  try {
    const contentGlobalStore = useContentGlobalStore();
    const contentApprobationGlobalStore = useContentApprobationGlobalStore();
    const contentDictionary = contentGlobalStore.dictionary;
    const contentApprobations = contentApprobationGlobalStore.actives;
    const contentApprobationsDictionary =
      contentApprobationGlobalStore.dictionary;
    if (!contentDictionary || !contentApprobationsDictionary)
      throw new Error("One dictionary is missing");

    const contentApprobation =
      contentApprobationsDictionary[contentApprobationID];

    if (!contentApprobation) {
      throw new Error("Content approbation is missing");
    }
    const approbatorsLineSlug = contentApprobation?.approbatorsLineSlug;
    const content = contentDictionary[contentApprobation.contentID];

    if (!approbatorsLineSlug) {
      throw new Error("Approbators slug is missing");
    }
    if (!content?.usersListsToApprove) {
      throw new Error("No content or approbators found");
    }

    if (!contentApprobations) {
      throw new Error("No content approbations");
    }

    const allApprobationsFromContentByUserId: Record<
      string,
      IContentApprobation
    > = {};
    for (const contentApprobation of contentApprobations) {
      if (contentApprobation.contentID !== content.id) continue;

      allApprobationsFromContentByUserId[contentApprobation.userID] =
        contentApprobation;
    }

    contentApprobations.filter(
      (approbation) => approbation.contentID === content.id,
    );

    const usersListsToApprove = content.usersListsToApprove;
    const newUsersListsToApprove: Partial<IContentUsersListToApprove>[] = [];
    let contentPublishedStatus = content.published;
    let globalRejected = false;
    let globalNeedRevision = false;

    for (let approbatorsList of usersListsToApprove) {
      if (!approbatorsList.usersWhoCanApprove) continue;
      const isUsersListFromContentBeingUpdated =
        approbatorsList.approbatorsLineSlug === approbatorsLineSlug;
      const onlyOneApprobatorWillRemain =
        approbatorsList.pending &&
        approbatorsList.pending.length === 2 &&
        approbatorsList.pending.includes(contentApprobation.userID);
      const allApprobatorsHaveAnswered =
        !approbatorsList.pending ||
        approbatorsList.pending.length === 0 ||
        (approbatorsList.pending.length === 1 &&
          approbatorsList.pending[0] === contentApprobation.userID);

      const approved: UserID[] = [];
      const rejected: UserID[] = [];
      const pending: UserID[] = [];
      for (const currentApprobatorId of approbatorsList.usersWhoCanApprove) {
        const currentApprobator =
          allApprobationsFromContentByUserId[currentApprobatorId];

        if (!currentApprobator) {
          throw new Error("Approbator does not exists");
        }

        const currentApprobatorIsTheOneRespondingNow =
          currentApprobatorId === contentApprobation.userID;

        if (currentApprobatorIsTheOneRespondingNow) {
          if (approvedOrRefused === EApprovedOrRefused.approved) {
            approved.push(currentApprobator.userID);
          } else {
            rejected.push(currentApprobator.userID);
          }
        } else if (currentApprobator.hasApproved) {
          approved.push(currentApprobator.userID);
        } else if (currentApprobator.hasRejected) {
          rejected.push(currentApprobator.userID);
        } else {
          pending.push(currentApprobator.userID);
        }

        if (isUsersListFromContentBeingUpdated) {
          if (
            onlyOneApprobatorWillRemain &&
            approbatorsList.pending?.includes(currentApprobator.userID) &&
            !currentApprobatorIsTheOneRespondingNow
          ) {
            currentApprobator.isLastRemainingApprobator = true;
          } else {
            currentApprobator.isLastRemainingApprobator = false;
          }

          currentApprobator.allApprobatorsHaveAnswered =
            allApprobatorsHaveAnswered;

          // Update approbators from same line in db here
          let currentApprobatorPayload: Partial<IContentApprobation> = {
            ...currentApprobator,
          };

          const currentApprobatorDocumentId = currentApprobatorPayload.id;
          if (!currentApprobatorDocumentId) {
            throw new Error("No Id found for an approbator");
          }

          delete currentApprobatorPayload.id;
          if (currentApprobatorIsTheOneRespondingNow) {
            currentApprobatorPayload = {
              ...currentApprobatorPayload,
              hasApproved: approvedOrRefused === EApprovedOrRefused.approved,
              hasRejected: approvedOrRefused === EApprovedOrRefused.refused,
              revisionNumber: content.RevisionNumber,
            };

            if (editApprobationComment) {
              currentApprobatorPayload.comment = editApprobationComment;
            }

            if (approvedOrRefused === EApprovedOrRefused.approved) {
              currentApprobatorPayload.approvedDate = new Date();
            } else {
              currentApprobatorPayload.rejectedDate = new Date();
            }
          }
          await contentApprobationClientHelper.update(
            currentApprobatorDocumentId,
            currentApprobatorPayload,
          );
        }
      }

      approbatorsList = {
        ...approbatorsList,
        approved,
        atLeastOneHasApproved: approved.length > 0,
        lineIsValidate: approved.length > 0 && rejected.length === 0,
        numberOfPendingApprobations: pending.length,
        pending,
        rejected,
      };
      newUsersListsToApprove.push(approbatorsList);
      if (contentPublishedStatus !== BasePublish.rejected) {
        if (rejected.length > 0) {
          globalRejected = true;
          contentPublishedStatus = BasePublish.rejected;
        } else if (pending.length > 0 && approved.length === 0) {
          globalNeedRevision = true;
        }
      }
    }
    // if it was rejected by at least one approver
    if (globalRejected) {
      contentPublishedStatus = BasePublish.rejected;
      // if there was at least one approvers list that didn't respond yet
    } else if (globalNeedRevision) {
      contentPublishedStatus = BasePublish.need_revision;
      // if it was approverd by all required approvers
    } else {
      contentPublishedStatus = BasePublish.published;
    }
    if (contentPublishedStatus === BasePublish.published) {
      if (content.notify_employees) {
        const linkEmployeeContentList =
          linkEmployeeContentBindedToCurrentContent(content.id);
        if (!isEmpty(linkEmployeeContentList)) {
          await useEmployeeContentStore().deleteMany(
            linkEmployeeContentList,
            false,
          );
        }
        await cloudRunClient.notificationOnCreateContentNotifyEmployees(content.id);
      }
      // we're approving a first version a creation
      if (content.RevisionNumber > 0) {
        await cloudRunClient.notificationOnUpdateContentAfterApproval(content.id);
        // we're approving a new version an update
      } else {
        await cloudRunClient.notificationOnCreateContentAfterApproval(content.id);
      }

      // Be sure to delete all contents already published from same origin (same content slug)
      // Only one published can be active
      const contentListBySlug = getAllAlreadyPublishedContentsByContentSlug(
        content.contentSlug,
      );
      if (!isEmpty(contentListBySlug))
        await useContentGlobalStore().deleteMany(contentListBySlug, false);
    }

    const contentPayload: Partial<IContent> = {
      ...content,
      published: contentPublishedStatus,
      usersListsToApprove: newUsersListsToApprove,
    };
    delete contentPayload.id;
    await contentClientHelper.update(content.id, contentPayload);
    /* I need to wait for the update because i need the right number of pending
    approbators in the backend */
    // if it was rejected by at least one approver
    if (globalRejected) {
      if (approvedOrRefused === EApprovedOrRefused.refused) {
        await cloudRunClient.notificationOnRejection(
          content.id,
          contentApprobation.userID,
        );
      }
      // if there was at least one approvers list that didn't respond yet
    } else if (globalNeedRevision) {
      if (approvedOrRefused === EApprovedOrRefused.approved) {
        await cloudRunClient.notificationOnApprovalBeforePublish(
          content.id,
          contentApprobation.userID,
        );
      }
      // if it was approverd by all required approvers
    } else if (approvedOrRefused === EApprovedOrRefused.approved) {
      await cloudRunClient.notificationOnApprovalOnPublish(
        content.id,
        contentApprobation.userID,
      );
    }
  } catch (error) {
    console.error(error);
  }
};

interface IApprobationsForUser {
  contentApprobationslastActions: IContentApprobation[];
  contentApprobationsResponsabilities: IContentApprobation[];
}

export const getApprobationsForUser = (
  userID: UserID,
): IApprobationsForUser => {
  const contentApprobationGlobalStore = useContentApprobationGlobalStore();
  const contentApprobationsCopy = [
    ...(contentApprobationGlobalStore.actives || []),
  ];
  const contentGlobalStore = useContentGlobalStore();
  const contentDictionary = contentGlobalStore.dictionary;
  const userGlobalStore = useUserGlobalStore();
  const usersDictionary = userGlobalStore.dictionary;

  const userRightsApprobations = usePermissions(ESectionName.Enum.approbations);

  const contentApprobationsFilteredByCurrentUser = userRightsApprobations
    .readAllowed.value
    ? contentApprobationsCopy
    : contentApprobationsCopy.filter(
        (approbation) => approbation.userID === userID,
      );
  let contentApprobationsResponsabilities = [] as IContentApprobation[];
  let contentApprobationslastActions = [] as IContentApprobation[];

  if (!contentDictionary || !usersDictionary) {
    throw new Error("No contents or users found");
  }

  for (const contentApprobation of contentApprobationsFilteredByCurrentUser) {
    const copyOfContentApprobation: any = {
      ...contentApprobation,
    };
    if (copyOfContentApprobation.userID !== userID) {
      copyOfContentApprobation.isExternalObserver = true;
    } else {
      copyOfContentApprobation.isExternalObserver = false;
    }
    const content = contentDictionary[copyOfContentApprobation.contentID];
    copyOfContentApprobation.title = content?.title;

    const editorID = content?.update_user_id;
    const updatedBy =
      editorID && usersDictionary[editorID] ? usersDictionary[editorID] : null;

    if (updatedBy) {
      copyOfContentApprobation.updated_by = {
        avatar_url: updatedBy.avatar_url,
        first_name: updatedBy.first_name,
        fullName: `${updatedBy.first_name} ${updatedBy.last_name}`,
        last_name: updatedBy.last_name,
      };
    } else {
      copyOfContentApprobation.updated_by = null;
    }

    const authorID = content?.author;
    const author =
      authorID && usersDictionary[authorID] ? usersDictionary[authorID] : false;

    if (!author) continue;
    copyOfContentApprobation.author = {
      avatar_url: author.avatar_url,
      first_name: author.first_name,
      fullName: `${author.first_name} ${author.last_name}`,
      last_name: author.last_name,
    };
    copyOfContentApprobation.numberOfApprobators -= 1;
    if (
      copyOfContentApprobation.hasApproved ||
      copyOfContentApprobation.hasRejected
    ) {
      contentApprobationslastActions.push(copyOfContentApprobation);
    } else {
      contentApprobationsResponsabilities.push(copyOfContentApprobation);
    }
  }

  contentApprobationsResponsabilities = contentApprobationsResponsabilities =
    orderBy(
      contentApprobationsResponsabilities,
      ["isExternalObserver", "updated_at"],
      ["asc", "desc"],
    );
  contentApprobationslastActions = contentApprobationslastActions = orderBy(
    contentApprobationslastActions,
    ["isExternalObserver", "updated_at"],
    ["asc", "desc"],
  );

  return {
    contentApprobationslastActions,
    contentApprobationsResponsabilities,
  };
};

export const updateContentApprobatorsStatus = async (
  contentSlug: string,
  status = BaseStatus.deleted,
) => {
  const previousContentApprobators =
    await contentApprobationClientHelper.getDocumentsWithWhere([
      {
        fieldName: "client_id",
        filterOp: "==",
        value: useAuthentificationStore().active_client?.id || "",
      },
      {
        fieldName: "site_id",
        filterOp: "==",
        value: useAuthentificationStore().active_site_full?.id || "",
      },
      {
        fieldName: "contentSlug",
        filterOp: "==",
        value: contentSlug,
      },
    ]);

  const contentApprobatorPayload = previousContentApprobators.map(
    (contentApprobator) => {
      contentApprobator.status = status;
      contentApprobator.updated_at = new Date();
      return contentApprobator;
    },
  );
  await contentApprobationClientHelper.updateMany(contentApprobatorPayload);
};
