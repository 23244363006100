import type { DataProxy } from "@apollo/client";

import {
  CreateLSkill,
  DeleteLSkill,
  DeleteManyLSkill,
  LSkills,
  UpdateLSkill,
} from "@/graphql/employees/LSkill";
import { useEmployeeSkillStore } from "@/pinia/collections";
import { apolloProvider } from "@/vue-apollo";

import { updateStoreAfterCreation, updateStoreAfterUpdate } from "./utils";

export const createLSkill = async (payload: { data: any }) => {
  try {
    return await apolloProvider.defaultClient.mutate({
      mutation: CreateLSkill,
      update: (cache, data) => {
        updateStoreAfterCreation(
          cache as DataProxy,
          LSkills,
          "LSkills",
          data.data.createLSkill,
          useEmployeeSkillStore(),
        );
      },
      variables: {
        data: payload.data,
      },
    });
  } catch (error) {
    console.error(error);
  }
};

export const updateLSkill = async (payload: { data: any; where: any }) => {
  try {
    return await apolloProvider.defaultClient.mutate({
      mutation: UpdateLSkill,
      update: (cache, data) => {
        updateStoreAfterUpdate(
          cache as DataProxy,
          LSkills,
          "LSkills",
          data.data.updateLSkill,
          useEmployeeSkillStore(),
        );
      },
      variables: {
        data: payload.data,
        where: payload.where,
      },
    });
  } catch (error) {
    console.error(error);
  }
};

export const deleteLSkill = async (payload: { where: any }) => {
  try {
    return await apolloProvider.defaultClient.mutate({
      mutation: DeleteLSkill,
      update: (cache, data) => {
        updateStoreAfterUpdate(
          cache as DataProxy,
          LSkills,
          "LSkills",
          data.data.deleteLSkill,
          useEmployeeSkillStore(),
        );
      },
      variables: {
        where: payload.where,
      },
    });
  } catch (error) {
    console.error(error);
  }
};

export const archiveLSkill = async (payload: { data: any; where: any }) => {
  try {
    return await apolloProvider.defaultClient.mutate({
      mutation: UpdateLSkill,
      update: (cache, data) => {
        updateStoreAfterUpdate(
          cache as DataProxy,
          LSkills,
          "LSkills",
          data.data.updateLSkill,
          useEmployeeSkillStore(),
        );
      },
      variables: {
        data: payload.data,
        where: payload.where,
      },
    });
  } catch (error) {
    console.error(error);
  }
};

export const deleteManyLSkill = async (payload: { where: any }) => {
  try {
    return await apolloProvider.defaultClient.mutate({
      mutation: DeleteManyLSkill,
      variables: {
        where: payload.where,
      },
    });
  } catch (error) {
    console.error(error);
  }
};
