import axios, { type AxiosInstance, isAxiosError } from "axios";
import { getAuth } from "firebase/auth";
import type { Dictionary } from "lodash";

import {
  type Template,
  TemplateSchema,
  TemplatesSchema,
} from "@/components/planningv2/types/AutoSchedules";
import {
  formatReviewDetail,
  signReviewtoApiDomain,
  templateApiToEditDomain,
} from "@/modules/performance-review-v2/api/clientToApiDomain";
import { UpdateApplicantsData } from "@/modules/performance-review-v2/types/applicantsUpdate";
import { archiveTemplate } from "@/modules/performance-review-v2/types/archiveTemplate";
import type { CampaignCreateData } from "@/modules/performance-review-v2/types/campaignEdition";
import { CampaignGeneralInfoUpdateData } from "@/modules/performance-review-v2/types/campaignGeneralInfoUpdate";
import { Campaign } from "@/modules/performance-review-v2/types/campainCreation";
import { deleteCampaign } from "@/modules/performance-review-v2/types/deleteCampaign";
import { deleteTemplate } from "@/modules/performance-review-v2/types/deleteTemplate";
import { CampaignCard } from "@/modules/performance-review-v2/types/entities/campaign";
import { EmployeeReview } from "@/modules/performance-review-v2/types/entities/employeeReview";
import type {
  GlobalAssessment,
  NewGoals,
  ReviewResponse,
} from "@/modules/performance-review-v2/types/entities/review-response";
import { Template as PerformanceReviewTemplate } from "@/modules/performance-review-v2/types/entities/template.entity";
import { ReviewDetail } from "@/modules/performance-review-v2/types/reviewDetail";
import { SaveReview } from "@/modules/performance-review-v2/types/saveReview";
import {
  type SignatureReview,
  SignReview,
} from "@/modules/performance-review-v2/types/signReview";
import { StartReview } from "@/modules/performance-review-v2/types/startReview";
import type { TemplateCreateData } from "@/modules/performance-review-v2/types/templateEdition";
import type { UpdateGoalStatus } from "@/modules/performance-review-v2/types/updateGoalStatus";
import type {
  ReminderScenarioId,
  ReminderSummary,
} from "@/modules/reminders/types";
import { type EmailDomain, EmailDomainSchema } from "@/views/types/EmailDomain";

import { createAxiosClient } from "./axios";

export default class APICloudRunClient {
  apiClient: AxiosInstance;
  userTokenId: string;
  // source: any;

  constructor() {
    this.apiClient = createAxiosClient();
    this.userTokenId = "";
  }

  async archivePerformanceReviewTemplate(
    templateId: string,
    templateRevision: number,
  ) {
    const data = archiveTemplate.parse({
      template_id: templateId,
      template_revision: templateRevision,
    });
    return this.apiClient
      .post(
        "api/performance-review/designing/archive-template",
        data,
        await this.getHeaders(),
      )
      .then(() => {
        return "OK" as const;
      })
      .catch(() => {
        return "ERROR" as const;
      });
  }

  async createAutoScheduleTemplate(data: Template) {
    return this.apiClient
      .post(
        "api/schedule/auto-schedule/templates",
        data,
        await this.getHeaders(),
      )
      .then(() => "OK" as const)
      .catch((error) => {
        return isAxiosError(error) && 409 === error.response?.status
          ? ("CONFLICT" as const)
          : ("ERROR" as const);
      });
  }

  async createPerformanceReviewCampaign(data: CampaignCreateData) {
    return this.apiClient
      .post(
        "api/performance-review/reviewing/launch-campaign",
        data,
        await this.getHeaders(),
      )
      .then(() => "OK" as const)
      .catch(() => {
        return "ERROR" as const;
      });
  }

  async createPerformanceReviewTemplate(data: TemplateCreateData) {
    return this.apiClient
      .post(
        "api/performance-review/designing/create-template",
        data,
        await this.getHeaders(),
      )
      .then(() => "OK" as const)
      .catch(() => {
        return "ERROR" as const;
      });
  }

  async deleteAutoScheduleTemplateById(id: Template["id"]) {
    return this.apiClient
      .delete(
        `api/schedule/auto-schedule/template/${id}`,
        await this.getHeaders(),
      )
      .then(() => "OK" as const)
      .catch(() => "ERROR" as const);
  }

  async deletePerformanceReviewCampaign(campaignId: string) {
    const data = deleteCampaign.parse({
      campaign_id: campaignId,
    });
    return this.apiClient
      .post(
        "api/performance-review/reviewing/delete-campaign",
        data,
        await this.getHeaders(),
      )
      .then(() => {
        return "OK" as const;
      })
      .catch(() => {
        return "ERROR" as const;
      });
  }

  async deletePerformanceReviewTemplate(templateId: string) {
    const data = deleteTemplate.parse({
      template_id: templateId,
    });
    return this.apiClient
      .post(
        "api/performance-review/designing/delete-template",
        data,
        await this.getHeaders(),
      )
      .then(() => {
        return "OK" as const;
      })
      .catch(() => {
        return "ERROR" as const;
      });
  }

  async deletePositionCover(positionId: string): Promise<void> {
    return this.apiClient
      .delete(
        `/contentv2/positions/${positionId}/cover`,
        await this.getHeaders(),
      )
      .then(() => void 0);
  }

  async deleteTeamCover(teamId: string): Promise<void> {
    return this.apiClient
      .delete(`/contentv2/teams/${teamId}/cover`, await this.getHeaders())
      .then(() => void 0);
  }

  async downloadAsset(id: string, accessToken: string) {
    await this.getToken();
    return this.apiClient.get(
      `/api/client-asset/download/${id}?token=${accessToken}`,
      {
        headers: {
          "Cache-Control": "no-cache, no-store, must-revalidate",
          Pragma: "no-cache",
        },
        responseType: "blob",
      },
    );
  }

  async downloadRenameFile(url: string, fileName: string) {
    return this.apiClient({
      method: "GET",
      responseType: "blob",
      url,
    }).then((response) => {
      const url = window.URL.createObjectURL(new Blob([response.data]));
      const link = document.createElement("a");
      link.href = url;
      link.setAttribute("download", fileName);
      document.body.appendChild(link);
      link.click();
    });
  }

  async evalTeamLeadersReminder(
    instanceKey: string,
    employeeIds: string[],
    scenarioId: ReminderScenarioId,
    by_employee = false,
  ) {
    return this.apiClient
      .post(
        "api/reminder/trigger-team-leaders-reminder",
        {
          by_employee,
          dry_run: true,
          employee_ids: employeeIds,
          instance_key: instanceKey,
          scenario_id: scenarioId,
        },
        await this.getHeaders(),
      )
      .then((response) => {
        return response.data as ReminderSummary;
      })
      .catch(() => {
        return "ERROR" as const;
      });
  }

  async exportPerformanceReviewCampaign(campaignId: string, locale = "fr") {
    const headers = await this.getHeaders();
    return this.apiClient
      .get(
        `api/performance-review/reviewing/campaign-progression/${campaignId}?locale=${encodeURIComponent(locale)}`,
        { responseType: "blob", ...headers },
      )
      .then((response) => {
        const contentDisposition =
          response.headers["content-disposition"] ||
          response.headers["Content-Disposition"];
        let fileName = "downloaded_file.xlsx"; // Default file name

        if (contentDisposition?.includes("filename=")) {
          fileName = contentDisposition
            .split("filename=")[1]
            .split(";")[0]
            .trim();
          fileName = fileName.replace(/['"]/g, ""); // Remove any quotes around the file name
        }

        const url = window.URL.createObjectURL(
          new Blob([response.data], {
            type: "application/vnd.openxmlformats-officedocument.spreadsheetml.sheet",
          }),
        );

        const link = document.createElement("a");
        link.href = url;
        link.setAttribute("download", fileName); // Set the download attribute with the desired file name

        // Append the link to the body
        document.body.appendChild(link);

        // Programmatically click the link to trigger the download
        link.click();

        // Clean up and remove the link
        if (link.parentNode) {
          link.parentNode.removeChild(link);
        }

        // Revoke the object URL to free up memory
        window.URL.revokeObjectURL(url);
        return "OK" as const;
      })
      .catch(() => {
        return "ERROR" as const;
      });
  }

  async generateFunctionSheetSection(data: {
    client?: string;
    job: string;
    section: string;
    site?: string;
  }) {
    return this.apiClient.post(
      "/internal/openai/functionSheetSection",
      data,
      await this.getHeaders(),
    );
  }

  async generateFunctionSheetSummary(data: {
    client_name?: string;
    job: string;
    site_name?: string;
  }) {
    return this.apiClient.post(
      "/internal/openai/functionSheetSummary",
      data,
      await this.getHeaders(),
    );
  }

  getAnonymousHeaders() {
    const h = {
      headers: {
        common: {
          Accept: "application/json",
          "Cache-Control": "no-cache, no-store, must-revalidate",
          "Content-Type": "application/json",
          Pragma: "no-cache",
        },
      },
    };
    return h;
  }

  async getAutoScheduleTemplateById(id: Template["id"]) {
    return this.apiClient
      .get(`api/schedule/auto-schedule/template/${id}`, await this.getHeaders())
      .then((response) => {
        const maybeTemplate = TemplateSchema.safeParse(response.data);
        if (!maybeTemplate.success) return "ERROR" as const;
        return maybeTemplate.data;
      })
      .catch(() => "ERROR" as const);
  }

  async getAutoScheduleTemplates(teamId: Template["owner"]) {
    return this.apiClient
      .get(
        `api/schedule/auto-schedule/templates/${teamId}`,
        await this.getHeaders(),
      )
      .then((response) => {
        const maybeTemplates = TemplatesSchema.safeParse(response.data);
        if (!maybeTemplates.success) return "ERROR" as const;
        return maybeTemplates.data;
      })
      .catch(() => "ERROR" as const);
  }

  async getContents() {
    return this.apiClient.get("/api/content-legacy", await this.getHeaders());
  }

  async getEmployeePerformanceReviews(employeeId: string) {
    return this.apiClient
      .get(
        `api/performance-review/reviewing/employee-reviews?employee_id=${employeeId}`,
        await this.getHeaders(),
      )
      .then((response) => {
        const reviews = response.data;
        const maybeEmployeeReviews = EmployeeReview.array().safeParse(
          reviews
            ? reviews.map((review) => {
                return {
                  campaignId: review.campaign_id,
                  campaignName: review.campaign_name,
                  creatorId: review.creator_id,
                  goals: review.goals,
                  reviewValidatedAt: review.review_validated_at,
                  status: review.status,
                  stepCompleted: review.step_completed,
                  stepCount: review.step_count,
                  templateName: review.template_name,
                };
              })
            : [],
        );
        if (!maybeEmployeeReviews.success) {
          console.error("Parse ERROR in getEmployeePerformanceReviews");
          return "ERROR" as const;
        }
        return maybeEmployeeReviews.data;
      })
      .catch(() => {
        return "ERROR" as const;
      });
  }

  async getExtractFile(fileName: string) {
    return this.apiClient.get(
      `/api/extract/${fileName}`,
      await this.getHeaders(),
    );
  }

  async getHeaders(): Promise<any> {
    await this.getToken();
    const h = {
      headers: {
        common: {
          Accept: "application/json",
          Authorization: `Bearer ${this.userTokenId}`,
          "Cache-Control": "no-cache, no-store, must-revalidate",
          "Content-Type": "application/json",
          Pragma: "no-cache",
        },
      },
    };
    return h;
  }

  async getPdfConsultationProofExport(
    employeeId: string,
    revisionId: string,
    title: string,
  ) {
    const convertedTitle = title
      .replace(/[^a-z0-9\s]/gi, "")
      .replace(/\s+/g, "_");
    const headers = await this.getHeaders();
    return this.apiClient
      .get(`/contentv2/view/${revisionId}/by/${employeeId}/pdf`, {
        responseType: "blob",
        ...headers,
      })
      .then((response) => {
        const contentDisposition =
          response.headers["content-disposition"] ||
          response.headers["Content-Disposition"];
        let fileName = `${convertedTitle}.pdf`;

        if (contentDisposition?.includes("filename=")) {
          fileName = contentDisposition
            .split("filename=")[1]
            .split(";")[0]
            .trim();
          fileName = fileName.replace(/['"]/g, ""); // Remove any quotes around the file name
        }

        const url = window.URL.createObjectURL(
          new Blob([response.data], { type: "application/pdf" }),
        );

        const link = document.createElement("a");
        link.href = url;
        link.setAttribute("download", fileName); // Set the download attribute with the desired file name

        // Append the link to the body
        document.body.appendChild(link);

        // Programmatically click the link to trigger the download
        link.click();

        // Clean up and remove the link
        if (link.parentNode) {
          link.parentNode.removeChild(link);
        }

        // Revoke the object URL to free up memory
        window.URL.revokeObjectURL(url);
      })
      .catch((error) => {
        console.error("Error fetching data:", error);
        throw error;
      });
  }

  async getPdfRevisionExport(revisionId: string, title: string) {
    const convertedTitle = title
      .replace(/[^a-z0-9\s]/gi, "")
      .replace(/\s+/g, "_");
    const headers = await this.getHeaders();
    return this.apiClient
      .get(`/contentv2/view/${revisionId}/pdf`, {
        responseType: "blob",
        ...headers,
      })
      .then((response) => {
        const contentDisposition =
          response.headers["content-disposition"] ||
          response.headers["Content-Disposition"];
        let fileName = `${convertedTitle}.pdf`;

        if (contentDisposition?.includes("filename=")) {
          fileName = contentDisposition
            .split("filename=")[1]
            .split(";")[0]
            .trim();
          fileName = fileName.replace(/['"]/g, ""); // Remove any quotes around the file name
        }

        const url = window.URL.createObjectURL(
          new Blob([response.data], { type: "application/pdf" }),
        );

        const link = document.createElement("a");
        link.href = url;
        link.setAttribute("download", fileName); // Set the download attribute with the desired file name

        // Append the link to the body
        document.body.appendChild(link);

        // Programmatically click the link to trigger the download
        link.click();

        // Clean up and remove the link
        if (link.parentNode) {
          link.parentNode.removeChild(link);
        }

        // Revoke the object URL to free up memory
        window.URL.revokeObjectURL(url);
      })
      .catch((error) => {
        console.error("Error fetching data:", error);
        throw error;
      });
  }

  async getPerformanceReview(campaignId: string, employeeId: string) {
    return this.apiClient
      .get(
        `api/performance-review/reviewing/review-detail/${campaignId}/${employeeId}`,
        await this.getHeaders(),
      )
      .then((response) => {
        const formatResponseIntoReviewDetail = formatReviewDetail(
          response.data,
        );

        const maybeReviewDetail = ReviewDetail.safeParse(
          formatResponseIntoReviewDetail,
        );

        if (!maybeReviewDetail.success) {
          console.error("Parse ERROR in getPerformanceReview");
          return "ERROR" as const;
        }

        return maybeReviewDetail.data;
      })
      .catch(() => {
        return "ERROR" as const;
      });
  }

  async getPerformanceReviewCampaign(id: string) {
    return this.apiClient
      .get(
        `api/performance-review/reviewing/campaign-detail/${id}`,
        await this.getHeaders(),
      )
      .then((response) => {
        const maybeCampaign = Campaign.safeParse(response.data);
        if (!maybeCampaign.success) {
          console.error("Parse ERROR in getPerformanceReviewCampaign");
          return "ERROR" as const;
        }
        return maybeCampaign.data;
      })
      .catch(() => {
        return "ERROR" as const;
      });
  }

  async getPerformanceReviewCampaignsList(site_id: string) {
    return this.apiClient
      .get(
        `api/performance-review/reviewing/campaign-list?site_id=${site_id}`,
        await this.getHeaders(),
      )
      .then((response) => {
        const maybeCampaigns = CampaignCard.array().safeParse(
          response.data?.campaigns,
        );
        if (!maybeCampaigns.success) {
          console.error("Parse ERROR in getPerformanceReviewCampaignsList");
          return "ERROR" as const;
        }
        return maybeCampaigns.data;
      })
      .catch(() => {
        return "ERROR" as const;
      });
  }

  async getPerformanceReviewTemplate(templateId: string, revision: number) {
    return this.apiClient
      .get(
        `api/performance-review/designing/template-detail/${templateId}?revision=${revision}`,
        await this.getHeaders(),
      )
      .then((response) => {
        const data = response.data;
        const templateDetail = templateApiToEditDomain(data);
        if (templateDetail === "ERROR") {
          console.error("ERROR in templateApiToEditDomain");
          return "ERROR" as const;
        }
        return templateDetail;
      })
      .catch(() => {
        return "ERROR" as const;
      });
  }

  async getPerformanceReviewTemplatesList(site_id: string) {
    return this.apiClient
      .get(
        `api/performance-review/designing/template-list?site_id=${site_id}`,
        await this.getHeaders(),
      )
      .then((response) => {
        const maybeTemplates = PerformanceReviewTemplate.array().safeParse(
          response.data?.templates.map((template) => {
            return {
              archived: template.archived,
              createdAt: new Date(template.created_at),
              creatorId: template.creator_id,
              id: template.id,
              name: template.name,
              revision: template.revision,
              revisions: template.revisions.map((revision) => ({
                ...revision,
                createdAt: new Date(revision.created_at),
                creatorId: revision.creator_id,
              })),
            };
          }),
        );
        if (!maybeTemplates.success) {
          console.error("Parse ERROR in getPerformanceReviewTemplatesList");
          return "ERROR" as const;
        }
        return maybeTemplates.data;
      })
      .catch(() => {
        return "ERROR" as const;
      });
  }

  async getSSODomains(): Promise<"ERROR" | EmailDomain[]> {
    return this.apiClient
      .get("/api/authentication/email-domains", await this.getHeaders())
      .then((response) => {
        const maybeDomains = EmailDomainSchema.array().safeParse(response.data);
        if (!maybeDomains.success) return "ERROR" as const;
        return maybeDomains.data;
      })
      .catch(() => "ERROR" as const);
  }

  async getToken() {
    const user: any = getAuth().currentUser;
    const token: string = await user?.getIdToken();
    this.setUserTokenId(token);
  }

  async getViewedContent(employeeId: string) {
    return this.apiClient
      .get(`api/content-legacy/viewed/${employeeId}`, await this.getHeaders())
      .then((response) => response.data)
      .catch(() => "ERROR" as const);
  }

  async getWorkerIsSkilledForAPosition(payload: {
    position_id: string;
    worker_id: string;
  }) {
    return this.apiClient
      .post(
        "api/skill-management/worker-is-skilled-for-a-position",
        payload,
        await this.getHeaders(),
      )
      .then((answerAxios) => {
        const { data } = answerAxios;
        return data as {
          code: string;
          is_skilled: boolean;
          reason?: string;
          skill_id: string;
          skill_name: string;
        }[];
      })
      .catch(() => "ERROR" as const);
  }

  async getWorkerSkill(payload: { skill_id: string; worker_id: string }) {
    return this.apiClient
      .post(
        "api/skill-management/worker-skills",
        payload,
        await this.getHeaders(),
      )
      .then((answerAxios) => {
        const { data } = answerAxios;
        return data as {
          end_date: Date | null;
          evaluator: boolean;
          id?: string;
          keywords?: {
            color: string;
            id: string;
            name: string;
          }[];
          level?: number;
          skill_id: string;
          skill_name?: string;
          start_date: Date | null;
          trainer: boolean;
        };
      })
      .catch(() => "ERROR" as const);
  }

  /* On Approval before publish */
  async notificationOnApprovalBeforePublish(id: string, approver_id: string) {
    return this.apiClient.post(
      `/modules/content-email/${id}/${approver_id}/approve/before_publish`,
      {},
      await this.getHeaders(),
    );
  }

  /* On last required approval to publish */
  async notificationOnApprovalOnPublish(id: string, approver_id: string) {
    return this.apiClient.post(
      `/modules/content-email/${id}/${approver_id}/approve/publish`,
      {},
      await this.getHeaders(),
    );
  }

  // On creation with or without approval (before approval)
  async notificationOnCreateContent(id: string) {
    return this.apiClient.post(
      `/modules/content-email/${id}/oncreate`,
      {},
      await this.getHeaders(),
    );
  }

  // On create with approval (after approval)
  async notificationOnCreateContentAfterApproval(id: string) {
    return this.apiClient.post(
      `/modules/content-email/${id}/oncreate/after_approval`,
      {},
      await this.getHeaders(),
    );
  }

  /* On Create (and update) with or without approval to
ask employees to consult the content */
  async notificationOnCreateContentNotifyEmployees(id: string) {
    return this.apiClient.post(
      `/modules/content-email/${id}/oncreate/notify_employees`,
      {},
      await this.getHeaders(),
    );
  }

  // On creation with or without approval (before approval)
  async notificationOnCreateContentWithoutApprobationSystem(id: string) {
    return this.apiClient.post(
      `/modules/content-email/${id}/oncreate_no_approbation`,
      {},
      await this.getHeaders(),
    );
  }

  /* On Reject */
  async notificationOnRejection(id: string, approver_id: string) {
    return this.apiClient.post(
      `/modules/content-email/${id}/${approver_id}/reject`,
      {},
      await this.getHeaders(),
    );
  }

  // On update with or without approval (before approval)
  async notificationOnUpdateContent(id: string) {
    return this.apiClient.post(
      `/modules/content-email/${id}/onupdate`,
      {},
      await this.getHeaders(),
    );
  }

  // On update with approval (after approval)
  async notificationOnUpdateContentAfterApproval(id: string) {
    return this.apiClient.post(
      `/modules/content-email/${id}/onupdate/after_approval`,
      {},
      await this.getHeaders(),
    );
  }

  // On update with or without approval (before approval)
  async notificationOnUpdateContentWithoutApprobationSystem(id: string) {
    return this.apiClient.post(
      `/modules/content-email/${id}/onupdate_no_approbation`,
      {},
      await this.getHeaders(),
    );
  }

  async printPerformanceReview(
    id: string,
    localization: {
      collaborator: string;
      employeeCompanyId: string;
      employeeStartDate: string;
      job: string;
      locale: string;
      managers: string;
      performanceReview: string;
      signatures: string;
      signingDate: string;
    },
    fileName?: string,
  ) {
    const headers = await this.getHeaders();
    return this.apiClient
      .get(`/modules/performance-review/${id}/pdf`, {
        responseType: "blob",
        ...headers,
        params: {
          ...localization,
        },
      })
      .then((response) => {
        const url = window.URL.createObjectURL(new Blob([response.data]));
        const link = document.createElement("a");
        link.href = url;
        link.setAttribute(
          "download",
          fileName ? `${fileName}.pdf` : `${id}.pdf`,
        );
        document.body.appendChild(link);
        link.click();
      });
  }

  async printReview(campaignId: string, employeeId: string) {
    const headers = await this.getHeaders();
    return this.apiClient
      .get(
        `api/performance-review/reviewing/review-export/${campaignId}/${employeeId}`,
        { responseType: "blob", ...headers },
      )
      .then((response) => {
        const contentDisposition =
          response.headers["content-disposition"] ||
          response.headers["Content-Disposition"];
        let fileName = "downloaded_file.pdf";

        if (contentDisposition?.includes("filename=")) {
          fileName = contentDisposition
            .split("filename=")[1]
            .split(";")[0]
            .trim();
          fileName = fileName.replace(/['"]/g, ""); // Remove any quotes around the file name
        }

        const url = window.URL.createObjectURL(
          new Blob([response.data], { type: "application/pdf" }),
        );

        const link = document.createElement("a");
        link.href = url;
        link.setAttribute("download", fileName); // Set the download attribute with the desired file name

        // Append the link to the body
        document.body.appendChild(link);

        // Programmatically click the link to trigger the download
        link.click();

        // Clean up and remove the link
        if (link.parentNode) {
          link.parentNode.removeChild(link);
        }

        // Revoke the object URL to free up memory
        window.URL.revokeObjectURL(url);
        return "OK" as const;
      })
      .catch(() => {
        return "ERROR" as const;
      });
  }

  async runNewAutomation(data: {
    currentSuperPositionIDs?: string[];
    end_date: Date;
    expertiseDays?: number;
    expertiseVersatilityStartDay?: Date | string;
    includeExternal: boolean;
    includePositionSelection: boolean;
    scoreMax?: number;
    severity?: number;
    shiftFromSelection: number;
    start_date: Date;
    superPositionWeight?: Dictionary<number>;
    team_id: string;
    teamsFiltered?: string[];
    versatilityDays?: number;
    versatilityExpertiseScore?: number;
  }) {
    await this.apiClient.post(
      "/api/schedule/experiment/munkres-new",
      data,
      await this.getHeaders(),
    );
    return "ok";
  }

  async savePerformanceReview(
    campaignId: string,
    employeeId: string,
    responses: ReviewResponse,
    goals?: NewGoals,
    globalAssessment?: GlobalAssessment,
  ) {
    const data = SaveReview.parse({
      campaign_id: campaignId,
      employee_id: employeeId,
      global_assessment: globalAssessment,
      goals,
      responses,
    });
    return this.apiClient
      .post(
        "api/performance-review/reviewing/save-review",
        data,
        await this.getHeaders(),
      )
      .then(() => {
        return "OK" as const;
      })
      .catch(() => {
        return "ERROR" as const;
      });
  }

  async selectFunctionSheetSkills(data: {
    client?: string;
    job: string;
    site?: string;
    skills: string[];
  }) {
    return this.apiClient.post(
      "/internal/openai/functionSheetSkills",
      data,
      await this.getHeaders(),
    );
  }

  setUserTokenId(userTokenId: string) {
    this.userTokenId = userTokenId;
  }

  async signRejectReview(signatureReview: SignatureReview) {
    const data = signReviewtoApiDomain(signatureReview);

    const parsedData = SignReview.safeParse(data);

    if (!parsedData.success) return "ERROR" as const;
    return this.apiClient
      .post(
        "api/performance-review/reviewing/sign-review",
        parsedData.data,
        await this.getHeaders(),
      )
      .then(() => "OK" as const)
      .catch(() => {
        return "ERROR" as const;
      });
  }

  async startPerformanceReview(campaignId: string, employeeId: string) {
    const data = StartReview.parse({
      campaign_id: campaignId,
      employee_id: employeeId,
    });
    return this.apiClient
      .post(
        "api/performance-review/reviewing/start-review",
        data,
        await this.getHeaders(),
      )
      .then(() => {
        return "OK" as const;
      })
      .catch(() => {
        return "ERROR" as const;
      });
  }

  async triggerTeamLeadersReminder(data: {
    by_employee?: boolean;
    data?: Record<string, string>;
    dry_run?: boolean;
    employee_ids: string[];
    instance_key: string;
    scenario_id: ReminderScenarioId;
  }) {
    return this.apiClient
      .post(
        "api/reminder/trigger-team-leaders-reminder",
        data,
        await this.getHeaders(),
      )
      .then((response) => {
        return response.data as ReminderSummary;
      })
      .catch(() => {
        return "ERROR" as const;
      });
  }

  async updatePerformanceReviewCampaignApplicants(data: UpdateApplicantsData) {
    const parsedUpdateData = UpdateApplicantsData.safeParse(data);
    if (!parsedUpdateData.success) return "ERROR" as const;
    return this.apiClient
      .post(
        "api/performance-review/reviewing/update-campaign-applicants",
        parsedUpdateData.data,
        await this.getHeaders(),
      )
      .then(() => "OK" as const)
      .catch(() => {
        return "ERROR" as const;
      });
  }

  async updatePerformanceReviewCampaignGeneralInfo(
    data: CampaignGeneralInfoUpdateData,
  ) {
    const parsedUpdateData = CampaignGeneralInfoUpdateData.safeParse(data);
    if (!parsedUpdateData.success) return "ERROR" as const;
    return this.apiClient
      .post(
        "api/performance-review/reviewing/update-campaign-general-info",
        parsedUpdateData.data,
        await this.getHeaders(),
      )
      .then(() => "OK" as const)
      .catch(() => {
        return "ERROR" as const;
      });
  }

  async updatePerformanceReviewGoalStatus(data: UpdateGoalStatus) {
    return this.apiClient
      .post(
        "api/performance-review/reviewing/change-goal-status",
        data,
        await this.getHeaders(),
      )
      .then(() => {
        return "OK" as const;
      })
      .catch(() => {
        return "ERROR" as const;
      });
  }

  async updatePerformanceReviewTemplate(data: TemplateCreateData) {
    return this.apiClient
      .post(
        "api/performance-review/designing/update-template",
        data,
        await this.getHeaders(),
      )
      .then(() => "OK" as const)
      .catch(() => {
        return "ERROR" as const;
      });
  }

  async uploadAsset(
    file: File,
    uploadConfig?: {
      onUploadProgress?: (progress: number) => void;
      signal?: AbortSignal;
      timeout?: number;
    },
  ) {
    const formData = new FormData();
    const config = await this.getHeaders();
    config.headers["Content-Type"] = "multipart/form-data";
    formData.append("file", file);
    return this.apiClient.post<{ id: string }>(
      "/api/client-asset/upload",
      formData,
      {
        ...config,
        onUploadProgress:
          uploadConfig?.onUploadProgress &&
          ((progressEvent) => {
            const percentage = progressEvent.total
              ? Math.floor((progressEvent.loaded / progressEvent.total) * 100)
              : null;
            uploadConfig.onUploadProgress?.(percentage || 0);
          }),
        signal: uploadConfig?.signal,
        timeout: uploadConfig?.timeout || 300000,
      },
    );
  }

  async uploadFile(
    url: string,
    file: File,
    uploadConfig?: {
      onUploadProgress?: (progress: number) => void;
      signal?: AbortSignal;
      timeout?: number;
    },
  ) {
    const formData = new FormData();
    const config = await this.getHeaders();
    config.headers["Content-Type"] = "multipart/form-data";
    formData.append("cover", file);
    return this.apiClient.put(url, formData, {
      ...config,
      onUploadProgress:
        uploadConfig?.onUploadProgress &&
        ((progressEvent) => {
          const percentage = progressEvent.total
            ? Math.floor((progressEvent.loaded / progressEvent.total) * 100)
            : null;
          uploadConfig.onUploadProgress?.(percentage || 0);
        }),
      signal: uploadConfig?.signal,
      timeout: uploadConfig?.timeout || 300000,
    });
  }

  async validatePerformanceReview(campaignId: string, employeeId: string) {
    const data = StartReview.parse({
      campaign_id: campaignId,
      employee_id: employeeId,
    });
    return this.apiClient
      .post(
        "api/performance-review/reviewing/validate-review",
        data,
        await this.getHeaders(),
      )
      .then(() => {
        return "OK" as const;
      })
      .catch(() => {
        return "ERROR" as const;
      });
  }
}
