import { z } from "zod";

import {
  GridQuestionResponseResource,
  OpenQuestionResponseResource,
  RatingResponseResource,
  TableResponseResource,
  TextResource,
} from "./resource.entity";

export const BlockAnswer = z.object({
  blocks: z.array(
    z.object({
      description: z.string().optional(),
      optional: z.boolean().optional().default(false),
      resource: z.discriminatedUnion("type", [
        TextResource,
        OpenQuestionResponseResource,
        GridQuestionResponseResource,
        TableResponseResource,
        RatingResponseResource,
      ]),
    }),
  ),
  filler: z.enum(["employee", "manager", "both"]),
  title: z.string(),
});

export type BlockAnswer = z.infer<typeof BlockAnswer>;
