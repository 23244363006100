import { useUiStore } from "@/pinia/modules/ui";

export const snackbarHelper = () => {
  const uiStore = useUiStore();
  return {
    error: uiStore.errorSnackbar,
    info: uiStore.infoSnackbar,
    success: uiStore.successSnackbar,
    warning: uiStore.warningSnackbar,
  };
};
