import type { TranslateResult } from "vue-i18n";

import i18n from "@/i18n";

enum EFirebaseCodeError {
  TOO_MANY_REQUESTS = "auth/too-many-requests",
  USER_NOT_FOUND = "auth/user-not-found",
  WRONG_PASSWORD = "auth/wrong-password",
}

export const firebaseErrorHandler = (codeError: string) => {
  let message: TranslateResult = "";
  switch (codeError) {
    case EFirebaseCodeError.TOO_MANY_REQUESTS:
      message = i18n.t("global.login_too_many_requests");
      break;
    case EFirebaseCodeError.USER_NOT_FOUND:
    case EFirebaseCodeError.WRONG_PASSWORD:
      message = i18n.t("global.invalid_login_credentials");
      break;
    default:
      message = i18n.t("errors.multiple_queries_failed_message");
      break;
  }
  return message;
};
