import { find, includes, map } from "lodash";

import type { IUserRights } from "@/modules/rights/types";
import type { ISite } from "@/tscript/interfaces";
import type { UserID } from "@/tscript/mercateam";

export const setRights = (userID: UserID, site: ISite): IUserRights => {
  let defaultUserRights = find(site.rights, (right) => {
    const usersIDs = map(right.users, (user) => user.id);
    return includes(usersIDs, userID);
  });

  if (!defaultUserRights) {
    defaultUserRights = find(site.rights, (right) => {
      return right.default;
    });
  }

  return defaultUserRights;
};

export const setRightsNoDefault = (
  userID: UserID,
  site: ISite,
): IUserRights | null => {
  return (
    find(site.rights, (right) => {
      const usersIDs = map(right.users, (user) => user.id);
      return includes(usersIDs, userID);
    }) || null
  );
};
