const childrenPositions = [
  {
    component: () => import("@/components/library/LibraryPositionsList.vue"),
    name: "LibraryPositionsList",
    path: "list",
  },
  {
    component: () => import("@/components/library/LibraryPositionsLevels.vue"),
    name: "LibraryPositionsLevels",
    path: "levels",
  },
];

export default childrenPositions;
