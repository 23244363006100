import {
  CreateNote,
  DeleteNote,
  Notes,
  UpdateNote,
} from "@/graphql/team/Notes";
import { useNotesStore } from "@/pinia/collections";
import { setGlobalStoreGraphQL } from "@/utils/graphql.utils";
import { apolloProvider } from "@/vue-apollo";

export const createNote = async (payload: { data: any }) => {
  try {
    return await apolloProvider.defaultClient.mutate({
      mutation: CreateNote,
      update: (store, { data: { createNote } }) => {
        updateStoreAfterCreateNote(store, createNote);
      },
      variables: {
        data: payload.data,
      },
    });
  } catch (error) {
    console.error(error);
  }
};

export const updateNote = async (payload: { data: any; where: any }) => {
  try {
    return await apolloProvider.defaultClient.mutate({
      mutation: UpdateNote,
      update: (store, data) => {
        updateStoreAfterUpdateNote(store, data.data.updateNote);
      },
      variables: {
        data: payload.data,
        where: payload.where,
      },
    });
  } catch (error) {
    console.error(error);
  }
};

export const deleteNote = async (payload: { where: any }) => {
  try {
    return await apolloProvider.defaultClient.mutate({
      mutation: DeleteNote,
      update: (store, data) => {
        updateStoreAfterDeleteNote(store, data.data.deleteNote.id);
      },
      variables: {
        where: payload.where,
      },
    });
  } catch (error) {
    console.error(error);
  }
};

const updateStoreAfterCreateNote = (store, createNote) => {
  const data = store.readQuery({
    query: Notes,
  });
  data.Notes.push(createNote);
  setGlobalStoreGraphQL(useNotesStore(), data.Notes);
  store.writeQuery({
    data,
    query: Notes,
  });
};

const updateStoreAfterDeleteNote = (store, id) => {
  const data = store.readQuery({
    query: Notes,
  });
  const index = data.Notes.findIndex((c) => c.id === id);
  if (index !== -1) {
    // Mutate cache result
    data.Notes.splice(index, 1);
    setGlobalStoreGraphQL(useNotesStore(), data.Notes);
    // Write back to the cache
    store.writeQuery({
      data,
      query: Notes,
    });
  }
};

const updateStoreAfterUpdateNote = (store, updateNote) => {
  const data = store.readQuery({
    query: Notes,
  });
  const index = data.Notes.findIndex((c) => c.id === updateNote.id);
  if (index !== -1) {
    // Mutate cache result
    data.Notes.splice(index, 1, updateNote);
    setGlobalStoreGraphQL(useNotesStore(), data.Notes);
    // Write back to the cache
    store.writeQuery({
      data,
      query: Notes,
    });
  }
};
