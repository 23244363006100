/* eslint-disable */
/* prettier-ignore */

import StackdriverErrorReporter from "stackdriver-errors-js";

const loggerHelper = new StackdriverErrorReporter();
loggerHelper.start({
  disabled: false,
  key: "AIzaSyAqYRg4cSMA0NQxcpwcLvSNETZ8VEYz9iI",
  projectId: "mercateam-prod",
  service: "mercateam-prod-webapp",
  // The following optional arguments can also be provided:

  //
  // Name of the service reporting the error, defaults to 'web'.

  // version: myServiceVersion,
  // Version identifier of the service reporting the error.

  // reportUncaughtExceptions: false
  // Set to false to prevent reporting unhandled exceptions, default: `true`.

  // reportUnhandledPromiseRejections: false
  // Set to false to prevent reporting unhandled promise rejections, default: `true`.

  //
  // Set to true to not send error reports, this can be used when developing locally, default: `false`.

  // context: {user: 'user1'}
  // You can set the user later using setUser()
});
export default loggerHelper;
