import { gql } from "graphql-tag";

export const CreateAbsence = gql`
  mutation CreateAbsence($data: AbsenceCreateInput!) {
    createAbsence(data: $data) {
      id
      status
      name
      abbreviation
      color
    }
  }
`;

export const UpdateAbsence = gql`
  mutation UpdateAbsence(
    $where: AbsenceWhereUniqueInput!
    $data: AbsenceUpdateInput!
  ) {
    updateAbsence(where: $where, data: $data) {
      id
      status
      name
      abbreviation
      color
    }
  }
`;

export const DeleteAbsence = gql`
  mutation DeleteAbsence($where: AbsenceWhereUniqueInput!) {
    deleteAbsence(where: $where) {
      id
      status
      name
      abbreviation
      color
    }
  }
`;

export const Absences = gql`
  query Absences(
    $orderBy: [AbsenceOrderByWithRelationAndSearchRelevanceInput!]
    $where: AbsenceWhereInput
  ) {
    Absences(orderBy: $orderBy, where: $where) {
      id
      status
      name
      abbreviation
      color
    }
  }
`;
