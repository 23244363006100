import type { DirectiveBinding } from "vue/types/options";

import CustomDirective from "./class/CustomDirective.class";

const vTooltip = new CustomDirective("tooltip", {
  bind: init,
  inserted: init,
  update: init,
});

function init(el: HTMLElement, binding: DirectiveBinding) {
  const position = binding.arg ?? "top";
  const tooltipText = binding.value ?? "Text";
  el.setAttribute("position", position);
  el.setAttribute("tooltip", tooltipText);
}

export default vTooltip;
