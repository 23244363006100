import { z } from "zod";

import { Id } from "@/shared/domain";

import { ResourceType } from "./entities/resource-type.enum";
import {
  BlockGridCols,
  BlockGridRows,
  RatingQuestionResource,
  Resource,
  TableQuestionResource,
} from "./entities/resource.entity";
import { Step } from "./entities/step.entity";

export const TemplateEdition = z.object({
  defineNewGoals: z.boolean(),
  description: z.string(),
  displayAttachedFiles: z.boolean(),
  displayGlobalAssessment: z.boolean(),
  displayPreviousGoals: z.boolean(),
  displaySkillsClearancesTrainings: z.boolean(),
  globalAssessment: z.array(z.object({ criteria: z.string() })),
  id: Id.optional(),
  name: z.string(),
  signatories: z.object({
    employeeRequired: z.boolean(),
    managerRequired: z.boolean(),
    others: z
      .record(
        z
          .array(
            z.object({
              user_id: z.string(),
            }),
          )
          .min(1),
      )
      .refine((object) => Object.keys(object).length > 0)
      .optional(),
  }),
  steps: Step.array(),
  updateSkillsClearancesTrainings: z.boolean(),
});

export type TemplateEdition = z.infer<typeof TemplateEdition>;

const TextBlockCreate = z.object({
  description: z.string().optional(),
  optional: z.boolean().default(false),
  text: z.string(),
  type: z.literal("information"),
});

const OpenQuestionBlockCreate = z.object({
  description: z.string().optional(),
  expected_response: z.string().optional(),
  optional: z.boolean().default(false),
  question: z.string(),
  type: z.literal("open-question"),
});

const GridQuestionBlockCreate = Resource.extend({
  cols: BlockGridCols.default([]),
  description: z.string().optional(),
  min_score: z.number().nullable().default(null),
  optional: z.boolean().default(false),
  rows: BlockGridRows.default([]),
  title: z.string().default(""),
  type: z.literal(ResourceType.grid).default(ResourceType.grid),
});
const TableQuestionCreate = TableQuestionResource.extend({
  description: z.string().optional(),
  optional: z.boolean().default(false),
});
const RatingQuestionCreate = RatingQuestionResource.extend({
  description: z.string().optional(),
  optional: z.boolean().default(false),
});

export const BlockCreate = z.discriminatedUnion("type", [
  TextBlockCreate,
  OpenQuestionBlockCreate,
  GridQuestionBlockCreate,
  TableQuestionCreate,
  RatingQuestionCreate,
]);
export type BlockCreate = z.infer<typeof BlockCreate>;

export const StepCreate = z.object({
  blocks: z.array(BlockCreate),
  filler: z.enum(["employee", "manager", "both"]),
  title: z.string(),
});
export type StepCreate = z.infer<typeof StepCreate>;

const SignatoriesCreate = z.object({
  employee_required: z.boolean(),
  manager_required: z.boolean(),
  others: z
    .record(
      z
        .array(
          z.object({
            user_id: z.string(),
          }),
        )
        .min(1),
    )
    .refine((object) => Object.keys(object).length > 0)
    .optional(),
});
type SignatoriesCreate = z.infer<typeof SignatoriesCreate>;

export const TemplateCreateData = z.object({
  define_new_goals: z.boolean(),
  description: z.string(),
  display_attached_files: z.boolean(),
  display_global_assessment: z.boolean(),
  display_previous_goals: z.boolean(),
  display_skills_clearances_trainings: z.boolean(),
  global_assessment: z.array(z.object({ criteria: z.string() })),
  id: Id.optional(),
  name: z.string().min(1),
  signatories: SignatoriesCreate,
  site_id: z.string(),
  steps: z.array(StepCreate),
  template_id: Id.optional(),
  update_skills_clearances_trainings: z.boolean(),
});

export type TemplateCreateData = z.infer<typeof TemplateCreateData>;
