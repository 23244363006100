import MD5 from "crypto-js/md5";
import jsonStringify from "json-stringify-deterministic";
import { isObject } from "lodash";

import type { PlanningParamsType } from "@/tscript/interfaces";

const isValidJSON = (str: string) => {
  try {
    JSON.parse(str);
    return true;
  } catch {
    return false;
  }
};
export const checkAndParsePlanningParams: (
  planningParamsJson: string | undefined,
) => PlanningParamsType = (planningParamsJson) => {
  if (!planningParamsJson || !isValidJSON(planningParamsJson))
    return { time: "week", type: "position" };
  const planningParams: unknown = JSON.parse(planningParamsJson);
  if (
    !isObject(planningParams) ||
    !(
      "time" in planningParams &&
      (planningParams.time === "week" ||
        planningParams.time === "day" ||
        planningParams.time === "month")
    ) ||
    !(
      "type" in planningParams &&
      (planningParams.type === "employee" ||
        planningParams.type === "position" ||
        planningParams.type === "shifts")
    )
  )
    return { time: "week", type: "position" };

  return { time: planningParams.time, type: planningParams.type };
};

export const jsonHash = (json) => {
  const jsonString = jsonStringify(json);
  return MD5(jsonString).toString();
};
