import { intersection } from "lodash";

import type { AbsenceResult, CellResult, ShiftResult } from "./planningBuilder";

export const PlanningFilterCells = (
  cells: CellResult[],
  filters: {
    category_ids: string[];
    clearance_ids: string[];
    contract_ids: string[];
    employee_ids: string[];
    position_ids: string[];
    restriction_ids: string[];
    shift_ids: string[];
    skill_ids: string[];
    subposition_ids: string[];
    tag_ids: string[];
  },
): CellResult[] => {
  if (!cells || cells.length === 0) return [];

  return cells.filter((cell) => {
    if (cell.status === "deleted") {
      return false;
    }
    const checkShift = () => {
      if (filters.shift_ids.length > 0) {
        return filters.shift_ids.includes(cell.shift_id);
      }
      return true;
    };
    if (filters.employee_ids.length > 0) {
      return filters.employee_ids.includes(cell.employee_id) && checkShift();
    }

    if (filters.position_ids.length > 0) {
      return filters.position_ids.includes(cell.position_id) && checkShift();
    }

    if (filters.subposition_ids.length > 0) {
      return (
        // @ts-expect-error automatically added
        filters.subposition_ids.includes(cell.subposition_id) && checkShift()
      );
    }

    if (filters.clearance_ids.length > 0) {
      return (
        // @ts-expect-error automatically added
        intersection(filters.clearance_ids, cell?.employee?.clearance_ids)
          .length > 0 && checkShift()
      );
    }

    if (filters.skill_ids.length > 0) {
      return (
        // @ts-expect-error automatically added
        intersection(filters.skill_ids, cell?.employee?.skill_ids).length > 0 &&
        checkShift()
      );
    }

    if (filters.contract_ids.length > 0) {
      // @ts-expect-error automatically added
      return filters.contract_ids.includes(cell.contract_id) && checkShift();
    }

    if (filters.tag_ids.length > 0) {
      // @ts-expect-error automatically added
      return tag_ids.includes(cell.tag_temp) && checkShift();
    }

    if (filters.restriction_ids.length > 0) {
      // TODO PLANNINGV3
      let match = false;
      for (const restrictionId of cell.restriction_ids) {
        if (filters.restriction_ids.includes(restrictionId)) {
          match = true;
          break;
        }
      }

      return match && checkShift();
    }

    if (filters.category_ids.length > 0) {
      // @ts-expect-error automatically added
      return filters.category_ids.includes(cell.category_id) && checkShift();
    }

    if (
      filters.shift_ids.length > 0 &&
      filters.employee_ids.length === 0 &&
      filters.position_ids.length === 0 &&
      filters.subposition_ids.length === 0 &&
      filters.clearance_ids.length === 0 &&
      filters.skill_ids.length === 0 &&
      filters.contract_ids.length === 0 &&
      filters.restriction_ids.length === 0 &&
      filters.category_ids.length === 0
    ) {
      return checkShift();
    }

    return true;
  });
};

export const AbsenceFilterCells = (
  absences: AbsenceResult[],
  filters: {
    employee_ids: (null | string)[];
    reason_id: null | string;
  },
) => {
  if (!absences || absences.length === 0) return [];
  const { employee_ids, reason_id } = filters;
  return absences.filter((absence) => {
    // @ts-expect-error automatically added
    if (absence.status === "deleted") {
      return false;
    }
    if (employee_ids && employee_ids.length > 0) {
      return employee_ids.includes(absence.employee_id);
    }

    if (reason_id) {
      return absence.reason === reason_id;
    }

    return true;
  });
};

export const ShiftFilterCells = (
  shifts: ShiftResult[],
  filters: {
    shift_ids: (null | string)[];
  },
) => {
  if (!shifts || shifts.length === 0) return [];

  if (filters.shift_ids && filters.shift_ids.length > 0) {
    return shifts.filter((shift) => {
      if (shift && filters.shift_ids) {
        return filters.shift_ids.includes(shift.shift_id);
      }
    });
  }

  return shifts;
};
