import { gql } from "graphql-tag";

export const CreateSkillLegend = gql`
  mutation CreateSkillLegend($data: SkillLegendCreateInput!) {
    createSkillLegend(data: $data) {
      id
      status
      name
      level
    }
  }
`;

export const UpdateSkillLegend = gql`
  mutation UpdateSkillLegend(
    $data: SkillLegendUpdateInput!
    $where: SkillLegendWhereUniqueInput!
  ) {
    updateSkillLegend(data: $data, where: $where) {
      id
      status
      name
      level
    }
  }
`;

export const DeleteSkillLegend = gql`
  mutation DeleteSkillLegend($where: SkillLegendWhereUniqueInput!) {
    deleteSkillLegend(where: $where) {
      id
      status
      name
      level
    }
  }
`;

export const SkillLegends = gql`
  query SkillLegends {
    SkillLegends {
      id
      status
      name
      level
    }
  }
`;
