import { z } from "zod";

export interface SignatureReview {
  campaignId: string;
  employeeId: string;
  signatures: {
    employee?:
      | { date: string; signature: string; type: "accepted" }
      | { date: string; type: "refused" };
    manager?:
      | { date: string; signature: string; type: "accepted"; user_id: string }
      | { date: string; type: "refused"; user_id: string };
    others?: Record<
      string,
      { date: string; userId: string } & (
        | { signature: string; type: "accepted" }
        | { type: "refused" }
      )
    >;
  };
}

export const SignReview = z.object({
  campaign_id: z.string(),
  employee_id: z.string(),
  signatures: z.object({
    employee: z.optional(
      z.union([
        z.object({
          date: z.string(),
          signature: z.string(),
          type: z.literal("accepted"),
        }),
        z.object({
          date: z.string(),
          type: z.literal("refused"),
        }),
      ]),
    ),
    manager: z.optional(
      z.union([
        z.object({
          date: z.string(),
          signature: z.string(),
          type: z.literal("accepted"),
          user_id: z.string(),
        }),
        z.object({
          date: z.string(),
          type: z.literal("refused"),
          user_id: z.string(),
        }),
      ]),
    ),
    others: z.optional(
      z.record(
        z.string(),
        z
          .object({
            date: z.string(),
            user_id: z.string(),
          })
          .and(
            z.union([
              z.object({
                signature: z.string(),
                type: z.literal("accepted"),
              }),
              z.object({
                type: z.literal("refused"),
              }),
            ]),
          ),
      ),
    ),
  }),
});

export type SignReview = z.infer<typeof SignReview>;
