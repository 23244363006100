const childrenPlanningEmployee = [
  {
    component: () => import("@/views/planning/employee/EmployeeWeek.vue"),
    name: "PlanningWeekEmployee",
    path: "week",
  },
  {
    component: () => import("@/views/planning/employee/EmployeeMonth.vue"),
    name: "PlanningMonthEmployee",
    path: "month",
  },
  {
    component: () => import("@/views/planning/employee/EmployeeDay.vue"),
    name: "PlanningDayEmployee",
    path: "day",
  },
];

export default childrenPlanningEmployee;
