import {
  CreateFilesAttachment,
  DeleteFilesAttachment,
  UpdateFilesAttachment,
} from "@/graphql/employees/FilesAttachment";
import { apolloProvider } from "@/vue-apollo";

export const createFilesAttachment = async (payload: { data: any }) => {
  try {
    return await apolloProvider.defaultClient.mutate({
      mutation: CreateFilesAttachment,
      variables: {
        data: payload.data,
      },
    });
  } catch (error) {
    console.error(error);
  }
};

export const updateFilesAttachment = async (payload: {
  data: any;
  where: any;
}) => {
  try {
    return await apolloProvider.defaultClient.mutate({
      mutation: UpdateFilesAttachment,
      variables: {
        data: payload.data,
        where: payload.where,
      },
    });
  } catch (error) {
    console.error(error);
  }
};

export const deleteFilesAttachment = async (payload: { where: any }) => {
  try {
    return await apolloProvider.defaultClient.mutate({
      mutation: DeleteFilesAttachment,
      variables: {
        where: payload.where,
      },
    });
  } catch (error) {
    console.error(error);
  }
};
