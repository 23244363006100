import type { DataProxy } from "@apollo/client";

import {
  CreatePosition,
  DeleteManyPosition,
  DeletePosition,
  Positions,
  UpdatePosition,
} from "@/graphql/library/Positions";
import { usePositionGlobalStore } from "@/pinia/collections";
import { apolloProvider } from "@/vue-apollo";

import {
  updateStoreAfterCreation,
  updateStoreAfterDeletion,
  updateStoreAfterUpdate,
} from "./utils";

export const createPosition = async (payload: { data: any }) => {
  try {
    return await apolloProvider.defaultClient.mutate({
      mutation: CreatePosition,
      update: (cache, data) => {
        updateStoreAfterCreation(
          cache as DataProxy,
          Positions,
          "Positions",
          data.data.createPosition,
          usePositionGlobalStore(),
        );
      },
      variables: {
        data: payload.data,
      },
    });
  } catch (error) {
    console.error(error);
  }
};

export const updatePosition = async (payload: { data: any; where: any }) => {
  try {
    await apolloProvider.defaultClient.mutate({
      mutation: UpdatePosition,
      update: (cache, data) => {
        updateStoreAfterUpdate(
          cache as DataProxy,
          Positions,
          "Positions",
          data.data.updatePosition,
          usePositionGlobalStore(),
        );
      },
      variables: {
        data: payload.data,
        where: payload.where,
      },
    });
  } catch (error) {
    console.error(error);
  }
};

export const deletePosition = async (payload: { where: any }) => {
  try {
    await apolloProvider.defaultClient.mutate({
      mutation: DeletePosition,
      update: (cache, data) => {
        updateStoreAfterDeletion(
          cache as DataProxy,
          Positions,
          "Positions",
          data.data.deletePosition,
          usePositionGlobalStore(),
        );
      },
      variables: {
        where: payload.where,
      },
    });
  } catch (error) {
    console.error(error);
  }
};

export const archivePosition = async (payload: { data: any; where: any }) => {
  try {
    return await apolloProvider.defaultClient.mutate({
      mutation: UpdatePosition,
      update: (cache, data) => {
        updateStoreAfterUpdate(
          cache as DataProxy,
          Positions,
          "Positions",
          data.data.updatePosition,
          usePositionGlobalStore(),
        );
      },
      variables: {
        data: payload.data,
        where: payload.where,
      },
    });
  } catch (error) {
    console.error(error);
  }
};

export const readPosition = async (payload: { where: any }) => {
  try {
    return await apolloProvider.defaultClient.query({
      query: Positions,
      variables: {
        where: payload.where,
      },
    });
  } catch (error) {
    console.error(error);
  }
};

export const deleteManyPosition = async (payload: { where: any }) => {
  try {
    return await apolloProvider.defaultClient.mutate({
      mutation: DeleteManyPosition,
      variables: {
        where: payload.where,
      },
    });
  } catch (error) {
    console.error(error);
  }
};
