import { z } from "zod";

import { Id } from "@/shared/domain";

export const CampaignCard = z.object({
  done: z.number(),
  due_date: z.string().nullable(),
  id: Id,
  name: z.string(),
  total: z.number(),
});

export type CampaignCard = z.infer<typeof CampaignCard>;
