import type { DataProxy } from "@apollo/client";

import {
  CreateSite,
  DeleteSite,
  Sites,
  UpdateSite,
} from "@/graphql/admin/Sites";
import { useSiteGlobalStore } from "@/pinia/collections";
import { apolloProvider } from "@/vue-apollo";

import {
  updateStoreAfterCreation,
  updateStoreAfterDeletion,
  updateStoreAfterUpdate,
} from "./utils";

export const createSite = async (payload: { data: any }) => {
  try {
    return await apolloProvider.defaultClient.mutate({
      mutation: CreateSite,
      update: (cache, data) => {
        updateStoreAfterCreation(
          cache as DataProxy,
          Sites,
          "Sites",
          data.data.createSite,
          useSiteGlobalStore(),
        );
      },
      variables: {
        data: payload.data,
      },
    });
  } catch (error) {
    console.error(error);
  }
};

export const updateSite = async (payload: { data: any; where: any }) => {
  try {
    return await apolloProvider.defaultClient.mutate({
      mutation: UpdateSite,
      update: (cache, data) => {
        updateStoreAfterUpdate(
          cache as DataProxy,
          Sites,
          "Sites",
          data.data.updateSite,
          useSiteGlobalStore(),
        );
      },
      variables: {
        data: payload.data,
        where: payload.where,
      },
    });
  } catch (error) {
    console.error(error);
  }
};

export const deleteSite = async (payload: { where: any }) => {
  try {
    return await apolloProvider.defaultClient.mutate({
      mutation: DeleteSite,
      update: (cache, data) => {
        updateStoreAfterDeletion(
          cache as DataProxy,
          Sites,
          "Sites",
          data.data.deleteSite,
          useSiteGlobalStore(),
        );
      },
      variables: {
        where: payload.where,
      },
    });
  } catch (error) {
    console.error(error);
  }
};

export const readSite = async (payload: { where: any }) => {
  try {
    return await apolloProvider.defaultClient.query({
      query: Sites,
      variables: {
        where: payload.where,
      },
    });
  } catch (error) {
    console.error(error);
  }
};
