const childrenFunctionSheet = [
  {
    path: "",
    redirect: "list",
  },
  {
    component: () =>
      import(
        "@/modules/workforce-planning/function-sheet/views/FunctionSheetList.vue",
      ),
    meta: {
      requiresAuth: true,
      userRightsSection: "workforce_planning",
    },
    name: "function-sheet-list",
    path: "list",
  },
  {
    component: () =>
      import(
        "@/modules/workforce-planning/function-sheet/views/FunctionSheetCategorize.vue",
      ),
    meta: {
      requiresAuth: true,
      userRightsSection: "workforce_planning",
    },
    name: "function-sheet-categorization",
    path: "categorization",
  },
  {
    component: () =>
      import(
        "@/modules/workforce-planning/function-sheet/views/FunctionSheetSettings.vue",
      ),
    meta: {
      requiresAuth: true,
      userRightsSection: "workforce_planning",
    },
    name: "function-sheet-settings",
    path: "settings",
  },
];

const childrenFunctionSheetDisplay = [
  {
    path: "",
    redirect: "description",
  },
  {
    component: () =>
      import(
        "@/modules/workforce-planning/function-sheet/views/FunctionSheetDisplayDescription.vue",
      ),
    meta: {
      requiresAuth: true,
      userRightsSection: "workforce_planning",
    },
    name: "function-sheet-display",
    path: "description",
  },
  {
    component: () =>
      import(
        "@/modules/workforce-planning/function-sheet/views/FunctionSheetDisplayMatchFunctionSheet.vue",
      ),
    meta: {
      requiresAuth: true,
      userRightsSection: "workforce_planning",
    },
    name: "function-sheet-display-match-function",
    path: "match-function",
  },
  {
    component: () =>
      import(
        "@/modules/workforce-planning/function-sheet/views/FunctionSheetDisplayMatchCollab.vue",
      ),
    meta: {
      requiresAuth: true,
      userRightsSection: "workforce_planning",
    },
    name: "function-sheet-display-match-collab",
    path: "match-collab",
  },
  {
    component: () =>
      import(
        "@/modules/workforce-planning/function-sheet/views/FunctionSheetDisplayUimm.vue",
      ),
    meta: {
      requiresAuth: true,
      userRightsSection: "workforce_planning",
    },
    name: "function-sheet-display-uimm",
    path: "uimm",
  },
];

const childrenFunctionSheetMatching = [
  {
    component: () =>
      import(
        "@/modules/workforce-planning/function-sheet/views/FunctionSheetMatchFunctionSheet.vue",
      ),
    meta: {
      requiresAuth: true,
      userRightsSection: "workforce_planning",
    },
    name: "function-sheet-matching-function-sheet",
    path: "function-sheet/:idSource/:idToCompare",
  },
  {
    component: () =>
      import(
        "@/modules/workforce-planning/function-sheet/views/FunctionSheetMatchCollaborator.vue",
      ),
    meta: {
      requiresAuth: true,
      userRightsSection: "workforce_planning",
    },
    name: "function-sheet-matching-collaborator",
    path: "collaborator/:idSource/:idToCompare",
  },
];

export {
  childrenFunctionSheet,
  childrenFunctionSheetDisplay,
  childrenFunctionSheetMatching,
};
