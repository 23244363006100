const childrenAdmin = [
  {
    component: () => import("@/components/admin/UsersAdmin.vue"),
    name: "UsersAdmin",
    path: "",
  },
  {
    component: () => import("@/components/admin/ClientsAdmin.vue"),
    name: "ClientsAdmin",
    path: "client",
  },
  {
    component: () => import("@/components/admin/SuperAdmin.vue"),
    name: "SuperAdmin",
    path: "super",
  },
  {
    component: () => import("@/components/admin/SitesAdmin.vue"),
    name: "SitesAdmin",
    path: "sites",
  },
  {
    component: () => import("@/components/admin/FactoriesAdmin.vue"),
    name: "FactoriesAdmin",
    path: "factories",
  },
  {
    component: () => import("@/components/admin/TeamsAdmin.vue"),
    name: "TeamsAdmin",
    path: "teams",
  },
  {
    component: () => import("@/components/admin/EmployeesAdmin.vue"),
    name: "EmployeesAdmin",
    path: "employees",
  },
  {
    component: () => import("@/components/admin/EventsAdmin.vue"),
    name: "EventsAdmin",
    path: "events",
  },
  {
    component: () => import("@/components/admin/CustomerSucessAdmin.vue"),
    name: "CustomerSucessAdmin",
    path: "customer-sucess",
  },
  /*   {
    path: "exports",
    name: "exports",
    component: () => import("@/components/admin/export/Exports.vue"),
  }, */
];

export default childrenAdmin;
