<template>
  <v-card class="pa-2" flat>
    <v-btn @click="closePopinAndDestroy()" color="black" icon id="closedCard">
      <v-icon>mdi-close</v-icon>
    </v-btn>

    <v-card-title class="font-weight-bold" style="word-break: break-word">{{
      $t("profile.parameters")
    }}</v-card-title>

    <v-tabs
      background-color="transparent"
      class="tabs"
      color="primary"
      v-model="tab"
    >
      <v-tab
        :disabled="tab.disabled"
        :key="tab.id"
        class="text-capitalize"
        v-for="tab in tabs"
      >
        {{ tab.name }}
      </v-tab>
    </v-tabs>

    <v-divider></v-divider>

    <v-card-text>
      <v-container>
        <div class="mt-3" v-if="tab === 0">
          <ContentTeamData
            @has-changed="(value) => (hasChanged = value)"
            @team-selected="(value) => (teamsSelected = value)"
          />
        </div>
      </v-container>
    </v-card-text>

    <v-card-actions>
      <v-spacer></v-spacer>
      <v-btn :disabled="!hasChanged" @click="save" color="primary" depressed>
        <div class="text-capitalize">
          {{ $t("parameters.save") }}
        </div>
      </v-btn>
    </v-card-actions>
  </v-card>
</template>

<script lang="ts">
import { mapActions, mapStores } from "pinia";
import Vue from "vue";

import type { TeamID } from "@/tscript/mercateam";

import ContentTeamData from "@/components/content/ContentTeamData.vue";
import { useUserGlobalStore } from "@/pinia/collections";
import { useUiStore } from "@/pinia/modules/ui";
import { useAuthentificationStore } from "@/pinia/user";

interface ITabs {
  disabled: boolean;
  id: string;
  name: string;
}

export default Vue.extend({
  components: {
    ContentTeamData,
  },
  computed: {
    ...mapStores(useAuthentificationStore, useUserGlobalStore),
    tabs(): ITabs[] {
      const tabs = [
        {
          disabled: false,
          id: "1",
          name: this.$root.$t("global.teams") as string,
        },
        {
          disabled: true,
          id: "2",
          name: this.$root.$t("global.notifications") as string,
        },
        {
          disabled: true,
          id: "3",
          name: this.$root.$t("global.planning") as string,
        },
      ];

      return tabs;
    },
  },
  data() {
    return {
      hasChanged: false as boolean,
      tab: null,
      teamsSelected: [] as TeamID[],
    };
  },
  methods: {
    ...mapActions(useUiStore, ["closePopinAndDestroy"]),
    save() {
      try {
        if (this.useAuthentificationStore.data?.id) {
          const updateData: any = {};
          if (this.teamsSelected) {
            updateData.favorite_teams = { set: this.teamsSelected };
            this.useAuthentificationStore.data.favorite_teams =
              this.teamsSelected;
          }
        } else {
          throw new Error("Missing user id");
        }
      } finally {
        this.closePopinAndDestroy();
      }
    },
  },
  name: "PopinParameters",
  watch: {
    tab() {
      this.hasChanged = false;
    },
  },
});
</script>

<style scoped>
#closedCard {
  position: absolute;
  top: 0;
  right: 0;
}
</style>
