import { computed, type ComputedRef } from "vue";

import {
  ESectionAction,
  type ESectionName,
  type ESectionScope,
} from "@/modules/rights/types";
import { useAuthentificationStore } from "@/pinia/user";

import { isAllowed } from "../utils/permission";

export interface UsablePermissions {
  anyActionAllowed: ComputedRef<boolean>;
  archiveAllowed: ComputedRef<boolean>;
  createAllowed: ComputedRef<boolean>;
  deleteAllowed: ComputedRef<boolean>;
  readAllowed: ComputedRef<boolean>;
  updateAllowed: ComputedRef<boolean>;
}

export default function usePermissions(
  sectionName: ESectionName,
  scopeInfos?: ESectionScope,
) {
  const { permissions } = useAuthentificationStore();

  const readAllowed: UsablePermissions["readAllowed"] = computed(() => {
    return isAllowed(sectionName, ESectionAction.Enum.read, scopeInfos);
  });

  const createAllowed: UsablePermissions["createAllowed"] = computed(() => {
    return isAllowed(sectionName, ESectionAction.Enum.create, scopeInfos);
  });

  const updateAllowed: UsablePermissions["updateAllowed"] = computed(() => {
    return isAllowed(sectionName, ESectionAction.Enum.update, scopeInfos);
  });

  const archiveAllowed: UsablePermissions["archiveAllowed"] = computed(() => {
    return isAllowed(sectionName, ESectionAction.Enum.archive, scopeInfos);
  });

  const deleteAllowed: UsablePermissions["deleteAllowed"] = computed(() => {
    return isAllowed(sectionName, "delete", scopeInfos);
  });

  const anyActionAllowed: UsablePermissions["anyActionAllowed"] = computed(
    () => {
      return isAllowed(sectionName, "anyAction", scopeInfos);
    },
  );

  const scopedReadAllowed = (scopeInfos: ESectionScope): boolean => {
    return isAllowed(sectionName, ESectionAction.Enum.read, scopeInfos);
  };

  const scopedUpdateAllowed = (scopeInfos: ESectionScope): boolean => {
    return isAllowed(sectionName, ESectionAction.Enum.update, scopeInfos);
  };

  const rights = computed(() => {
    const defaultValues = {
      edit:
        permissions[sectionName]?.section.default_value_edit || "total_access",
      readonly:
        permissions[sectionName]?.section.default_value_readonly ||
        "total_access",
    };
    return permissions[sectionName]?.values || defaultValues;
  });

  const { IS_NOT_USER } = useAuthentificationStore();

  return {
    anyActionAllowed,
    archiveAllowed,
    createAllowed,
    deleteAllowed,
    IS_NOT_USER,
    // Global
    readAllowed,
    rights,
    // Scoped
    scopedReadAllowed,
    scopedUpdateAllowed,
    updateAllowed,
  };
}
