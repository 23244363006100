import { gql } from "graphql-tag";

export const CreateContract = gql`
  mutation CreateContract($data: ContractCreateInput!) {
    createContract(data: $data) {
      id
      contract_name
      expires
      color_bg
      color_text
      status
      contract_tags {
        id
        value
        contract_id
      }
      notice_periods {
        id
        min_day
        max_day
        end_alert
      }
    }
  }
`;

export const UpdateContract = gql`
  mutation UpdateContract(
    $where: ContractWhereUniqueInput!
    $data: ContractUpdateInput!
  ) {
    updateContract(where: $where, data: $data) {
      id
      contract_name
      expires
      color_bg
      color_text
      status
      contract_tags {
        id
        value
        contract_id
      }
      notice_periods {
        id
        min_day
        max_day
        end_alert
      }
    }
  }
`;

export const DeleteContract = gql`
  mutation DeleteContract($where: ContractWhereUniqueInput!) {
    deleteContract(where: $where) {
      id
      contract_name
      expires
      color_bg
      color_text
      status
      contract_tags {
        id
        value
        contract_id
      }
      notice_periods {
        id
        min_day
        max_day
        end_alert
      }
    }
  }
`;

export const DeleteManyContract = gql`
  mutation DeleteManyContract($where: ContractWhereInput) {
    deleteManyContract(where: $where) {
      count
    }
  }
`;

export const Contracts = gql`
  query Contracts($where: ContractWhereInput) {
    Contracts(where: $where) {
      id
      contract_name
      expires
      color_bg
      color_text
      status
      contract_tags {
        id
        value
        contract_id
      }
      notice_periods {
        id
        min_day
        max_day
        end_alert
      }
    }
  }
`;
