import { EResolverName } from "@/tscript/interfaces";
import { getToken } from "@/vue-apollo";

import { updateAbsence } from "../_tables/absence";
import { updateCategory } from "../_tables/category";
import {
  updateCellAbsence,
  updateManyCellAbsence,
} from "../_tables/cellAbsence";
import { updateCellResult, updateManyCellResult } from "../_tables/cellResult";
import { updateCellShift, updateManyCellShift } from "../_tables/cellShift";
import { updateClearance } from "../_tables/clearances";
import { updateClient } from "../_tables/client";
import { updateClosure } from "../_tables/closures";
import { updateContract } from "../_tables/contract";
import { updateEmployee } from "../_tables/employee";
import { updateExternalTrainer } from "../_tables/externalTrainers";
import { updateFactory } from "../_tables/factories";
import { updateFilesAttachment } from "../_tables/filesAttachment";
import { updateFolder } from "../_tables/folder";
import { updateLClearance } from "../_tables/lclearances";
import { updateLPositionClosed } from "../_tables/lpositionClosed";
import { updateLRestriction } from "../_tables/lrestrictions";
import { updateLSkill } from "../_tables/lskills";
import { updateLTraining } from "../_tables/ltrainings";
import { updateNote } from "../_tables/notes";
import { updatePosition } from "../_tables/positions";
import { updateRestriction } from "../_tables/restriction";
import { updateShift } from "../_tables/shifts";
import { updateSite } from "../_tables/sites";
import { updateSkillLegend } from "../_tables/skillLegend";
import { updateSkill } from "../_tables/skills";
import { updateSubposition } from "../_tables/subpositions";
import { updateTeam } from "../_tables/teams";
import { updateUser } from "../_tables/users";

export const updateSwitcher = async (
  resolverName: EResolverName,
  payload: any,
) => {
  // Store token into localstorage
  await getToken();

  switch (resolverName) {
    case EResolverName.absence:
      await updateAbsence(payload);
      break;
    case EResolverName.category:
      await updateCategory(payload);
      break;
    case EResolverName.cellAbsence:
      await updateCellAbsence(payload);
      break;
    case EResolverName.cellResult:
      await updateCellResult(payload);
      break;
    case EResolverName.cellShift:
      await updateCellShift(payload);
      break;
    case EResolverName.clearance:
      await updateClearance(payload);
      break;
    case EResolverName.client:
      await updateClient(payload);
      break;
    case EResolverName.closure:
      await updateClosure(payload);
      break;
    case EResolverName.contract:
      await updateContract(payload);
      break;
    case EResolverName.employee:
      await updateEmployee(payload);
      break;
    case EResolverName.external_trainer:
      await updateExternalTrainer(payload);
      break;
    case EResolverName.factory:
      await updateFactory(payload);
      break;
    case EResolverName.filesAttachment:
      await updateFilesAttachment(payload);
      break;
    case EResolverName.folder:
      await updateFolder(payload);
      break;
    case EResolverName.lclearance:
      await updateLClearance(payload);
      break;
    case EResolverName.lposition_closed:
      await updateLPositionClosed(payload);
      break;
    case EResolverName.lrestriction:
      await updateLRestriction(payload);
      break;
    case EResolverName.lskill:
      await updateLSkill(payload);
      break;
    case EResolverName.ltraining:
      await updateLTraining(payload);
      break;
    case EResolverName.note:
      await updateNote(payload);
      break;
    case EResolverName.position:
      await updatePosition(payload);
      break;
    case EResolverName.restriction:
      await updateRestriction(payload);
      break;
    case EResolverName.shift:
      await updateShift(payload);
      break;
    case EResolverName.site:
      await updateSite(payload);
      break;
    case EResolverName.skill:
      await updateSkill(payload);
      break;
    case EResolverName.skillLegend:
      await updateSkillLegend(payload);
      break;
    case EResolverName.subposition:
      await updateSubposition(payload);
      break;
    case EResolverName.team:
      await updateTeam(payload);
      break;
    case EResolverName.user:
      await updateUser(payload);
      break;
    default:
      break;
  }
};

export const updateManySwitcher = async (
  resolverName: EResolverName,
  payload: any,
) => {
  // Store token into localstorage
  await getToken();

  switch (resolverName) {
    case EResolverName.cellAbsence:
      await updateManyCellAbsence(payload);
      break;
    case EResolverName.cellResult:
      await updateManyCellResult(payload);
      break;
    case EResolverName.cellShift:
      await updateManyCellShift(payload);
      break;
    default:
      break;
  }
};
