import childrenPositions from "./positions";

const childrenLibrary = [
  {
    alias: "",
    component: () => import("@/components/library/LibrarySkills.vue"),
    name: "LibrarySkillsView",
    path: "skills",
  },
  {
    component: () => import("@/components/library/LibraryClearances.vue"),
    name: "LibraryClearancesView",
    path: "clearances",
  },
  {
    component: () => import("@/components/library/LibraryKeywords.vue"),
    name: "LibraryKeywordsView",
    path: "keywords",
  },
  {
    children: childrenPositions,
    component: () => import("@/components/library/LibraryPositions.vue"),
    name: "LibraryPositionsView",
    path: "positions",
    redirect: "positions/list",
  },
];

export default childrenLibrary;
