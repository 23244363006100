import type { ComputedRef } from "vue";
import type { TranslateResult } from "vue-i18n";

import type {
  IPricing,
  IUserRights,
  LSection,
  Section,
} from "@/modules/rights/types";

import type {
  LAbsence,
  LContract,
  LPlanning,
  LShift,
  LTraining,
} from "./links_employee";
import type {
  ApprobationID,
  AssignationTagID,
  ClearanceID,
  ClientID,
  ContentApprobationID,
  ContentCommentID,
  ContentDocumentTypeID,
  ContentID,
  ContentStepID,
  ContentUsersListToApprove,
  ContractID,
  EmployeeCategoryID,
  EmployeeID,
  EvaluationModelID,
  FactoryID,
  FolderID,
  KeywordID,
  Languages,
  PositionID,
  PositionShiftID,
  QuestionnaireID,
  QuestionnaryModelID,
  ReasonID,
  RestrictionID,
  RightID,
  ShiftID,
  SiteID,
  SiteIDs,
  SkillID,
  SubpositionID,
  TeamID,
  UserID,
} from "./mercateam";
import type {
  OnGoingTrainingStatus,
  RestrictionStatus,
  ShiftStatus,
  StaffedStatus,
  TrainedStatus,
} from "./utils/planning";

export enum ActionType {
  functionSheetCategorize = "functionSheetCategorize",
}

export enum BasePublish {
  none = 0,
  draft = 1,
  need_revision = 2,
  published = 3,
  rejected = 4,
}

export enum BaseStatus {
  none = 0,
  active = 1,
  archived = 2,
  deleted = 3,
}

export enum Collections {
  link_employee_planning = "link_employee_planning",
  link_employee_shifts = "link_employee_shifts",
  link_employee_time_absences = "link_employee_time_absences",
}

export enum EApprobationType {
  content = "content",
  contentv2 = "contentv2",
  questionnaire = "questionnaire",
}

export enum EApprovedOrRefused {
  approved = "approved",
  refused = "refused",
}

export enum EApprovedOrRejectedOrBoth {
  approved = "approved",
  rejected = "rejected",
  approvedOrRejected = "approvedOrRejected",
}

export enum EBasePublish {
  none = "none",
  draft = "draft",
  need_revision = "need_revision",
  published = "published",
  rejected = "rejected",
}

export enum EBaseStatus {
  none = "none",
  active = "active",
  archived = "archived",
  deleted = "deleted",
}

export enum EChangeOrderParam {
  up = "up",
  down = "down",
}

export enum EColors {
  doc_type_chip_blue = "doc_type_chip_blue",
  doc_type_chip_turquoise = "doc_type_chip_turquoise",
  doc_type_chip_rose = "doc_type_chip_rose",
  doc_type_chip_red = "doc_type_chip_red",
  doc_type_chip_orange = "doc_type_chip_orange",
  doc_type_chip_yellow = "doc_type_chip_yellow",
  doc_type_chip_green = "doc_type_chip_green",
}

export enum EContentCategory {
  generic = "generic",
  training = "training",
}

export enum EContentCommentType {
  correction = "correction",
  improvement = "improvement",
  other = "other",
}

export enum EFileType {
  video = "video",
  image = "image",
  doc = "doc",
}

export enum ENotificationType {
  update_team = "update_team",
  fidelity = "fidelity",
  training_validation = "training_validation",
  training_expiration = "training_expiration",
  training_creation = "training_creation",
  questionnaire_creation = "questionnaire_creation",
  questionnaire_edition = "questionnaire_edition",
  content_creation = "content_creation",
  content_edition = "content_edition",
  clearance_expiration = "clearance_expiration",
  skill_expiration = "skill_expiration",
}

export enum EPlanningType {
  position = "position",
  employee = "employee",
  shift = "shift",
}

export enum EPositionOrEmployeeView {
  position = "position",
  employee = "employee",
  shift = "shift",
}

export enum EQuestionnnaireResponseStatus {
  in_progress = "in_progress",
  paused = "paused",
  need_correction = "need_correction",
  completed = "completed",
}

export enum EResolverName {
  cellResult = "cellResult",
  cellShift = "cellShift",
  cellAbsence = "cellAbsence",
  skill = "skill",
  clearance = "clearance",
  position = "position",
  positionOrder = "positionOrder",
  subposition = "subposition",
  team = "team",
  shift = "shift",
  contract = "contract",
  absence = "absence",
  category = "category",
  skillLegend = "skillLegend",
  folder = "folder",
  restriction = "restriction",
  external_trainer = "external_trainer",
  lposition_closed = "lposition_closed",
  factory = "factory",
  filesAttachment = "filesAttachment",
  site = "site",
  user = "user",
  lclearance = "lclearance",
  employee = "employee",
  note = "note",
  lskill = "lskill",
  ltraining = "ltraining",
  client = "client",
  lrestriction = "lrestriction",
  closure = "closure",
}

export enum Gender {
  none = "none",
  H = "H",
  F = "F",
}

export enum PlanningAssignationMode {
  assignPositions = 0,
  assignEmployees = 1,
}

export enum QuestionnaireQuestionType {
  checkboxUnique = "checkboxUnique",
  checkboxMultiple = "checkboxMultiple",
  truth = "truth",
  open = "open",
}

export enum QuestionnaireTemplateType {
  question = "question",
  checklist = "checklist",
  points_question = "points_question",
  none = "none",
}

export enum TimeFrames {
  fullYear = "fullYear",
  yearByMonths = "yearByMonths",
  fullWeek = "fullWeek",
  weekByDays = "weekByDays",
}

export enum TrainingStatus {
  none = 0,
  ongoing = 1,
  expiresoon = 2,
  expired = 3,
}

export enum undoActions {
  delete = 0,
  create = 1,
}
export enum undoGeneralActions {
  positionsCreation = 0,
  positionsDeletion = 1,
  shiftCreation = 2,
  shiftDeletion = 3,
  absenceCreation = 4,
  absenceDeletion = 5,
}

export interface ApexChartCustom {
  categories: string[];
  data: number[];
}

export interface AvailableSlotsFilter {
  availableEmployees: EmployeeID[];
  availablePositions: PositionID[];
  availableSubPositions: SubpositionID[];
}

export interface Base extends Document {
  client_id: ClientID;
  published?: BasePublish | EBasePublish;
  querySlug?: string;
  querySlugMonth?: string;
  site_id: SiteID;
  status: BaseStatus | EBaseStatus;
  teststring?: string;
  update_user_id?: UserID;
}
export interface CurrentAssignationData {
  currentAssignationTagsIDs: AssignationTagID[];
  currentDays: boolean[];
  currentEmployeeID: EmployeeID;
  currentEmployeeIDs: EmployeeID[];
  currentEndDate: Date | null;
  currentEndTime: null | number;
  currentPositionID: PositionID;
  currentPositionsSubpositionsIDs: PositionID[];
  currentShiftID: ShiftID;
  currentStartDate: Date | null;
  currentStartTime: null | number;
  currentSubpositionID: null | PositionID;
  defaultAssignationTagsIDs: AssignationTagID[];
  defaultEmployeeID?: EmployeeID;
  defaultEnd: null | number;
  defaultPositionID: PositionID;
  defaultShiftID: ShiftID;
  defaultStart: null | number;
  defaultSubpositionID: PositionID | undefined;
  employeeWithStatus: any[] | null;
  mode: "add" | "edit";
  shift: IShift | undefined;
}

export interface DataPlanning
  extends Omit<
    LPlanning,
    | "date"
    | "day"
    | "end"
    | "month"
    | "start"
    | "subposition_id"
    | "week"
    | "year"
  > {
  date: any;
  day: number;
  day_nb: number;
  end?: null | number;
  firstName?: null | string;
  isTomorrow?: boolean;
  month: number;
  parentID?: string;
  start?: null | number;
  subposition_id: null | string;
  tomorrowID?: string;
  week: number;
  year: number;
}

export type DataPlanningInput = Omit<
  DataPlanning,
  "day" | "id" | "month" | "week" | "weekBeginsOnYear" | "year"
>;

export interface DayHours {
  day_name: TranslateResult;
  day_nb: number;
  end: number;
  id?: any;
  start: number;
}
export interface Document {
  id: string;
}

export interface FastEmployeeCard extends IEmployee {
  absences: LAbsence[];
  clearances: any[];
  name: string;
  planning: LPlanning[];
  planningWithTomorrow: LPlanning[];
  restrictions: RestrictionID[];
  shifts: LShift[];
  skills: any[];
  team_loan: null | TeamID;
  team_loan_external: boolean | null;
  team_loan_name: null | string;
  trainings: LTraining[];
}

export interface IAbsenceType extends Base {
  client_id: ClientID;
  id: string;
  name: string;
  published?: BasePublish;
  site_id: string;
  status: BaseStatus;
  user_update: UserID;
}
export interface IAddElementItem {
  icon?: string;
  text: TranslateResult;
  value: string;
}

export interface IAlertInfo {
  iconCode: string;
  iconColor: string;
  iconTooltipText: TranslateResult[];
}

export type IAllMonthlyPositionsEmployeeDictionnary = Record<
  string,
  boolean | string
>;

export interface IApprobationPayload {
  approbators: UserID[][];
  automatic_approbation: boolean;
  needApprobation: boolean;
  number_of_month_before_approbation: INumber_of_month_before_approbation;
}
export interface IAssignationTag extends Base {
  color_bg: string;
  color_text: string;
  id: AssignationTagID;
  tag_name: string;
  teams_ids: TeamID[];
}

export interface IClearance extends Base {
  id: ClearanceID;
  internal_code?: string;
  keywords?: IKeyword[];
  keywords_temp: KeywordID[];
  minimal_days?: null | number[];
  name: string;
  select: {
    id: ClearanceID;
    type: string;
  };
  validity: 0 | 3 | 6 | 12 | 18 | 24 | 36 | 48 | 60 | null;
}

export interface IClient extends Base {
  enable_planning: boolean;
  has_planning: boolean;
  id: string;
  logo_url: null | string;
  name: string;
  slug: string;
}
export interface IContent extends Base {
  approbation_deadline: INumber_of_month_before_approbation | null;
  author: UserID;
  category: EContentCategory | null;
  consultationNumber: number;
  contentSlug: string;
  cover: null | string;
  created_at: any;
  description: null | string;
  document_type: ContentDocumentTypeID | null;
  external_link: null | string;
  factories?: string[];
  files?: Partial<IContentStepFile>[];
  id: ContentID;
  isApproved: boolean;
  isOnlyFiles: boolean;
  isReadOnly?: boolean;
  isRestricted?: boolean;
  need_approbation: boolean;
  notify_employees: boolean;
  publishDate?: any;
  reason?: string;
  restrictedAccessUserIDS?: UserID[];
  RevisionNumber: number;
  teams?: string[];
  title: null | string;
  updated_at: any;
  updated_by_id?: UserID;
  usersListsToApprove?: Partial<IContentUsersListToApprove>[];
}

export interface IContentApprobation extends Base {
  allApprobatorsHaveAnswered: boolean;
  approbatorsLineSlug: string;
  approvedDate?: any;
  comment?: string;
  contentID: ContentID;
  contentSlug: string;
  created_at?: any;
  hasApproved: boolean;
  hasRejected: boolean;
  id: ContentApprobationID;
  isExternalObserver: boolean;
  isLastRemainingApprobator: boolean;
  numberOfApprobators: number;
  rejectedDate?: any;
  revisionNumber: number;
  updated_at: any;
  userID: UserID;
}

export interface IContentAsGeneric extends IContent {
  factories: FactoryID[];
  teams: TeamID[];
}

export interface IContentAsTraining extends IContent {
  habilities: string[];
  levels: number[];
  positions: PositionID[];
  skills: SkillID[];
}

export interface IContentComment extends Base {
  author: EmployeeID | UserID;
  comment: string;
  content_id: ContentID;
  created_at: any;
  id: ContentCommentID;
  revision_number: number;
  step: number;
  type: EContentCommentType;
}

export interface IContentCover {
  file: any;
  format: string;
}

export type IContentDetailed =
  | IContentAsGenericDetailed
  | IContentAsTrainingDetailed;

export interface IContentDocumentType extends Base {
  category: EContentCategory;
  client_id: ClientID;
  color: EColors;
  id: ContentDocumentTypeID;
  name: string;
  site_id: SiteID;
}

export interface IContentFilePayload {
  client_id: ClientID;
  cover?: string;
  coverAsFile?: any;
  date: any;
  download_url: string;
  fileNameUuid: string;
  name: string;
  site_id: SiteID;
  status: BaseStatus;
  updated_by_id: UserID;
  video_image_or_other: EFileType;
}

export interface IContentStep {
  client_id: ClientID;
  comments?: ContentCommentID[];
  content: string;
  external_link?: string;
  files?: Partial<IContentStepFile>[];
  id: ContentStepID;
  site_id: SiteID;
  step: number;
  title: string;
}

export interface IContentStepFile {
  cover?: string;
  coverAsFile?: any;
  display_url?: string;
  download_url: string;
  file?: any;
  format?: string | undefined;
  label?: string | undefined;
  title?: string | undefined;
  type?: string | undefined;
  video_image_or_other: EFileType;
}

export interface IContentUsersListToApprove {
  approbatorsLineSlug: string;
  approved: UserID[];
  atLeastOneHasApproved: boolean;
  created_at: any;
  id: ContentUsersListToApprove;
  lineIsValidate: boolean;
  numberOfPendingApprobations?: number;
  pending: UserID[];
  rejected: UserID[];
  usersWhoCanApprove: UserID[];
}

export interface IContract extends Base {
  client_id: ClientID;
  color_bg: string;
  color_text: string;
  contract_name: string;
  expires?: boolean;
  id: ContractID;
  site_id: SiteID;
}

export type ICssVars = Record<string, string>;

export interface IcsvHeads {
  headName?: string | TranslateResult;
  text?: string | TranslateResult;
  value: string;
}

export interface ICurrentWeekHeader {
  date: any;
  day: number;
  day_week: number;
  isWeekEnd: boolean;
  month: number;
  string: TranslateResult;
  week: number;
  year: number;
}

export interface IDetailedScoring {
  ongoingTraining?: {
    color: null | string;
    count: null | number;
    label: null | TranslateResult;
    status: OnGoingTrainingStatus | OnGoingTrainingStatus[] | undefined;
  };
  restriction?: {
    count: null | number;
    restrictions_ids?: RestrictionID[];
    status: RestrictionStatus | RestrictionStatus[] | undefined;
  };
  shift?: {
    count: null | number;
    shifts_by_days?: TooltipList[];
    status: ShiftStatus | ShiftStatus[] | undefined;
  };
  staffed?: {
    absences_by_days: TooltipList[];
    count: null | number;
    positions_by_days: TooltipList[];
    status: StaffedStatus[] | undefined;
  };
  trained?: {
    count: null | number;
    status: TrainedStatus | undefined;
  };
}

export interface IDragAndDropFilesPayload {
  background_color?: string;
  cover?: IContentCover;
  download_url?: string;
  file?: any;
  format?: string;
  isVideo?: boolean;
  label?: string;
  title?: string;
  type?: string;
  video_image_or_other?: EFileType;
}
export interface IDropdownOptions {
  color?: string;
  icon?: string;
  id: null | string;
  name: string | TranslateResult;
}

export interface IEmployee extends Base {
  birthday_date?: any;
  categories?: any[];
  category_id?: string;
  client_id: ClientID;
  comment: string;
  contract?: any;
  contract_id?: string;
  date?: string;
  email?: string;
  employee_company_id: string;
  employee_id?: EmployeeID;
  end_date_contract?: any;
  external_link: string;
  factory_id: FactoryID;
  first_name: string;
  fullname: string;
  function_sheet: any;
  function_sheet_id: string;
  gender: Gender;
  internal_id?: string;
  job?: string;
  keywords_temp: KeywordID[];
  last_name: string;
  last_update: any;
  link_contracts?: LContract[];
  link_skills: any[];
  phone?: string;
  picture_url: string;
  position_id: string;
  site_id: SiteID;
  skillsEvaluatedBy: any[];
  skillsTrainedBy: any[];
  start_date?: any;
  status: any;
  team?: ITeam;
  team_id: TeamID;
  update?: any;
  update_user_id: string;
  user_id: UserID;
}

export interface IEmployeeCards {
  birth_day: number;
  birth_month: number;
  birth_year: number;
  birthday_date: any;
  cache: any;
  client_id: ClientID;
  employee_company_id: string;
  employeeClearances: any[];
  employeeKeywords: any[];
  employeeShift: string;
  employeeSkills: any[];
  employeeTraining: any[];
  factory_id: string;
  files_attachment: any;
  first_name: string;
  id: string;
  last_name: string;
  name: string;
  picture_url: string;
  site_id: SiteID;
  skillsEvaluatedBy: any[];
  skillsTrainedBy: any[];
  start_date: any;
  status?: BaseStatus;
  team_id: TeamID;
  team_name: string;
  updated_at: any;
}

export interface IEmployeeCategory extends Base {
  client_id: ClientID;
  color: {
    bg: string;
    text: string;
  };
  id: EmployeeCategoryID;
  name: string;
  site_id: string;
  status: BaseStatus;
}

export interface IEmployeeDetails extends IEmployee {
  factory_name?: string;
  fullname?: string;
  team_name?: string;
}

export interface IEmployeeLoan extends IEmployee {
  loaned: boolean;
  team_from_name: null | string;
  team_loan: null | TeamID;
  team_loan_name: null | string;
}

export interface IEmployeeWithStatus
  extends Required<
    Pick<
      IEmployeeDetails,
      "first_name" | "fullname" | "id" | "last_name" | "picture_url"
    >
  > {
  backgroundColor?: string;
  badge?: IBadge;
  border: number | string;
  isExternal: boolean;
  position?: IPosition;
  score: number;
  shifts?: LShift[];
  statusList: IStatus[];
  warning?: IWarning;
}

export interface IEvent extends Base {
  created_date?: any;
  data: any;
  employee_id?: EmployeeID;
  type?: string;
  user_id?: UserID;
}

// External trainer
export interface IExternalTrainer extends Base {
  created_at: any;
  id: string;
  name: string;
  updated_at: any;
}

export interface IFactory extends Base {
  client_id: string;
  factory_name: string;
  id: string;
  route: string;
  site_id: string;
  status: any;
}

export interface IFactory {
  client_id: string;
  factory_name: string;
  id: string;
  route: string;
  site_id: string;
  status: any;
  teams?: ITeam[];
}

export interface IFactoryData extends IFactory {
  logo_url?: string;
  name?: string;
}

export interface IFileAttach extends IInputFileAttach {
  id: string;
}

export interface IFolder extends InputFolder {
  id: FolderID;
}

export interface IGeneralClosure extends Base {
  client_id: ClientID;
  end_date: any;
  name: string;
  site_id: string;
  start_date: any;
  teams: ITeam[];
}

export interface IGeneralInformationsTrainingPayload
  extends IGeneralInformationsPayload {
  clearances: ClearanceID[];
  levels: number[];
  position: string[];
  skills: SkillID[];
}

export interface IInputFileAttach extends Base {
  date: any;
  download_url: string;
  employee_id: EmployeeID;
  last_updated_by: UserID;
  name: string;
  virtual_path: null | string;
}
export interface IKeyword {
  category_id?: string;
  category_name?: string;
  clearance_id?: ClearanceID;
  client_id: ClientID;
  color?: string;
  id: KeywordID;
  is_process?: boolean;
  keyword_id?: KeywordID;
  name: string;
  site_id: SiteID;
  skill_id?: SkillID;
}

export interface IKeywordLink extends Base {
  category_name?: string;
  clearance_id?: ClearanceID;
  client_id: ClientID;
  color?: string;
  employee_id?: EmployeeID;
  is_process?: boolean;
  keyword_id?: KeywordID;
  name: string;
  skill_id?: SkillID;
}

export interface IMAddElement {
  items: IAddElementItem[];
  title: TranslateResult;
}

export interface IMentions {
  id: string;
  label: string;
  value: string;
}

/* This Type is not regular with the document in database */
export interface INote extends Base {
  client_id: UserID;
  dataMessage: string[];
  feature: boolean;
  firstName?: string;
  lastName?: string;
  message: string[];
  pictureUrl?: string;
  size?: string;
  team_id: TeamID;
  updated_at: any;
  user_id: UserID;
}

export interface INotification extends IInputNotification {
  id: EvaluationModelID;
}

export interface INotificationV2 extends IInputNotificationV2 {
  id: EvaluationModelID;
}

export interface InputPlanningColor {
  bg?: string;
  text?: string;
}

export interface InputReason extends Base {
  abbreviation?: null | string;
  color?: null | string;
  name: string;
}

export interface InputRestriction extends Base {
  name: string;
}

export interface InputShift {
  client_id: ClientID;
  color: InputPlanningColor;
  color_bg: string;
  color_text: string;
  custom_shifts?: DayHours[];
  days_hours?: DayHours[];
  end: number; // End time ex. 12h45 is 12.75
  hide_weekend: boolean;
  is_custom_days_hours: boolean;
  name: string;
  order?: number;
  site_id: string;
  start: number; // Start time ex. 15h30 is 15.5
  teams: ITeam[];
}

export interface IPlanningTemplate extends Base {
  documents: (DataPlanningInput | LinkPlanningInput)[];
  name: string;
  team_id: TeamID;
}

export interface IPosition extends Base {
  clearances: ClearanceID[] | null;
  cover?: null | string;
  fullname: string;
  keywords: KeywordID[] | null;
  name: string;
  position?: any;
  restricted_positions?: any[];
  restrictions: null | RestrictionID[];
  select: {
    id: PositionID;
    type: string;
  };
  shift_positions?: ShiftID[];
  skills: null | SkillID[];
  team_ids: null | TeamID[];
}
export interface IPositionLevel extends IPosition {
  cover?: null | string;
  level: number;
  position?: {
    id: PositionID;
    name: string;
  };
  position_id: string;
  position_parent_id: string;
  position_parent_name?: string;
}

export interface IQuestionnaireApprobation {
  allApprobatorsHaveAnswered: boolean;
  approvedDate?: any;
  created_at?: any;
  hasApproved: boolean;
  hasRejected: boolean;
  id: ApprobationID;
  isLastRemainingApprobator: boolean;
  numberOfApprobators: number;
  questionnaireID?: QuestionnaireID;
  rejectedDate?: any;
  updated_at: any;
  userID: UserID;
}
export interface IQuestionnaireChecklist {
  files?: any[];
  id?: string;
  optional: boolean;
  order: number;
  value: string;
}
export interface IQuestionnaireInputv3
  extends Omit<
    IQuestionnairev3,
    | "approbators"
    | "clearances"
    | "cocreators"
    | "createdAt"
    | "id"
    | "isLastRevision"
    | "qid"
    | "revisionNumber"
    | "skills"
    | "updated_by"
    | "updated_by_id"
    | "updatedAt"
  > {
  approbators: UserID[][];
  clearances: ClearanceID[];
  cocreators: UserID[];
  qid?: string;
  skills: SkillID[];
}
export interface IQuestionnaireQuestion {
  description?: string;
  files?: any[];
  id: string;
  optional: boolean;
  order: number;
  questionText: string;
  type: QuestionnaireQuestionType;
  values: IQuestionnaireQuestionValue[];
}

export interface IQuestionnaireQuestionValue {
  id: string;
  order: number;
  points: number;
  value: string;
}
export interface IQuestionnaireResponsev3 {
  areAllQuestionsAnswered: boolean;
  checklistAnswerIds: string[];
  createdAt: string;
  id: string;
  isClosed: boolean;
  isFailed: boolean;
  isValidated: boolean;
  minimumScoreReach: boolean;
  questionAnswers: {
    answers: string[];
    openResponse: string;
    question_id: string;
  }[];
  questionnaire: IQuestionnairev3;
  questionnaireResponseId: string;
  questions_from_questionnaire_response: IQuestionnaireQuestion[];
  score: number;
  status: EQuestionnnaireResponseStatus;
  templateType: QuestionnaireTemplateType;
  updatedAt: string;
}
export type IQuestionnaireResponsev3Input = Omit<
  IQuestionnaireResponsev3,
  | "areAllQuestionsAnswered"
  | "createdAt"
  | "id"
  | "isClosed"
  | "isFailed"
  | "isValidated"
  | "minimumScoreReach"
  | "questionnaire"
  | "questionnaireResponseId"
  | "score"
  | "status"
  | "templateType"
  | "updatedAt"
>;

// TODO SEMINAIRE This is the new questionnaire interface, not final
export interface IQuestionnairev3 {
  approbators: QuestionnaireApprobators[];
  automaticApprobationRequestInMinutesDelay: number;
  checklist: IQuestionnaireChecklist[];
  checklistToAnswer?: IQuestionnaireChecklist[];
  clearances: IClearance[];
  cocreators: IUser[];
  createdAt: string;
  description?: string;
  durationInMinutes: number;
  hasBeenApproved: boolean;
  id: QuestionnaireID;
  isLastRevision: boolean;
  levels: number[];
  minimumScore: number;
  needApprobation: boolean;
  published: EBasePublish;
  qid: string;
  questions: IQuestionnaireQuestion[];
  questionsToAnswer?: IQuestionnaireQuestion[];
  randomContents: number;
  reason?: string;
  requiredQuestionsNumber?: number;
  revisionNumber: number;
  signatureRequired: boolean;
  // responses: any[]; //TODO bind responses type
  skills: ISkill[];
  status?: EBaseStatus;
  templateType: QuestionnaireTemplateType;
  title: string;
  updated_by: IUser;
  updated_by_id: UserID;
  updatedAt: string;
}

export interface IReason extends InputReason {
  id: ReasonID;
}

export interface IRestriction extends InputRestriction {
  id: RestrictionID;
}

export interface ISearchFilter extends Base {
  contract_ids?: string[];
  name: string;
  reason_ids?: string[];
  team_ids?: string[];
}

export interface IShift extends InputShift {
  id: ShiftID;
  status: BaseStatus;
  update_user_id: UserID;
}

export interface ISite extends Base {
  assignation_per_day: number;
  complementaryRightsSections: Section[];
  content_category_name: {
    generic: null | string;
    training: null | string;
  };
  external_id: string;
  hours_worked: number;
  id: string;
  name: string;
  pricing: IPricing;
  rest_time: number;
  rights?: any;
  settings: {
    goals: {
      percentageMode: boolean;
    };
  };
  settings_absent_score: number;
  settings_already_assigned_score: number;
  settings_assignation_per_day: number;
  settings_content_feature: boolean;
  settings_content_feature_v2: boolean;
  settings_content_vizualisation_signature: boolean;
  settings_contract_score: number;
  settings_enable_imports: boolean;
  settings_expertise_versatility_score: number;
  settings_force_approbation: boolean;
  settings_hide_download_content: boolean;
  settings_hours_worked: number;
  settings_kpis: boolean;
  settings_monitoring_main_goal: boolean;
  settings_monitoring_v1: boolean;
  settings_new_overwork: boolean;
  settings_new_training_drawer: boolean;
  settings_only_evaluator: boolean;
  settings_only_trainer: boolean;
  settings_performance_reviews_v1: boolean;
  settings_performance_reviews_v2: boolean;
  settings_planning_archived_employees: boolean;
  settings_planning_beta: boolean;
  settings_planning_compact: boolean;
  settings_planning_delta: boolean;
  settings_position_conflict: number;
  settings_restriction_score: number;
  settings_shift_score: number;
  settings_show_score: boolean;
  settings_skills_clearances_score: number;
  settings_skills_end_date: boolean;
  settings_training_score: number;
  settings_training_time: boolean;
  settings_versatility_user_calendar: boolean;
  settings_versatilty_feature: boolean;
  settings_without_shift: number;
  settings_workload: boolean;
  settings_workload_management: boolean;
  team_for_new_employee: TeamID;
  timezone: string;
  update: any;
}

export interface ISkill extends InputSkill {
  id: SkillID;
}

/** Use the toISOString() ISO 8601 format. See https://en.wikipedia.org/wiki/ISO_8601 */
export type ISODate = string;

export interface ITabAbsenteismDataPerTimeFrame {
  absence_days_number?: number;
  absenteeism?: string;
  date: string | TranslateResult;
  employee: number;
  worked_days_number?: number;
}
export interface ITeam extends Base {
  cover?: null | string;
  factory_id: string;
  id: string;
  route: string;
  status: number | string;
  team_leaders?: { id: string }[];
  team_name: string;
}

export interface ITeam {
  client_id: string;
  factory_id: string;
  id: string;
  route: string;
  site_id: string;
  status: number | string;
  team_leader_id?: string;
  team_name: string;
}
export interface IUndoActions {
  actions: Record<string, { id: string; status: BaseStatus }[]>;
  originalAction?: undoGeneralActions;
  shiftActionSlug: null | string;
}

export interface IUndoElement {
  action: undoActions;
  collection: Collections;
  id: string;
  lastActionSlug?: string;
  originalAction?: undoGeneralActions;
}

export interface IUser extends Base {
  avatar_url: string;
  category_job: string;
  client?: IClient;
  created_at: any;
  current_right: IUserRights;
  email: string;
  enable_birthday_notifications?: boolean;
  enable_content_approbation?: boolean;
  enable_content_consultation?: boolean;
  enable_expiring_clearance_notifications?: boolean;
  enable_expiring_contract_notifications?: boolean;
  enable_expiring_skills_notifications?: boolean;
  enable_expiring_training_notifications?: boolean;
  enable_fidelity_notifications?: boolean;
  enable_planning?: boolean;
  enable_questionnaire_approbation?: boolean;
  enable_team_change?: boolean;
  enable_validation_training?: boolean;
  favorite_teams?: TeamID[];
  first_name: string;
  firstname_parameter: boolean;
  fullname: string;
  id: UserID;
  is_rights_admin: boolean;
  job: string;
  language: Languages;
  last_name: string;
  limited_access_teams?: {
    id: TeamID;
    team_name: string;
  }[];
  password?: string;
  permissions: LSection[];
  planning_parameter: string;
  rights: IUserRights[];
  role: string;
  settings_monitoring_main_goal: boolean;
  settings_monitoring_v1: boolean;
  settings_planning_beta: boolean;
  settings_workload: boolean;
  signature?: string;
  site?: ISite;
  site_ids: SiteIDs;
  sites?: ISite[];
  teams_as_leader: {
    id: TeamID;
    team_name: string;
  }[];
  updated_at: any;
  user_rights: RightID;
}

export interface IVueChartData {
  borderColor?: string[];
  borderWidth?: number;
  color?: string;
  data?: number[];
  name?: string | TranslateResult | undefined;
}

export interface IWeek {
  week: number;
  year: number;
}

export interface IWeekOptional {
  week: null | number;
  year: null | number;
}

export interface IWidget {
  color: string;
  openByDefault?: boolean | undefined;
  pointer: string;
  show: boolean | ComputedRef<boolean>;
  subtitle: TranslateResult;
  title: null | number | string;
  type: string;
}

export type LinkPlanningInput = Omit<
  LinkEmployeePlanningWithDayNb,
  "day" | "id" | "month" | "week" | "weekBeginsOnYear" | "year"
>;

export interface MFilter {
  items: any[];
  itemText: string;
  itemValue: string;
  label: TranslateResult;
  multiple?: boolean;
  name: string;
}

export type NotificationType =
  | "birthday"
  | "clearance-expiration"
  | "content-approbation"
  | "content-consultation"
  | "contract-expiration"
  | "questionnaire-approbation"
  | "skill-expiration"
  | "team-change"
  | "training-expiration"
  | "update-team"
  | "validation-training";

export type PartialPayload = Omit<
  LShift,
  "client_id" | "id" | "site_id" | "team_id" | "update" | "update_user_id"
>;

export interface PlanningParamsType {
  time: PlanningTime;
  type: PlanningType;
}
export type PlanningTime = "day" | "month" | "week";

export interface PositionShift extends Base {
  duration: number;
  end_time: number;
  id: PositionShiftID;
  position_id: string;
  shift_id: string;
  start_time: number;
}

export interface PositionWithFullName extends PositionWithTemplateInfos {
  fullName: string;
}

export interface PositionWithTemplateInfos extends IPosition {
  parent_name?: string;
  sub_row_span?: number;
}

export type Roles = "ADMIN" | "CUSTOMER_SUCCESS" | "USER";

export interface SkillsLegends extends Base {
  client_id: string;
  id: string;
  level: number;
  name: string;
  site_id: string;
}

export interface SpecificDateTimeFormatOptions extends DateTimeFormatOptions {
  day?: DateTimeDigital;
  era?: DateTimeHumanReadable;
  formatMatcher?: FormatMatcher;
  hour?: DateTimeDigital;
  localeMatcher?: LocaleMatcher;
  minute?: DateTimeDigital;
  month?: DateTimeDigital | DateTimeHumanReadable;
  second?: DateTimeDigital;
  timeZoneName?: "long" | "short";
  weekday?: DateTimeHumanReadable;
  year?: DateTimeDigital;
}

export interface SubpositionWithFullName extends SubpositionWithTemplateInfos {
  fullName: string;
}

export interface SubpositionWithTemplateInfos extends IPositionLevel {
  parent_name?: string;
  sub_row_span?: number;
}

export interface Tab {
  // eslint-disable-next-line @typescript-eslint/no-unsafe-function-type
  callback?: Function;
  disabled?: boolean;
  icon?: string;
  legend: TranslateResult;
  route?: { name: string };
  segment?: string;
  show?: boolean;
  title?: TranslateResult;
}

export interface TeamHistory {
  employee_id: EmployeeID;
  id: string;
  new_team_id: TeamID;
  old_team_id: TeamID;
  timestamp: any;
}

export interface TeamHistoryDoc {
  employee_id: EmployeeID;
  new_team_id: TeamID;
  old_team_id: TeamID;
  timestamp: any;
}

export interface TooltipList {
  text: TranslateResult;
  title: TranslateResult;
}

export interface UserData {
  avatar_url: string;
  category: string;
  client_id: ClientID;
  email: string;
  enable_birthday_notifications: boolean;
  enable_planning?: boolean;
  first_name: string;
  job: string;
  last_name: string;
  role: string;
  site_id: SiteID;
}

type DateTimeDigital = "2-digit" | "numeric";
interface DateTimeFormatOptions {
  dateStyle?: "full" | "long" | "medium" | "short" | undefined;
  dayPeriod?: "long" | "narrow" | "short" | undefined;
  formatMatcher?: "basic" | "best fit" | undefined;
  fractionalSecondDigits?: 0 | 1 | 2 | 3 | undefined;
  timeStyle?: "full" | "long" | "medium" | "short" | undefined;
}

type DateTimeHumanReadable = "long" | "narrow" | "short";

type FormatMatcher = "basic" | "best fit";

interface IAlingCell {
  alignementCell: boolean;
  id: string;
  isAbsence: boolean;
  position_id: null | string;
  shift_id: null | string;
  start: null | number | undefined;
}

interface IBadge {
  color?: "green" | "orange" | "red";
  disabled?: boolean;
}
interface IContentAsGenericDetailed extends IContentAsGeneric {
  document_type_object: IContentDocumentType;
}

interface IContentAsTrainingDetailed extends IContentAsTraining {
  document_type_object: IContentDocumentType;
}
interface IContentFile {
  type: EFileType;
}
interface IFactoryWithFactoryOrTeamName extends IFactory {
  factory_or_team_name?: string;
}
interface IGeneralInformationsPayload {
  category: EContentCategory;
  document_type: ContentDocumentTypeID;
}

interface IInputNotification extends Base {
  client_id: ClientID;
  created_at: any;
  data: any;
  read: boolean;
  site_id: SiteID;
  type: NotificationType;
  updated_at: any;
  user_id: UserID;
}

interface IInputNotificationV2 extends Base {
  client_id: ClientID;
  created_at: any;
  data: {
    clearance_id?: ClearanceID;
    content_id?: ContentID;
    employee_id?: EmployeeID;
    expiration_date?: any;
    new_team_id?: TeamID;
    new_version?: number;
    old_team_id?: TeamID;
    questionnaire_id?: QuestionnaryModelID;
    skill_id?: SkillID;
  };
  read: boolean;
  site_id: SiteID;
  type: ENotificationType;
  updated_at: any;
  user_id: UserID;
}
interface InputFolder extends Base {
  name: string;
}

interface InputSkill extends Base {
  clearances: ClearanceID[];
  comments: null | string;
  evaluator_id?: string;
  evaluator_ids?: string[];
  evaluators: any[];
  internal_code?: string;
  keywords_temp?: KeywordID[];
  label?: string;
  level: number | string;
  minimal_days?: null | number[];
  minimal_practice_time?: number; // In Days
  name: string;
  search?: string;
  select?: {
    id: SkillID;
    type: string;
  };
  teams?: ITeam[];
  trainers: any[];
  training_length?: string;
  update?: any;
  validity_months: null | number;
}

interface INumber_of_month_before_approbation {
  date: any;
  number: number;
}
interface IStatus {
  absences_by_days: string[];
  assignation_by_days: number[];
  color: string;
  hours_worked_by_days: number[];
  label: string;
  positions_by_days: {
    text: string;
    title: string;
  }[];
  restrictionsList: string[];
  shifts_by_days: string[];
  slug: string;
  title: string;
}

interface ITeamWithFactoryOrTeamName extends ITeam {
  factory_or_team_name?: string;
}

interface IUserApp {
  client?: IClient;
  site?: ISite;
}
interface IWarning {
  color?: string;
  title: string;
  visible: boolean;
}

interface LinkEmployeePlanningWithDayNb extends LPlanning {
  day_nb?: number;
}

type LocaleMatcher = "best fit" | "lookup";
type PlanningType = "employee" | "position" | "shifts";
interface QuestionnaireApprobators {
  approbators: IUser[];
  id?: UserID;
  questionnaire?: IQuestionnairev3;
  questionnaire_id?: string;
}
