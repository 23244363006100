import { z } from "zod";

import { Id } from "@/shared/domain";

import {
  GridQuestionResource,
  OpenQuestionResource,
  RatingQuestionResource,
  TableQuestionResource,
  TextResource,
} from "./resource.entity";

export const Block = z.object({
  description: z.string().optional(),
  id: Id.optional(),
  optional: z.boolean().default(false),
  resource: z.discriminatedUnion("type", [
    TextResource,
    OpenQuestionResource,
    GridQuestionResource,
    TableQuestionResource,
    RatingQuestionResource,
  ]),
});

export type Block = z.infer<typeof Block>;
