import { z } from "zod";

import { BlockAnswer } from "./entities/block-answer.entity";
import { GlobalAssessment } from "./entities/review-response";
import { Score } from "./score";
import { Signature } from "./signature";

const OthersSignatories = z.object({
  signatories: z.array(z.object({ userId: z.string() })),
  type: z.literal("notSigned"),
});

const OthersSignedRejected = z
  .object({
    userId: z.string(),
  })
  .and(
    z.union([
      z.object({
        date: z.string().optional(),
        signature: z.string(),
        type: z.literal("accepted"),
      }),
      z.object({
        date: z.string().optional(),
        type: z.literal("refused"),
      }),
    ]),
  );

const Signatures = z.object({
  employee: Signature.nullable(),
  manager: Signature.nullable(),
  others: z.nullable(
    z.record(z.string(), z.union([OthersSignatories, OthersSignedRejected])),
  ),
});

export const ReviewDetail = z.object({
  defineNewGoals: z.boolean().default(false),
  description: z.string(),
  displayAttachedFiles: z.boolean().default(false),
  displayGlobalAssessment: z.boolean().default(false),
  displayJobDescription: z.boolean().default(false),
  displayPreviousGoals: z.boolean().default(false),
  displaySkillsClearancesTrainings: z.boolean().default(false),
  globalAssessment: GlobalAssessment,
  goals: z.array(
    z.object({
      description: z.string(),
      priority: z
        .union([
          z.literal("critical"),
          z.literal("high"),
          z.literal("normal"),
          z.literal("low"),
        ])
        .nullable(),
      title: z.string(),
    }),
  ),
  goalsHistory: z.array(
    z.object({
      campaignId: z.string(),
      campaignName: z.string(),
      goals: z.array(
        z.object({
          description: z.string(),
          priority: z.enum(["critical", "high", "normal", "low"]),
          status: z
            .enum(["achieved", "in-progress", "not-achieved"])
            .nullable(),
          title: z.string(),
        }),
      ),
      reviewValidatedAt: z.string(),
      templateName: z.string(),
    }),
  ),
  managerId: z.string().nullable().default(null),
  name: z.string(),
  scores: z.array(Score),
  signatures: Signatures,
  status: z.union([
    z.literal("to-be-started"),
    z.literal("on-going"),
    z.literal("completed"),
  ]),
  steps: z.array(BlockAnswer),
  updateSkillsClearancesTrainings: z.boolean().default(false),
});

export type ReviewDetail = z.infer<typeof ReviewDetail>;
