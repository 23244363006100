import { type Dictionary, orderBy, uniq } from "lodash";

import { usePlanningDisplayStore } from "@/pinia/features/planning-display";
import { useAuthentificationStore } from "@/pinia/user";

import type { CellResult } from "../planningBuilder";
import type {
  IPlanningDataDictionary,
  IPositionsMonthPlanning,
} from "./interfaces.alignement";

export class PositionAlignement {
  private _init = () => {
    const cells = this.cells;
    for (const cell of cells) {
      if (cell.isTomorrow) continue;
      this._setCompactPlanningRow(cell);
      this._setCompactPlanningByPosAndSubpos(cell);
      this._setPositionOrSubpositionID(cell);
      this._setCompactPlanningByPositionLine(cell);
      this._setShiftName(cell);
      this._setPositionsMonthPlanning(cell);
      this._setAllDataByPosition(cell);
    }
    this._setAlignementCell();
    this._orderCells();
  };

  private _orderCells = () => {
    const allDataByPosition = this.allDataByPosition;
    const alignementCellRules = this.alignementCellRules;

    for (const positionID in allDataByPosition) {
      const dataByPosition = allDataByPosition[positionID];
      for (const dayOfTheMonth in dataByPosition) {
        const dataByPositionAndDay = dataByPosition[dayOfTheMonth];
        allDataByPosition[positionID][dayOfTheMonth] = orderBy(
          dataByPositionAndDay,
          alignementCellRules,
        );
      }
    }
  };

  private _setAlignementCell = () => {
    const positionsMonthPlanning = this.positionsMonthPlanning;
    const allDataByPosition = this.allDataByPosition;

    for (const position_id in positionsMonthPlanning) {
      const positionIDShifts = positionsMonthPlanning[position_id];

      // For each shift data of the position
      for (const shiftKey in positionIDShifts) {
        // If shiftKey correspond to a day
        if (shiftKey !== "monthPositionShifts") {
          const shiftDay = shiftKey;
          const shiftDataAlreadyExistingForTheDay = positionIDShifts[shiftDay];

          // Get the needed data for the day and loop through each needed shift
          const monthPositionShiftsDay = positionIDShifts.monthPositionShifts;
          for (const neededShift in monthPositionShiftsDay) {
            const shiftDayData =
              positionIDShifts.monthPositionShifts[neededShift];

            if (!shiftDataAlreadyExistingForTheDay[neededShift]) {
              const employeeIdsTocheck: string[] = uniq(
                shiftDayData.employeesIds,
              );
              employeeIdsTocheck.forEach((employeeID) => {
                allDataByPosition[position_id][shiftDay].push({
                  alignementCell: true,
                  employee: {
                    first_name:
                      shiftDayData.employeesData[employeeID].firstName,
                  },
                  employeesId: employeeID,
                  firstName: shiftDayData.employeesData[employeeID].firstName,
                  shift: shiftDayData.name,
                  shift_id: shiftDayData.employeesData[employeeID].shift_id,
                  start: shiftDayData.employeesData[employeeID].start,
                  time: {
                    startTime: shiftDayData.employeesData[employeeID].start,
                  },
                });
              });
            } else if (
              shiftDataAlreadyExistingForTheDay[neededShift].number !==
              shiftDayData.number
            ) {
              const employeeIdsTocheck: string[] = uniq(
                shiftDayData.employeesIds,
              );
              employeeIdsTocheck.forEach((employeeID) => {
                if (
                  !shiftDataAlreadyExistingForTheDay[
                    neededShift
                  ].employeesIds.includes(employeeID)
                ) {
                  allDataByPosition[position_id][shiftDay].push({
                    alignementCell: true,
                    employee: {
                      first_name:
                        shiftDayData.employeesData[employeeID].firstName,
                    },
                    employeesId: employeeID,
                    firstName: shiftDayData.employeesData[employeeID].firstName,
                    shift: shiftDayData.name,
                    shift_id: shiftDayData.employeesData[employeeID].shift_id,
                    start: shiftDayData.employeesData[employeeID].start,
                    time: {
                      startTime: shiftDayData.employeesData[employeeID].start,
                    },
                  });
                }
              });
            }
          }
        }
      }
    }
  };

  private _setAlignementCellRules = () => {
    if (this.PlanningDisplayStore.isAlignHours) {
      this.alignementCellRules = [
        "shift_id",
        "employee.first_name",
        "time.startTime",
      ];
    }
    if (this.PlanningDisplayStore.isAlignEmployees) {
      this.alignementCellRules = ["employee.first_name"];
    }
    if (this.PlanningDisplayStore.isAlignShifts) {
      this.alignementCellRules = [
        "shift_id",
        "employee.first_name",
        "time.startTime",
      ];
    }
  };

  private _setAllDataByPosition = (cell: CellResult) => {
    const allDataByPosition = this.allDataByPosition;
    const positionOrSubpositionID = this.positionOrSubpositionID;
    const positionsMonthPlanning = this.positionsMonthPlanning;
    const shift_name = this.shift_name;

    if (allDataByPosition[positionOrSubpositionID]) {
      if (allDataByPosition[positionOrSubpositionID][cell.day]) {
        if (
          positionsMonthPlanning[positionOrSubpositionID][cell.day][shift_name]
        ) {
          const dayShift =
            positionsMonthPlanning[positionOrSubpositionID][cell.day][
              shift_name
            ];
          dayShift.number++;

          if (dayShift.employeesIds) {
            dayShift.employeesIds.push(cell.employee_id);
            dayShift.employeesData[cell.employee_id] = {
              firstName: cell.employee.first_name,
              shift_id: cell.shift_id,
              start: cell.time.startTime,
            };
          } else {
            dayShift.employeesIds = [cell.employee_id];
            dayShift.employeesData = {
              [cell.employee_id]: {
                firstName: cell.employee.first_name,
                shift_id: cell.shift_id,
                start: cell.time.startTime,
              },
            };
          }
        } else {
          positionsMonthPlanning[positionOrSubpositionID][cell.day][
            shift_name
          ] = {
            employeesData: {
              [cell.employee_id]: {
                firstName: cell.employee.first_name,
                shift_id: cell.shift_id,
                start: cell.time.startTime,
              },
            },
            employeesIds: [cell.employee_id],
            name: shift_name,
            number: 1,
          };
        }

        allDataByPosition[positionOrSubpositionID][cell.day].push(cell);
      } else {
        positionsMonthPlanning[positionOrSubpositionID][cell.day] = {
          [shift_name]: {
            employeesData: {
              [cell.employee_id]: {
                firstName: cell.employee.first_name,
                shift_id: cell.shift_id,
                start: cell.time.startTime,
              },
            },
            employeesIds: [cell.employee_id],
            name: shift_name,

            number: 1,
          },
        };
        allDataByPosition[positionOrSubpositionID][cell.day] = [cell];
      }
    } else {
      positionsMonthPlanning[positionOrSubpositionID] = {
        [cell.day]: {
          [shift_name]: {
            employeesData: {
              [cell.employee_id]: {
                firstName: cell.employee.first_name,
                shift_id: cell.shift_id,
                start: cell.time.startTime,
              },
            },
            employeesIds: [cell.employee_id],
            name: shift_name,
            number: 1,
          },
        },
        monthPositionShifts: {
          [shift_name]: {
            employeesData: {
              [cell.employee_id]: {
                firstName: cell.employee.first_name,
                shift_id: cell.shift_id,
                start: cell.time.startTime,
              },
            },
            employeesIds: [cell.employee_id],
            name: shift_name,
            number: 1,
          },
        },
      };
      allDataByPosition[positionOrSubpositionID] = {
        [cell.day]: [cell],
      };
    }
  };

  private _setCompactPlanningByPosAndSubpos = (cell: CellResult) => {
    const compactPlanningByPosAndSubpos = this.compactPlanningByPosAndSubpos;
    if (cell.subposition_id) {
      if (compactPlanningByPosAndSubpos[cell.position_id]) {
        if (
          compactPlanningByPosAndSubpos[cell.position_id][cell.subposition_id]
        ) {
          compactPlanningByPosAndSubpos[cell.position_id][
            cell.subposition_id
          ]++;
        } else {
          compactPlanningByPosAndSubpos[cell.position_id][cell.subposition_id] =
            1;
        }
      } else {
        compactPlanningByPosAndSubpos[cell.position_id] = {
          [cell.subposition_id]: 1,
        };
      }
    } else if (!compactPlanningByPosAndSubpos[cell.position_id]) {
      compactPlanningByPosAndSubpos[cell.position_id] = {};
    }
  };

  private _setCompactPlanningByPositionLine = (cell: CellResult) => {
    const compactPlanningByPositionLine = this.compactPlanningByPositionLine;
    if (compactPlanningByPositionLine[cell.position_id]) {
      compactPlanningByPositionLine[cell.position_id] +=
        cell.time.totalTimeNumberInMinutes;
    } else {
      compactPlanningByPositionLine[cell.position_id] =
        cell.time.totalTimeNumberInMinutes;
    }
  };

  private _setCompactPlanningRow = (cell: CellResult) => {
    const compactPlanningRow = this.compactPlanningRow;
    if (compactPlanningRow[cell.day]) {
      compactPlanningRow[cell.day] += cell.time.totalTimeNumberInMinutes;
    } else {
      compactPlanningRow[cell.day] = cell.time.totalTimeNumberInMinutes;
    }
  };

  private _setPositionOrSubpositionID = (cell: CellResult) => {
    this.positionOrSubpositionID = cell.subposition_id
      ? cell.subposition_id
      : cell.position_id;
  };

  private _setPositionsMonthPlanning = (cell: CellResult) => {
    const positionsMonthPlanning = this.positionsMonthPlanning;
    const positionOrSubpositionID = this.positionOrSubpositionID;
    const shift_name = this.shift_name;

    if (positionsMonthPlanning[positionOrSubpositionID]) {
      if (
        positionsMonthPlanning[positionOrSubpositionID].monthPositionShifts[
          shift_name
        ]
      ) {
        positionsMonthPlanning[positionOrSubpositionID].monthPositionShifts[
          shift_name
        ].employeesIds.push(cell.employee_id);
        positionsMonthPlanning[positionOrSubpositionID].monthPositionShifts[
          shift_name
        ].employeesData[cell.employee_id] = {
          firstName: cell.employee.first_name,
          shift_id: cell.shift_id,
          start: cell.time.startTime,
        };
        positionsMonthPlanning[positionOrSubpositionID].monthPositionShifts[
          shift_name
        ].number++;
      } else {
        positionsMonthPlanning[positionOrSubpositionID].monthPositionShifts[
          shift_name
        ] = {
          employeesData: {
            [cell.employee_id]: {
              firstName: cell.employee.first_name,
              shift_id: cell.shift_id,
              start: cell.time.startTime,
            },
          },
          employeesIds: [cell.employee_id],
          name: shift_name,
          number: 1,
        };
      }
    } else {
      positionsMonthPlanning[positionOrSubpositionID] = {
        monthPositionShifts: {
          [shift_name]: {
            employeesData: {
              [cell.employee_id]: {
                firstName: cell.employee.first_name,
                shift_id: cell.shift_id,
                start: cell.time.startTime,
              },
            },
            employeesIds: [cell.employee_id],
            name: shift_name,
            number: 1,
          },
        },
      };
    }
  };

  private _setShiftName = (cell: CellResult) => {
    if (
      this.PlanningDisplayStore.isAlignEmployees &&
      this.clientID === "FKn00LrLbjRYoqWPG9Ae"
    )
      return;
    this.shift_name = cell.shift.name.toLocaleLowerCase();
  };

  private PlanningDisplayStore = usePlanningDisplayStore();

  private alignementCellRules = [] as string[];

  private allDataByPosition = {} as IPlanningDataDictionary;

  /************************/
  /** * PUBLIC METHODS ***/
  private authentificationStore = useAuthentificationStore();

  /************************/
  /** * UTILS METHODS ***/
  private cells: CellResult[];

  private clientID = this.authentificationStore.active_client?.id;

  private compactPlanningByPosAndSubpos = {} as Dictionary<any>;

  private compactPlanningByPositionLine = {} as Dictionary<number>;

  private compactPlanningRow = {} as Dictionary<number>;

  private positionOrSubpositionID = "";

  private positionsMonthPlanning: IPositionsMonthPlanning = {};

  private shift_name = "isAlignByEmployees";

  constructor(cells: CellResult[]) {
    this.cells = cells;
    this._setAlignementCellRules();
    this._init();
  }

  /************************/
  public getPlanningPayload() {
    const allDataByPosition = this.allDataByPosition;
    const compactPlanningRow = this.compactPlanningRow;
    const compactPlanningByPositionLine = this.compactPlanningByPositionLine;
    const compactPlanningByPosAndSubpos = this.compactPlanningByPosAndSubpos;

    return {
      allDataByPosition,
      compactPlanningByPosAndSubpos,
      compactPlanningByPositionLine,
      compactPlanningRow,
    };
  }
}
