export const getDecimals = (time: number): number => {
  return Number((time - Math.floor(time)).toFixed(2));
};

export const getInteger = (time: number): number => {
  return Math.trunc(time);
};

export const getTotalTime = (end: number, start: number): number => {
  if (start === 0 && end === 0) {
    return 24;
  }
  if (end > start) {
    return end - start;
  }
  return 24 - start + end;
};

export const convertNumToTime = (number: number) => {
  let sign: any = number >= 0 ? 1 : -1;
  number *= sign;
  const hour = Math.floor(number);
  let decpart = number - hour;
  const min = 1 / 60;
  decpart = min * Math.round(decpart / min);
  let minute = `${Math.floor(decpart * 60)}`;
  if (minute.length < 2) {
    minute = `0${minute}`;
  }
  sign = sign === 1 ? "" : "-";
  const time = `${sign + hour}:${minute}`;
  return time;
};
