import { gql } from "graphql-tag";

export const CreateNote = gql`
  mutation CreateNote($data: NoteCreateInput!) {
    createNote(data: $data) {
      id
      createdAt
      updatedAt
      status
      dataMessage
      message
      updated_by {
        id
        last_name
        first_name
        avatar_url
      }
      team_id
      team {
        id
        team_name
      }
    }
  }
`;

export const UpdateNote = gql`
  mutation UpdateNote($where: NoteWhereUniqueInput!, $data: NoteUpdateInput!) {
    updateNote(where: $where, data: $data) {
      id
      createdAt
      updatedAt
      status
      dataMessage
      message
      updated_by {
        id
        last_name
        first_name
        avatar_url
      }
      team_id
      team {
        id
        team_name
      }
    }
  }
`;

export const DeleteNote = gql`
  mutation DeleteNote($where: NoteWhereUniqueInput!) {
    deleteNote(where: $where) {
      id
    }
  }
`;

export const Notes = gql`
  query Notes {
    Notes {
      id
      createdAt
      updatedAt
      status
      message
      created_by {
        id
        last_name
        first_name
        avatar_url
      }
    }
  }
`;
