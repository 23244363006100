<template>
  <v-list class="pa-0" subheader three-line>
    <v-divider></v-divider>
    <v-list-item>
      <v-list-item-content class="pb-0">
        <v-list-item-subtitle class="text--primary">
          {{ title }}
        </v-list-item-subtitle>
        <v-list-item-subtitle>
          {{ sinceCurrentDate }}
        </v-list-item-subtitle>
        <v-list-item-subtitle>
          <v-btn
            :to="{
              name: link.route.name,
              params: link.route.params,
              query: link.route.query,
            }"
            class="text-lowercase"
            color="primary"
            small
            text
          >
            {{ link.label }}
            <v-icon right small> mdi-arrow-right </v-icon>
          </v-btn>
        </v-list-item-subtitle>
      </v-list-item-content>
    </v-list-item>
  </v-list>
</template>

<script lang="ts">
import Vue, { type PropType } from "vue";

interface Link {
  label: string;
  route: {
    name: string;
    params: any;
  };
}

export default Vue.extend({
  name: "NotificationItemDetails",
  props: {
    link: {
      required: true,
      type: Object as PropType<Link>,
    },
    sinceCurrentDate: {
      required: true,
      type: String as PropType<string>,
    },
    title: {
      required: true,
      type: String as PropType<string>,
    },
  },
});
</script>
