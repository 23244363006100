import {
  CreateLPositionClosed,
  CreateManyLPositionClosed,
  DeleteLPositionClosed,
  DeleteManyLPositionClosed,
  LPositionCloseds,
  UpdateLPositionClosed,
} from "@/graphql/planning/LPositionCloseds";
import { usePositionsClosedStore } from "@/pinia/collections";
import { usePlanningStore } from "@/pinia/features/planning";
import { setGlobalStoreGraphQL } from "@/utils/graphql.utils";
import { apolloProvider } from "@/vue-apollo";

export const createLPositionClosed = async (payload: { data: any }) => {
  try {
    return await apolloProvider.defaultClient.mutate({
      mutation: CreateLPositionClosed,
      update: (store, { data: { createLPositionClosed } }) => {
        updateStoreAfterCreateLPositionClosed(store, createLPositionClosed);
      },
      variables: {
        data: payload.data,
      },
    });
  } catch (error) {
    console.error(error);
  }
};

export const createManyLPositionClosed = async (payload: { data: any }) => {
  try {
    return await apolloProvider.defaultClient.mutate({
      mutation: CreateManyLPositionClosed,
      variables: {
        data: payload.data,
      },
    });
  } catch (error) {
    console.error(error);
  }
};

export const deleteManyLPositionClosed = async (payload: { where: any }) => {
  try {
    return await apolloProvider.defaultClient.mutate({
      mutation: DeleteManyLPositionClosed,
      variables: {
        where: payload.where,
      },
    });
  } catch (error) {
    console.error(error);
  }
};

export const updateLPositionClosed = async (payload: {
  data: any;
  where: any;
}) => {
  try {
    return await apolloProvider.defaultClient.mutate({
      mutation: UpdateLPositionClosed,
      update: (store, data) => {
        updateStoreAfterUpdateLPositionClosed(
          store,
          data.data.updateLPositionClosed,
        );
      },
      variables: {
        data: payload.data,
        where: payload.where,
      },
    });
  } catch (error) {
    console.error(error);
  }
};

export const deleteLPositionClosed = async (payload: { where: any }) => {
  try {
    return await apolloProvider.defaultClient.mutate({
      mutation: DeleteLPositionClosed,
      update: (store, data) => {
        updateStoreAfterDeleteLPositionClosed(
          store,
          data.data.deleteLPositionClosed.id,
        );
      },
      variables: {
        where: payload.where,
      },
    });
  } catch (error) {
    console.error(error);
  }
};

const updateStoreAfterCreateLPositionClosed = (
  store,
  createLPositionClosed,
) => {
  const data = store.readQuery({
    query: LPositionCloseds,
    variables: usePlanningStore().apolloCacheVariables.positionCloseds,
  });
  data.LPositionCloseds.push(createLPositionClosed);
  setGlobalStoreGraphQL(usePositionsClosedStore(), data.LPositionCloseds);
  store.writeQuery({
    data,
    query: LPositionCloseds,
  });
};

const updateStoreAfterDeleteLPositionClosed = (store, id) => {
  const data = store.readQuery({
    query: LPositionCloseds,
    variables: usePlanningStore().apolloCacheVariables.positionCloseds,
  });
  const index = data.LPositionCloseds.findIndex((c) => c.id === id);
  if (index !== -1) {
    data.LPositionCloseds.splice(index, 1);
    setGlobalStoreGraphQL(usePositionsClosedStore(), data.LPositionCloseds);
    store.writeQuery({
      data,
      query: LPositionCloseds,
    });
  }
};

const updateStoreAfterUpdateLPositionClosed = (
  store,
  updateLPositionClosed,
) => {
  const data = store.readQuery({
    query: LPositionCloseds,
    variables: usePlanningStore().apolloCacheVariables.positionCloseds,
  });
  const index = data.LPositionCloseds.findIndex(
    (c) => c.id === updateLPositionClosed.id,
  );
  if (index !== -1) {
    data.LPositionCloseds.splice(index, 1, updateLPositionClosed);
    setGlobalStoreGraphQL(usePositionsClosedStore(), data.LPositionCloseds);
    store.writeQuery({
      data,
      query: LPositionCloseds,
    });
  }
};
