import {
  add,
  addDays,
  addMonths,
  compareAsc,
  compareDesc,
  eachDayOfInterval,
  endOfISOWeek,
  endOfWeek,
  formatDistance,
  formatISO,
  getDay,
  getISOWeek,
  isValid,
  type Locale,
  parseISO,
  setISOWeek,
  startOfDay,
  startOfISOWeek,
  startOfISOWeekYear,
  startOfWeek,
  sub,
  subMinutes,
} from "date-fns";
import { de, enGB, es, fr, it, pt } from "date-fns/locale";
import { Timestamp } from "firebase/firestore";
import { type Dictionary, round } from "lodash";
import type { TranslateResult } from "vue-i18n";

import i18n from "@/i18n";
import { getDecimals, getInteger } from "@/tscript/utils/math";

import type { SpecificDateTimeFormatOptions } from "../interfaces";
import type { Languages } from "../mercateam";

const eachDayOfIntervalUTC = (startDate: Date, endDate: Date): Date[] => {
  // Get All Days between two dates
  return eachDayOfInterval({
    end: subMinutes(endDate, new Date().getTimezoneOffset()),
    start: subMinutes(startDate, new Date().getTimezoneOffset()),
  }).map((date) => subMinutes(date, new Date().getTimezoneOffset()));
};

const getDateFromWeek = (year, week) => {
  // Get the first day of the ISO week year
  const startOfYear = startOfISOWeekYear(new Date(year, 5, 15));
  // Set the ISO week number
  const date = setISOWeek(startOfYear, week);
  return date;
};

export const DateUtils = {
  convertToDate(date: Date | Timestamp): Date {
    // When default value is a regular javascript Date
    if (date instanceof Date) {
      return date;
    }
    // When default value is a firebase date.
    return DateUtils.timestampToDate(date);
  },
  convertToDateOrNull(
    date: Date | null | string | Timestamp | undefined,
  ): Date | null {
    // If no date
    if (!date) return null;
    // When default value is a regular javascript Date
    if (date instanceof Date) {
      return date;
    }
    if (typeof date === "string") {
      return null;
    }
    // When default value is a firebase date.
    return DateUtils.timestampToDate(date);
  },
  dayAbbrArray(): TranslateResult[] {
    return [
      i18n.t("global.days_of_week.abbr.sunday"),
      i18n.t("global.days_of_week.abbr.monday"),
      i18n.t("global.days_of_week.abbr.tuesday"),
      i18n.t("global.days_of_week.abbr.wednesday"),
      i18n.t("global.days_of_week.abbr.thursday"),
      i18n.t("global.days_of_week.abbr.friday"),
      i18n.t("global.days_of_week.abbr.saturday"),
    ];
  },

  dayAbbrDictionnary(): Dictionary<TranslateResult> {
    return {
      0: i18n.t("global.days_of_week.abbr.sunday"),
      1: i18n.t("global.days_of_week.abbr.monday"),
      2: i18n.t("global.days_of_week.abbr.tuesday"),
      3: i18n.t("global.days_of_week.abbr.wednesday"),
      4: i18n.t("global.days_of_week.abbr.thursday"),
      5: i18n.t("global.days_of_week.abbr.friday"),
      6: i18n.t("global.days_of_week.abbr.saturday"),
    };
  },
  dayAbbrIndexArray(): TranslateResult[] {
    return [
      i18n.t("global.days_of_week.abbr.monday"),
      i18n.t("global.days_of_week.abbr.tuesday"),
      i18n.t("global.days_of_week.abbr.wednesday"),
      i18n.t("global.days_of_week.abbr.thursday"),
      i18n.t("global.days_of_week.abbr.friday"),
      i18n.t("global.days_of_week.abbr.saturday"),
      i18n.t("global.days_of_week.abbr.sunday"),
    ];
  },
  /**
   * Returns the date of expiration counting from current date given a number of days or months.
   * @param interval
   * @param rule
   * @param format
   */
  expires(
    interval: number,
    rule: "days" | "months",
    format?: "iso" | "locale",
  ): string | undefined {
    const dateObj = Date.now();
    const today = new Date(dateObj);
    today.toDateString();
    const result: Date | undefined =
      rule === "days"
        ? addDays(today, interval)
        : rule === "months"
          ? addMonths(today, interval)
          : undefined;
    if (result && format && format === "iso")
      return formatISO(result, {
        representation: "date",
      });
    if (format && format === "locale") return result?.toLocaleDateString();
    return result?.toDateString();
  },
  formatDateToLocaleString(
    dateOrDateString: Date | string,
  ): string | undefined {
    const date =
      "string" === typeof dateOrDateString
        ? parseISO(dateOrDateString)
        : dateOrDateString;
    return isValid(date) ? date.toLocaleDateString() : undefined;
  },
  formatDistanceAndTranslation(date: Date, language: Languages) {
    let fnsLocale: Locale = enGB;
    switch (language) {
      case "de":
        fnsLocale = de;
        break;
      case "en":
        fnsLocale = enGB;
        break;
      case "es":
        fnsLocale = es;
        break;
      case "fr":
        fnsLocale = fr;
        break;
      case "it":
        fnsLocale = it;
        break;
      case "pt":
        fnsLocale = pt;
        break;
    }
    return formatDistance(date, new Date(), {
      addSuffix: true,
      locale: fnsLocale,
    });
  },
  fromUserLanguageToDateTimeFormat(languageFromUser: string): string {
    const dictionnary: any = {
      de: "de-DE",
      en: "en-GB",
      es: "es-ES",
      fr: "fr-FR",
      it: "it-IT",
      pt: "pt-PT",
    };
    return dictionnary[languageFromUser] || "en-GB";
  },
  getAllBaseTenOfSelectedDays(
    currentDayArray: boolean[],
  ): number[] | undefined {
    const indexArray: number[] = [];
    for (let i = 0; i < currentDayArray.length; i++) {
      if (currentDayArray[i]) indexArray.push(i + 1);
    }
    return indexArray;
  },
  getAllDayNbsOfSelectedDays(currentDayArray: boolean[]): number[] | undefined {
    const indexArray: number[] = [];
    for (let i = 0; i < currentDayArray.length; i++) {
      if (currentDayArray[i]) {
        if (i === 6) {
          indexArray.push(0);
        } else {
          indexArray.push(i + 1);
        }
      }
    }
    return indexArray;
  },
  getAllIndexsOfDays(currentDayArray: boolean[]): number[] | undefined {
    const indexArray: number[] = [];
    for (let i = 0; i < currentDayArray.length; i++) {
      indexArray.push(i);
    }
    return indexArray;
  },

  getAllIndexsOfSelectedDays(currentDayArray: boolean[]): number[] | undefined {
    const indexArray: number[] = [];
    for (let i = 0; i < currentDayArray.length; i++) {
      if (currentDayArray[i]) indexArray.push(i);
    }
    return indexArray;
  },
  getDateOfISOWeek(w: number, y: number) {
    const simple = new Date(y, 0, 1 + (w - 1) * 7);
    const dow = simple.getDay();
    const ISOweekStart = simple;
    if (dow <= 4) ISOweekStart.setDate(simple.getDate() - simple.getDay() + 1);
    else ISOweekStart.setDate(simple.getDate() + 8 - simple.getDay());
    return ISOweekStart;
  },
  getDayNbOfSelectedDay(currentDayArray: boolean[]): number | undefined {
    for (let i = 0; i < currentDayArray.length; i++) {
      if (currentDayArray[i]) {
        if (i === 6) return 0;
        return i + 1;
      }
    }
  },
  getDayOfTheWeek(date: Date): boolean[] {
    const dayISO = date.getDay() - 1;
    return [
      dayISO === 0,
      dayISO === 1,
      dayISO === 2,
      dayISO === 3,
      dayISO === 4,
      dayISO === 5,
      dayISO === -1,
    ];
  },
  getDayTranslation(dayNb: number): TranslateResult {
    const days = [
      i18n.t("global.days_of_week.abbr.sunday"),
      i18n.t("global.days_of_week.abbr.monday"),
      i18n.t("global.days_of_week.abbr.tuesday"),
      i18n.t("global.days_of_week.abbr.wednesday"),
      i18n.t("global.days_of_week.abbr.thursday"),
      i18n.t("global.days_of_week.abbr.friday"),
      i18n.t("global.days_of_week.abbr.saturday"),
    ];
    return days[dayNb];
  },
  getEndOfWeekByDate(date: Date): Date {
    const firstdayOfTheWeek = endOfWeek(date, { weekStartsOn: 1 });
    return firstdayOfTheWeek;
  },
  getFormatedDate(
    date: Date | Timestamp,
    userLanguage: string,
    showHours?: boolean,
  ): string {
    let options: SpecificDateTimeFormatOptions = {
      day: "numeric",
      month: "numeric",
      year: "2-digit",
    };
    if (showHours)
      options = {
        ...options,
        hour: "numeric",
        minute: "numeric",
      };
    return this.convertToDate(date).toLocaleDateString(
      userLanguage,
      options as any,
    );
  },

  getIndexOfSelectedDay(currentDayArray: boolean[]): number | undefined {
    for (let i = 0; i < currentDayArray.length; i++) {
      if (currentDayArray[i]) return i;
    }
  },

  getISOWeeks(y: number) {
    const currentLastYearWeek = getISOWeek(new Date(y - 1, 11, 31));
    const day = getDay(new Date(y - 1, 11, 31));
    return currentLastYearWeek === 1 && day >= 3 ? 53 : 52;
  },
  getStartOfWeekByDate(date: Date): Date {
    const firstdayOfTheWeek = startOfWeek(date, { weekStartsOn: 1 });
    return firstdayOfTheWeek;
  },

  /**
   * In case you have the week number and you want to know the first day of that week
   * @param week
   * @param year
   * @return ISOWeekStart
   */
  getStartOfWeekISO(week: number, year: number): Date {
    const calculatedDate = new Date(year, 0, 1 + (week - 1) * 7);
    const dayOfWeek = calculatedDate.getDay();
    const ISOWeekStart = calculatedDate;
    if (dayOfWeek <= 4) {
      ISOWeekStart.setDate(
        calculatedDate.getDate() - calculatedDate.getDay() + 1,
      );
    } else {
      ISOWeekStart.setDate(
        calculatedDate.getDate() + 8 - calculatedDate.getDay(),
      );
    }
    return ISOWeekStart;
  },

  getStartOfWeekISOBeta(week: number, year: number): Date {
    const calculatedDate = new Date(Date.UTC(year, 0, 1 + (week - 1) * 7));
    const dayOfWeek = calculatedDate.getUTCDay();
    const ISOWeekStart = calculatedDate;
    if (dayOfWeek <= 4) {
      ISOWeekStart.setDate(
        calculatedDate.getUTCDate() - calculatedDate.getUTCDay() + 1,
      );
    } else {
      ISOWeekStart.setDate(
        calculatedDate.getUTCDate() + 8 - calculatedDate.getUTCDay(),
      );
    }
    return ISOWeekStart;
  },

  getTomorrow(): Date {
    const date = new Date();
    return add(
      new Date(date.getUTCFullYear(), date.getMonth(), date.getDate(), 0, 0),
      { days: 1 },
    );
  },

  getWeekNumber(d: Date) {
    d = new Date(+d); // Copy date so don't modify original.
    d.setHours(0, 0, 0, 0); // Reset hours.
    d.setDate(d.getDate() + 4 - (d.getDay() || 7)); // Set to nearest Thursday: current date + 4 - current day number and make Sunday's day number 7
    const yearStart = new Date(d.getFullYear(), 0, 1); // Get first day of year
    const weekNo = Math.ceil(
      ((d.getTime() - yearStart.getTime()) / 86400000 + 1) / 7,
    ); // Calculate full weeks to nearest Thursday
    return weekNo; // Return week number
  },

  /**
   * Returns a date in ISO format (only date, no time)
   * @param date
   */
  iso(date: Date): string {
    return formatISO(date, {
      representation: "date",
    });
  },

  IsoWeekToDaysConverter(week: number, year: number) {
    return eachDayOfIntervalUTC(
      startOfISOWeek(getDateFromWeek(year, week)),
      startOfDay(endOfISOWeek(getDateFromWeek(year, week))),
    )
      .map((date) => date.toISOString().split("T")[0])
      .map((date) => date.split("-"))
      .map((date) => ({
        day: Number.parseInt(date[2], 10),
        month: Number.parseInt(date[1], 10),
        year: Number.parseInt(date[0], 10),
      }));
  },

  /**
   * Return if the first date is after the second
   * @param dateLeft
   * @param dateRight
   */
  isPastDate(dateLeft: Date, dateRight: Date): boolean {
    return compareAsc(dateLeft, dateRight) === 1;
  },

  /**
   * Return if the first date is after or equal the second
   * @param dateLeft
   * @param dateRight
   */
  isPastOrEqualDate(dateLeft: Date, dateRight: Date): boolean {
    return compareAsc(dateLeft, dateRight) !== -1;
  },

  last12months(today: Date): Date[] {
    return [
      today,
      sub(today, { months: 1 }),
      sub(today, { months: 2 }),
      sub(today, { months: 3 }),
      sub(today, { months: 4 }),
      sub(today, { months: 5 }),
      sub(today, { months: 6 }),
      sub(today, { months: 7 }),
      sub(today, { months: 8 }),
      sub(today, { months: 9 }),
      sub(today, { months: 10 }),
      sub(today, { months: 11 }),
    ];
  },
  localDate(date: Timestamp, onlyDate = false) {
    const dateAsDate = date.toDate();
    const dateAsString = dateAsDate.toLocaleDateString();
    const dateHour = dateAsDate.getHours();
    let dateMinutes: number | string = dateAsDate.getMinutes();
    if (dateMinutes < 10) {
      dateMinutes = `0${dateMinutes}`;
    }
    if (onlyDate) return dateAsString;
    return `${dateAsString} - ${dateHour}h${dateMinutes}`;
  },

  localDateFromMaybeTimestamp(
    date: Timestamp | { _nanoseconds: number; _seconds: number },
  ) {
    let localDate:
      | string
      | Timestamp
      | { _nanoseconds: number; _seconds: number } = date;
    if (!("toDate" in date)) {
      localDate = new Timestamp(date._seconds, date._nanoseconds);
      localDate = localDate?.toDate().toLocaleDateString();
    } else if ("toDate" in date) {
      localDate = date?.toDate().toLocaleDateString();
    }
    return localDate;
  },

  // Global
  retrieveDecimalFromInputTime(
    inputTime: null | string,
    maxHourRange: 12 | 24,
    isDuration24 = false,
  ): null | number {
    if (!inputTime) return null;
    const inputMaxHourRange = inputTime.replace(/[^aApPmM]/g, "");
    const regPM = new RegExp(/[pP][mM]/g);
    const resRegPM = regPM.test(inputMaxHourRange);
    const res = inputTime.replace(/[^\d:h]/g, "");
    let timeArray: string[] = res.split(":");
    if (timeArray.length === 1) timeArray = res.split("h");
    let hours: number;
    let minutes: number;
    if (timeArray.length === 1) {
      if (timeArray[0].length > 1) {
        hours = Number(timeArray[0].slice(0, 2));
      } else {
        hours = Number(timeArray[0]);
      }
      if (timeArray[0].length < 4) {
        if (hours > maxHourRange || hours < 10 || timeArray[0].length === 3) {
          hours = Number(timeArray[0].slice(0, 1));
          minutes = Number(timeArray[0].slice(1, timeArray[0].length));
        } else {
          hours = Number(timeArray[0].slice(0, 2));
          minutes = Number(timeArray[0].slice(2, timeArray[0].length));
        }
      } else if (hours > maxHourRange) {
        hours = Number(timeArray[0].slice(0, 1));
        minutes = Number(timeArray[0].slice(1, timeArray[0].length));
      } else {
        hours = Number(timeArray[0].slice(0, 2));
        minutes = Number(timeArray[0].slice(2, timeArray[0].length));
      }
    } else {
      hours = Number(timeArray[0]);
      if (hours > maxHourRange || hours < 10) {
        hours = Number(timeArray[0].slice(0, 1));
      } else {
        hours = Number(timeArray[0].slice(0, 2));
      }
      minutes = Number(timeArray[1]);
    }
    minutes = Math.round(
      Number(
        `${Number(minutes.toString().slice(0, 2))}.${Number(
          minutes.toString().slice(2, minutes.toString().length),
        )}`,
      ),
    );
    minutes /= 60;
    if (hours + minutes >= maxHourRange && !isDuration24) {
      hours = 0;
      // resRegPM = false;
    }
    if (minutes >= 1) {
      hours += 1;
      minutes -= 1;
    }
    if (resRegPM && hours < 12) {
      hours += 12;
    }
    return round(hours + minutes, 6);
  },
  retrieveInputTimeFormatFromDecimal(
    decimal: null | number,
    maxHourRange: 12 | 24 = 24,
    isDuration24 = false,
  ): null | string {
    if (!decimal && decimal !== 0) return null;
    let hours: number = getInteger(decimal);
    const minutes: number = getDecimals(decimal) * 60;
    let minutesFormated = "";
    minutesFormated = Math.round(minutes).toString();
    if (minutesFormated.length === 1) minutesFormated = `0${minutesFormated}`;
    if (minutesFormated.length === 0) minutesFormated = "00";
    if (maxHourRange === 12) {
      hours = decimal <= 12 ? hours : hours - 12;
    }
    let hoursFormated =
      hours === 0 || hours === maxHourRange ? "0" : hours.toString();
    if (isDuration24) {
      hoursFormated = hours.toString();
    }
    if (hours > maxHourRange) {
      hoursFormated = hoursFormated.slice(0, 1);
    }
    let dayMoment = "";
    if (maxHourRange === 12) {
      dayMoment = decimal < 12 || decimal === 24 ? "am" : "pm";
    }
    return `${hoursFormated}:${minutesFormated}${dayMoment}`;
  },

  retrieveTemplateTimeFormatTotalHoursFromDecimal(
    decimal: null | number,
  ): null | string {
    if (!decimal && decimal !== 0) return null;
    const hours: number = getInteger(decimal);
    const minutes: number = getDecimals(decimal) * 60;
    let minutesFormated = "";
    if (minutes > 1) minutesFormated = Math.round(minutes).toString();
    if (minutesFormated.length === 1) minutesFormated = `0${minutesFormated}`;
    if (minutesFormated.length === 0) minutesFormated = "00";
    let hoursFormated = "";
    if (hours >= 1) hoursFormated = Math.round(hours).toString();
    if (hoursFormated.length === 1) hoursFormated = `0${hoursFormated}`;
    if (hoursFormated.length === 0) hoursFormated = "00";
    return `${hoursFormated}${i18n.t(
      "global.hour_first_letter",
    )}${minutesFormated}`;
  },

  retrieveTemplateTimeFromDecimal(
    decimal: null | number,
    maxHourRange: 12 | 24,
  ): null | string {
    if (!decimal && decimal !== 0) return null;
    let hours: number = getInteger(decimal);
    const minutes: number = getDecimals(decimal) * 60;
    let minutesFormated = "";
    minutesFormated = Math.round(minutes).toString();
    if (minutesFormated.length === 1) minutesFormated = `0${minutesFormated}`;
    if (minutesFormated.length === 0) minutesFormated = "00";
    let dayMoment = "";
    if (maxHourRange === 12) {
      dayMoment = decimal < 12 || decimal === 24 ? "am" : "pm";
      hours = decimal <= 12 ? hours : hours - 12;
      hours = decimal < 1 ? 12 : hours;
    }
    if (maxHourRange === 24) {
      hours = decimal === 24 ? 0 : hours;
    }
    let hoursFormated = hours.toString();
    if (hours > maxHourRange) {
      hoursFormated = hoursFormated.slice(0, 1);
    }
    return `${hoursFormated}${i18n.t(
      "global.hour_first_letter",
    )}${minutesFormated}${dayMoment}`;
  },

  /**
   * Sorts an array of JS Date objects in either ascending or descending direc()tion.
   * @param dates
   * @param direction
   */
  sort(dates: Date[], direction: "asc" | "desc"): Date[] | undefined {
    if (direction === "asc") return dates.sort(compareAsc);
    if (direction === "desc") return dates.sort(compareDesc);
    return;
  },

  /**
   * Converts Firebase Timestamp to date
   * @param timestamp
   */
  timestampToDate(timestamp: Timestamp): Date {
    const dateToTime = new Date().setTime(timestamp.seconds * 1000);
    return new Date(dateToTime);
  },

  /**
   * Converts Firebase Timestamp to date
   * @param timestamp
   */
  timestampVanillaToDate(s: Date | number | string): string {
    return new Date(s).toLocaleString();
  },

  totalTime(end: number, start: number) {
    if (start === 0 && end === 0) {
      return 24;
    }
    return end - start;
  },

  /**
   * Converts a date string to a new date string according to the user's localization
   * @param date
   */
  userFormat(date: string): null | string {
    if (!isValid(new Date(date))) return null;
    return new Date(`${date} 00:00:00`).toLocaleDateString();
  },

  willExpire(date: Date, interval: number, rule: "days" | "months"): boolean {
    const dateObj = Date.now();
    const today = new Date(dateObj);
    const result =
      rule === "days"
        ? addDays(today, interval)
        : rule === "months"
          ? addMonths(today, interval)
          : undefined;
    if (!result) return false;
    return this.isPastDate(result, date);
  },
};
