import { every } from "lodash";
import { defineStore } from "pinia";

import APIClient from "@/api/client";
import APICloudRunClient from "@/api/cloudrun";
import * as baseCollection from "@/pinia/collections";

export const useGlobalStore = defineStore("useGlobal", {
  actions: {
    loadApiClientAction() {
      const apiClient = new APIClient();
      this.apiClient = apiClient;
    },
  },
  getters: {
    globalLoaded(): boolean {
      const TeamGlobalStore = baseCollection.useTeamGlobalStore();
      const SkillGlobalStore = baseCollection.useSkillGlobalStore();
      const ClearanceGlobalStore = baseCollection.useClearanceGlobalStore();
      const EmployeeGlobalStore = baseCollection.useEmployeeGlobalStore();
      const EventGlobalStore = baseCollection.useEventGlobalStore();
      const UserGlobalStore = baseCollection.useUserGlobalStore();
      const SiteGlobalStore = baseCollection.useSiteGlobalStore();
      const FactoryGlobalStore = baseCollection.useFactoryGlobalStore();
      if (
        TeamGlobalStore.hasRealtime &&
        SkillGlobalStore.hasRealtime &&
        ClearanceGlobalStore.hasRealtime &&
        EmployeeGlobalStore.hasRealtime &&
        EventGlobalStore.hasRealtime &&
        UserGlobalStore.hasRealtime &&
        SiteGlobalStore.hasRealtime &&
        FactoryGlobalStore.hasRealtime
      ) {
        return every([
          TeamGlobalStore.hasSubcribed,
          SkillGlobalStore.hasSubcribed,
          ClearanceGlobalStore.hasSubcribed,
          EmployeeGlobalStore.hasSubcribed,
          EventGlobalStore.hasSubcribed,
          UserGlobalStore.hasSubcribed,
          SiteGlobalStore.hasSubcribed,
          FactoryGlobalStore.hasSubcribed,
        ]);
      }
      return true;
    },
  },
  state: () => ({
    apiClient: new APIClient(),
    cloudRunClient: new APICloudRunClient(),
    drawer: true,
  }),
});
