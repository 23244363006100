import Vue from "vue";
import VueI18n, { type LocaleMessages } from "vue-i18n";

Vue.use(VueI18n);

function loadLocaleMessages(): LocaleMessages {
  const locales = import.meta.glob("./locales/*.json");
  const messages: LocaleMessages = {};

  for (const locale in locales) {
    void locales[locale]().then((mod: any) => {
      const matched = /([A-Za-z0-9-_]+)\./i.exec(locale);
      if (matched && matched.length > 1) {
        const locale = matched[1];
        messages[locale] = mod;
      }
    });
  }
  return messages;
}

export default new VueI18n({
  fallbackLocale: "en",
  locale: navigator?.language,
  messages: loadLocaleMessages(),
  silentTranslationWarn: true,
});
