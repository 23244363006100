import { gql } from "graphql-tag";

export const CreateClosure = gql`
  mutation CreateClosure($data: ClosureCreateInput!) {
    createClosure(data: $data) {
      id
      name
      start_date
      end_date
      status
      teams {
        team_name
        id
      }
    }
  }
`;

export const UpdateClosure = gql`
  mutation UpdateClosure(
    $where: ClosureWhereUniqueInput!
    $data: ClosureUpdateInput!
  ) {
    updateClosure(where: $where, data: $data) {
      id
      name
      start_date
      end_date
      status
      teams {
        team_name
        id
      }
    }
  }
`;

export const DeleteClosure = gql`
  mutation DeleteClosure($where: ClosureWhereUniqueInput!) {
    deleteClosure(where: $where) {
      id
    }
  }
`;

export const Closures = gql`
  query Closures($where: ClosureWhereInput) {
    Closures(where: $where) {
      id
      name
      start_date
      end_date
      status
      teams {
        team_name
        id
      }
    }
  }
`;
