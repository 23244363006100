import { filter, includes, intersection, isEmpty } from "lodash";

import type {
  ClearanceID,
  ContentDocumentTypeID,
  PositionID,
  SkillID,
} from "@/tscript/mercateam";

import {
  type BasePublish,
  BaseStatus,
  type IContentDetailed,
} from "@/tscript/interfaces";

export const searchForDocumentsType = (
  contentsFilteredResult: any,
  selectedDocumentsType: ContentDocumentTypeID[],
): IContentDetailed[] | null => {
  if (!isEmpty(selectedDocumentsType)) {
    return filter(contentsFilteredResult, (content) => {
      return includes(selectedDocumentsType, content.document_type);
    });
  }
  return contentsFilteredResult;
};
export const searchForStatus = (
  contentsFilteredResult: any,
  selectedStatus: BasePublish[],
): IContentDetailed[] | null => {
  if (!isEmpty(selectedStatus)) {
    return filter(contentsFilteredResult, (content) => {
      return includes(selectedStatus, content.published);
    });
  }
  return contentsFilteredResult;
};
export const searchForPosition = (
  contentsFilteredResult: any,
  selectedPositions: PositionID[],
): IContentDetailed[] | null => {
  if (!isEmpty(selectedPositions)) {
    return filter(contentsFilteredResult, (content) => {
      return !isEmpty(intersection(selectedPositions, content.positions));
    });
  }
  return contentsFilteredResult;
};
export const searchForClearances = (
  contentsFilteredResult: any,
  selectedClearances: ClearanceID[],
): IContentDetailed[] | null => {
  if (!isEmpty(selectedClearances)) {
    return filter(contentsFilteredResult, (content) => {
      return !isEmpty(intersection(selectedClearances, content.habilities));
    });
  }
  return contentsFilteredResult;
};
export const searchForSkills = (
  contentsFilteredResult: any,
  selectedSkills: SkillID[],
): IContentDetailed[] | null => {
  if (!isEmpty(selectedSkills)) {
    return filter(contentsFilteredResult, (content) => {
      return !isEmpty(intersection(selectedSkills, content.skills));
    });
  }
  return contentsFilteredResult;
};

export const searchForArchived = (
  contentsFilteredResult: any,
  showArchivedContents: boolean,
): IContentDetailed[] | null => {
  if (showArchivedContents) {
    return filter(
      contentsFilteredResult,
      (content) => content.status === BaseStatus.archived,
    );
  }
  return contentsFilteredResult;
};
