<template>
  <svg
    height="24"
    version="1.1"
    viewBox="0 0 24 24"
    width="24"
    xmlns="http://www.w3.org/2000/svg"
    xmlns:xlink="http://www.w3.org/1999/xlink"
  >
    <path
      d="M5.5,20C2.46,20 0,17.54 0,14.5C0,11.46 2.46,9 5.5,9C6.5,6.65 8.8,5 11.5,5C14.93,5 17.74,7.66 18,11.03L18.5,11C21,11 23,13 23,15.5C23,18 21,20 18.5,20H5.5M5.5,10C3,10 1,12 1,14.5C1,17 3,19 5.5,19H18.5C20.43,19 22,17.43 22,15.5C22,13.57 20.43,12 18.5,12C17.94,12 17.4,12.13 16.93,12.37L17,11.5C17,8.46 14.54,6 11.5,6C8.96,6 6.83,7.72 6.19,10.05L5.5,10M12,17V11.75L14.25,14L15,13.34L11.5,9.84L8,13.34L8.75,14L11,11.75V17H12Z"
    />
  </svg>
</template>

<script lang="ts">
import Vue from "vue";

export default Vue.extend({
  name: "Importation",
  props: ["selection"],
});
</script>
