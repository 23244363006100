<template>
  <svg
    fill="none"
    height="24"
    viewBox="0 0 24 24"
    width="24"
    xmlns="http://www.w3.org/2000/svg"
  >
    <path
      :fill="selection"
      d="M5 3H18C19.66 3 21 4.34 21 6V19C21 20.66 19.66 22 18 22H5C3.34 22 2 20.66 2 19V6C2 4.34 3.34 3 5 3ZM5 4C3.9 4 3 4.9 3 6V17.59L7.29 13.29L9.79 15.79L14.79 10.79L20 16V6C20 4.9 19.1 4 18 4H5ZM9.79 17.21L7.29 14.71L3 19C3 20.1 3.9 21 5 21H18C19.1 21 20 20.1 20 19V17.41L14.79 12.21L9.79 17.21ZM7.5 6C8.88 6 10 7.12 10 8.5C10 9.88 8.88 11 7.5 11C6.12 11 5 9.88 5 8.5C5 7.12 6.12 6 7.5 6ZM7.5 7C6.67 7 6 7.67 6 8.5C6 9.33 6.67 10 7.5 10C8.33 10 9 9.33 9 8.5C9 7.67 8.33 7 7.5 7Z"
    />
  </svg>
</template>

<script lang="ts">
import Vue from "vue";

export default Vue.extend({
  name: "Trainings",
  props: ["selection"],
});
</script>
