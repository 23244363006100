import { z } from "zod";

export const Signature = z.discriminatedUnion("type", [
  z.object({
    date: z.string(),
    signature: z.string().url(),
    type: z.literal("accepted"),
    userId: z.string().optional(),
  }),
  z.object({
    date: z.string(),
    type: z.literal("refused"),
    userId: z.string().optional(),
  }),
  z.object({
    type: z.literal("notSigned"),
  }),
]);
