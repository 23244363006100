import { gql } from "graphql-tag";

export const CreateRestriction = gql`
  mutation CreateRestriction($data: RestrictionCreateInput!) {
    createRestriction(data: $data) {
      id
      name
      client_id
      site_id
      status
      published
    }
  }
`;

export const UpdateRestriction = gql`
  mutation UpdateRestriction(
    $data: RestrictionUpdateInput!
    $where: RestrictionWhereUniqueInput!
  ) {
    updateRestriction(data: $data, where: $where) {
      id
      name
      client_id
      site_id
      status
      published
    }
  }
`;

export const DeleteRestriction = gql`
  mutation DeleteRestriction($where: RestrictionWhereUniqueInput!) {
    deleteRestriction(where: $where) {
      id
    }
  }
`;

export const Restrictions = gql`
  query Restrictions {
    Restrictions {
      id
      name
      client_id
      site_id
      status
      published
    }
  }
`;
