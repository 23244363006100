import { gql } from "graphql-tag";

export const CreateSkill = gql`
  mutation CreateSkill($data: SkillCreateInput!) {
    createSkill(data: $data) {
      id
      name
      client_id
      site_id
      site {
        name
      }
      validity_months
      minimal_days
      level
      minimal_practice_time
      status
      comments
      teams {
        id
        team_name
        status
      }
      keywords_temp
      internal_code
      evaluators {
        id
        first_name
        last_name
        fullname
        picture_url
      }
      trainers {
        id
        first_name
        last_name
        fullname
        picture_url
      }
    }
  }
`;

export const UpdateSkill = gql`
  mutation UpdateSkill(
    $data: SkillUpdateInput!
    $where: SkillWhereUniqueInput!
  ) {
    updateSkill(data: $data, where: $where) {
      id
      name
      client_id
      site_id
      site {
        name
      }
      validity_months
      minimal_days
      level
      minimal_practice_time
      status
      comments
      teams {
        id
        team_name
        status
      }
      keywords_temp
      internal_code
      evaluators {
        id
        first_name
        last_name
        fullname
        picture_url
      }
      trainers {
        id
        first_name
        last_name
        fullname
        picture_url
      }
    }
  }
`;

export const DeleteSkill = gql`
  mutation DeleteSkill($where: SkillWhereUniqueInput!) {
    deleteSkill(where: $where) {
      id
      name
      client_id
      site_id
      validity_months
      minimal_days
      level
      minimal_practice_time
      status
      comments
    }
  }
`;

export const Skills = gql`
  query Skills {
    Skills {
      id
      name
      client_id
      site_id
      site {
        name
      }
      validity_months
      minimal_days
      level
      minimal_practice_time
      status
      comments
      teams {
        id
        team_name
        status
      }
      keywords_temp
      internal_code
      evaluators {
        id
        first_name
        last_name
        fullname
        picture_url
      }
      trainers {
        id
        first_name
        last_name
        fullname
        picture_url
      }
    }
  }
`;

export const DeleteManySkill = gql`
  mutation DeleteManySkill($where: SkillWhereInput) {
    deleteManySkill(where: $where) {
      count
    }
  }
`;
