import { omit } from "lodash";

import {
  Absences,
  CreateAbsence,
  DeleteAbsence,
  UpdateAbsence,
} from "@/graphql/settings/Absences";
import { useParameterAbsenceStore } from "@/pinia/collections";
import { setGlobalStoreGraphQL } from "@/utils/graphql.utils";
import { apolloProvider } from "@/vue-apollo";

export const createAbsence = async (payload: { data: any }) => {
  try {
    return await apolloProvider.defaultClient.mutate({
      mutation: CreateAbsence,
      update: (store, { data: { createAbsence } }) => {
        updateStoreAfterCreateAbsence(store, createAbsence);
      },
      variables: {
        data: omit(payload.data, ["update_user_id"]),
      },
    });
  } catch (error) {
    console.error(error);
  }
};

export const updateAbsence = async (payload: { data: any; where: any }) => {
  try {
    return await apolloProvider.defaultClient.mutate({
      mutation: UpdateAbsence,
      update: (store, data) => {
        updateStoreAfterUpdateAbsence(store, data.data.updateAbsence);
      },
      variables: {
        data: payload.data,
        where: payload.where,
      },
    });
  } catch (error) {
    console.error(error);
  }
};

export const deleteAbsence = async (payload: { where: any }) => {
  try {
    return await apolloProvider.defaultClient.mutate({
      mutation: DeleteAbsence,
      update: (store, data) => {
        updateStoreAfterDeleteAbsence(store, data.data.deleteAbsence.id);
      },
      variables: {
        where: payload.where,
      },
    });
  } catch (error) {
    console.error(error);
  }
};

const updateStoreAfterCreateAbsence = (store, createAbsence) => {
  const data = store.readQuery({
    query: Absences,
  });
  data.Absences.push(createAbsence);
  setGlobalStoreGraphQL(useParameterAbsenceStore(), data.Absences);
  store.writeQuery({
    data,
    query: Absences,
  });
};

const updateStoreAfterDeleteAbsence = (store, id) => {
  const data = store.readQuery({
    query: Absences,
  });
  const index = data.Absences.findIndex((c) => c.id === id);
  if (index !== -1) {
    data.Absences.splice(index, 1);
    setGlobalStoreGraphQL(useParameterAbsenceStore(), data.Absences);
    store.writeQuery({
      data,
      query: Absences,
    });
  }
};

const updateStoreAfterUpdateAbsence = (store, updateAbsence) => {
  const data = store.readQuery({
    query: Absences,
  });
  const index = data.Absences.findIndex((c) => c.id === updateAbsence.id);
  if (index !== -1) {
    data.Absences.splice(index, 1, updateAbsence);
    setGlobalStoreGraphQL(useParameterAbsenceStore(), data.Absences);
    store.writeQuery({
      data,
      query: Absences,
    });
  }
};
