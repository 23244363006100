export const overviewChildren = [
  {
    component: () =>
      import("@/components/team/team-overview/OverviewSkills.vue"),
    name: "overview-skills",
    path: "skills",
  },
  {
    component: () =>
      import("@/components/team/team-overview/OverviewClearances.vue"),
    name: "overview-clearances",
    path: "clearances",
  },
  {
    component: () =>
      import("@/components/team/team-overview/OverviewPositions.vue"),
    name: "overview-positions",
    path: "positions",
  },
];
