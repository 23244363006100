import teamContentRoutes from "@/modules/contents/teamRoutes";
import { ESectionName } from "@/modules/rights/types";

import {
  childrenInformations,
  childrenPlanning,
  childrenTeamContent,
} from "../childrenLevel2";
import { absenceChildrenTeam } from "./factory";
import { overviewChildren } from "./overview";

const teamChildren = [
  {
    children: childrenInformations,
    component: () => import("@/views/team/TeamInformationsView.vue"),
    path: "informations",
  },
  {
    component: () => import("@/components/team/TeamEmployees.vue"),
    meta: {
      userRightsSection: ESectionName.Enum.team_employees,
    },
    name: "team",
    path: "employees",
  },
  {
    component: () => import("@/components/team/TeamMultiAdmin.vue"),
    name: "import",
    path: "import/:target",
  },
  {
    component: () => import("@/components/team/TeamOverview.vue"),
    meta: {
      userRightsSection: ESectionName.Enum.team_overview,
    },
    name: "overview",
    path: "overview",
  },
  {
    children: overviewChildren,
    component: () => import("@/components/team/TeamOverviewV2.vue"),
    meta: {
      userRightsSection: ESectionName.Enum.team_overview,
    },
    name: "overview2",
    path: "overview2",
  },
  {
    component: () => import("@/components/team/TeamVersatility.vue"),
    meta: {
      userRightsSection: ESectionName.Enum.team_versatility,
    },
    name: "versatility",
    path: "versatility",
  },
  {
    children: childrenPlanning,
    component: () => import("@/views/Planning.vue"),
    meta: {
      userRightsSection: ESectionName.Enum.team_planning_management,
    },
    name: "planningv2",
    path: "planning-v2",
    redirect: "planning-v2/position/week",
  },
  {
    component: () => import("@/views/PlanningOpti.vue"),
    meta: {
      userRightsSection: ESectionName.Enum.team_planning_management,
    },
    name: "planningv3",
    path: "planning-v3/position/week",
  },
  {
    component: () => import("@/views/PlanningOpti.vue"),
    meta: {
      userRightsSection: ESectionName.Enum.team_planning_management,
    },
    name: "planningmonthv3",
    path: "planning-v3/position/monthv3",
  },
  {
    component: () => import("@/views/PlanningOpti.vue"),
    meta: {
      userRightsSection: ESectionName.Enum.team_planning_management,
    },
    name: "planningdayv3",
    path: "planning-v3/position/dayv3",
  },
  {
    component: () => import("@/views/PlanningOpti.vue"),
    meta: {
      userRightsSection: ESectionName.Enum.team_planning_management,
    },
    name: "planningshiftsv3",
    path: "planning-v3/shifts",
  },
  {
    children: absenceChildrenTeam,
    component: () => import("@/views/factory/Absences.vue"),
    meta: {
      requiresAuth: true,
      userRightsSection: ESectionName.Enum.team_absences,
    },
    name: "absences-team",
    path: "absences",
  },
  {
    children: childrenTeamContent,
    component: () => import("@/components/team/TeamContent.vue"),
    meta: {
      userRightsSection: ESectionName.Enum.team_contents,
    },
    name: "content",
    path: "content",
    redirect: "content/overview",
  },
  ...teamContentRoutes,
];

export default teamChildren;
