const childrenAnalysis = [
  {
    path: "",
    redirect: "uimm",
  },
  {
    component: () =>
      import("@/modules/workforce-planning/analysis/views/UIMMAnalysis.vue"),
    meta: {
      requiresAuth: true,
      userRightsSection: "workforce_planning",
    },
    name: "uimm-analysis",
    path: "uimm",
  },
];

export { childrenAnalysis };
