import { gql } from "graphql-tag";

export const CreateTeam = gql`
  mutation CreateTeam($data: TeamCreateInput!) {
    createTeam(data: $data) {
      id
      client_id
      client {
        name
      }
      site_id
      site {
        name
      }
      factory_id
      factory {
        factory_name
      }
      status
      team_name
      team_leaders {
        id
        first_name
        last_name
      }
      skills {
        id
        name
      }
      clearances {
        id
        name
      }
      positions {
        id
        name
      }
      cover
    }
  }
`;

export const UpdateTeam = gql`
  mutation UpdateTeam($where: TeamWhereUniqueInput!, $data: TeamUpdateInput!) {
    updateTeam(where: $where, data: $data) {
      id
      client_id
      client {
        name
      }
      site_id
      site {
        name
      }
      factory_id
      factory {
        factory_name
      }
      status
      team_name
      team_leaders {
        id
        first_name
        last_name
      }
      skills {
        id
        name
      }
      clearances {
        id
        name
      }
      positions {
        id
        name
      }
      cover
    }
  }
`;

export const DeleteTeam = gql`
  mutation DeleteTeam($where: TeamWhereUniqueInput!) {
    deleteTeam(where: $where) {
      id
      client_id
      site_id
      factory_id
      status
      team_name
    }
  }
`;

export const DeleteManyTeam = gql`
  mutation DeleteManyTeam($where: TeamWhereInput) {
    deleteManyTeam(where: $where) {
      count
    }
  }
`;

export const Teams = gql`
  query Teams {
    Teams(orderBy: [{team_name: asc}]) {
      id
      client_id
      client {
        name
      }
      site_id
      site {
        name
      }
      factory_id
      factory {
        factory_name
      }
      status
      team_name
      team_leaders {
        id
        first_name
        last_name
      }
      skills {
        id
        name
      }
      clearances {
        id
        name
      }
      positions {
        id
        name
      }
      cover
      limited_access_users {
        id
        _name
        _type
        fullname
        avatar_url
      }
    }
  }
`;
