<script setup lang="ts">
import { doc, onSnapshot } from "firebase/firestore";
import { gt } from "semver";
import { computed, type Ref, ref } from "vue";

import db from "@/firebase/db";
import i18n from "@/i18n";

import { version as currentVersion } from "../../package.json";

const version: Ref<null | string> = ref(null);
const appVersion: Ref<null | string> = ref(currentVersion);

onSnapshot(doc(db, "version", "currentVersion"), (doc) => {
  const data = doc.data();
  if (data) {
    version.value = data.v;
  }
});

const needRefresh = computed(() => {
  if (!version.value || !appVersion.value) return false;
  return gt(version.value, appVersion.value);
});

const refreshApp = () => {
  window.location.reload();
};
</script>

<template>
  <div :class="$style['pwa-toast']" role="alert" v-if="needRefresh">
    <div style="height: 24px">
      <svg
        fill="none"
        height="24"
        viewBox="0 0 24 24"
        width="24"
        xmlns="http://www.w3.org/2000/svg"
      >
        <g clip-path="url(#clip0_11_89)">
          <circle cx="12" cy="12" fill="white" r="12" />
          <path
            clip-rule="evenodd"
            d="M16.1721 7.76286C15.7829 7.03156 14.8746 6.75423 14.1433 7.14343C13.4119 7.53264 13.1346 8.44099 13.5238 9.1723L16.7516 15.2371C17.1408 15.9685 18.0491 16.2458 18.7804 15.8566C19.5117 15.4674 19.7891 14.559 19.3999 13.8277L16.1721 7.76286ZM10.5624 7.74687C10.2992 7.26634 9.79482 6.96758 9.2469 6.96758C8.69898 6.96758 8.19465 7.26634 7.93139 7.74687L4.60872 13.8117C4.21068 14.5383 4.47698 15.4499 5.20352 15.8479C5.93006 16.246 6.84171 15.9797 7.23974 15.2531L9.2469 11.5895L11.2541 15.2531C11.6521 15.9797 12.5637 16.246 13.2903 15.8479C14.0168 15.4499 14.2831 14.5383 13.8851 13.8117L10.5624 7.74687Z"
            fill="#6733FD"
            fill-rule="evenodd"
          />
        </g>
        <defs>
          <clipPath id="clip0_11_89">
            <rect fill="white" height="24" width="24" />
          </clipPath>
        </defs>
      </svg>
    </div>
    <div style="margin-left: 10px; margin-right: 10px"></div>
    <div :class="$style.message">
      {{ i18n.t("newversion") }}
    </div>
    <div style="margin-left: 10px; margin-right: 10px"></div>
    <v-btn @click="refreshApp()" color="white" rounded v-if="needRefresh">
      {{ i18n.t("newversion_button") }}
    </v-btn>
  </div>
</template>

<style module>
.pwa-toast {
  display: flex;
  justify-content: center;
  align-items: center;
  z-index: 99999;
  position: fixed;
  right: 0;
  bottom: 0;
  margin: 0px;
  text-align: center;
  color: white;
  box-shadow: 3px 4px 5px 0 #8885;
  background-color: rgb(103, 51, 253);
  width: 100%;
  padding: 10px;
}
.pwa-toast .message {
  margin-top: 4px;
}
</style>
