import { v4 as uuidv4 } from "uuid";
import { z } from "zod";

const newId = () => uuidv4();

export const Id = z.string().trim().min(1).default(newId).brand("Id");
export type Id = z.infer<typeof Id>;

export const BaseEntitySchema = z.object({
  createdAt: z.date().nullish(),
  id: Id,
  updatedAt: z.date().nullish(),
});

export const areIndexIncremental = {
  check: <T extends { index: number }>(array: T[]) =>
    array
      .sort((a, b) => a.index - b.index)
      .every((block, index) => block.index === index),
  message: "Must be an array of incremental index",
};
