import {
  CreateSkillLegend,
  DeleteSkillLegend,
  SkillLegends,
  UpdateSkillLegend,
} from "@/graphql/library/SkillLegends";
import { useSkillLegendsStore } from "@/pinia/collections";
import { setGlobalStoreGraphQL } from "@/utils/graphql.utils";
import { apolloProvider } from "@/vue-apollo";

export const createSkillLegend = async (payload: { data: any }) => {
  try {
    return await apolloProvider.defaultClient.mutate({
      mutation: CreateSkillLegend,
      update: (store, { data: { createSkillLegend } }) => {
        updateStoreAfterCreateSkillLegend(store, createSkillLegend);
      },
      variables: {
        data: payload.data,
      },
    });
  } catch (error) {
    console.error(error);
  }
};

export const updateSkillLegend = async (payload: { data: any; where: any }) => {
  try {
    return await apolloProvider.defaultClient.mutate({
      mutation: UpdateSkillLegend,
      update: (store, data) => {
        updateStoreAfterUpdateSkillLegend(store, data.data.updateSkillLegend);
      },
      variables: {
        data: payload.data,
        where: payload.where,
      },
    });
  } catch (error) {
    console.error(error);
  }
};

export const deleteSkillLegend = async (payload: { where: any }) => {
  try {
    return await apolloProvider.defaultClient.mutate({
      mutation: DeleteSkillLegend,
      update: (store, data) => {
        updateStoreAfterDeleteSkillLegend(
          store,
          data.data.deleteSkillLegend.id,
        );
      },
      variables: {
        where: payload.where,
      },
    });
  } catch (error) {
    console.error(error);
  }
};

const updateStoreAfterCreateSkillLegend = (store, createSkillLegend) => {
  const data = store.readQuery({
    query: SkillLegends,
  });
  data.SkillLegends.push(createSkillLegend);
  setGlobalStoreGraphQL(useSkillLegendsStore(), data.SkillLegends);
  store.writeQuery({
    data,
    query: SkillLegends,
  });
};

const updateStoreAfterDeleteSkillLegend = (store, id) => {
  const data = store.readQuery({
    query: SkillLegends,
  });
  const index = data.SkillLegends.findIndex((c) => c.id === id);
  if (index !== -1) {
    data.SkillLegends.splice(index, 1);
    setGlobalStoreGraphQL(useSkillLegendsStore(), data.SkillLegends);
    store.writeQuery({
      data,
      query: SkillLegends,
    });
  }
};

const updateStoreAfterUpdateSkillLegend = (store, updateSkillLegend) => {
  const data = store.readQuery({
    query: SkillLegends,
  });
  const index = data.SkillLegends.findIndex(
    (c) => c.id === updateSkillLegend.id,
  );
  if (index !== -1) {
    data.SkillLegends.splice(index, 1, updateSkillLegend);
    setGlobalStoreGraphQL(useSkillLegendsStore(), data.SkillLegends);
    store.writeQuery({
      data,
      query: SkillLegends,
    });
  }
};
