import { gql } from "graphql-tag";

export const CreateLRestriction = gql`
  mutation CreateLRestriction($data: LRestrictionCreateInput!) {
    createLRestriction(data: $data) {
      id
      status
      employee_id
      restriction_id
      restriction {
        name
      }
    }
  }
`;

export const UpdateLRestriction = gql`
  mutation UpdateLRestriction(
    $where: LRestrictionWhereUniqueInput!
    $data: LRestrictionUpdateInput!
  ) {
    updateLRestriction(where: $where, data: $data) {
      id
      status
      employee_id
      restriction_id
      restriction {
        name
      }
    }
  }
`;

export const DeleteLRestriction = gql`
  mutation DeleteLRestriction($where: LRestrictionWhereUniqueInput!) {
    deleteLRestriction(where: $where) {
      id
    }
  }
`;

export const LRestrictions = gql`
  query LRestrictions($where: LRestrictionWhereInput) {
    LRestrictions(where: $where) {
      id
      status
      employee_id
      restriction_id
      restriction {
        name
      }
    }
  }
`;
