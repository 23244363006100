import axios, {
  type AxiosInstance,
  type AxiosResponse,
  isAxiosError,
} from "axios";
import { getAuth } from "firebase/auth";
import qs from "qs";

import { BACKEND_GRAPHQL_URL } from "@/config/index";
import i18n from "@/i18n";

type dashboardType =
  | "employee"
  | "performanceReviews"
  | "schedule"
  | "skills"
  | "training";

type RequestError<Data = any> =
  | {
      data: Data;
      error: "http";
      status: number | undefined;
      statusText: string | undefined;
    }
  | {
      error: "unknown";
      message: string;
    };

export default class APIGraphQLClient {
  readonly #client: AxiosInstance = axios.create({
    baseURL: BACKEND_GRAPHQL_URL,
    paramsSerializer: {
      encode: (params) => qs.stringify(params, { arrayFormat: "repeat" }),
    },
  });

  #userTokenId: string | undefined;

  async createUniqueToken(): Promise<string> {
    const isTokenResponse = (
      response: AxiosResponse,
    ): response is AxiosResponse<{ token: string }> =>
      response.data &&
      "object" === typeof response.data &&
      "token" in response.data &&
      "string" === typeof response.data.token &&
      response.data.token;
    return this.#client
      .post("/api/token/create", undefined, await this.#getHeaders())
      .then((response) => {
        if (!isTokenResponse(response)) {
          throw new Error(
            "Unexpected response when attempting to get a token.",
          );
        }
        return response.data.token;
      });
  }

  async exportMatrix(data: any) {
    return this.#handleRequest("/api/export/matrix", data);
  }

  async generateIframeUrlsFromDashboardType(data: {
    dashboardType: dashboardType;
  }) {
    try {
      const resultAxios = await this.#client.post(
        "/api/kpis",
        data,
        await this.#getHeaders(),
      );
      return resultAxios?.data;
    } catch (error) {
      console.error(error);
      return error;
    }
  }

  async generatePdfFromPlanning(data: any) {
    try {
      const resultAxios = await this.#client.post("/api/schedule/print", data, {
        ...(await this.#getHeaders()),
        responseType: "arraybuffer",
      });
      return resultAxios?.data;
    } catch (error) {
      console.error(error);
      return error;
    }
  }

  async getPositionShifts() {
    const resultAxios: any = await this.#client
      .get("/api/schedule/position-shifts", await this.#getHeaders())
      .then((response) => {
        return response;
      })
      .catch((error) => {
        const errorResult = {
          data: {
            data: error?.response?.data,
            status: error?.response?.status,
            statusText: error?.response?.statusText,
            type: "error",
          },
        };
        return errorResult;
      });
    return resultAxios?.data;
  }

  async getReportFromPlanningAuto(data: { querySlug: string }) {
    try {
      const resultAxios = await this.#client.post(
        "/api/schedule/report",
        data,
        await this.#getHeaders(),
      );
      return resultAxios?.data;
    } catch (error) {
      console.error(error);
      return error;
    }
  }

  async gsTestConnection(docId: string) {
    const resultAxios: any = await this.#client.get(
      `/api/gsheets/test_connection/${docId}`,
      await this.#getHeaders(),
    );
    return resultAxios?.data;
  }

  async ipCheck(email: string) {
    const resultAxios: any = await this.#client
      .post("/api/authentification-module/check-ip", {
        email,
      })
      .then((response) => {
        return response;
      })
      .catch((error) => {
        const errorResult = {
          data: {
            data: error?.response?.data,
            status: error?.response?.status,
            statusText: error?.response?.statusText,
            type: "error",
          },
        };
        return errorResult;
      });
    return resultAxios?.data;
  }

  async postGSData(data: any) {
    const resultAxios: any = await this.#client
      .post("/api/gsheets/data/", data, await this.#getHeaders()) // online
      .then((response: any) => {
        return response;
      })
      .catch((error: any) => {
        return {
          data: null,
          error: JSON.stringify(error),
        };
      });
    return resultAxios;
  }

  async postImportEmployees(data: any, mode = "full") {
    const resultAxios: any = await this.#client
      .post(`/api/employees_list?mode=${mode}`, data, await this.#getHeaders())
      .then((response) => {
        return response;
      })
      .catch((error) => {
        const errorResult = {
          data: {
            data: error?.response?.data,
            status: error?.response?.status,
            statusText: error?.response?.statusText,
            type: "error",
          },
        };
        return errorResult;
      });
    return resultAxios?.data;
  }

  async postImportHolidays(data: any) {
    const resultAxios: any = await this.#client
      .post("/api/planning/holidays/", data, await this.#getHeaders())
      .then((response) => {
        return response;
      })
      .catch((error) => {
        const errorResult = {
          data: {
            data: error?.response?.data,
            status: error?.response?.status,
            statusText: error?.response?.statusText,
            type: "error",
          },
        };
        return errorResult;
      });
    return resultAxios?.data;
  }

  async postImportHR4YOU(data: any) {
    const resultAxios: any = await this.#client
      .post("/api/import_hr4you", data, await this.#getHeaders())
      .then((response) => {
        return response;
      })
      .catch((error) => {
        const errorResult = {
          data: {
            data: error?.response?.data,
            status: error?.response?.status,
            statusText: error?.response?.statusText,
            type: "error",
          },
        };
        return errorResult;
      });
    return resultAxios?.data;
  }

  async postImportSAP(data: any) {
    const resultAxios: any = await this.#client
      .post("/api/import_sap", data, await this.#getHeaders())
      .then((response) => {
        return response;
      })
      .catch((error) => {
        const errorResult = {
          data: {
            data: error?.response?.data,
            status: error?.response?.status,
            statusText: error?.response?.statusText,
            type: "error",
          },
        };
        return errorResult;
      });
    return resultAxios?.data;
  }

  async postImportShifts(data: any) {
    const resultAxios: any = await this.#client
      .post("/api/imports/shifts/", data, await this.#getHeaders())
      .then((response) => {
        return response;
      })
      .catch((error) => {
        const errorResult = {
          data: {
            data: error?.response?.data,
            status: error?.response?.status,
            statusText: error?.response?.statusText,
            type: "error",
          },
        };
        return errorResult;
      });
    return resultAxios?.data;
  }

  async postImportWorkload(data: any) {
    const form = new FormData();
    form.append("file", data);
    form.append("worksheet", "Workload");
    return this.#client
      .post("/api/schedule/workload/import/", form, {
        headers: {
          ...(await this.#getHeaders()).headers.common,
          "content-type": "multipart/form-data",
        },
      })
      .then(() => ({ code: "OK" as const }))
      .catch((error) => {
        if (
          isAxiosError(error) &&
          error.response &&
          400 === error.response.status
        ) {
          // TODO: parse data
          return Array.isArray(error.response.data)
            ? {
                code: "VALIDATION_ERRORS" as const,
                errors: error.response.data,
              }
            : { code: "MISSING_FILE_OR_SHEETNAME" as const };
        }
        return { code: "ANYTHING_ELSE" as const };
      });
  }

  async postPdfBase64(data: any) {
    try {
      const resultAxios: any = await this.#client.post(
        "/api/pdf_base64/",
        data,
        await this.#getHeaders(),
      );
      return resultAxios?.data;
    } catch (error) {
      console.error(error);
    }
  }

  async recyclage() {
    const resultAxios: any = await this.#client
      .post("/api/recyclage", {}, await this.#getHeaders())
      .then((response) => {
        return response;
      })
      .catch((error) => {
        const errorResult = {
          data: {
            data: error?.response?.data,
            status: error?.response?.status,
            statusText: error?.response?.statusText,
            type: "error",
          },
        };
        return errorResult;
      });
    return resultAxios?.data;
  }

  async transcoder(data: any) {
    const resultAxios: any = await this.#client
      .post("/api/transcoder", data, await this.#getHeaders())
      .then((response) => {
        return response;
      })
      .catch((error) => {
        const errorResult = {
          data: {
            data: error?.response?.data,
            status: error?.response?.status,
            statusText: error?.response?.statusText,
            type: "error",
          },
        };
        return errorResult;
      });
    return resultAxios?.data;
  }

  async #getHeaders(): Promise<any> {
    const user = getAuth().currentUser;
    const token = await user?.getIdToken();
    this.#setUserTokenId(token);

    return {
      headers: {
        common: {
          Accept: "application/json",
          "Accept-Language": i18n.locale ?? "en",
          Authorization: `Bearer ${this.#userTokenId}`,
          "Cache-Control": "no-cache, no-store, must-revalidate",
          "Content-Type": "application/json",
          Pragma: "no-cache",
        },
      },
    };
  }

  async #handleRequest<Input = any, Output = any, ErrorData = any>(
    url: string,
    data: Input,
  ): Promise<Output | RequestError<ErrorData>> {
    try {
      const headers = await this.#getHeaders();
      const result = await this.#client.post(url, data, {
        ...headers,
        responseType: "blob",
      });
      return result.data;
    } catch (_) {
      const error = isAxiosError(_)
        ? {
            ..._.toJSON(),
            data: _.response?.data,
            error: "http" as const,
            status: _.response?.status,
            statusText: _.response?.statusText,
          }
        : {
            error: "unknown" as const,
            message: String(_),
          };

      console.error(error);

      return error;
    }
  }

  #setUserTokenId(userTokenId: string | undefined) {
    this.#userTokenId = userTokenId;
  }
}
