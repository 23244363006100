/* eslint-disable @typescript-eslint/no-floating-promises */
import type { DocumentData } from "firebase/firestore/lite";
import {
  find as _find,
  findIndex as _findIndex,
  remove as _remove,
  groupBy,
  isEqual,
  map,
  orderBy,
} from "lodash";
import { defineStore } from "pinia";
import { v1 as uuidv1 } from "uuid";

import APICloudRunClient from "@/api/cloudrun";
import { Employees } from "@/graphql/_Employees";
import { Clients } from "@/graphql/admin/Clients";
import { Factories } from "@/graphql/admin/Factories";
import { Sites } from "@/graphql/admin/Sites";
import { Teams } from "@/graphql/admin/Teams";
import { FilesAttachments } from "@/graphql/employees/FilesAttachment";
import { LClearances } from "@/graphql/employees/LClearance";
import { LRestrictions } from "@/graphql/employees/LRestriction";
import { LSkills } from "@/graphql/employees/LSkill";
import { LTrainings } from "@/graphql/employees/LTraining";
import { Clearances } from "@/graphql/library/Clearances";
import { Positions } from "@/graphql/library/Positions";
import { SkillLegends } from "@/graphql/library/SkillLegends";
import { Skills } from "@/graphql/library/Skills";
import { Subpositions } from "@/graphql/library/Subpositions";
import { Absences } from "@/graphql/settings/Absences";
import { Categories } from "@/graphql/settings/Categories";
import { Contracts } from "@/graphql/settings/Contracts";
import { ExternalTrainers } from "@/graphql/settings/ExternalTrainers";
import { Folders } from "@/graphql/settings/Folders";
import { Closures } from "@/graphql/settings/planning/closures";
import { Shifts } from "@/graphql/settings/planning/Shift";
import { Restrictions } from "@/graphql/settings/Restrictions";
import { Notes } from "@/graphql/team/Notes";
import { Users } from "@/graphql/Users";
import { usePlanningStore } from "@/pinia/features/planning";
import { useUndoActionStore } from "@/pinia/undoAction.store";
import { useAuthentificationStore } from "@/pinia/user";
import { setGlobalStoreGraphQL } from "@/utils/graphql.utils";
import { apolloProvider } from "@/vue-apollo";

import type { Func } from "../../utils/types.utils";
import { archiveSwitcher } from "../apollo/mutation/archive";
import { createManySwitcher, createSwitcher } from "../apollo/mutation/create";
import { deleteManySwitcher, deleteSwitcher } from "../apollo/mutation/delete";
import { unarchiveSwitcher } from "../apollo/mutation/unarchive";
import { updateManySwitcher, updateSwitcher } from "../apollo/mutation/update";
import {
  type Base,
  BaseStatus,
  Collections,
  EBaseStatus,
  EResolverName,
  type IUndoElement,
  undoActions,
  type undoGeneralActions,
} from "../interfaces";
import { createDictionary } from "../utils/dictionary";
import ClientHelper from "./ClientHelper";
import type { ISearchCriteria } from "./FirestoreHelper";

enum collectionsGraphQLPayload {
  clearances = "createClearance",
  clients = "createClient",
  employees = "createEmployee",
  external_trainers = "createExternalTrainer",
  factories = "createFactory",
  files_attachment = "createFilesAttachment",
  link_employee_clearances = "createLClearance",
  link_employee_restrictions = "createLRestriction",
  link_employee_skills = "createLSkill",
  link_trainings_employee = "createLTraining",
  notes = "createNote",
  parameters_absences = "createAbsence",
  parameters_closure = "createClosure",
  parameters_contract = "createContract",
  parameters_employee_categories = "createCategory",
  parameters_folders = "createFolder",
  parameters_planning = "createShift",
  parameters_restrictions = "createRestriction",
  position_order = "createPositionOrder",
  positions = "createPosition",
  positions_level = "createSubposition",
  sites = "createSite",
  skills = "create_Skill",
  skills_legends = "createSkillLegend",
  teams = "createTeam",
  users = "add_User",
}

enum collectionsResolver {
  clearances = "clearance",
  clients = "client",
  employees = "employee",
  external_trainers = "external_trainer",
  factories = "factory",
  files_attachment = "filesAttachment",
  link_employee_clearances = "lclearance",
  link_employee_planning = "cellResult",
  link_employee_restrictions = "lrestriction",
  link_employee_shifts = "cellShift",
  link_employee_skills = "lskill",
  link_employee_time_absences = "cellAbsence",
  link_teams_positions_closed = "lposition_closed",
  link_trainings_employee = "ltraining",
  notes = "note",
  parameters_absences = "absence",
  parameters_closure = "closure",
  parameters_contract = "contract",
  parameters_employee_categories = "category",
  parameters_folders = "folder",
  parameters_planning = "shift",
  parameters_restrictions = "restriction",
  position_order = "positionOrder",
  positions = "position",
  positions_level = "subposition",
  sites = "site",
  skills = "skill",
  skills_legends = "skillLegend",
  teams = "team",
  users = "user",
}

const buildQuerySlug = (data: any, optionQuerySlug: string[]) => {
  let slug = "";
  for (const querySlug of optionQuerySlug) {
    slug += data[querySlug];
  }
  return slug;
};

const buildQUerySlugMany = (
  datas: any[],
  optionQuerySlug: string[],
  optionquerySlugMonth: string[],
) => {
  return map(datas, (data) => {
    return {
      ...data,
      querySlug: buildQuerySlug(data, optionQuerySlug),
      querySlugMonth: buildQuerySlug(data, optionquerySlugMonth),
    };
  });
};

const stateBase = <G>() => {
  return {
    activesScoped: null as G[] | null,
    apolloCacheVariables: {},
    archivedScoped: [] as G[],
    deletedScoped: [] as G[],
    deleteQueueArray: [] as {
      id: string;
      lastActionSlug?: string;
      status: EBaseStatus;
    }[],
    documentsRealTimeScoped: null as G[] | null,
    documentsScoped: null as G[] | null,
    getData: false as boolean,
    hasRealtime: false as boolean,
    hasSubcribed: false as boolean,
    logs: {
      subscriberSearchCriterias: null as ISearchCriteria[] | null,
    },
    number_of_retries: 0,
    queryFetched: false as boolean,
    refetchNumber: 1,
    storeHasBeenFilled: false as boolean,
    subscriber: null as Func | null,
    undoArray: [] as { action: undoActions; id: string }[],
  };
};

const filledStoreIfNeeded = async (
  collection: string,
  store: any,
  fetchPolicy?: string,
  doReset = true,
) => {
  try {
    store.storeHasBeenFilled = true;
    const queryByCollectionName = {
      clearances: {
        loadAdmin: false,
        query: Clearances,
        queryName: "Clearances",
      },
      clients: {
        loadAdmin: true,
        query: Clients,
        queryName: "Clients",
      },
      employees: {
        loadAdmin: false,
        query: Employees,
        queryName: "Employees",
      },
      external_trainers: {
        loadAdmin: false,
        query: ExternalTrainers,
        queryName: "ExternalTrainers",
      },
      factories: {
        loadAdmin: true,
        query: Factories,
        queryName: "Factories",
      },
      files_attachment: {
        loadAdmin: false,
        query: FilesAttachments,
        queryName: "FilesAttachments",
      },
      link_employee_clearances: {
        loadAdmin: false,
        query: LClearances,
        queryName: "LClearances",
      },
      link_employee_restrictions: {
        loadAdmin: false,
        query: LRestrictions,
        queryName: "LRestrictions",
      },
      link_employee_skills: {
        loadAdmin: false,
        query: LSkills,
        queryName: "LSkills",
      },
      link_trainings_employee: {
        loadAdmin: false,
        query: LTrainings,
        queryName: "LTrainings",
      },
      notes: {
        loadAdmin: false,
        query: Notes,
        queryName: "Notes",
      },
      parameters_absences: {
        loadAdmin: false,
        query: Absences,
        queryName: "Absences",
      },
      parameters_closure: {
        loadAdmin: false,
        query: Closures,
        queryName: "Closures",
      },
      parameters_contract: {
        loadAdmin: false,
        query: Contracts,
        queryName: "Contracts",
      },
      parameters_employee_categories: {
        loadAdmin: false,
        query: Categories,
        queryName: "Categories",
      },
      parameters_folders: {
        loadAdmin: false,
        query: Folders,
        queryName: "Folders",
      },
      parameters_planning: {
        loadAdmin: false,
        query: Shifts,
        queryName: "Shifts",
      },
      parameters_restrictions: {
        loadAdmin: false,
        query: Restrictions,
        queryName: "Restrictions",
      },
      positions: {
        loadAdmin: false,
        query: Positions,
        queryName: "Positions",
      },
      positions_level: {
        loadAdmin: false,
        query: Subpositions,
        queryName: "Subpositions",
      },
      sites: {
        loadAdmin: true,
        query: Sites,
        queryName: "Sites",
      },
      skills: {
        loadAdmin: false,
        query: Skills,
        queryName: "Skills",
      },
      skills_legends: {
        loadAdmin: false,
        query: SkillLegends,
        queryName: "SkillLegends",
      },
      teams: {
        loadAdmin: true,
        query: Teams,
        queryName: "Teams",
      },
      users: {
        loadAdmin: true,
        query: Users,
        queryName: "Users",
      },
    };

    if (!queryByCollectionName[collection]) return;
    const user = useAuthentificationStore();
    if (
      user.data?.role === "ADMIN" &&
      !queryByCollectionName[collection].loadAdmin
    )
      return;

    if (doReset) {
      store.reset(false);
    }

    // Fill the store
    const queryObject: any = {
      query: queryByCollectionName[collection].query,
    };
    if (fetchPolicy) {
      queryObject.fetchPolicy = fetchPolicy;
    }
    const result = await apolloProvider.defaultClient.query(queryObject);
    let data = result.data[queryByCollectionName[collection].queryName];

    if (collection === "employees") {
      data = orderBy(data, ["last_name"], ["asc"]);
    }

    if (data) {
      setGlobalStoreGraphQL(store, data);
    }
  } catch (error: any) {
    if (store.number_of_retries <= 2) {
      setTimeout(() => {
        store.number_of_retries++;
        store.storeHasBeenFilled = false;
        store.queryFetched = false;
        filledStoreIfNeeded(collection, store);
      }, 1000);
    } else {
      store.number_of_retries = 0;
    }
    console.error(`GRAPHQL ERROR filledStoreIfNeeded ${collection}`, error);
  }
};

const debounce = <Args extends unknown[]>(
  fn: (...args: Args) => void,
  defaultInterval = 100,
) => {
  let timeout: null | ReturnType<typeof setTimeout> = null;
  return (interval = defaultInterval) =>
    (...args: Args) => {
      if (timeout) {
        clearTimeout(timeout);
      }
      timeout = setTimeout(() => {
        fn(...args);
      }, interval);
    };
};

export function defineStandard<T extends Base, I extends string>(
  id: I,
  collection: string,
  options?: {
    dictionary?: boolean;
    dictionaryActives?: boolean;
    linkEmployee?: boolean;
    orderBy?: string;
    querySlug?: string[];
    querySlugMonth?: string[];
  },
) {
  const client = new ClientHelper<T>(collection);
  const cloudRunClient = new APICloudRunClient();
  return defineStore(id, {
    actions: {
      // TODO Need to fix this any into <Omit<T, 'id'>
      async add(
        data: any,
        actionType: null | undoGeneralActions = null,
        fbPayload: any = null,
      ) {
        if (
          options?.querySlug &&
          options.querySlug.length > 0 &&
          options?.querySlugMonth &&
          options.querySlugMonth.length > 0
        ) {
          data.slug = buildQuerySlug(data, options.querySlug);
          data.querySlugMonth = buildQuerySlug(data, options.querySlugMonth);
        }
        let id;
        const resolverName = collection && collectionsResolver[collection];
        const payloadName = collection && collectionsGraphQLPayload[collection];
        if (resolverName) {
          const authPassword = data.password;
          delete data.password;
          const res: any = await createSwitcher(EResolverName[resolverName], {
            data,
            password: authPassword,
          });
          id = res?.data[payloadName]?.id;

          if (resolverName === EResolverName.user) {
            id = res?.data?.addUser?.id;

            if (fbPayload) {
              delete fbPayload.uid;
              await client.set(id, fbPayload);
            }
          }
        } else {
          const res = await client.add(data);
          id = res.id;
        }

        if (Object.keys(Collections).includes(collection)) {
          const undoElement: IUndoElement = {
            action: undoActions.delete,
            collection: Collections[collection],
            id: id,
            lastActionSlug: data.lastActionSlug ? data.lastActionSlug : "",
          };
          if (actionType || actionType === 0)
            undoElement.originalAction = actionType;
          this.updateUndoArray(undoElement);
        }

        return id;
      },
      async addMany(docs: any[], actionType: null | undoGeneralActions = null) {
        docs =
          options?.querySlug &&
          options.querySlug.length > 0 &&
          options?.querySlugMonth &&
          options.querySlugMonth.length > 0
            ? buildQUerySlugMany(
                docs,
                options.querySlug,
                options.querySlugMonth,
              )
            : docs;
        let response;
        const resolverName = collection && collectionsResolver[collection];
        if (resolverName) {
          if (
            resolverName === collectionsResolver.link_employee_planning ||
            resolverName === collectionsResolver.link_employee_time_absences ||
            resolverName === collectionsResolver.link_employee_shifts ||
            resolverName === collectionsResolver.link_teams_positions_closed
          ) {
            docs = docs.map((doc) => {
              delete doc.querySlugMonth;
              return { ...doc, id: doc.id ? doc.id : uuidv1() };
            });
            await createManySwitcher(EResolverName[resolverName], {
              data: docs,
            });
          } else {
            // eslint-disable-next-line @typescript-eslint/no-misused-promises
            docs.forEach(async (doc) => {
              delete doc?.querySlugMonth;
              doc.id = doc.id ? doc.id : uuidv1();
              await createSwitcher(EResolverName[resolverName], {
                data: doc,
              });
            });
          }
          response = { documents: docs };
        } else {
          response = await client.addMany(docs);
        }
        if (!response) return;

        for (const document of response.documents) {
          const undoPayload = this.createUndoPayloadFromDocumentData(
            document,
            undoActions.create,
            actionType,
          );
          this.updateUndoArray(undoPayload);
        }
        useUndoActionStore().disableUndo = false;
        return response?.documents;
      },
      async archive(data: any, id: string) {
        const resolverName = collection && collectionsResolver[collection];
        if (resolverName) {
          await archiveSwitcher(EResolverName[resolverName], {
            data,
            where: { id },
          });
        }
      },
      createUndoPayloadFromDocumentData(
        document: DocumentData,
        actionForTheUndo: undoActions,
        actionType: null | undoGeneralActions = null,
      ) {
        const undoElement: IUndoElement = {
          action: actionForTheUndo,
          collection: Collections[collection],
          id: document.id,
          lastActionSlug: document.lastActionSlug
            ? document.lastActionSlug
            : "",
        };
        if (actionType || actionType === 0)
          undoElement.originalAction = actionType;
        return undoElement;
      },
      async delete(id: string) {
        const resolverName = collection && collectionsResolver[collection];
        if (resolverName) {
          await deleteSwitcher(EResolverName[resolverName], {
            where: { id },
          });
        } else {
          await client.delete(id);
        }
        if (!this.hasRealtime && this.documentsScoped)
          _remove(this.documentsScoped, (d) => d.id === id);
      },
      async deleteMany(
        ids: string[],
        reallyDelete = true,
        lastActionSlug?: string,
        actionType: null | undoGeneralActions = null,
      ) {
        if (!reallyDelete && Object.keys(Collections).includes(collection)) {
          // const undoElement: IUndoElement = {
          const undoElements: IUndoElement[] = ids.map((id) => {
            const undoElement: IUndoElement = {
              action: undoActions.delete,
              collection: Collections[collection],
              id,
              lastActionSlug,
            };
            if (actionType || actionType === 0)
              undoElement.originalAction = actionType;
            return undoElement;
          });
          this.updateUndoArray(undoElements);
        }

        const resolverName = collection && collectionsResolver[collection];
        if (reallyDelete) {
          if (resolverName) {
            if (
              resolverName === collectionsResolver.link_employee_planning ||
              resolverName ===
                collectionsResolver.link_employee_time_absences ||
              resolverName === collectionsResolver.link_employee_shifts ||
              resolverName === collectionsResolver.link_teams_positions_closed
            ) {
              await deleteManySwitcher(EResolverName[resolverName], {
                where: { id: { in: ids } },
              });
            } else {
              const deleteMany = ids.map((id) => {
                deleteSwitcher(EResolverName[resolverName], {
                  where: {
                    id,
                  },
                });
              });
              Promise.all(deleteMany);
            }
          } else {
            await client.deleteMany(ids);
          }
        } else {
          const updatePayload = ids.map((id) => {
            return {
              id,
              status: "deleted",
            };
          });
          if (resolverName) {
            if (
              resolverName === collectionsResolver.link_employee_planning ||
              resolverName ===
                collectionsResolver.link_employee_time_absences ||
              resolverName === collectionsResolver.link_employee_shifts ||
              resolverName === collectionsResolver.link_teams_positions_closed
            ) {
              await updateManySwitcher(EResolverName[resolverName], {
                data: {
                  status: "deleted",
                },
                where: { id: { in: ids } },
              });
            } else {
              const updateMany = updatePayload.map((doc) => {
                updateSwitcher(EResolverName[resolverName], {
                  data: {
                    status: "deleted",
                  },
                  where: {
                    id: doc.id,
                  },
                });
              });
              Promise.all(updateMany);
            }
          } else {
            await client.updateMany(updatePayload);
          }
        }
        if (!this.hasRealtime) {
          // I would not recommend to use any methods here. Prefered way : Update the store after the promise deleteMany directly into the component or composable.
          // 1. this.getDocuments()
          // 2. this.getDocumentsWithWere()
          // 3. Local update
        }
      },
      async deleteQueue(
        id: string,
        lastActionSlug?: string,
        actionType: null | undoGeneralActions = null,
        setTimeoutCount = 300,
        isMonthView = false,
      ) {
        this.deleteQueueArray.push({
          id,
          lastActionSlug,
          status: EBaseStatus.deleted,
        });
        if (Object.keys(Collections).includes(collection)) {
          const undoElement: IUndoElement = {
            action: undoActions.delete,
            collection: Collections[collection],
            id,
            lastActionSlug,
          };
          if (actionType || actionType === 0)
            undoElement.originalAction = actionType;
          this.updateUndoArray(undoElement);
        }

        useUndoActionStore().disableUndo = true;

        if (this.deleteQueueArray.length) {
          const deletedQueueArray = [...this.deleteQueueArray];
          this.deleteQueueArray = [];
          try {
            const resolverName = collection && collectionsResolver[collection];
            if (resolverName) {
              const ids = deletedQueueArray.map((obj) => obj.id);
              const newData = {
                isMonthView:
                  EResolverName[resolverName] === EResolverName.cellResult
                    ? isMonthView
                    : undefined,
                lastActionSlug,
                status: EBaseStatus.deleted,
              };
              await updateManySwitcher(EResolverName[resolverName], {
                data: newData,
                where: { id: { in: ids } },
              });
              this.refetchPlanningStore(setTimeoutCount)();
              useUndoActionStore().disableUndo = false;
            } else {
              await client.updateMany(deletedQueueArray);
            }
          } catch {
            for (const toUpdate of deletedQueueArray) {
              try {
                await client.update(toUpdate.id, toUpdate as any);
              } catch {}
            }
          }
        }
      },
      async getDocuments() {
        this.documentsScoped = (await client.getDocuments()) as null | T[];
      },
      async getDocumentsWithWhere(
        searchCriteria: ISearchCriteria[],
        limit?: number,
        orderBy?: string,
      ) {
        let result: T[] = [];
        if (collection === "content") {
          const contentsResponse = await cloudRunClient.getContents();
          result = contentsResponse?.data ?? [];
        } else {
          result = await client.getDocumentsWithWhere(
            searchCriteria,
            limit,
            orderBy,
          );
        }

        this.documentsScoped = result as any;
        this.activesScoped = [];
        this.archivedScoped = [];
        this.deletedScoped = [];

        for (const document of this.documentsScoped || []) {
          if (
            document.status === BaseStatus.active ||
            document.status === undefined
          ) {
            this.activesScoped.push(document);
          }
          if (document.status === BaseStatus.archived) {
            this.archivedScoped.push(document);
          }
          if (
            document.status === BaseStatus.deleted ||
            document.status === "deleted"
          ) {
            this.deletedScoped.push(document);
          }
        }
        this.queryFetched = true;
      },
      async refetch(fetchPolicy?: any, doReset = true) {
        this.storeHasBeenFilled = false;
        this.queryFetched = false;
        await filledStoreIfNeeded(collection, this, fetchPolicy, doReset);
        this.storeHasBeenFilled = true;
        this.queryFetched = true;
      },
      refetchPlanningStore: debounce(
        () => (usePlanningStore().refetch = Date.now()),
      ),
      reset(setStoreHasBeenFilled = true) {
        this.documentsScoped = null;
        this.documentsRealTimeScoped = null;
        this.hasRealtime = false;
        this.hasSubcribed = false;
        this.logs = { subscriberSearchCriterias: null };
        this.getData = false;
        if (setStoreHasBeenFilled) {
          this.storeHasBeenFilled = false;
          this.queryFetched = false;
        }
      },
      async set(
        data: any,
        id: string,
        actionType: null | undoGeneralActions = null,
      ) {
        if (
          options?.querySlug &&
          options.querySlug.length > 0 &&
          options?.querySlugMonth &&
          options.querySlugMonth.length > 0
        ) {
          data.querySlug = buildQuerySlug(data, options.querySlug);
          data.querySlugMonth = buildQuerySlug(data, options.querySlugMonth);
        }
        await client.set(id, data);
        if (Object.keys(Collections).includes(collection)) {
          const undoElement: IUndoElement = {
            action: undoActions.create,
            collection: Collections[collection],
            id: id,
            lastActionSlug: data.lastActionSlug ? data.lastActionSlug : "",
          };
          if (actionType || actionType === 0)
            undoElement.originalAction = actionType;
          this.updateUndoArray(undoElement);
        }
        if (!this.hasRealtime) this.documentsScoped?.push(data);
      },
      subscribe(
        searchCriteria: ISearchCriteria[],
        limit?: number,
        orderBy?: string,
      ) {
        if (this.subscriber) return;
        this.hasRealtime = true;
        this.logs.subscriberSearchCriterias = searchCriteria;
        this.subscriber = client.getRealTimeWithWhere(
          searchCriteria,
          {
            queue: this.deleteQueueArray,
            update: (
              documents: T[],
              actives: T[],
              archived: T[],
              deleted: T[],
            ) => {
              // Update Realtime
              this.documentsRealTimeScoped = documents;
              // Update Non Realtime
              this.documentsScoped = documents;
              // Tell the store that subscriber is over
              this.hasSubcribed = true;
              this.activesScoped = actives;
              this.archivedScoped = archived;
              this.deletedScoped = deleted;
              this.storeHasBeenFilled = true;
              this.queryFetched = true;
            },
          },
          limit,
          orderBy,
        );
      },
      subscribeSilent(
        searchCriteria: ISearchCriteria[],
        limit?: number,
        orderBy?: string,
      ) {
        if (this.subscriber) return;
        this.logs.subscriberSearchCriterias = searchCriteria;
        this.subscriber = client.getRealTimeWithWhere(
          searchCriteria,
          {
            queue: this.deleteQueueArray,
            update: (
              documents: T[],
              actives: T[],
              archived: T[],
              deleted: T[],
            ) => {
              // Check if payload has changed
              if (!isEqual(documents, this.documents) && this.getData) {
                // Check if current week and year are corrects.
                const doc: any = documents[0];
                const PlanningStore = usePlanningStore();
                if (
                  Number(doc.week) === Number(PlanningStore.selected.week) &&
                  (Number(doc.year) === Number(PlanningStore.selected.year) ||
                    Number(doc.weekBeginsOnYear) ===
                      Number(PlanningStore.selected.weekBeginsOnYear))
                ) {
                  this.documentsScoped = documents;
                  this.activesScoped = actives;
                  this.archivedScoped = archived;
                  this.deletedScoped = deleted;
                }
              } else {
                this.getData = true;
              }
            },
          },
          limit,
          orderBy,
        );
      },
      async unarchive(id: string) {
        const resolverName = collection && collectionsResolver[collection];
        if (resolverName) {
          await unarchiveSwitcher(EResolverName[resolverName], {
            data: { status: "active" },
            where: { id },
          });
        }
      },
      unsubscribe() {
        if (!this.subscriber) return;
        this.subscriber();
        this.subscriber = null;
      },
      async update(
        data: any,
        id: string,
        _local = true,
        fbPayload: any = null,
      ) {
        if (
          options?.querySlug &&
          options.querySlug.length > 0 &&
          options?.querySlugMonth &&
          options.querySlugMonth.length > 0
        ) {
          data.querySlug = buildQuerySlug(data, options.querySlug);
          data.querySlugMonth = buildQuerySlug(data, options.querySlugMonth);
        }
        const resolverName = collection && collectionsResolver[collection];
        if (resolverName) {
          await updateSwitcher(EResolverName[resolverName], {
            data,
            where: { id },
          });
          if (resolverName === EResolverName.user) {
            try {
              const clientPaylaod = {};
              if (fbPayload) {
                delete fbPayload.uid;
                await client.set(id, fbPayload);
              } else {
                for (const prop in data) {
                  if (data[prop]) {
                    if (prop === "client") {
                      clientPaylaod.client_id = data[prop].connect.id;
                      continue;
                    }
                    if (prop === "site") {
                      clientPaylaod.site_id = data[prop].connect.id;
                      continue;
                    }
                    if (prop === "rights") {
                      clientPaylaod.rights_ids = data[prop].connect.map(
                        (i) => i.id,
                      );
                      continue;
                    }
                    clientPaylaod[prop] = data[prop].set;
                  }
                }
                await client.set(id, clientPaylaod);
              }
            } catch (error) {
              console.error(error);
            }
          }
        } else {
          await client.update(id, data);
        }
        if (!this.hasRealtime) {
          const oldDocument = _find(this.documentsScoped, ["id", id]);
          if (!oldDocument) return;
          const index = _findIndex(this.documentsScoped, ["id", id]);
          this.documentsScoped?.splice(index, 1, {
            ...oldDocument,
            ...data,
          });
        }
      },
      async updateMany(docs: T[], useQuerySlug = true) {
        if (useQuerySlug) {
          docs =
            options?.querySlug &&
            options.querySlug.length > 0 &&
            options?.querySlugMonth &&
            options.querySlugMonth.length > 0
              ? buildQUerySlugMany(
                  docs,
                  options.querySlug,
                  options.querySlugMonth,
                )
              : docs;
        }
        const resolverName = collection && collectionsResolver[collection];
        if (resolverName) {
          for (const doc of docs) {
            const id = doc.id;
            // @ts-expect-error automatically added
            delete doc.id;
            await updateSwitcher(EResolverName[resolverName], {
              data: doc,
              where: { id },
            });
          }
        } else {
          await client.updateMany(docs);
        }
      },
      updateUndoArray(undoElement: IUndoElement | IUndoElement[]) {
        const undoActionStore = useUndoActionStore();
        undoActionStore.updateUndoArray(undoElement);
      },
    },
    getters: {
      actives(): null | T[] {
        if (!this.storeHasBeenFilled) {
          filledStoreIfNeeded(collection, this);
        }
        return this.activesScoped as null | T[];
      },
      archived(): any[] {
        if (!this.storeHasBeenFilled) {
          filledStoreIfNeeded(collection, this);
        }
        return this.archivedScoped;
      },
      deleted(): any[] {
        if (!this.storeHasBeenFilled) {
          filledStoreIfNeeded(collection, this);
        }
        return this.deletedScoped;
      },
      dictionary() {
        if (!this.storeHasBeenFilled) {
          this.storeHasBeenFilled = true;
          filledStoreIfNeeded(collection, this);
        }
        if (options?.dictionary) {
          const list: T[] = this.list;
          return createDictionary<T>(list);
        }
        return null;
      },
      dictionaryActives() {
        if (!this.storeHasBeenFilled) {
          this.storeHasBeenFilled = true;
          filledStoreIfNeeded(collection, this);
        }
        if (options?.dictionaryActives) {
          const actives: T[] = this.activesScoped as T[];
          return createDictionary<T>(actives);
        }
        return null;
      },
      dictionaryByEmployees() {
        if (!this.storeHasBeenFilled) {
          this.storeHasBeenFilled = true;
          filledStoreIfNeeded(collection, this);
        }
        if (!this.isLinkEmployee) return {};
        const actives: T[] = this.activesScoped as T[];
        return groupBy(actives, "employee_id");
      },
      documents(): null | T[] {
        if (!this.storeHasBeenFilled) {
          filledStoreIfNeeded(collection, this);
        }
        return this.documentsScoped as null | T[];
      },
      documentsRealTime(): null | T[] {
        if (!this.storeHasBeenFilled) {
          filledStoreIfNeeded(collection, this);
        }
        return this.documentsRealTimeScoped as null | T[];
      },
      isLinkEmployee(): boolean {
        if (!this.storeHasBeenFilled) {
          this.storeHasBeenFilled = true;
          filledStoreIfNeeded(collection, this);
        }
        if (options?.linkEmployee) {
          return true;
        }
        return false;
      },
      list(): T[] {
        let list: T[];
        if (!this.hasRealtime && this.documents) {
          list = this.documents;
        } else if (this.documentsRealTime) {
          list = this.documentsRealTime;
        } else {
          list = [];
        }
        if (options?.orderBy) {
          list = orderBy(list, [options.orderBy], ["asc"]);
        }
        return list;
      },
    },
    state: () => stateBase<T>(),
  });
}
