import {
  CreateTeam,
  DeleteManyTeam,
  DeleteTeam,
  UpdateTeam,
} from "@/graphql/admin/Teams";
import { useTeamGlobalStore } from "@/pinia/collections";
import { apolloProvider } from "@/vue-apollo";

const refetch = async () => useTeamGlobalStore().refetch("no-cache", false);

export const createTeam = async (payload: { data: unknown }) => {
  try {
    const result = await apolloProvider.defaultClient.mutate({
      mutation: CreateTeam,
      variables: {
        data: payload.data,
      },
    });
    await refetch();
    return result;
  } catch (error: unknown) {
    console.error("GRAPHQL ERROR", error);
  }
};

export const updateTeam = async (payload: {
  data: unknown;
  where: unknown;
}) => {
  try {
    const result = await apolloProvider.defaultClient.mutate({
      mutation: UpdateTeam,
      variables: {
        data: payload.data,
        where: payload.where,
      },
    });
    await refetch();
    return result;
  } catch (error: unknown) {
    console.error("GRAPHQL ERROR", error);
  }
};

export const deleteTeam = async (payload: { where: unknown }) => {
  try {
    const result = await apolloProvider.defaultClient.mutate({
      mutation: DeleteTeam,
      variables: {
        where: payload.where,
      },
    });
    await refetch();
    return result;
  } catch (error: unknown) {
    console.error("GRAPHQL ERROR", error);
  }
};

export const archiveTeam = async (payload: {
  data: unknown;
  where: unknown;
}) => {
  try {
    const result = await apolloProvider.defaultClient.mutate({
      mutation: UpdateTeam,
      variables: {
        data: payload.data,
        where: payload.where,
      },
    });
    await refetch();
    return result;
  } catch (error: unknown) {
    console.error("GRAPHQL ERROR", error);
  }
};

export const deleteManyTeam = async (payload: { where: unknown }) => {
  try {
    const result = await apolloProvider.defaultClient.mutate({
      mutation: DeleteManyTeam,
      variables: {
        where: payload.where,
      },
    });
    await refetch();
    return result;
  } catch (error: unknown) {
    console.error("GRAPHQL ERROR", error);
  }
};
