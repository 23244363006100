import {
  CellResult,
  CellResultCreate,
  CellResultCreateMany,
  CellResultDelete,
  CellResults,
  CellResultUpdate,
  CellResultUpdateMany,
} from "@/graphql/planning/CellResults";
import { usePlanningStore } from "@/pinia/features/planning";
import { usePlanningFastStore } from "@/pinia/features/planning-fast";
import { apolloProvider } from "@/vue-apollo";

export const deleteCellResult = async (payload: { where: any }) => {
  try {
    await apolloProvider.defaultClient.mutate({
      mutation: CellResultDelete,
      update: (store, data) => {
        updateStoreAfterDeleteCellResult(store, data.data.DeleteCellresult.id);
      },
      variables: {
        where: payload.where,
      },
    });
  } catch (error) {
    console.error(error);
  }
};

export const updateCellResult = async (payload: { data: any; where: any }) => {
  try {
    await apolloProvider.defaultClient.mutate({
      mutation: CellResultUpdate,
      update: (store, data) => {
        updateStoreAfterUpdateCellResult(store, data.data.updateCellresult);
      },
      variables: {
        data: payload.data,
        where: payload.where,
      },
    });
  } catch (error) {
    console.error(error);
  }
};

export const createCellResult = async (payload: { data: any }) => {
  try {
    return await apolloProvider.defaultClient.mutate({
      mutation: CellResultCreate,
      update: (store, { data: { createCellresult } }) => {
        updateStoreAfterCreateCellResult(store, createCellresult);
      },
      variables: {
        data: payload.data,
      },
    });
  } catch (error) {
    console.error(error);
  }
};

export const createManyCellResult = async (payload: { data: any }) => {
  try {
    return await apolloProvider.defaultClient.mutate({
      mutation: CellResultCreateMany,
      variables: {
        data: payload.data,
      },
    });
  } catch (error) {
    console.error(error);
  }
};

export const updateManyCellResult = async (payload: {
  data: any;
  where: any;
}) => {
  try {
    return await apolloProvider.defaultClient.mutate({
      mutation: CellResultUpdateMany,
      variables: {
        data: payload.data,
        where: payload.where,
      },
    });
  } catch (error) {
    console.error(error);
  }
};

export const readCellResult = async (payload: { where: any }) => {
  try {
    return await apolloProvider.defaultClient.query({
      query: CellResult,
      variables: {
        where: payload.where,
      },
    });
  } catch (error) {
    console.error(error);
  }
};

const updateStoreAfterCreateCellResult = (store) => {
  const data = store.readQuery({
    query: CellResults,
    variables: usePlanningStore().apolloCacheVariables.cellResults,
  });
  store.writeQuery({
    data,
    query: CellResults,
  });
};

const updateStoreAfterDeleteCellResult = (store, id) => {
  const planningStore = usePlanningStore();
  const data = store.readQuery({
    query: CellResults,
    variables: planningStore.apolloCacheVariables.cellResults,
  });
  const index = data.CellResults.cells.findIndex((c) => c.id === id);
  if (index !== -1) {
    data.CellResults.cells.splice(index, 1);
    usePlanningFastStore().cells = data.CellResults.cells;
    store.writeQuery({
      data,
      query: CellResults,
    });
  }
};

const updateStoreAfterUpdateCellResult = (store, updateCellResults) => {
  const data = store.readQuery({
    query: CellResults,
    variables: usePlanningStore().apolloCacheVariables.cellResults,
  });
  const index = data.CellResults.cells.findIndex(
    (c) => c.id === updateCellResults.id,
  );
  if (index !== -1) {
    if (updateCellResults.status === "active") {
      data.CellResults.cells.splice(index, 1, updateCellResults);
    } else {
      data.CellResults.cells.splice(index, 1);
    }
    store.writeQuery({
      data,
      query: CellResults,
    });
  }
};
