const childrenQuestionnaire = [
  {
    component: () =>
      import(
        "@/views/questionnaire/questionnaireCreation/QuestionnaireCreationQuestions",
      ),
    meta: {
      hideFooter: true,
      hideNavigation: true,
      requiresAuth: true,
    },
    name: "questionnaireCreationQuestions",
    path: "questions",
  },
  {
    component: () =>
      import(
        "@/views/questionnaire/questionnaireCreation/QuestionnaireCreationChecklist.vue",
      ),
    meta: {
      hideFooter: true,
      hideNavigation: true,
      requiresAuth: true,
    },
    name: "questionnaireCreationChecklist",
    path: "checklist",
  },
  {
    component: () =>
      import(
        "@/views/questionnaire/questionnaireDisplay/QuestionnaireCreationPreview",
      ),
    meta: {
      hideFooter: true,
      hideNavigation: true,
      requiresAuth: true,
    },
    name: "questionnaireCreationPreview",
    path: "preview",
  },
  {
    component: () =>
      import(
        "@/views/questionnaire/questionnaireCreation/QuestionnaireCreationInformations",
      ),
    meta: {
      hideFooter: true,
      hideNavigation: true,
      requiresAuth: true,
    },
    name: "questionnaireCreationInformations",
    path: "informations",
  },
];

export default childrenQuestionnaire;
