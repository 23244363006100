import type { VueConstructor } from "vue";
import type { DirectiveOptions } from "vue/types/umd";

class CustomDirective {
  public directiveName: string;
  public directiveOptions: DirectiveOptions;

  constructor(directiveName: string, directiveOptions: DirectiveOptions) {
    this.directiveName = directiveName;
    this.directiveOptions = directiveOptions;
  }

  applyTo(appInstance: VueConstructor) {
    appInstance.directive(this.directiveName, this.directiveOptions);
  }
}

export default CustomDirective;
