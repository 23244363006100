import { ESectionName } from "@/modules/rights/types";

const absenceChildrenFactory = [
  {
    component: () => import("@/views/factory/absences/AbsencesList.vue"),
    meta: {
      requiresAuth: true,
      userRightsSection: ESectionName.Enum.team_absences,
    },
    name: "absences-list",
    path: "list",
  },
  {
    component: () => import("@/views/factory/absences/AbsencesTable.vue"),
    meta: {
      requiresAuth: true,
      userRightsSection: ESectionName.Enum.team_absences,
    },
    name: "factory-absences",
    path: "table",
  },
];
export const absenceChildrenTeam = [
  {
    component: () => import("@/views/factory/absences/AbsencesList.vue"),
    meta: {
      requiresAuth: true,
      userRightsSection: ESectionName.Enum.team_absences,
    },
    name: "team-absences-list",
    path: "list",
  },
  {
    component: () => import("@/views/factory/absences/AbsencesTable.vue"),
    meta: {
      requiresAuth: true,
      userRightsSection: ESectionName.Enum.team_absences,
    },
    name: "team-absences",
    path: "table",
  },
];

const factoryChildren = [
  {
    component: () => import("@/views/factory/FactoryTeams.vue"),
    name: "factory-teams",
    path: "teams",
  },
  {
    children: absenceChildrenFactory,
    component: () => import("@/views/factory/Absences.vue"),
    meta: {
      requiresAuth: true,
      userRightsSection: ESectionName.Enum.team_absences,
    },
    name: "absences",
    path: "absences",
  },
];

export default factoryChildren;
