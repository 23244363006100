import { getDownloadURL, getStorage, ref } from "firebase/storage";
import type Vue from "vue";

import db from "@/firebase/db";

import DbHelper from "../dbHelper";
import EmployeeHelper from "../employeeHelper";

/* Pictures */
async function getEmployeePicture(employeeId: string, clientSlug: string) {
  let employeepictureURL = "";
  const picturePath = `clients/${clientSlug}/employees/${employeeId}.jpg`;
  const storage = getStorage();
  const storageRef = ref(storage, picturePath);
  await getDownloadURL(storageRef)
    .then((url) => {
      employeepictureURL = url;
    })
    .catch((error: unknown) => {
      console.error(error);
    });
  return employeepictureURL;
}

async function updatePicture(employeeId: string, employeePictureURL: string) {
  const dbHelper = new DbHelper(db);
  await dbHelper.updateDataToCollection("employees", employeeId, {
    picture_url: employeePictureURL,
  });
}

export const matchEmployeePictureFromFirebaseStorage = async (
  siteId: string,
) => {
  let clientSlug = "";
  const dbHelper = new DbHelper(db);
  const site = await dbHelper.getDocFromCollection("sites", siteId);
  if (site) {
    const client = await dbHelper.getDocFromCollection(
      "clients",
      site.client_id,
    );
    clientSlug = client.slug;
  }
  const employeeHelper = new EmployeeHelper(db);
  const teams = await employeeHelper.getTeamsFromSiteId(siteId);
  let employees: {
    employee_company_id: string;
    id: string;
    picture_url: string;
  }[] = [];
  for (const team of teams) {
    const emp = await employeeHelper.getEmployeesFromTeamId(team.id);
    employees = employees.concat(emp);
  }
  for (const employee of employees) {
    if (!employee.picture_url) {
      const employeePictureURL = await getEmployeePicture(
        employee.employee_company_id,
        clientSlug,
      );
      employee.picture_url = employeePictureURL;
      await updatePicture(employee.id, employeePictureURL);
    }
  }
  return employees;
};
/* End of pictures */

export const stringToColor = (string: string) => {
  let hash = 0;
  let i: number;
  for (i = 0; i < string?.length || 0; i += 1) {
    hash = string.charCodeAt(i) + ((hash << 5) - hash);
  }

  let color = "#";

  for (i = 0; i < 3; i += 1) {
    const value = (hash >> (i * 8)) & 0xff;
    color += `00${value.toString(16)}`.slice(-2);
  }

  return color;
};

// Get Translation
export const getT = (t: null | Vue, id: string) => {
  let translation = "";
  if (id) {
    if (t?.$root?.$t) {
      if (t.$root.$t(id)) {
        translation = String(t.$root.$t(id));
      }
    }
  }
  return translation;
};

export const normalizeString = (
  s: string,
  transform = (s: string) => s.toLocaleLowerCase(),
) => {
  const normalized = transform(s);

  return normalized.normalize("NFD").replace(/[\u0300-\u036f]/g, "");
};

export const datesAreOnSameDay = (first: Date, second: Date) =>
  first.getMonth() === second.getMonth() &&
  first.getDate() === second.getDate();

export const removeChars = (text: string) => {
  let result = "";
  if (text) {
    result = text
      .replace("á", "a")
      .replace("à", "a")
      .replace("â", "a")
      .replace("ä", "a")
      .replace("é", "e")
      .replace("è", "e")
      .replace("ê", "e")
      .replace("ë", "e")
      .replace("ì", "i")
      .replace("í", "i")
      .replace("î", "i")
      .replace("ï", "i")
      .replace("ó", "o")
      .replace("ò", "o")
      .replace("ö", "o")
      .replace("ô", "o")
      .replace("ú", "u")
      .replace("ù", "u")
      .replace("ü", "u")
      .replace("û", "u")
      .replace("ç", "c");
  }
  return result;
};

export const linkify = (text: string) => {
  const urlPattern =
    /(?:https?:)?\/\/(?:(?:[\w-]+\.)+[\w/#@~.-]*)(?:\?(?:[\w&=.!,;$#%-]+)?)?/gi;
  return (text || "").replace(
    urlPattern,
    (url) => `<a target="_BLANK" href="${url}">${url}</a>`,
  );
};

export const truncate = (str: string, max: number, sep: string) => {
  // Default to 10 characters
  max = max || 10;

  const len = str?.length;
  if (len > max) {
    // Default to elipsis
    sep = sep || "...";

    const seplen = sep.length;

    // If seperator is larger than character limit,
    // well then we don't want to just show the seperator,
    // so just show right hand side of the string.
    if (seplen > max) {
      return str.slice(len - max);
    }

    // Half the difference between max and string length.
    // Multiply negative because small minus big.
    // Must account for length of separator too.
    const n = -0.5 * (max - len - seplen);

    // This gives us the centerline.
    const center = len / 2;

    const front = str.slice(0, center - n);
    const back = str.slice(len - center + n); // without second arg, will automatically go to end of line.

    return front + sep + back;
  }

  return str;
};

export const getRightWhere = (user: {
  client_id?: number;
  site_id?: number;
}) => {
  const whereConditions: { client_id?: number; site_id?: number } = {
    client_id: user?.client_id,
  };
  if (user?.site_id) {
    whereConditions.site_id = user?.site_id;
  }
  return whereConditions;
};
