import type { Dictionary } from "vue-router/types/router";

import { defineStore } from "pinia";

import type { Result } from "@/tscript/logic/planningBuilder";

import router from "@/router";
import { PositionAlignement } from "@/tscript/logic/alignement/PositionAlignement.class";
import {
  type IAbsenceDataDictionary,
  type IPlanningDataDictionary,
  type IShiftDataDictionary,
  planningAligment,
} from "@/tscript/logic/planningAlignment";
import { planningAligmentMonth } from "@/tscript/logic/planningAlignmentMonth";

export const usePlanningAligmentStore = defineStore("usePlanningAligment", {
  actions: {
    reset() {
      this.allDataByPosition = {};
      this.allDataByEmployee = {};
      this.allDataByEmloyeeAbsence = {};
      this.allShiftByEmployee = {};
      this.statPlanningByEmployeeRow = {};
      this.compactPlanningByEmployeeLine = {};
      this.compactPlanningRow = {};
      this.compactPlanningByPositionLine = {};
      this.compactPlanningByPosAndSubpos = {};
    },
    setData(
      cells: Result["cells"],
      absences: Result["absences"],
      shifts: Result["shifts"],
    ) {
      this.reset();

      let planningAligmentRes: any;

      if (router.currentRoute.name === "PlanningMonthPosition") {
        let positionAlignement: null | PositionAlignement =
          new PositionAlignement(cells);
        planningAligmentRes = positionAlignement.getPlanningPayload();

        const {
          allDataByPosition,
          compactPlanningByPosAndSubpos,
          compactPlanningByPositionLine,
          compactPlanningRow,
        } = planningAligmentRes;

        this.allDataByPosition = allDataByPosition;
        this.compactPlanningRow = compactPlanningRow;
        this.compactPlanningByPositionLine = compactPlanningByPositionLine;
        this.compactPlanningByPosAndSubpos = compactPlanningByPosAndSubpos;

        positionAlignement = null;
      } else if (router.currentRoute.name === "PlanningMonthEmployee") {
        planningAligmentRes = planningAligmentMonth(cells, absences, shifts);

        const {
          allDataByEmloyeeAbsence,
          allDataByEmployee,
          allShiftByEmployee,
          compactPlanningByEmployeeLine,
          compactPlanningRow,
          statPlanningByEmployeeRow,
        } = planningAligmentRes;

        this.allDataByEmployee = allDataByEmployee;
        this.allDataByEmloyeeAbsence = allDataByEmloyeeAbsence;
        this.allShiftByEmployee = allShiftByEmployee;
        this.statPlanningByEmployeeRow = statPlanningByEmployeeRow;
        this.compactPlanningByEmployeeLine = compactPlanningByEmployeeLine;
        this.compactPlanningRow = compactPlanningRow;
      } else {
        planningAligmentRes = planningAligment(
          cells,
          absences,
          shifts,
          this.removeDoublonShift,
        );

        const {
          allDataByEmloyeeAbsence,
          allDataByEmployee,
          allDataByPosition,
          allShiftByEmployee,
          compactPlanningByEmployeeLine,
          compactPlanningByPosAndSubpos,
          compactPlanningByPositionLine,
          compactPlanningRow,
          statPlanningByEmployeeRow,
        } = planningAligmentRes;

        this.allDataByPosition = allDataByPosition;
        this.allDataByEmployee = allDataByEmployee;
        this.allDataByEmloyeeAbsence = allDataByEmloyeeAbsence;
        this.allShiftByEmployee = allShiftByEmployee;
        this.statPlanningByEmployeeRow = statPlanningByEmployeeRow;
        this.compactPlanningByEmployeeLine = compactPlanningByEmployeeLine;
        this.compactPlanningRow = compactPlanningRow;
        this.compactPlanningByPositionLine = compactPlanningByPositionLine;
        this.compactPlanningByPosAndSubpos = compactPlanningByPosAndSubpos;
      }
    },
  },
  state: () => {
    return {
      allDataByEmloyeeAbsence: {} as IAbsenceDataDictionary,
      allDataByEmployee: {} as IPlanningDataDictionary,
      allDataByPosition: {} as IPlanningDataDictionary,
      allShiftByEmployee: {} as IShiftDataDictionary,
      compactPlanningByEmployeeLine: {} as Dictionary<number>,
      compactPlanningByPosAndSubpos: {} as Dictionary<any>,
      compactPlanningByPositionLine: {} as Dictionary<number>,
      compactPlanningRow: {} as Dictionary<number>,
      removeDoublonShift: false as boolean,
      // statPlanningByEmployeeLine: {} as Dictionary<number>,
      statPlanningByEmployeeRow: {} as Dictionary<number>,
    };
  },
});
