import { gql } from "graphql-tag";

export const CellResult = gql`
  query Cellresult($where: LPlanningWhereUniqueInput) {
    Cellresult(where: $where) {
      id
      team_id
      day
      day_nb
      employee_id
      shift_id
      position_id
      subposition_id
      category_id
      parentID
      status
      isTomorrow
      tag_temp
      time {
        startTimeNumber
        endTimeNumber
        isFullDay
        totalTime
        startTime
        endTime
        endsTomorrowAt
        totalTimeNumberInMinutes
      }
      isExternalTeam
      warning {
        color
        tooltips {
          skills_missing
          clearances_missing
          skills_expired
          clearances_expired
          wrong_shift
          already_assigned
          has_restrictions
          restricted_positions
          warning_hours_worked
          previous_assignation
          rest_time
          otherAssignations
        }
        count
        workload
      }
      isInTraining {
        color
        onGoing
        hasExpired
        willExpired
        nameOnGoing
        nameExpired
        nameWillExpired
      }
      contract_id
      employee {
        status
        full_name
        first_name
        last_name
        avatar_url
        clearance_ids
        skill_ids
      }
      shift {
        bgColor
        textColor
        name
      }
      positionName
      subpositionName
      skill_ids
      clearance_ids
      restriction_ids
      isExternalAssignement
      isAbsent {
        id
        employee_id
        day_nb
        time {
          startTimeNumber
          endTimeNumber
          totalTimeNumber
          totalTimeNumberInMinutes
          startTime
          endTime
          totalTime
          isFullDay
        }
        day
        reason
        isAbsence
      }
    }
  }
`;

export const CellResults = gql`
  query CellResults($where: LPlanningWhereInput, $team_id: String) {
    CellResults(where: $where, team_id: $team_id) {
      subpositionIds
      positionIds
      employeeIds
      cells {
        id
        team_id
        date
        day
        day_nb
        employee_id
        shift_id
        position_id
        subposition_id
        category_id
        parentID
        tomorrowID
        status
        isTomorrow
        lastActionSlug
        tag_temp
        time {
          startTimeNumber
          endTimeNumber
          isFullDay
          totalTime
          startTime
          endTime
          endsTomorrowAt
          totalTimeNumberInMinutes
        }
        isExternalTeam
        warning {
          color
          tooltips {
            skills_missing
            clearances_missing
            skills_expired
            clearances_expired
            wrong_shift
            already_assigned
            has_restrictions
            restricted_positions
            warning_hours_worked
            previous_assignation
            rest_time
            otherAssignations
          }
          count
          workload
        }
        isAbsent {
          id
          employee_id
          day_nb
          time {
            startTimeNumber
            endTimeNumber
            totalTimeNumber
            totalTimeNumberInMinutes
            startTime
            endTime
            totalTime
            isFullDay
          }
          day
          reason
          isAbsence
        }
        isInTraining {
          color
          onGoing
          hasExpired
          willExpired
          nameOnGoing
          nameExpired
          nameWillExpired
        }
        isTrainer {
          skill_names
        }
        contract_id
        employee {
          status
          full_name
          first_name
          last_name
          avatar_url
          clearance_ids
          skill_ids
        }
        shift {
          bgColor
          textColor
          name
        }
        positionName
        subpositionName
        skill_ids
        clearance_ids
        restriction_ids
        isExternalAssignement
        groupSlug
        groupSize
      }
    }
  }
`;

export const CellResultCreate = gql`
  mutation CreateCellresult($data: LPlanningCreateInput!) {
    createCellresult(data: $data) {
      id
      team_id
      date
      day
      day_nb
      employee_id
      shift_id
      position_id
      subposition_id
      category_id
      parentID
      status
      isTomorrow
      lastActionSlug
      tag_temp
      time {
        startTimeNumber
        endTimeNumber
        isFullDay
        totalTime
        startTime
        endTime
        endsTomorrowAt
        totalTimeNumberInMinutes
      }
      isExternalTeam
      warning {
        color
        tooltips {
          skills_missing
          clearances_missing
          skills_expired
          clearances_expired
          wrong_shift
          already_assigned
          has_restrictions
          restricted_positions
          warning_hours_worked
          previous_assignation
          rest_time
          otherAssignations
        }
        count
        workload
      }
      isAbsent {
        id
        employee_id
        day_nb
        time {
          startTimeNumber
          endTimeNumber
          totalTimeNumber
          totalTimeNumberInMinutes
          startTime
          endTime
          totalTime
          isFullDay
        }
        day
        reason
        isAbsence
      }
      contract_id
      employee {
        status
        full_name
        first_name
        last_name
        avatar_url
        clearance_ids
        skill_ids
      }
      shift {
        bgColor
        textColor
        name
      }
      positionName
      subpositionName
      skill_ids
      clearance_ids
      restriction_ids
      isExternalAssignement
    }
  }
`;

export const CellResultDelete = gql`
  mutation DeleteCellresult($where: LPlanningWhereUniqueInput!) {
    deleteCellresult(where: $where) {
      id
    }
  }
`;

export const CellResultUpdate = gql`
  mutation UpdateCellresult(
    $where: LPlanningWhereUniqueInput!
    $data: LPlanningUpdateInput!
  ) {
    updateCellresult(where: $where, data: $data) {
      id
      team_id
      date
      day
      day_nb
      employee_id
      shift_id
      position_id
      subposition_id
      category_id
      parentID
      status
      lastActionSlug
      tag_temp
      time {
        startTimeNumber
        endTimeNumber
        isFullDay
        totalTime
        startTime
        endTime
        endsTomorrowAt
        totalTimeNumberInMinutes
      }
      isExternalTeam
      warning {
        color
        tooltips {
          skills_missing
          clearances_missing
          skills_expired
          clearances_expired
          wrong_shift
          already_assigned
          has_restrictions
          restricted_positions
          warning_hours_worked
          previous_assignation
          rest_time
          otherAssignations
        }
        count
        workload
      }
      isAbsent {
        id
        employee_id
        day_nb
        time {
          startTimeNumber
          endTimeNumber
          totalTimeNumber
          totalTimeNumberInMinutes
          startTime
          endTime
          totalTime
          isFullDay
        }
        day
        reason
        isAbsence
      }
      contract_id
      employee {
        status
        full_name
        first_name
        last_name
        avatar_url
        clearance_ids
        skill_ids
      }
      shift {
        bgColor
        textColor
        name
      }
      positionName
      subpositionName
      skill_ids
      clearance_ids
      restriction_ids
      isExternalAssignement
    }
  }
`;

export const CellResultCreateMany = gql`
  mutation CreateManyLPlanning($data: [LPlanningCreateManyInput!]!) {
    createManyLPlanning(data: $data) {
      count
    }
  }
`;

export const CellResultUpdateMany = gql`
  mutation CellResultUpdateMany(
    $data: LPlanningUpdateManyMutationInput!
    $where: LPlanningWhereInput
  ) {
    updateManyLPlanning(data: $data, where: $where) {
      count
    }
  }
`;
