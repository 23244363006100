import { ESectionName } from "@/modules/rights/types";

const childrenContent = [
  {
    component: () => import("@/views/content/ContentOverview.vue"),
    meta: {
      userRightsSection: ESectionName.Enum.content_management,
    },
    name: "ContentOverview",
    path: "overview",
  },
  {
    component: () => import("@/views/content/ContentCreation.vue"),
    meta: {
      userRightsSection: ESectionName.Enum.content_management,
    },
    name: "ContentCreation",
    path: "creation",
  },
  {
    component: () => import("@/views/content/ContentCreation.vue"),
    meta: {
      userRightsSection: ESectionName.Enum.content_management,
    },
    name: "ContentEdit",
    path: "edit/:id",
  },
  {
    component: () => import("@/views/content/ContentListMiniatures.vue"),
    meta: {
      userRightsSection: ESectionName.Enum.content_management,
    },
    name: "ContentListMiniatures",
    path: "miniatures",
  },
  {
    component: () => import("@/views/content/ContentListDetails.vue"),
    meta: {
      userRightsSection: ESectionName.Enum.content_management,
    },
    name: "ContentListDetails",
    path: "details",
  },
  {
    component: () => import("@/components/content/ContentListGlobal.vue"),
    meta: {
      userRightsSection: ESectionName.Enum.content_management,
    },
    name: "ContentListGlobal",
    path: "list",
  },
  {
    component: () => import("@/views/content/ContentDisplay.vue"),
    meta: {
      userRightsSection: ESectionName.Enum.content_management,
    },
    name: "ContentDisplayRevision",
    path: "view/revision/:id",
  },
  {
    component: () => import("@/views/content/ContentDisplay.vue"),
    meta: {
      userRightsSection: ESectionName.Enum.employee_content_to_watch,
    },
    name: "ContentDisplay",
    path: "view/:id",
  },
];

export default childrenContent;
