import type { DataProxy } from "@apollo/client";

import {
  Clearances,
  CreateClearance,
  DeleteClearance,
  DeleteManyClearance,
  UpdateClearance,
} from "@/graphql/library/Clearances";
import { useClearanceGlobalStore } from "@/pinia/collections";
import { apolloProvider } from "@/vue-apollo";

import {
  updateStoreAfterCreation,
  updateStoreAfterDeletion,
  updateStoreAfterUpdate,
} from "./utils";

export const createClearance = async (payload: { data: any }) => {
  try {
    return await apolloProvider.defaultClient.mutate({
      mutation: CreateClearance,
      update: (cache, data) => {
        updateStoreAfterCreation(
          cache as DataProxy,
          Clearances,
          "Clearances",
          data.data.createClearance,
          useClearanceGlobalStore(),
        );
      },
      variables: {
        data: payload.data,
      },
    });
  } catch (error) {
    console.error(error);
  }
};

export const updateClearance = async (payload: { data: any; where: any }) => {
  try {
    return await apolloProvider.defaultClient.mutate({
      mutation: UpdateClearance,
      update: (cache, data) => {
        updateStoreAfterUpdate(
          cache as DataProxy,
          Clearances,
          "Clearances",
          data.data.updateClearance,
          useClearanceGlobalStore(),
        );
      },
      variables: {
        data: payload.data,
        where: payload.where,
      },
    });
  } catch (error) {
    console.error(error);
  }
};

export const deleteClearance = async (payload: { where: any }) => {
  try {
    return await apolloProvider.defaultClient.mutate({
      mutation: DeleteClearance,
      update: (cache, data) => {
        updateStoreAfterDeletion(
          cache as DataProxy,
          Clearances,
          "Clearances",
          data.data.deleteClearance,
          useClearanceGlobalStore(),
        );
      },
      variables: {
        where: payload.where,
      },
    });
  } catch (error) {
    console.error(error);
  }
};

export const readClearance = async (payload: { where: any }) => {
  try {
    return await apolloProvider.defaultClient.query({
      query: Clearances,
      variables: {
        where: payload.where,
      },
    });
  } catch (error) {
    console.error(error);
  }
};

export const deleteManyClearance = async (payload: { where: any }) => {
  try {
    return await apolloProvider.defaultClient.mutate({
      mutation: DeleteManyClearance,
      variables: {
        where: payload.where,
      },
    });
  } catch (error) {
    console.error(error);
  }
};
