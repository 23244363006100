import { gql } from "graphql-tag";

export const CreateFilesAttachment = gql`
  mutation CreateFilesAttachment($data: FilesAttachmentCreateInput!) {
    createFilesAttachment(data: $data) {
      id
      name
      date
      download_url
      employee_id
      virtual_path
      client_id
      site_id
      status
      published
    }
  }
`;
export const UpdateFilesAttachment = gql`
  mutation UpdateFilesAttachment(
    $data: FilesAttachmentUpdateInput!
    $where: FilesAttachmentWhereUniqueInput!
  ) {
    updateFilesAttachment(data: $data, where: $where) {
      id
      name
      date
      download_url
      employee_id
      virtual_path
      client_id
      site_id
      status
      published
    }
  }
`;
export const DeleteFilesAttachment = gql`
  mutation DeleteFilesAttachment($where: FilesAttachmentWhereUniqueInput!) {
    deleteFilesAttachment(where: $where) {
      id
    }
  }
`;

export const FilesAttachments = gql`
  query FilesAttachments {
    FilesAttachments {
      id
      name
      date
      download_url
      employee_id
      virtual_path
      client_id
      site_id
      status
      published
    }
  }
`;
