import {
  getDownloadURL,
  getStorage,
  ref as refFirebase,
  uploadBytes,
} from "firebase/storage";
import { replace } from "lodash";
import { v4 as uuidv4 } from "uuid";

import { BACKEND_GRAPHQL_URL } from "@/config";
import { app_files_formats } from "@/config/files/app_files_formats";
import { useGlobalStore } from "@/pinia/global";
import { useAuthentificationStore } from "@/pinia/user";
import { EFileType } from "@/tscript/interfaces";

export default function useStorage() {
  const user = useAuthentificationStore();

  /** Find a way to save a file upload without sending to firebase storage */
  // Can be a blob, base64 or other.
  const getBlob = (): void => {
    return;
  };

  const saveFileIntoFirebaseStorage = async (
    file: File,
  ): Promise<{
    name: string;
    type: EFileType;
    url: string;
  }> => {
    if (!file) throw new Error("Missing file");
    if (file.size > 10000000) throw new Error("Limit size exceed"); // + TODO add a warning notification bar for the user.
    if (!user.active_client?.slug) throw new Error("Missing client slug");
    const fileName = uuidv4();
    const extension = file.name.split(".").pop();
    const filePath = `clients/${user.active_client.slug}/${fileName}.${extension}`;

    const storage = getStorage();
    const storageRef = refFirebase(storage, filePath);

    let video_image_or_other: EFileType = EFileType.image;
    if (extension) {
      if (app_files_formats.video.includes(`.${extension}`)) {
        video_image_or_other = EFileType.video;
      } else if (app_files_formats.files.includes(`.${extension}`)) {
        video_image_or_other = EFileType.doc;
      }
    }

    const url = await uploadBytes(storageRef, file).then(
      async () => await getDownloadURL(storageRef).catch(() => undefined),
    );

    if (!url) throw new Error("Missing url");
    return { name: fileName, type: video_image_or_other, url };
  };

  const dataURLtoFile = (data: string, filename: string): Promise<File> => {
    return fetch(data)
      .then((res) => res.blob())
      .then((blob) => {
        const file = new File([blob], filename, { type: "image/png" });
        return file;
      });
  };

  const base64toFile = (dataURL: string, filename: string): File => {
    const arr = dataURL.split(",");
    const extension = dataURL.substring(
      dataURL.indexOf("/") + 1,
      dataURL.indexOf(";"),
    );
    const mime = /:(.*?);/.exec(arr[0])?.[1];
    const base64str = atob(arr[1]);
    let n = base64str.length;
    const u8arr = new Uint8Array(n);

    while (n--) {
      u8arr[n] = base64str.charCodeAt(n);
    }

    return new File([u8arr], `${filename}.${extension}`, { type: mime });
  };

  const extractPath = (url: string): null | string => {
    // Case https://firebasestorage.googleapis.com
    if (url.includes("firebasestorage.googleapis.com")) {
      const indexStart = url.indexOf(".appspot.com/o/");
      const indexEnd = url.indexOf("?");
      if (!indexStart || !indexEnd) return null;
      return url.substring(indexStart + 15, indexEnd);
    }

    // Case https://storage.googleapis.com
    if (url.includes("storage.googleapis.com")) {
      const indexStart = url.indexOf(".appspot.com/");
      const indexEnd = url.indexOf("?");
      if (!indexStart || !indexEnd) return null;
      return url.substring(indexStart + 13, indexEnd);
    }

    return null;
  };

  const findAndReplaceURLStorage = (content: string): string => {
    // Create Empty DOM
    const dom = document.createElement("div");
    // Fill DOM with content
    dom.innerHTML = content;
    // Get all images
    const images = dom.getElementsByTagName("img");
    // Replace all urls contains in content by the new one
    for (const image of images) {
      // Get the proxy url
      const proxyUrl = getProxyUrl(
        image.src,
        window.localStorage.getItem("apollo-token") ?? "",
      );
      if (!proxyUrl) continue;
      // Replace the url by the proxy url, and clean the url
      content = replace(content, replace(image.src, "&", "&amp;"), proxyUrl);
    }

    // Return the new content
    return content;
  };

  const getProxyUrl = (url: string, bearer?: string): null | string => {
    if (!url) return null;
    if (!bearer) return null;

    // Case https://firebasestorage.googleapis.com
    if (url.includes("firebasestorage.googleapis.com")) {
      const indexStart = url.indexOf(".appspot.com/o/");
      const indexEnd = url.indexOf("?");
      if (!indexStart || !indexEnd) return null;
      const path = url.substring(indexStart + 15, indexEnd);
      return `${BACKEND_GRAPHQL_URL}/api/proxy?file=${path}&bearer=${bearer}&type=firebasestorage`;
    }

    // Case https://storage.googleapis.com
    if (url.includes("storage.googleapis.com")) {
      const indexStart = url.indexOf(".appspot.com/");
      const indexEnd = url.indexOf("?");
      if (!indexStart || !indexEnd) return null;
      const path = url.substring(indexStart + 13, indexEnd);
      return `${BACKEND_GRAPHQL_URL}/api/proxy?file=${path}&bearer=${bearer}&type=storage`;
    }

    // Case already proxified
    if (url.includes("/api/proxy?file=")) {
      const parsedUrl = new URL(url);
      const filePath = parsedUrl.searchParams.get("file");
      const storageType = parsedUrl.searchParams.get("type");
      return `${BACKEND_GRAPHQL_URL}/api/proxy?storage=${storageType}&file=${filePath}&bearer=${bearer}`;
    }

    return null;
  };

  const getProxyUrlToken = (
    url: string,
    uniqtoken: string,
  ): null | string => {
    if (!url) throw new Error("Missing url");
    if (!uniqtoken) throw new Error("Missing uniqtoken");

    // Case https://firebasestorage.googleapis.com
    if (url.includes("firebasestorage.googleapis.com")) {
      const indexStart = url.indexOf(".appspot.com/o/");
      const indexEnd = url.indexOf("?");
      if (!indexStart || !indexEnd) return null;
      const path = url.substring(indexStart + 15, indexEnd);
      return `${BACKEND_GRAPHQL_URL}/api/proxy?file=${path}&uniqtoken=${uniqtoken}&type=firebasestorage`;
    }

    // Case https://storage.googleapis.com
    if (url.includes("storage.googleapis.com")) {
      const indexStart = url.indexOf(".appspot.com/");
      const indexEnd = url.indexOf("?");
      if (!indexStart || !indexEnd) return null;
      const path = url.substring(indexStart + 13, indexEnd);
      return `${BACKEND_GRAPHQL_URL}/api/proxy?file=${path}&uniqtoken=${uniqtoken}&type=storage`;
    }

    // Case already proxified
    if (url.includes("/api/proxy?file=")) {
      const parsedUrl = new URL(url);
      const filePath = parsedUrl.searchParams.get("file");
      const storageType = parsedUrl.searchParams.get("type");
      return `${BACKEND_GRAPHQL_URL}/api/proxy?storage=${storageType}&file=${filePath}&uniqtoken=${uniqtoken}`;
    }

    return null;
  };

  const proxify = async (url: string) => {
    const token = await useGlobalStore().apiClient.createUniqueToken();
    return useStorage().getProxyUrlToken(url, token);
  };

  return {
    base64toFile,
    dataURLtoFile,
    extractPath,
    findAndReplaceURLStorage,
    getBlob,
    getProxyUrl,
    getProxyUrlToken,
    proxify,
    saveFileIntoFirebaseStorage,
  };
}
