import { ESectionName } from "../rights/types";
import { ContentCategory } from "./types/entities/content-category.enum";

const contentRoutes = [
  {
    children: [
      {
        component: () =>
          import("@/modules/contents/views/ContentsHome/AllContents.vue"),
        meta: {
          requiresAuth: true,
        },
        name: "AllContents",
        path: "contents/:category",
      },
      {
        component: () =>
          import(
            "@/modules/contents/views/ContentsHome/RefineByDocumentType.vue"
          ),
        meta: {
          requiresAuth: true,
        },
        name: "ContentByDocumentTypes",
        path: "contents/:category/document-types",
      },
      {
        component: () =>
          import(
            "@/modules/contents/views/ContentsHome/ContentByDocumentType.vue"
          ),
        meta: {
          requiresAuth: true,
        },
        name: "ContentByDocumentType",
        path: "contents/:category/document-types/:documentTypeId",
      },
      {
        component: () =>
          import("@/modules/contents/views/ContentsHome/RefineByClearance.vue"),
        meta: {
          requiresAuth: true,
        },
        name: "ContentByClearances",
        path: "contents/:category/clearances",
      },
      {
        component: () =>
          import(
            "@/modules/contents/views/ContentsHome/ContentByClearance.vue"
          ),
        meta: {
          requiresAuth: true,
        },
        name: "ContentByClearance",
        path: "contents/:category/clearances/:clearanceId",
      },
      {
        component: () =>
          import("@/modules/contents/views/ContentsHome/RefineBySkill.vue"),
        meta: {
          requiresAuth: true,
        },
        name: "ContentBySkills",
        path: "contents/:category/skills",
      },
      {
        component: () =>
          import("@/modules/contents/views/ContentsHome/ContentBySkill.vue"),
        meta: {
          requiresAuth: true,
        },
        name: "ContentBySkill",
        path: "contents/:category/skills/:skillId",
      },
      {
        component: () =>
          import("@/modules/contents/views/ContentsHome/RefineByTeams.vue"),
        meta: {
          requiresAuth: true,
        },
        name: "ContentByTeams",
        path: "contents/:category/teams",
      },
      {
        component: () =>
          import("@/modules/contents/views/ContentsHome/RefineByPosition.vue"),
        meta: {
          requiresAuth: true,
        },
        name: "ContentByPositions",
        path: "contents/:category/teams/positions",
      },
      {
        component: () =>
          import("@/modules/contents/views/ContentsHome/ContentByPosition.vue"),
        meta: {
          requiresAuth: true,
        },
        name: "ContentByPosition",
        path: "contents/:category/teams/positions/:positionId",
      },
    ],
    component: () => import("@/modules/contents/views/ContentsHome.vue"),
    meta: {
      requiresAuth: true,
      userRightsSection: ESectionName.Enum.content_management,

    },
    name: "ContentV2",
    path: "/contentv2",
    redirect: {
      name: "ContentByTeams",
      params: { category: ContentCategory.instruction },
    },
  },
  {
    component: () => import("@/modules/contents/views/ContentEdit.vue"),
    meta: {
      requiresAuth: true,
      userRightsSection: ESectionName.Enum.content_management,
    },
    name: "ContentV2Edit",
    path: "/contentv2/edit",
  },
  {
    component: () => import("@/modules/contents/views/ContentView.vue"),
    meta: {
      requiresAuth: true,
      userRightsSection: ESectionName.Enum.content_management,
    },
    name: "ContentV2View",
    path: "/contentv2/view/:id",
  },
  {
    component: () => import("@/modules/contents/views/ContentRevisions.vue"),
    meta: {
      requiresAuth: true,
      userRightsSection: ESectionName.Enum.content_management,
    },
    name: "ContentV2Revisions",
    path: "/contentv2/revisions/:id",
  },
  {
    component: () => import("@/modules/contents/views/ContentsTobeViewed.vue"),
    meta: {
      requiresAuth: true,
      userRightsSection: ESectionName.Enum.content_management,
    },
    name: "ContentsToBeViewed",
    path: "/contents-to-be-viewed",
  },
  {
    component: () => import("@/modules/contents/views/NotFoundErrorView.vue"),
    meta: {
      requiresAuth: true,
      userRightsSection: ESectionName.Enum.content_management,
    },
    name: "ContentV2Error",
    path: "/contentv2/error",
  },
  {
    component: () =>
      import(
        "@/modules/schedule/workload-management/components/fake-parent.vue"
      ),
    meta: {
      requiresAuth: true,
      userRightsSection: ESectionName.Enum.content_management,
    },
    name: "WorkloadManager",
    path: "/workload-management",
  },
];

export default contentRoutes;
