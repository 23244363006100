import { Clients, CreateClient, UpdateClient } from "@/graphql/admin/Clients";
import { useClientGlobalStore } from "@/pinia/collections";
import { setGlobalStoreGraphQL } from "@/utils/graphql.utils";
import { apolloProvider } from "@/vue-apollo";

export const createClient = async (payload: { data: any }) => {
  try {
    return await apolloProvider.defaultClient.mutate({
      mutation: CreateClient,
      update: (store, { data: { createClient } }) => {
        updateStoreAfterCreateClient(store, createClient);
      },
      variables: {
        data: payload.data,
      },
    });
  } catch (error) {
    console.error(error);
  }
};

export const updateClient = async (payload: { data: any; where: any }) => {
  try {
    return await apolloProvider.defaultClient.mutate({
      mutation: UpdateClient,
      update: (store, data) => {
        updateStoreAfterUpdateClient(store, data.data.updateClient);
      },
      variables: {
        data: payload.data,
        where: payload.where,
      },
    });
  } catch (error) {
    console.error(error);
  }
};

const updateStoreAfterCreateClient = (store, createClient) => {
  const data = store.readQuery({
    query: Clients,
  });
  data.Clients.push(createClient);
  setGlobalStoreGraphQL(useClientGlobalStore(), data.Clients);
  store.writeQuery({
    data,
    query: Clients,
  });
};

const updateStoreAfterUpdateClient = (store, updateClient) => {
  const data = store.readQuery({
    query: Clients,
  });
  const index = data.Clients.findIndex((c) => c.id === updateClient.id);
  if (index !== -1) {
    // Mutate cache result
    data.Clients.splice(index, 1, updateClient);
    setGlobalStoreGraphQL(useClientGlobalStore(), data.Clients);
    // Write back to the cache
    store.writeQuery({
      data,
      query: Clients,
    });
  }
};
