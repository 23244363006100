import { getApp, initializeApp, setLogLevel } from "firebase/app";
import { connectFunctionsEmulator, getFunctions } from "firebase/functions";

import { config, FIREBASE_CONFIG } from "@/config/index";

const FIREBASE_CONFIG_SWITCHER = () => {
  if (config.useLocalFirestore && config.mode === "staging") {
    return {
      apiKey: "AIzaSyBaA0RZv-xrZ4Aiwk2v6eiVkWE6VNbLXhw",
      appId: "1:359950159463:web:3acfa08e49fa2900fc8ec2",
      projectId: "mercateam-staging",
    };
  }
  return { ...FIREBASE_CONFIG };
};

const firebaseApp = initializeApp(FIREBASE_CONFIG_SWITCHER());
if (config.useLocalFunctions && config.portLocalFunctions) {
  const functions = getFunctions(getApp());
  connectFunctionsEmulator(functions, "localhost", config.portLocalFunctions);
  if (config.logLevel) {
    setLogLevel(config.logLevel);
  }
}

export default firebaseApp;
