import {
  childrenPlanningEmployee,
  childrenPlanningPosition,
  childrenPlanningShift,
} from "../childrenLevel3";

const childrenPlanning = [
  {
    children: childrenPlanningEmployee,
    component: () => import("@/views/planning/employee/PlanningEmployee.vue"),
    name: "PlanningEmployee",
    path: "employee",
    redirect: { name: "PlanningWeekEmployee" },
  },
  {
    children: childrenPlanningPosition,
    component: () => import("@/views/planning/position/PlanningPosition.vue"),
    name: "PlanningPosition",
    path: "position",
    redirect: { name: "PlanningWeekPosition" },
  },
  {
    children: childrenPlanningShift,
    component: () => import("@/views/planning/shift/PlanningShift.vue"),
    name: "PlanningShifts",
    path: "shifts",
    redirect: { name: "PlanningWeekShifts" },
  },
  {
    component: () => import("@/views/planning/PositionClosureV2.vue"),
    name: "PositionClosure",
    path: "position-closure",
  },
];

export default childrenPlanning;
