const childrenPlanningShift = [
  {
    component: () => import("@/views/planning/shift/ShiftWeek.vue"),
    name: "PlanningWeekShifts",
    path: "week",
  },
  {
    component: () => import("@/views/planning/shift/ShiftMonth.vue"),
    name: "PlanningMonthShifts",
    path: "month",
  },
  {
    component: () => import("@/views/planning/shift/ShiftDay.vue"),
    name: "PlanningDayShifts",
    path: "day",
  },
];

export default childrenPlanningShift;
