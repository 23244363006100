<template>
  <nav>
    <v-app-bar
      app
      color="white"
      flat
      height="55"
    >
      <v-toolbar-items>
        <v-layout align-center class="spacing-playground py-0 px-2">
          <v-btn color="grey_middle" text @click="drawer = !drawer">
            <v-icon>mdi-menu</v-icon>
          </v-btn>
        </v-layout>
      </v-toolbar-items>

      <v-autocomplete
        v-if="isRoleUser"
        v-model="select"
        :filter="filterAutocomplete"
        :items="itemsFound"
        :loading="loadingSearchBar"
        :placeholder="$t('global.search_employee_skill')"
        :search-input.sync="search"
        clearable
        color="grey_middle"
        data-cy="search-input"
        dense
        flat
        full-width
        hide-details
        hide-no-data
        item-text="search"
        item-value="select"
        prepend-inner-icon="mdi-magnify"
        solo
        style="font-size: 13px;"
        @change="itemChange"
      >
        <template #item="{item}">
          <v-list-item-avatar>
            <v-img
              v-if="item.picture_url && item.team_id"
              :src="item.picture_url"
            />

            <v-icon
              v-else-if="!item.picture_url && item.team_id"
              class="grey--text"
            >
              mdi-account-circle-outline
            </v-icon>
            <v-icon v-else-if="item.level || item.level === 0">
              mdi-school-outline
            </v-icon>
            <v-icon v-else-if="item.team_name">
              mdi-account-group
            </v-icon>
            <v-icon v-else text>
              {{ firstLetterToString($t("global.clearances")) }}
            </v-icon>
          </v-list-item-avatar>
          <v-list-item-content>
            <v-list-item-title>{{ item.search }}</v-list-item-title>
          </v-list-item-content>
        </template>
      </v-autocomplete>

      <v-btn
        v-if="isRoleUser && userRightsAdvancedSearch.readAllowed"
        color="grey_light"
        data-cy="advanced-search-btn"
        depressed
        x-small
        @click="advancedSearch"
      >
        <div class="grey_dark--text">
          {{ $t("global.advanced_search") }}
        </div>
      </v-btn>

      <v-spacer />

      <v-toolbar-items>
        <NotificationsPopup2 class="mr-3" />

        <v-menu v-if="sitesList.length > 0" offset-y>
          <template #activator="{on}">
            <v-btn color="grey_middle" icon v-on="on">
              <v-icon>mdi-account-switch</v-icon>
            </v-btn>
          </template>
          <v-list>
            <v-list-item-group v-model="selectedSite">
              <v-list-item v-for="(site, id) in sitesList" :key="id">
                <v-list-item-title>{{ site.name }} </v-list-item-title>
              </v-list-item>
            </v-list-item-group>
          </v-list>
        </v-menu>

        <v-btn
          v-if="isFrench"
          color="info"
          href="https://help.merca.team/fr/"
          target="_blank"
          text
          @click="logHelpdesk"
        >
          <v-icon>mdi-help-circle-outline </v-icon>
        </v-btn>

        <v-btn
          v-if="!isFrench"
          color="info"
          href="https://help.merca.team/en"
          target="_blank"
          text
        >
          <v-icon>mdi-help-circle-outline </v-icon>
        </v-btn>

        <v-menu class="pointer pt-3" data-cy="profile" offset-y>
          <template #activator="{on, attrs}">
            <v-btn
              v-bind="attrs"
              class="text-capitalize"
              text
              @click="dropdownOpen = !dropdownOpen"
              v-on="on"
            >
              <Avatar
                v-if="userData"
                :default-width="true"
                :dropdown="true"
                :dropdown-open="attrs['aria-expanded']"
                :first-name="userData.first_name"
                :job="userData.job"
                :last-name="userData.last_name"
                :picture-url="userData.avatar_url"
                size="35"
              />
              <span
                v-if="hasNotifications"
                :class="$style.avatarRedDot"
                class="redDot"
              />
            </v-btn>
          </template>
          <v-list dense>
            <v-list-item
              v-for="(item, index) in profileMenuItems"
              :key="index"
              class="justify-center"
              @click="goTo(item.routeName)"
            >
              <v-list-item-icon>
                <v-icon medium>
                  {{ item.icon }}
                </v-icon>
              </v-list-item-icon>

              <v-list-item-title class="align-center">
                <div>
                  <v-badge
                    v-if="item.hasNotification"
                    dot
                    inline
                    offset-x="-10"
                  >
                    {{ item.title }}
                  </v-badge>
                  <span v-else>
                    {{ item.title }}
                  </span>
                </div>
              </v-list-item-title>
            </v-list-item>
          </v-list>
        </v-menu>

        <v-btn
          v-if="!isRoleUser"
          color="grey_middle"
          data-cy="logout"
          text
          @click="logout"
        >
          <v-icon>mdi-exit-to-app</v-icon>
        </v-btn>
      </v-toolbar-items>
    </v-app-bar>
    <v-navigation-drawer
      v-model="drawer"
      :hide-overlay="true"
      :mini-variant.sync="mini"
      app
      class="white left-nav-menu"
      width="210"
    >
      <v-layout align-center class="mt-5 mb-6" justify-center>
        <router-link :to="{name: 'home'}">
          <v-img :src="logoMercateam" alt="Mercateam logo" max-width="150" />
        </router-link>
      </v-layout>

      <div class="text-center">
        <v-menu :offset-x="offset">
          <template #activator="{on, attrs}">
            <v-list-item class="mb-2" v-bind="attrs" v-on="on">
              <v-avatar
                v-if="
                  useAuthentificationStore.active_client &&
                    useAuthentificationStore.active_client.logo_url
                "
                color="grey_light"
                contain
                data-cy="client-logo"
                size="50px"
              >
                <v-img
                  :src="useAuthentificationStore.active_client.logo_url"
                  contain
                />
              </v-avatar>
              <v-list-item-content>
                <v-list-item-title class="client_name text-wrap">
                  <span
                    v-if="
                      useAuthentificationStore.active_client &&
                        useAuthentificationStore.active_client.name
                    "
                  >{{ useAuthentificationStore.active_client.name }}</span>
                </v-list-item-title>
              </v-list-item-content>
              <v-icon color="grey_middle">
                mdi-chevron-right
              </v-icon>
            </v-list-item>
          </template>

          <v-list
            class="font-weight-bold"
            dense
            nav
            shaped
          >
            <v-list-item
              v-for="(link, index) in linksAdmin"
              :id="link.id"
              :key="index"
              :to="link.route"
              color="selected"
              router
            >
              <v-list-item-icon class="ml-1">
                <Administration
                  v-if="link.id === 'administration'"
                  :selection="
                    link.route === $route.path ? '#673ff9' : '#2B2B2B'
                  "
                />
                <Portfolio
                  v-if="link.id === 'library'"
                  :selection="
                    link.route === $route.path ? '#673ff9' : '#2B2B2B'
                  "
                />
                <Settings
                  v-if="link.id === 'settings'"
                  :selection="
                    link.route === $route.path ? '#673ff9' : '#2B2B2B'
                  "
                />
                <Extraction
                  v-if="link.id === 'exports'"
                  :selection="
                    link.route === $route.path ? '#673ff9' : '#2B2B2B'
                  "
                />
                <Importation
                  v-if="link.id === 'imports'"
                  :selection="
                    link.route === $route.path ? '#673ff9' : '#2B2B2B'
                  "
                />
                <PerformanceReview
                  v-if="link.id === 'performance-reviews'"
                  :selection="
                    link.route === $route.path ? '#673ff9' : '#2B2B2B'
                  "
                />
                <Rights
                  v-if="link.id === 'rights'"
                  :selection="
                    link.route === $route.path ? '#673ff9' : '#2B2B2B'
                  "
                />

                <v-icon v-else>
                  {{ link.icon }}
                </v-icon>
              </v-list-item-icon>

              <v-list-item-content>
                <v-list-item-title
                  :selection="
                    link.route === $route.path ? '#673ff9' : '#2B2B2B'
                  "
                >
                  <span> {{ link.text }} </span>
                </v-list-item-title>
              </v-list-item-content>
            </v-list-item>
          </v-list>
        </v-menu>
      </div>

      <v-list
        class="font-weight-bold pr-2 pt-0 pb-0"
        dense
        nav
        shaped
      >
        <v-list-item
          v-for="(link, index) in links"
          :id="link.id ? link.id : ''"
          :key="index"
          :to="link.route"
          background-color="transparent"
          color="selected"
          router
        >
          <v-list-item-icon class="ml-1">
            <Piechart
              v-if="link.route === '/'"
              :selection="link.route === $route.path ? '#673ff9' : '#2B2B2B'"
            />
            <Trainings
              v-if="link.route.includes('training')"
              :selection="link.route === $route.path ? '#673ff9' : '#2B2B2B'"
            />
            <Process
              v-if="
                link.route.includes('monitoring') ||
                  link.route.includes('monitoringv2')
              "
              :selection="link.route === $route.path ? '#673ff9' : '#2B2B2B'"
            />

            <ContentIcon
              v-if="link.route.includes('content')"
              :selection="link.route === $route.path ? '#673ff9' : '#2B2B2B'"
            />
            <PerformanceReview
              v-if="link.route.includes('performance-reviews')"
              :selection="link.route === $route.path ? '#673ff9' : '#2B2B2B'"
            />
            <WorkforcePlanningIcon
              v-if="link.route.includes('workforce-planning')"
              :selection="link.route === $route.path ? '#673ff9' : '#2B2B2B'"
            />
            <IconKpis
              v-if="link.route.includes('kpis-dashboard')"
              :selection="link.route === $route.path ? '#673ff9' : '#2B2B2B'"
            />
            <v-icon v-else>
              {{ link.icon }}
            </v-icon>
          </v-list-item-icon>

          <v-badge
            v-if="link.beta"
            color="rgb(255, 90, 95)"
            content="beta"
            inline
          >
            <v-list-item-content>
              <v-list-item-title>
                <span> {{ link.text }} </span>
              </v-list-item-title>
            </v-list-item-content>
          </v-badge>
          <v-list-item-content v-else>
            <v-list-item-title>
              <span> {{ link.text }}
                <v-chip
                  v-if="link.chips"
                  color="primary_extra_light"
                  x-small
                >
                  {{ link.chips }}
                </v-chip>
              </span>
            </v-list-item-title>
          </v-list-item-content>
        </v-list-item>
      </v-list>

      <div style=" height: 3px;background-color: #fafafc;" />

      <v-list
        v-if="isRoleUser"
        class="pt-0"
        dense
        nav
        shaped
        style="padding-bottom: 77px;"
      >
        <v-list-group
          v-for="(site, index) in sitesListNavbar"
          :key="index"
          value="true"
        >
          <template #activator>
            <v-list-item-title>
              <p class="ma-0 pl-2">
                {{ site.name }}
              </p>
            </v-list-item-title>
          </template>

          <nav>
            <v-list-group
              v-for="(factory, j) in factoriesListDetailed"
              :key="j"
              sub-group
            >
              <template #activator>
                <v-list-item-title>
                  <router-link
                    :to="{name: 'factory-teams', params: {id: factory.id}}"
                    class="link-factory"
                  >
                    {{ factory.factory_name }}
                  </router-link>
                </v-list-item-title>
              </template>

              <v-list-item
                v-for="(team, i) in teamsListDetailed[factory.id]"
                :key="i"
                :to="{name: 'team-informations', params: {id: team.id}}"
                link
              >
                <v-list-item-title>{{ team.team_name }}</v-list-item-title>
              </v-list-item>
            </v-list-group>
          </nav>
        </v-list-group>
      </v-list>

      <v-footer absolute color="white">
        <div style=" height: 65px;background-color: #fff;" />
      </v-footer>
    </v-navigation-drawer>
  </nav>
</template>

<script lang="ts">
import { gql } from "@apollo/client/core";
import { getAnalytics, logEvent } from "firebase/analytics";
import {
  chain,
  cloneDeep,
  type Dictionary,
  groupBy,
  map,
  orderBy,
  sortBy,
} from "lodash";
import { mapActions, mapState, mapStores, mapWritableState } from "pinia";
import Vue, { type UnwrapRef } from "vue";
import type { TranslateResult } from "vue-i18n";

import logoMercateam from "@/assets/logo_mercateam.svg";
import { useGetPendingApprovalsQuery } from "@/modules/contents/composables/useGetPendingApprovalsQuery";
import usePermissions from "@/modules/rights/composables/use-permissions";
import { EPricingPlan, ERole, ESectionName } from "@/modules/rights/types";
import {
  useClearanceGlobalStore,
  useContentApprobationGlobalStore,
  useEmployeeGlobalStore,
  useFactoryGlobalStore,
  useSkillGlobalStore,
  useTeamGlobalStore,
  useUserGlobalStore,
} from "@/pinia/collections";
import { useGlobalStore } from "@/pinia/global";
import { useUiStore } from "@/pinia/modules/ui";
import { useAuthentificationStore } from "@/pinia/user";
import { segmentTriggerEvent } from "@/tscript/analytics";
import {
  EBasePublish,
  EBaseStatus,
  type IClearance,
  type IEmployee,
  type IFactory,
  type ISite,
  type ISkill,
  type ITeam,
  type IUser,
} from "@/tscript/interfaces";
import type { SiteID, UserID } from "@/tscript/mercateam";
import { normalizeString, removeChars } from "@/tscript/utils";
import { getApprobationsForUser } from "@/utils/content.utils";
import { Questionnaires_query } from "@/views/profile/approbations/graphql";

import IconKpis from "./icons/IconKpis.vue";
import PopinParameters from "@/components/content/popin/PopinParameters.vue";
import Avatar from "@/components/global/Avatar.vue";
import NotificationsPopup2 from "@/components/global/NotificationsPopup2.vue";
import Administration from "@/components/icons/Administration.vue";
import ContentIcon from "@/components/icons/Content.vue";
import Extraction from "@/components/icons/Extraction.vue";
import Importation from "@/components/icons/Importation.vue";
import PerformanceReview from "@/components/icons/PerformanceReview.vue";
import Piechart from "@/components/icons/Piechart.vue";
import Portfolio from "@/components/icons/Portfolio.vue";
import Process from "@/components/icons/Process.vue";
import Rights from "@/components/icons/Rights.vue";
import Settings from "@/components/icons/Settings.vue";
import Trainings from "@/components/icons/Trainings.vue";
import WorkforcePlanningIcon from "@/components/icons/WorkforcePlanning.vue";

type Clearance = Omit<IClearance, "search" | "select"> & {
  search: string;
  select: {
    id: string;
    type: "clearance";
  };
};

interface FactoryDetailed extends IFactory {
  route: string;
}

type IEmployeeDetailsOld = Omit<IEmployee, "search" | "select"> & {
  name: string;
  search: string;
  select: {
    id: string;
    type: "employee";
  };
};

type Skill = Omit<ISkill, "search" | "select"> & {
  search: string;
  select: {
    id: string;
    type: "skill";
  };
};

type Team = Omit<ITeam, "search" | "select"> & {
  search: string;
  select: {
    id: string;
    type: "team";
  };
};

interface TeamDetailed extends ITeam {
  route: string;
}

export default Vue.extend({
  name: "NavBar",
  components: {
    Administration,
    Avatar,
    ContentIcon,
    Extraction,
    IconKpis,
    Importation,
    NotificationsPopup2,
    PerformanceReview,
    Piechart,
    Portfolio,
    Process,
    Rights,
    Settings,
    Trainings,
    WorkforcePlanningIcon,
  },
  setup() {
    const { readAllowed: userRightsTrainingManagementReadAllowed } =
      usePermissions(ESectionName.Enum.training_management);

    const { readAllowed: userRightsKPIsReadAllowed } = usePermissions(
      ESectionName.Enum.kpis,
    );

    const { result: pendingApprovals } = useGetPendingApprovalsQuery({
      skip: 0,
      take: 0,
    });

    return {
      pendingApprovals,
      userRightsKPIsReadAllowed,
      userRightsTrainingManagementReadAllowed,
    };
  },
  data() {
    return {
      dialog: false,
      dropdownOpen: false,
      employeeData: null,
      factories: [],
      hasNotifications: false,
      items: [
        { title: "Click Me" },
        { title: "Click Me" },
        { title: "Click Me" },
        { title: "Click Me 2" },
      ],

      itemsFound: [] as (Clearance | IEmployeeDetailsOld | Skill | Team)[],
      langs: [],
      loadingSearchBar: false,
      logoMercateam: logoMercateam,
      mini: false,
      offset: true,
      search: "",
      select: null,
      selectedSite: null as null | SiteID,
      sites: [],
      teams: [],
      userRightsAdministration: usePermissions(
        ESectionName.Enum.administration,
      ),
      userRightsAdvancedSearch: usePermissions(
        ESectionName.Enum.advanced_search,
      ),
      userRightsContent: usePermissions(ESectionName.Enum.content_management),
      userRightsDashboardHome: usePermissions(ESectionName.Enum.dashboard_home),
      userRightsEmployeeGeneralInformations: usePermissions(
        ESectionName.Enum.employee_general_informations,
      ),
      userRightsEmployeeSkillsAndClearances: usePermissions(
        ESectionName.Enum.employee_skills_and_clearances,
      ),
      userRightsExport: usePermissions(ESectionName.Enum.export),
      userRightsImport: usePermissions(ESectionName.Enum.import),
      userRightsLibrary: usePermissions(ESectionName.Enum.library),
      userRightsMonitoringCartography: usePermissions(
        ESectionName.Enum.monitoring_cartography,
      ),
      userRightsMonitoringGoals: usePermissions(
        ESectionName.Enum.monitoring_goals,
      ),
      userRightsMonitoringJobs: usePermissions(
        ESectionName.Enum.monitoring_jobs,
      ),
      userRightsParameters: usePermissions(ESectionName.Enum.parameters),
      userRightsPerformanceReviewsAnalysis: usePermissions(
        ESectionName.Enum.performance_reviews_analysis,
      ),
      userRightsPerformanceReviewsCampaign: usePermissions(
        ESectionName.Enum.performance_reviews_campaign,
      ),
      userRightsPerformanceReviewsModel: usePermissions(
        ESectionName.Enum.performance_reviews_models,
      ),
      userRightsQuestionnaires: usePermissions(
        ESectionName.Enum.questionnaire_management,
      ),
      userRightsWorkforcePlanning: usePermissions(
        ESectionName.Enum.workforce_planning,
      ),
      waitingQuestionnaireApprobations: [],
    };
  },
  computed: {
    ...mapStores(useAuthentificationStore),
    ...mapState(useClearanceGlobalStore, {
      activesClearances: "actives",
    }),
    ...mapState(useSkillGlobalStore, {
      activesSkills: "actives",
    }),
    ...mapState(useEmployeeGlobalStore, {
      activesEmployees: "actives",
    }),
    ...mapState(useTeamGlobalStore, {
      activesTeams: "actives",
    }),
    ...mapState(useFactoryGlobalStore, {
      activesFactories: "actives",
    }),
    ...mapState(useAuthentificationStore, {
      userData: "data",
    }),
    ...mapWritableState(useGlobalStore, ["drawer"]),
    ...mapState(useContentApprobationGlobalStore, {
      contentApprobations: "actives",
    }),
    clearancesList(): Clearance[] {
      if (!this.userRightsEmployeeSkillsAndClearances.readAllowed) {
        return [];
      }

      return (this.activesClearances ?? []).map((clearance) => ({
        ...clearance,
        search: clearance.name,
        select: { id: clearance.id, type: "clearance" },
      }));
    },
    employeesList(): IEmployeeDetailsOld[] {
      const setSearch = (
        { employee_company_id, internal_id }: IEmployee,
        name: string,
      ) => {
        if (!this.userRightsEmployeeGeneralInformations.readAllowed) {
          return name;
        }

        const heading = employee_company_id ? `${employee_company_id} - ` : "";
        const ending = internal_id ? ` - ${internal_id}` : "";

        return `${heading}${name}${ending}`;
      };

      return (this.activesEmployees ?? []).map((e) => {
        const name = this.useAuthentificationStore.data?.firstname_parameter
          ? `${e.last_name} ${e.first_name}`
          : `${e.first_name} ${e.last_name}`;
        return {
          ...e,
          name,
          search: setSearch(e, name),
          select: { id: e.id, type: "employee" },
        };
      });
    },
    factoriesListDetailed(): FactoryDetailed[] {
      const siteId = this.useAuthentificationStore.active_site;
      const factories: IFactory[] | null = this.activesFactories;
      return chain(factories)
        .map((factory) => {
          return {
            ...factory,
            route: `/factory/${factory.id}`,
          };
        })
        .filter((factory) => {
          return factory.site_id === siteId;
        })
        .orderBy(["factory_name"], ["asc"])
        .value();
    },
    isContentV2ActivatedOnCurrentSite(): boolean {
      const currentPricing =
        this.useAuthentificationStore?.active_site_full?.pricing?.name;
      const hasSkillTrainerPricing =
        currentPricing === EPricingPlan.Enum.SkillTrainer;
      const hasSkillMasterPricing =
        currentPricing === EPricingPlan.Enum.SkillMaster;
      const hasAccesToContentV2 =
        hasSkillTrainerPricing || hasSkillMasterPricing;
      return hasAccesToContentV2;
    },
    isFrench(): boolean {
      if (!this.useAuthentificationStore.data) return false;
      return this.useAuthentificationStore.data?.language === "fr";
    },
    isRoleAdmin(): boolean {
      if (!this.userData) return false;
      return (
        this.userData.role === ERole.Enum.ADMIN ||
        this.userData.role === ERole.Enum.CUSTOMER_SUCCESS
      );
    },
    isRoleUser(): boolean {
      if (!this.userData) return true;
      return this.userData.role === ERole.Enum.USER;
    },
    links(): any[] {
      return this.getMenuData();
    },
    linksAdmin(): any[] {
      return this.getMenuAdminData();
    },
    pendingApprovalsCount(): number {
      return (
        (
          this.pendingApprovals as unknown as UnwrapRef<
            typeof this.pendingApprovals
          >
        )?.pendingReviews.total || 0
      );
    },
    profileMenuItems(): any {
      const res = [
        {
          hasNotification: false,
          icon: "mdi-account-outline",
          routeName: "profile",
          title: this.$t("profile.profile"),
        },
      ];
      if (
        this.userRightsQuestionnaires.readAllowed ||
        this.userRightsContent.readAllowed
      ) {
        res.push({
          hasNotification: this.hasNotifications,
          icon: "mdi-check-decagram-outline",
          routeName: "Approbations",
          title: this.$t("content.approbations"),
        });
      }
      return res;
    },
    sitesList(): ISite[] {
      return this.useAuthentificationStore.sites;
    },
    sitesListNavbar(): ISite[] {
      if (this.useAuthentificationStore.active_site_full) {
        return [this.useAuthentificationStore.active_site_full];
      }
      return [];
    },
    skillsList(): Skill[] {
      if (!this.userRightsEmployeeSkillsAndClearances.readAllowed) {
        return [];
      }

      return (this.activesSkills ?? []).map((skill) => ({
        ...skill,
        search: skill.name,
        select: { id: skill.id, type: "skill" },
      }));
    },
    teamsList(): Team[] {
      return (this.activesTeams ?? []).map((team) => ({
        ...team,
        search: team.team_name,
        select: { id: team.id, type: "team" },
      }));
    },
    teamsListDetailed(): Dictionary<TeamDetailed[]> {
      const teams: ITeam[] | null = this.activesTeams;
      const teamsDetailed = map(teams, (team) => {
        return {
          ...team,
          route: `/team/${team.id}`,
        };
      });
      const t = orderBy(teamsDetailed, ["team_name"], ["asc"]);
      return groupBy(t, "factory_id");
    },
    userData(): IUser | null {
      return this.useAuthentificationStore.data;
    },
    userID(): null | UserID {
      return this.userData?.id || null;
    },
  },
  watch: {
    contentApprobations: {
      deep: true,
      handler() {
        this.updateApprobationsNotifications();
      },
    },
    search(value) {
      if (value && value !== this.select) {
        this.querySelections(value);
      }
    },
    select(selectItem) {
      if (selectItem) {
        if (selectItem.id && selectItem.type && selectItem.type === "skill") {
          segmentTriggerEvent("simple_search_skill");
          this.$router
            .push({
              name: "SimpleSearchView",
              params: { id: selectItem.id, type: "skills" },
              query: { type: "skills" },
            })
            .catch(() => undefined);
        }
        if (
          selectItem.id &&
          selectItem.type &&
          selectItem.type === "employee"
        ) {
          segmentTriggerEvent("simple_search_employee");
          this.$router
            .push({ name: "employee", params: { id: selectItem.id } })
            .catch(() => undefined);
        }
        if (
          selectItem.id &&
          selectItem.type &&
          selectItem.type === "clearance"
        ) {
          segmentTriggerEvent("simple_search_clearance");
          this.$router
            .push({
              name: "SimpleSearchView",
              params: { id: selectItem.id, type: "clearances" },
              query: { type: "clearances" },
            })
            .catch(() => undefined);
        }
        if (selectItem.id && selectItem.type && selectItem.type === "team") {
          segmentTriggerEvent("simple_search_team");
          this.$router
            .push({ name: "team-informations", params: { id: selectItem.id } })
            .catch(() => undefined);
        }
        this.itemChange();
      }
    },
    async selectedSite(idx) {
      const site = this.sitesList[idx];
      if (!site) return;
      if (!this.useAuthentificationStore.data) return;
      this.search = "";
      await this.$apollo.mutate({
        mutation: gql`
          mutation ChangeSiteUser(
            $data: UserSiteUpdateInput!
            $where: UserWhereUniqueInput!
          ) {
            changeSiteUser(data: $data, where: $where) {
              site {
                name
              }
              sites {
                id
                name
              }
            }
          }
        `,
        variables: {
          data: {
            site_id: site.id,
          },
          where: {
            id: this.useAuthentificationStore.data.id,
          },
        },
      });
      window.location.reload();
    },
  },
  created() {
    this.setLanguage();
    logEvent(getAnalytics(), "navbar");
  },
  mounted() {
    this.updateApprobationsNotifications();
  },
  methods: {
    ...mapActions(useUiStore, {
      buildComponentPopin: "buildComponentPopin",
    }),
    ...mapActions(useUserGlobalStore, { updateUser: "update" }),
    advancedSearch() {
      this.$router
        .push({
          path: "/advanced-search",
        })
        .catch(() => undefined);
    },
    filterAutocomplete(item: any, queryText: string, itemText: string) {
      if (queryText === null || queryText.length === 0) {
        return true;
      }

      const testQuery = normalizeString(item.search).includes(
        normalizeString(queryText),
      );

      return itemText?.length > 0 && testQuery;
    },
    firstLetterToString(text: TranslateResult): string {
      return (text as string).charAt(0).toUpperCase();
    },
    getMenuAdminData() {
      const menuData: any[] = [];

      if (this.userRightsAdministration.readAllowed) {
        const adminMenu = {
          id: "administration",
          route: "/admin",
          text: this.$t("navbar.admin"),
        };

        menuData.push(adminMenu);
      }

      if (this.isRoleUser && this.userRightsLibrary.readAllowed) {
        const libraryMenu = {
          id: "library",
          route: "/library",
          text: this.$t("navbar.portfolio"),
        };
        menuData.push(libraryMenu);
      }

      if (this.isRoleUser && this.userRightsParameters.readAllowed) {
        const settingsMenu = {
          id: "settings",
          route: "/settings",
          text: this.$t("navbar.settings"),
        };
        menuData.push(settingsMenu);
      }
      if (!this.isRoleAdmin && this.userRightsExport.readAllowed) {
        const extractMenu = {
          id: "exports",
          route: "/exports",
          text: this.$t("navbar.export"),
        };
        menuData.push(extractMenu);
      }
      if (this.isRoleAdmin || this.userRightsImport.readAllowed) {
        const importMenu = {
          id: "imports",
          route: "/imports",
          text: this.$t("import_admin.import"),
        };
        menuData.push(importMenu);
      }

      if (!this.isRoleUser || this.userData?.is_rights_admin) {
        const rightsMenu = {
          id: "rights",
          route: "/rights",
          text: this.$tc("global.right", 2),
        };
        menuData.push(rightsMenu);
      }

      return menuData;
    },
    getMenuData() {
      const menuData: any[] = [];
      if (this.isRoleUser && this.userRightsDashboardHome.readAllowed) {
        const dashBoardMenu = {
          route: "/",
          text: this.$t("navbar.home"),
        };
        menuData.push(dashBoardMenu);
      }

      if (
        this.isRoleUser &&
        (this.userRightsTrainingManagementReadAllowed ||
          this.userRightsQuestionnaires.readAllowed)
      ) {
        const trainingMenu = {
          id: "goto-trainings",
          new: true,
          route: "/trainingv3/trainings",
          text: this.$t("navbar.training"),
        };
        menuData.push(trainingMenu);
      }

      if (
        this.isRoleUser &&
        this.userData?.site?.settings_monitoring_v1 &&
        (this.userRightsMonitoringCartography.readAllowed ||
          this.userRightsMonitoringGoals.readAllowed ||
          this.userRightsMonitoringJobs.readAllowed)
      ) {
        const route = this.userRightsMonitoringCartography.readAllowed
          ? "/monitoring/processes"
          : this.userRightsMonitoringJobs.readAllowed
            ? "/monitoring/jobs/list"
            : "/monitoring/goals/overview";

        const processMenu = {
          route,
          text: this.$t("navbar.monitoring"),
        };

        menuData.push(processMenu);
      }

      if (
        this.isRoleUser &&
        this.userRightsMonitoringGoals.readAllowed &&
        this.userData?.site?.settings_monitoring_main_goal
      ) {
        const monitoringv2Menu = {
          chips: "v2",
          id: "monitoringv2",
          route: "/monitoringv2",
          text: this.$tc("monitoringv2.monitoring", 2),
        };
        menuData.push(monitoringv2Menu);
      }

      if (
        this.isRoleUser &&
        this.useAuthentificationStore.active_site_full
          ?.settings_content_feature &&
        this.userRightsContent.readAllowed
      ) {
        const contentMenu = {
          new: true,
          route: "/content/overview",
          text: this.$t("navbar.content"),
        };
        menuData.push(contentMenu);
      }

      if (
        this.isContentV2ActivatedOnCurrentSite &&
        this.userRightsContent.readAllowed
      ) {
        const contentv2Menu = {
          id: "contentv2",
          route: "/contentv2",
          text: this.$t("contentv2.contents"),
        };
        menuData.push(contentv2Menu);
      }

      if (
        this.isRoleUser &&
        this.useAuthentificationStore.active_site_full
          ?.settings_performance_reviews_v1 &&
        (this.userRightsPerformanceReviewsCampaign.readAllowed ||
          this.userRightsPerformanceReviewsModel.readAllowed ||
          this.userRightsPerformanceReviewsAnalysis.readAllowed)
      ) {
        menuData.push({
          id: "performance-reviews",
          route: "/performance-reviews/campaigns",
          text: this.$t("global.performance_reviews"),
        });
      }

      if (
        this.useAuthentificationStore.active_site_full
          ?.settings_performance_reviews_v2 &&
        this.isRoleUser &&
        (this.userRightsPerformanceReviewsCampaign.readAllowed ||
          this.userRightsPerformanceReviewsModel.readAllowed)
      ) {
        menuData.push({
          id: "performance-reviews-v2",
          route: "/performance-reviews-v2",
          text: this.$t("performance_reviews_v2.performance_reviews"),
        });
      }

      if (this.isRoleUser && this.userRightsWorkforcePlanning.readAllowed) {
        const workforcePlanningMenu = {
          route: "/workforce-planning",
          text: this.$t("navbar.workforce_planning"),
        };
        menuData.push(workforcePlanningMenu);
      }

      if (this.isRoleUser && this.userRightsKPIsReadAllowed) {
        const kpis = {
          route: "/kpis-dashboard/skills",
          text: this.$t("navbar.kpis"),
        };
        menuData.push(kpis);
      }

      return menuData;
    },
    async goTo(routeName: string) {
      if (routeName === "profile" && this.userData) {
        if (
          this.$router.currentRoute.name === "profile" &&
          this.$route.params.id === this.userData.id
        )
          return;
        await this.$router.push({
          name: "profile",
          params: { id: this.userData.id },
        });
      } else if (routeName === "preferences") {
        this.buildComponentPopin({
          component: PopinParameters,
          options: {
            persistent: false,
          },
        });
      } else if (routeName === "Approbations" && this.userData) {
        if (this.$router.currentRoute.name === "Approbations") return;
        await this.$router.push({
          name: "Approbations",
          params: { id: this.userData.id },
        });
      }
    },
    hotReload() {
      const d = new Date().getTime();
      window.location.href = `${window.location}?${d?.toString()}`;
    },
    itemChange() {
      this.$nextTick(() => {
        this.search = "";
        this.select = null;
        this.itemsFound = [];
      });
    },
    logHelpdesk() {
      segmentTriggerEvent("helpdesk");
    },
    async logout() {
      await this.$router.push({ name: "logout" });
    },
    querySelections(value: any) {
      logEvent(getAnalytics(), "search_engine");
      this.loadingSearchBar = true;

      const searchValue: string = removeChars(value).toLowerCase();

      if (
        this.skillsList &&
        this.employeesList &&
        this.clearancesList &&
        this.teamsList
      ) {
        const skillsfound = this.skillsList.filter((x) => {
          if (x.status !== "active" && x.status !== undefined) {
            // remove non-skill user from search
            return false;
          }

          const xSearch = removeChars(x.search).toLowerCase();
          let found = false;
          if (xSearch) {
            found = xSearch.includes(searchValue);
          }
          return found;
        });
        const employeesfound = this.employeesList.filter((x) => {
          if (x.status !== "active" && x.status !== undefined) {
            // remove non-active user from search
            return false;
          }

          const xSearch = removeChars(x.search).toLowerCase();
          let found = false;
          if (xSearch) {
            found = xSearch.includes(searchValue);
          }
          return found;
        });
        const clearancesfound = this.clearancesList.filter((x) => {
          if (x.status !== "active" && x.status !== undefined) {
            // remove non-clearance user from search
            return false;
          }

          const xSearch = removeChars(x.search).toLowerCase();
          let found = false;
          if (xSearch) {
            found = xSearch.includes(searchValue);
          }
          return found;
        });
        const teamsfound = this.teamsList.filter((x) => {
          if (x.status !== "active" && x.status !== undefined) {
            // remove non-team user from search
            return false;
          }

          const xSearch = removeChars(x.search).toLowerCase();
          let found = false;
          if (xSearch) {
            found = xSearch.includes(searchValue);
          }
          return found;
        });

        this.itemsFound = sortBy(
          [
            ...skillsfound,
            ...employeesfound,
            ...clearancesfound,
            ...teamsfound,
          ],
          (o) => ("name" in o ? o.name : o.team_name),
        );
      }

      this.loadingSearchBar = false;
    },
    setLanguage() {
      if (this?.userData?.language) {
        const langISO = this.userData.language;
        this.$i18n.locale = langISO;
      } else {
        const langISO = "en";
        this.$i18n.locale = langISO;
      }
    },
    updateApprobationsNotifications() {
      try {
        if (!this.useAuthentificationStore.data) {
          throw new Error("No connected user found");
        }
        const userID = this.useAuthentificationStore.data.id;
        const approbationsForUser = getApprobationsForUser(userID);
        const hasContentNotification = this.isContentV2ActivatedOnCurrentSite
          ? this.pendingApprovalsCount > 0
          : approbationsForUser.contentApprobationsResponsabilities.filter(
              (item) => !item.isExternalObserver,
            ).length > 0;
        const hasQuestionnaireNotification =
          this.waitingQuestionnaireApprobations.length > 0;
        this.hasNotifications =
          hasContentNotification || hasQuestionnaireNotification;
      } catch (error) {
        console.error(error);
      }
    },
  },
  apollo: {
    Questionnaires(): any {
      return {
        query: Questionnaires_query,
        result({
          data,
        }: {
          data: {
            Questionnaires: any[];
          };
        }) {
          if (data?.Questionnaires) {
            this.waitingQuestionnaireApprobations =
              cloneDeep(data?.Questionnaires) || [];
          }
        },
        skip() {
          return this.isRoleAdmin;
        },
        update: (data: any) => data,
        variables() {
          return {
            where: {
              approbations: {
                none: {
                  approbatorUser_id: {
                    equals: this.userID,
                  },
                },
              },
              approbators: {
                some: {
                  approbators: {
                    some: {
                      id: {
                        equals: this.userID,
                      },
                    },
                  },
                },
              },
              needApprobation: {
                equals: true,
              },
              published: {
                equals: EBasePublish.need_revision,
              },
              status: {
                equals: EBaseStatus.active,
              },
            },
          };
        },
      };
    },
  },
});
</script>
<style lang="scss">
.v-application {
  .link-factory {
    display: block;
    width: 100%;
    height: 100%;
    padding: 0.75rem;
    color: initial;
    text-decoration: none;

    &.router-link-active {
      color: var(--v-anchor-base);
    }
  }

  .v-app-bar {
    z-index: 3 !important;
  }
}

.v-list--nav .mdi-menu-down::before {
  content: "" !important;
}

.mdi-menu-down::after {
  content: "" !important;
}

.v-list-group__header {
  padding-left: 0 !important;
  margin-top: 0 !important;
  margin-bottom: 0 !important;
  margin-left: 0 !important;
}

.v-list-item {
  margin-top: 0 !important;
  margin-bottom: 0 !important;
}

.v-list-item__icon {
  min-width: 0 !important;
}

.v-list--dense {
  padding-left: 0 !important;
}
</style>

<style lang="scss" module>
.avatarRedDot {
  position: absolute;
  bottom: 2px;
  left: 50px;
}
</style>
