import { EResolverName } from "@/tscript/interfaces";
import { getToken } from "@/vue-apollo";

import { deleteAbsence } from "../_tables/absence";
import { deleteCategory } from "../_tables/category";
import { deleteCellAbsence } from "../_tables/cellAbsence";
import { deleteCellResult } from "../_tables/cellResult";
import { deleteCellShift } from "../_tables/cellShift";
import { deleteClearance, deleteManyClearance } from "../_tables/clearances";
import { deleteClosure } from "../_tables/closures";
import { deleteContract, deleteManyContract } from "../_tables/contract";
import { deleteEmployee, deleteManyEmployee } from "../_tables/employee";
import { deleteExternalTrainer } from "../_tables/externalTrainers";
import { deleteFactory, deleteManyFactory } from "../_tables/factories";
import { deleteFilesAttachment } from "../_tables/filesAttachment";
import { deleteFolder } from "../_tables/folder";
import { deleteLClearance, deleteManyLClearance } from "../_tables/lclearances";
import {
  deleteLPositionClosed,
  deleteManyLPositionClosed,
} from "../_tables/lpositionClosed";
import { deleteLRestriction } from "../_tables/lrestrictions";
import { deleteLSkill, deleteManyLSkill } from "../_tables/lskills";
import { deleteLTraining } from "../_tables/ltrainings";
import { deleteNote } from "../_tables/notes";
import { deleteManyPosition, deletePosition } from "../_tables/positions";
import { deleteRestriction } from "../_tables/restriction";
import { deleteShift } from "../_tables/shifts";
import { deleteSite } from "../_tables/sites";
import { deleteSkillLegend } from "../_tables/skillLegend";
import { deleteManySkill, deleteSkill } from "../_tables/skills";
import {
  deleteManySubposition,
  deleteSubposition,
} from "../_tables/subpositions";
import { deleteManyTeam, deleteTeam } from "../_tables/teams";
import { deleteUser } from "../_tables/users";

export const deleteSwitcher = async (
  resolverName: EResolverName,
  payload: any,
) => {
  // Store token into localstorage
  await getToken();

  switch (resolverName) {
    case EResolverName.absence:
      await deleteAbsence(payload);
      break;
    case EResolverName.category:
      await deleteCategory(payload);
      break;
    case EResolverName.cellAbsence:
      await deleteCellAbsence(payload);
      break;
    case EResolverName.cellResult:
      await deleteCellResult(payload);
      break;
    case EResolverName.cellShift:
      await deleteCellShift(payload);
      break;
    case EResolverName.clearance:
      await deleteClearance(payload);
      break;
    case EResolverName.closure:
      await deleteClosure(payload);
      break;
    case EResolverName.contract:
      await deleteContract(payload);
      break;
    case EResolverName.employee:
      await deleteEmployee(payload);
      break;
    case EResolverName.external_trainer:
      await deleteExternalTrainer(payload);
      break;
    case EResolverName.factory:
      await deleteFactory(payload);
      break;
    case EResolverName.filesAttachment:
      await deleteFilesAttachment(payload);
      break;
    case EResolverName.folder:
      await deleteFolder(payload);
      break;
    case EResolverName.lclearance:
      await deleteLClearance(payload);
      break;
    case EResolverName.lposition_closed:
      await deleteLPositionClosed(payload);
      break;
    case EResolverName.lrestriction:
      await deleteLRestriction(payload);
      break;
    case EResolverName.lskill:
      await deleteLSkill(payload);
      break;
    case EResolverName.ltraining:
      await deleteLTraining(payload);
      break;
    case EResolverName.note:
      await deleteNote(payload);
      break;
    case EResolverName.position:
      await deletePosition(payload);
      break;
    case EResolverName.restriction:
      await deleteRestriction(payload);
      break;
    case EResolverName.shift:
      await deleteShift(payload);
      break;
    case EResolverName.site:
      await deleteSite(payload);
      break;
    case EResolverName.skill:
      await deleteSkill(payload);
      break;
    case EResolverName.skillLegend:
      await deleteSkillLegend(payload);
      break;
    case EResolverName.subposition:
      await deleteSubposition(payload);
      break;
    case EResolverName.team:
      await deleteTeam(payload);
      break;
    case EResolverName.user:
      await deleteUser(payload);
      break;
    default:
      break;
  }
};

export const deleteManySwitcher = async (
  resolverName: EResolverName,
  payload: any,
) => {
  // Store token into localstorage
  await getToken();
  switch (resolverName) {
    case EResolverName.clearance:
      await deleteManyClearance(payload);
      break;
    case EResolverName.contract:
      await deleteManyContract(payload);
      break;
    case EResolverName.employee:
      await deleteManyEmployee(payload);
      break;
    case EResolverName.factory:
      await deleteManyFactory(payload);
      break;
    case EResolverName.lclearance:
      await deleteManyLClearance(payload);
      break;
    case EResolverName.lposition_closed:
      await deleteManyLPositionClosed(payload);
      break;
    case EResolverName.lskill:
      await deleteManyLSkill(payload);
      break;
    case EResolverName.position:
      await deleteManyPosition(payload);
      break;
    case EResolverName.skill:
      await deleteManySkill(payload);
      break;
    case EResolverName.subposition:
      await deleteManySubposition(payload);
      break;
    case EResolverName.team:
      await deleteManyTeam(payload);
      break;
    default:
      break;
  }
};
