export const app_files_formats = {
  files: [
    ".pdf",
    ".doc",
    ".docx",
    ".ppt",
    ".pptx",
    ".pps",
    ".ppsx",
    ".odt",
    ".xls",
    ".xlsx",
    ".cvs",
    ".csv",
    ".txt",
  ],
  image: [".jpg", ".png", ".gif", ".tiff", ".jpeg"],
  office: ["XLS", "XLSX", "CSV", "DOC", "DOCX", "PPT", "PPTX", "ODT"],
  video: [".avi", ".flv", ".mov", ".mpeg4", ".wmv", ".mp4"],
};

export const files_formats = {
  files: {
    color: "#D8F5C8",
    formats: app_files_formats.files,
  },
  image: {
    color: "#FBEABC",
    formats: app_files_formats.image,
  },
  video: {
    color: "#dff7f6",
    formats: app_files_formats.video,
  },
};
