import {
  CreateLTraining,
  DeleteLTraining,
  LTrainings,
  UpdateLTraining,
} from "@/graphql/employees/LTraining";
import { useEmployeeTrainingStore } from "@/pinia/collections";
import { setGlobalStoreGraphQL } from "@/utils/graphql.utils";
import { apolloProvider } from "@/vue-apollo";

export const createLTraining = async (payload: { data: any }) => {
  try {
    return await apolloProvider.defaultClient.mutate({
      mutation: CreateLTraining,
      variables: {
        data: payload.data,
      },
    });
  } catch (error) {
    console.error(error);
  }
};

export const updateLTraining = async (payload: { data: any; where: any }) => {
  try {
    return await apolloProvider.defaultClient.mutate({
      mutation: UpdateLTraining,
      update: (store, data) => {
        updateStoreAfterUpdateLTraining(store, data.data.updateLTraining);
      },
      variables: {
        data: payload.data,
        where: payload.where,
      },
    });
  } catch (error) {
    console.error(error);
  }
};

export const deleteLTraining = async (payload: { where: any }) => {
  try {
    return await apolloProvider.defaultClient.mutate({
      mutation: DeleteLTraining,
      update: (store, data) => {
        updateStoreAfterDeleteLTraining(store, data.data.deleteLTraining.id);
      },
      variables: {
        where: payload.where,
      },
    });
  } catch (error) {
    console.error(error);
  }
};

export const archiveLTraining = async (payload: { data: any; where: any }) => {
  try {
    return await apolloProvider.defaultClient.mutate({
      mutation: UpdateLTraining,
      update: (store, data) => {
        updateStoreAfterArchiveLTraining(store, data.data.updateLTraining);
      },
      variables: {
        data: payload.data,
        where: payload.where,
      },
    });
  } catch (error) {
    console.error(error);
  }
};

const updateStoreAfterDeleteLTraining = (store, id) => {
  const data = store.readQuery({
    query: LTrainings,
  });
  const index = data.LTrainings.findIndex((c) => c.id === id);
  if (index !== -1) {
    data.LTrainings.splice(index, 1);
    setGlobalStoreGraphQL(useEmployeeTrainingStore(), data.LTrainings);
    store.writeQuery({
      data,
      query: LTrainings,
    });
  }
};

const updateStoreAfterUpdateLTraining = (store, updateLTraining) => {
  const data = store.readQuery({
    query: LTrainings,
  });
  const index = data?.LTrainings.findIndex((c) => c.id === updateLTraining.id);
  if (index >= 0) {
    data.LTrainings.splice(index, 1, updateLTraining);
    setGlobalStoreGraphQL(useEmployeeTrainingStore(), data.LTrainings);
    // Write back to the cache
    store.writeQuery({
      data,
      query: LTrainings,
    });
  }
};

const updateStoreAfterArchiveLTraining = (store, id) => {
  const data = store.readQuery({
    query: LTrainings,
  });
  const index = data.LTrainings.findIndex((c) => c.id === id);
  if (index !== -1) {
    data.LTrainings.splice(index, 1);
    setGlobalStoreGraphQL(useEmployeeTrainingStore(), data.LTrainings);
    store.writeQuery({
      data,
      query: LTrainings,
    });
  }
};
