const childrenKpisDashboard = [
  {
    meta: {
      requiresAuth: true,
    },
    name: "KpisSkills",
    path: "skills",
  },
  {
    meta: {
      requiresAuth: true,
    },
    name: "KpisTraining",
    path: "training",
  },
  {
    meta: {
      requiresAuth: true,
    },
    name: "KpisPlanning",
    path: "planning",
  },
  {
    meta: {
      requiresAuth: true,
    },
    name: "KpisPerformanceReviews",
    path: "performance-reviews",
  },
  {
    meta: {
      requiresAuth: true,
    },
    name: "KpisEmployees",
    path: "employees",
  },
];

export default childrenKpisDashboard;
