import { defineStore } from "pinia";

import type { AvailableSlotsFilter } from "@/tscript/interfaces";

import { useAuthentificationStore } from "../user";

export const usePlanningDisplayStore = defineStore("usePlanningDisplay", {
  actions: {
    setIsSearching(value: boolean) {
      this.isSearching = value;
    },
  },
  state: () => {
    const userData = useAuthentificationStore().data;
    const userId = userData?.id;
    const featureFlagWorkload = userData?.site?.settings_workload;
    const featureFlagWorkloadManagementv2 =
      userData?.site?.settings_workload_management;

    return {
      alignByShift:
        localStorage.getItem(`planningDisplay-alignByShift-${userId}`) ===
        "true",
      availableSlotsFilterResult: null as AvailableSlotsFilter | null,
      isAlign: localStorage.getItem(`planningDisplay-isAlign-${userId}`)
        ? localStorage.getItem(`planningDisplay-isAlign-${userId}`) === "true"
        : false,
      isAlignEmployees: localStorage.getItem(
        `planningDisplay-isAlignEmployees-${userId}`,
      )
        ? localStorage.getItem(`planningDisplay-isAlignEmployees-${userId}`) ===
        "true"
        : false,
      isAlignHours: localStorage.getItem(
        `planningDisplay-isAlignHours-${userId}`,
      )
        ? localStorage.getItem(`planningDisplay-isAlignHours-${userId}`) ===
        "true"
        : false,
      isAlignShifts: localStorage.getItem(
        `planningDisplay-isAlignShifts-${userId}`,
      )
        ? localStorage.getItem(`planningDisplay-isAlignShifts-${userId}`) ===
        "true"
        : false,
      isCompact: localStorage.getItem(`planningDisplay-isCompact-${userId}`)
        ? localStorage.getItem(`planningDisplay-isCompact-${userId}`) === "true"
        : false,
      isSearching: localStorage.getItem(`planningDisplay-isSearching-${userId}`)
        ? localStorage.getItem(`planningDisplay-isSearching-${userId}`) ===
        "true"
        : false,
      isShowingEmployeeCompanyId: localStorage.getItem(
        `planningDisplay-isShowingEmployeeCompanyId-${userId}`,
      )
        ? localStorage.getItem(
          `planningDisplay-isShowingEmployeeCompanyId-${userId}`,
        ) === "true"
        : false,
      isShowingExternalAssignation: localStorage.getItem(
        `planningDisplay-isShowingExternalAssignation-${userId}`,
      )
        ? localStorage.getItem(
          `planningDisplay-isShowingExternalAssignation-${userId}`,
        ) === "true"
        : true,
      isShowingTime: localStorage.getItem(
        `planningDisplay-isShowingTime-${userId}`,
      )
        ? localStorage.getItem(`planningDisplay-isShowingTime-${userId}`) ===
        "true"
        : false,
      showAbsencesCells: localStorage.getItem(
        `planningDisplay-showAbsencesCells-${userId}`,
      )
        ? localStorage.getItem(
          `planningDisplay-showAbsencesCells-${userId}`,
        ) === "true"
        : true,
      showAbsencesReason: localStorage.getItem(
        `planningDisplay-showAbsencesReason-${userId}`,
      )
        ? localStorage.getItem(
          `planningDisplay-showAbsencesReason-${userId}`,
        ) === "true"
        : true,
      showAssignationsCells: localStorage.getItem(
        `planningDisplay-showAssignationsCells-${userId}`,
      )
        ? localStorage.getItem(
          `planningDisplay-showAssignationsCells-${userId}`,
        ) === "true"
        : true,
      showAssignationsWarnings: localStorage.getItem(
        `planningDisplay-showAssignationsWarnings-${userId}`,
      )
        ? localStorage.getItem(
          `planningDisplay-showAssignationsWarnings-${userId}`,
        ) === "true"
        : true,
      showAssignationTags: localStorage.getItem(
        `planningDisplay-showAssignationTags-${userId}`,
      )
        ? localStorage.getItem(
          `planningDisplay-showAssignationTags-${userId}`,
        ) === "true"
        : true,
      showCategories: localStorage.getItem(
        `planningDisplay-showCategories-${userId}`,
      )
        ? localStorage.getItem(`planningDisplay-showCategories-${userId}`) ===
        "true"
        : false,
      showContracts: localStorage.getItem(
        `planningDisplay-showContracts-${userId}`,
      )
        ? localStorage.getItem(`planningDisplay-showContracts-${userId}`) ===
        "true"
        : false,
      showDelta:
        localStorage.getItem(`planningDisplay-showDelta-${userId}`) === "true",
      showOnlyFreeEmployeesWithPlannedShift: localStorage.getItem(
        `planningDisplay-showOnlyFreeEmployeesWithPlannedShift-${userId}`,
      )
        ? localStorage.getItem(
          `planningDisplay-showOnlyFreeEmployeesWithPlannedShift-${userId}`,
        ) === "true"
        : false,
      showOnlyFreePositionsNotClosed: localStorage.getItem(
        `planningDisplay-showOnlyFreePositionsNotClosed-${userId}`,
      )
        ? localStorage.getItem(
          `planningDisplay-showOnlyFreePositionsNotClosed-${userId}`,
        ) === "true"
        : false,
      showPlannedShiftCells: localStorage.getItem(
        `planningDisplay-showPlannedShiftCells-${userId}`,
      )
        ? localStorage.getItem(
          `planningDisplay-showPlannedShiftCells-${userId}`,
        ) === "true"
        : true,
      showRealShiftCells: localStorage.getItem(
        `planningDisplay-showRealShiftCells-${userId}`,
      )
        ? localStorage.getItem(
          `planningDisplay-showRealShiftCells-${userId}`,
        ) === "true"
        : true,
      showShifts: localStorage.getItem(`planningDisplay-showShifts-${userId}`)
        ? localStorage.getItem(`planningDisplay-showShifts-${userId}`) ===
        "true"
        : true,
      showShiftsName: localStorage.getItem(
        `planningDisplay-showShiftsName-${userId}`,
      )
        ? localStorage.getItem(`planningDisplay-showShiftsName-${userId}`) ===
        "true"
        : true,
      showTotalTable:
        localStorage.getItem(`planningDisplay-showTotalTable-${userId}`) ===
        "true"
          ? localStorage.getItem(`planningDisplay-showTotalTable-${userId}`) ===
          "true"
          : false,
      showWorkLoad:
        localStorage.getItem(`planningDisplay-showWorkLoad-${userId}`) ===
        "true",
      showWorkLoadManagement: featureFlagWorkload
        ? localStorage.getItem(
          `planningDisplay-showWorkLoadManagement-${userId}`,
        ) === "true"
        : false,
      showWorkLoadManagementv2: featureFlagWorkloadManagementv2
        ? localStorage.getItem(
          `planningDisplay-showWorkLoadManagementv2-${userId}`,
        ) === "true"
        : false,
    };
  },
});
