import { CreateUser, DeleteUser, UpdateUser } from "@/graphql/Users";
import { useUserGlobalStore } from "@/pinia/collections";
import { apolloProvider } from "@/vue-apollo";

const refetch = async () => useUserGlobalStore().refetch("no-cache", false);

export const createUser = async (payload: {
  data: unknown;
  password?: string;
}) => {
  try {
    const result = await apolloProvider.defaultClient.mutate({
      mutation: CreateUser,
      variables: {
        data: payload.data,
        password: payload.password,
      },
    });
    await refetch();
    return result;
  } catch (error: unknown) {
    console.error("GRAPHQL ERROR", error);
  }
};

export const updateUser = async (payload: {
  data: unknown;
  where: unknown;
}) => {
  try {
    const result = await apolloProvider.defaultClient.mutate({
      mutation: UpdateUser,
      variables: {
        data: payload.data,
        where: payload.where,
      },
    });
    await useUserGlobalStore().refetch("no-cache", false);
    return result;
  } catch (error: unknown) {
    console.error("GRAPHQL ERROR", error);
  }
};

export const deleteUser = async (payload: { where: unknown }) => {
  try {
    const result = await apolloProvider.defaultClient.mutate({
      mutation: DeleteUser,
      variables: {
        where: payload.where,
      },
    });
    await refetch();
    return result;
  } catch (error: unknown) {
    console.error("GRAPHQL ERROR", error);
  }
};

export const archiveUser = async (payload: {
  data: unknown;
  where: unknown;
}) => {
  try {
    const result = await apolloProvider.defaultClient.mutate({
      mutation: UpdateUser,
      variables: {
        data: payload.data,
        where: payload.where,
      },
    });
    await refetch();
    return result;
  } catch (error: unknown) {
    console.error("GRAPHQL ERROR", error);
  }
};
export const unarchiveUser = async (payload: {
  data: unknown;
  where: unknown;
}) => {
  try {
    const result = await apolloProvider.defaultClient.mutate({
      mutation: UpdateUser,
      variables: {
        data: payload.data,
        where: payload.where,
      },
    });
    await refetch();
    return result;
  } catch (error: unknown) {
    console.error("GRAPHQL ERROR", error);
  }
};
