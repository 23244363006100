const settingsChildren = [
  {
    alias: "",
    component: () => import("@/components/settings/SettingsContract.vue"),
    name: "SettingsContractView",
    path: "contract",
  },
  {
    children: [
      {
        name: "SettingsPlanningViewDefault",
        path: "",
        redirect: { name: "SettingsPlanningViewHours" },
      },
      {
        component: () =>
          import("@/components/settings/SettingsPlanningHours.vue"),
        name: "SettingsPlanningViewHours",
        path: "hours",
      },
      {
        component: () =>
          import("@/components/settings/SettingsPlanningOrder.vue"),
        name: "SettingsPlanningViewOrder",
        path: "order",
      },
      {
        component: () =>
          import("@/components/settings/SettingsPlanningCloseGeneral.vue"),
        name: "SettingsPlanningViewCloseGeneral",
        path: "close-general",
      },
      {
        component: () =>
          import("@/components/settings/SettingsPlanningAssignationTags.vue"),
        name: "SettingsPlanningViewAssignationTags",
        path: "assignation-tag",
      },
    ],
    component: () => import("@/components/settings/SettingsPlanning.vue"),
    path: "planning",
  },
  {
    component: () =>
      import("@/components/settings/SettingsExternalTrainers.vue"),
    name: "SettingsExternalTrainers",
    path: "external-trainers",
  },
  {
    component: () => import("@/components/settings/SettingsFolders.vue"),
    name: "SettingsFolders",
    path: "folders",
  },
  {
    component: () => import("@/components/settings/SettingsRestrictions.vue"),
    name: "SettingsRestrictions",
    path: "restrictions",
  },
  {
    component: () => import("@/components/settings/SettingsAbsences.vue"),
    name: "SettingsAbsences",
    path: "absences",
  },
  {
    component: () => import("@/components/settings/SettingsContent.vue"),
    name: "SettingsContent",
    path: "content",
  },
  {
    component: () => import("@/modules/contents/views/ContentSettings.vue"),
    name: "ContentSettings",
    path: "contentv2",
  },
  {
    component: () => import("@/components/settings/SettingsCategories.vue"),
    name: "SettingsCategories",
    path: "categories",
  },
];

export default settingsChildren;
