import { defineStore } from "pinia";

import type { Result } from "@/tscript/logic/planningBuilder";

import { usePlanningFilterStore } from "./planning-filters";

export const usePlanningFastStore = defineStore("usePlanningFast", {
  getters: {
    collection_not_ready(): boolean {
      try {
        return false;
      } catch (error: any) {
        console.error(error);
        return false;
      }
    },
    dataPlanningFast(): Result {
      usePlanningFilterStore().setData(this.cells, this.absences, this.shifts);
      return {
        absences: this.absences,
        cells: this.cells,
        shifts: this.shifts,
      };
    },
  },
  state() {
    return {
      absences: [] as Result["absences"],
      cells: [] as Result["cells"],
      month: null as null | number,
      shifts: [] as Result["shifts"],
      week: null as null | number,
    };
  },
});
