const childrenTraining = [
  {
    component: () => import("@/views/trainingv3/Trainings"),
    meta: {
      requiresAuth: true,
    },
    name: "trainingTrainings",
    path: "trainings",
  },
  {
    component: () => import("@/views/trainingv3/Questionnaires"),
    meta: {
      requiresAuth: true,
    },
    name: "trainingQuestionnaires",
    path: "questionnaire",
  },
  {
    component: () => import("@/views/trainingv3/Analysis.vue"),
    meta: {
      requiresAuth: true,
    },
    name: "trainingAnalysis",
    path: "analysis",
  },
  {
    component: () => import("@/views/trainingv3/QuestionnaireAnalysis.vue"),
    meta: {
      requiresAuth: true,
    },
    name: "questionnaireAnalysis",
    path: "analysis/questionnaire/:id",
  },
];

export default childrenTraining;
