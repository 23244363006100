import {
  CreateLClearance,
  DeleteLClearance,
  DeleteManyLClearance,
  UpdateLClearance,
} from "@/graphql/employees/LClearance";
import { useEmployeeClearanceStore } from "@/pinia/collections";
import { apolloProvider } from "@/vue-apollo";

const refetch = async () =>
  useEmployeeClearanceStore().refetch("no-cache", false);

export const createLClearance = async (payload: { data: any }) => {
  try {
    const result = await apolloProvider.defaultClient.mutate({
      mutation: CreateLClearance,
      variables: {
        data: payload.data,
      },
    });
    await refetch();
    return result;
  } catch (error: any) {
    console.error("GRAPHQL ERROR", error);
  }
};

export const updateLClearance = async (payload: { data: any; where: any }) => {
  try {
    const result = await apolloProvider.defaultClient.mutate({
      mutation: UpdateLClearance,
      variables: {
        data: payload.data,
        where: payload.where,
      },
    });
    await refetch();
    return result;
  } catch (error: any) {
    console.error("GRAPHQL ERROR", error);
  }
};

export const deleteLClearance = async (payload: { where: any }) => {
  try {
    const result = await apolloProvider.defaultClient.mutate({
      mutation: DeleteLClearance,
      variables: {
        where: payload.where,
      },
    });
    await refetch();
    return result;
  } catch (error: any) {
    console.error("GRAPHQL ERROR", error);
  }
};

export const archiveLClearance = async (payload: { data: any; where: any }) => {
  try {
    const result = await apolloProvider.defaultClient.mutate({
      mutation: UpdateLClearance,
      variables: {
        data: payload.data,
        where: payload.where,
      },
    });
    await refetch();
    return result;
  } catch (error: any) {
    console.error("GRAPHQL ERROR", error);
  }
};

export const deleteManyLClearance = async (payload: { where: any }) => {
  try {
    const result = await apolloProvider.defaultClient.mutate({
      mutation: DeleteManyLClearance,
      variables: {
        where: payload.where,
      },
    });
    await refetch();
    return result;
  } catch (error: any) {
    console.error("GRAPHQL ERROR", error);
  }
};
