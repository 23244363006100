import { gql } from "graphql-tag";

export const CreateLClearance = gql`
  mutation CreateLClearance($data: LClearanceCreateInput!) {
    createLClearance(data: $data) {
      id
      status
      start_date
      end_date
      employee_id
      clearance_id
      will_expire
      expired
      clearance {
        name
        validity
      }
    }
  }
`;

export const UpdateLClearance = gql`
  mutation UpdateLClearance(
    $where: LClearanceWhereUniqueInput!
    $data: LClearanceUpdateInput!
  ) {
    updateLClearance(where: $where, data: $data) {
      id
      status
      start_date
      end_date
      employee_id
      clearance_id
      will_expire
      expired
      clearance {
        name
        validity
      }
    }
  }
`;

export const DeleteLClearance = gql`
  mutation DeleteLClearance($where: LClearanceWhereUniqueInput!) {
    deleteLClearance(where: $where) {
      id
      status
      start_date
      end_date
      employee_id
      clearance_id
      will_expire
      expired
      clearance {
        name
        validity
      }
    }
  }
`;

export const DeleteManyLClearance = gql`
  mutation DeleteManyLClearance($where: LClearanceWhereInput) {
    deleteManyLClearance(where: $where) {
      count
    }
  }
`;

export const LClearances = gql`
  query LClearances($where: LClearanceWhereInput) {
    LClearances(where: $where) {
      id
      status
      start_date
      end_date
      employee_id
      clearance_id
      will_expire
      expired
      clearance {
        name
        validity
      }
    }
  }
`;
