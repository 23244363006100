// filter Actives
export const filterActives = (globalDocs) => {
  return globalDocs.filter((doc) => doc?.status === "active");
};

// filter Archives
export const filterArchived = (globalDocs) => {
  return globalDocs.filter((doc) => doc?.status === "archived");
};
// Query GraphQL Global Store Data
export const setGlobalStoreGraphQL = (store, queryRes) => {
  store.documentsScoped = queryRes;
  store.documentsRealTimeScoped = queryRes;
  store.activesScoped = queryRes && filterActives(queryRes);
  store.archivedScoped = queryRes && filterArchived(queryRes);
  store.hasRealtime = true;
  store.hasSubcribed = true;
  store.queryFetched = true;
};

export const resetStores = (stores: any[]) => {
  for (const storeObject of stores) {
    if (storeObject.isGlobalStore) {
      storeObject.store.reset();
    } else {
      storeObject.store.$reset();
    }
  }
};
