import { filter, includes } from "lodash";

import { useTeamGlobalStore } from "@/pinia/collections";
import { useAuthentificationStore } from "@/pinia/user";

import type { Roles } from "../interfaces";
import type { TeamID } from "../mercateam";

export const checkRole = (
  roles?: Roles[],
  teamManager?: { teamIds: TeamID[] },
): boolean => {
  const data = useAuthentificationStore().data;
  if (!data) return false;
  if (includes(roles, data.role) && !!roles && roles.length > 0) {
    return true;
  }
  if (teamManager && teamManager.teamIds.length > 0) {
    const teams = filter(useTeamGlobalStore().actives, (t) =>
      includes(teamManager.teamIds, t.id),
    );
    for (const team of teams) {
      if (team.team_leader_id === data.id) return true;
      const ids = team.team_leaders?.map((leader) => leader.id);
      if (includes(ids, data.id)) return true;
    }
  }
  return false;
};
