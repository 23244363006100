import {
  Closures,
  CreateClosure,
  DeleteClosure,
  UpdateClosure,
} from "@/graphql/settings/planning/closures";
import { useParameterClosureStore } from "@/pinia/collections";
import { setGlobalStoreGraphQL } from "@/utils/graphql.utils";
import { apolloProvider } from "@/vue-apollo";

export const createClosure = async (payload: { data: any }) => {
  try {
    return await apolloProvider.defaultClient.mutate({
      mutation: CreateClosure,
      update: (store, { data: { createClosure } }) => {
        updateStoreAfterCreateClosure(store, createClosure);
      },
      variables: {
        data: payload.data,
      },
    });
  } catch (error) {
    console.error(error);
  }
};

export const updateClosure = async (payload: { data: any; where: any }) => {
  try {
    return await apolloProvider.defaultClient.mutate({
      mutation: UpdateClosure,
      update: (store, data) => {
        updateStoreAfterUpdateClosure(store, data.data.updateClosure);
      },
      variables: {
        data: payload.data,
        where: payload.where,
      },
    });
  } catch (error) {
    console.error(error);
  }
};

export const deleteClosure = async (payload: { where: any }) => {
  try {
    return await apolloProvider.defaultClient.mutate({
      mutation: DeleteClosure,
      update: (store, data) => {
        updateStoreAfterDeleteClosure(store, data.data.deleteClosure.id);
      },
      variables: {
        where: payload.where,
      },
    });
  } catch (error) {
    console.error(error);
  }
};

const updateStoreAfterCreateClosure = (store, createClosure) => {
  const data = store.readQuery({
    query: Closures,
  });
  data.Closures.push(createClosure);
  setGlobalStoreGraphQL(useParameterClosureStore(), data.Closures);
  store.writeQuery({
    data,
    query: Closures,
  });
};

const updateStoreAfterDeleteClosure = (store, id) => {
  const data = store.readQuery({
    query: Closures,
  });
  const index = data.Closures.findIndex((c) => c.id === id);
  if (index !== -1) {
    data.Closures.splice(index, 1);
    setGlobalStoreGraphQL(useParameterClosureStore(), data.Closures);
    store.writeQuery({
      data,
      query: Closures,
    });
  }
};

const updateStoreAfterUpdateClosure = (store, updateClosure) => {
  const data = store.readQuery({
    query: Closures,
  });
  const index = data.Closures.findIndex((c) => c.id === updateClosure.id);
  if (index !== -1) {
    data.Closures.splice(index, 1, updateClosure);
    setGlobalStoreGraphQL(useParameterClosureStore(), data.Closures);
    store.writeQuery({
      data,
      query: Closures,
    });
  }
};
