import { gql } from "graphql-tag";

export const CreateClient = gql`
  mutation createClient($data: ClientCreateInput!) {
    createClient(data: $data) {
      id
      logo_url
      name
      slug
      status
      published
      enable_planning
      has_planning
    }
  }
`;

export const UpdateClient = gql`
  mutation updateClient(
    $data: ClientUpdateInput!
    $where: ClientWhereUniqueInput!
  ) {
    updateClient(data: $data, where: $where) {
      id
      logo_url
      name
      slug
      status
      published
      enable_planning
      has_planning
    }
  }
`;

export const Clients = gql`
  query Clients {
    Clients(orderBy: [{name: asc}]) {
      id
      logo_url
      name
      slug
      status
      published
      enable_planning
      has_planning
    }
  }
`;
