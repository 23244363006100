import {
  find,
  forEach,
  includes,
  intersection,
  isEmpty,
  map,
  orderBy,
} from "lodash";
import { defineStore } from "pinia";
import type { TranslateResult } from "vue-i18n";

import i18n from "@/i18n";
import usePermissions from "@/modules/rights/composables/use-permissions";
import { ESectionName } from "@/modules/rights/types";
import {
  useContentDocumentTypeGlobalStore,
  useContentGlobalStore,
  usePositionGlobalStore,
  usePositionLevelGlobalStore,
  useTeamGlobalStore,
} from "@/pinia/collections";
import { useAuthentificationStore } from "@/pinia/user";
import ClientHelper from "@/tscript/database/ClientHelper";
import {
  BasePublish,
  EContentCategory,
  type IContent,
  type IContentAsGeneric,
  type IContentAsTraining,
  type IContentDetailed,
} from "@/tscript/interfaces";
import type {
  ClearanceID,
  ContentDocumentTypeID,
  ContentID,
  FactoryID,
  PositionID,
  SkillID,
  SubpositionID,
  TeamID,
  UserID,
} from "@/tscript/mercateam";
import { checkRole } from "@/tscript/utils/roles-ts";
import {
  searchForArchived,
  searchForClearances,
  searchForDocumentsType,
  searchForPosition,
  searchForSkills,
  searchForStatus,
} from "@/utils/contentSearch.utils";

interface ICurrentRouteQuery {
  category?: EContentCategory;
  content?: ContentID;
  documentType?: ContentDocumentTypeID;
  factory?: FactoryID;
  position?: PositionID;
  team?: TeamID;
  type?: string;
}

export const useContentSearchStore = defineStore("useContentSearch", {
  actions: {
    cleanAll() {
      this.selectedDocumentsType = [];
      this.selectedStatus = [];
      this.selectedPositions = [];
      this.selectedClearances = [];
      this.selectedSkills = [];
      this.showArchivedContents = false;
    },
  },
  getters: {
    contentsByRouteQuery(): IContent[] | IContentDetailed[] | null {
      const documentTypeDictionary =
        useContentDocumentTypeGlobalStore().dictionary;
      const contentsListActive = this.contentsListManagementView;
      const contentsList = contentsListActive
        ? orderBy(
            contentsListActive,
            ["title", "status", "created_at"],
            ["asc", "asc", "desc"],
          )
        : null;
      if (!contentsList) return null;
      if (!this.currentRouteQuery) return contentsList;
      let contentsListFiltered = contentsList;

      if (this.currentRouteQuery.category) {
        contentsListFiltered = contentsList.filter((content) => {
          return content.category === this.currentRouteQuery?.category;
        });
      }
      const positionID = this.currentRouteQuery.position;
      const type = this.currentRouteQuery.type;
      const teamID = this.currentRouteQuery.team;
      if (positionID) {
        const subpositionDictionary = usePositionLevelGlobalStore().dictionary;
        const positionDictionary = usePositionGlobalStore().dictionary;
        contentsListFiltered = contentsListFiltered.filter((content) => {
          if ((!content.teams || isEmpty(content.teams)) && teamID) {
            let teamsFromPosition: null | TeamID[] | undefined = [];
            if (!positionDictionary || !subpositionDictionary) return false;

            teamsFromPosition = positionDictionary[positionID]?.team_ids;
            if (!teamsFromPosition || isEmpty(teamsFromPosition)) {
              teamsFromPosition = subpositionDictionary[positionID]?.team_ids;
            }
            if (!teamsFromPosition || isEmpty(teamsFromPosition)) return false;
            const itemFounded = includes(teamsFromPosition, teamID);
            return itemFounded;
          }
          if (this.showAllContents) {
            if (type === "position" && positionDictionary) {
              const listOfPositions = [this.currentRouteQuery?.position];

              const parentPosition: any = positionDictionary[positionID];
              forEach(parentPosition?.subpositions, (subposition) => {
                listOfPositions.push(subposition.id);
              });
              return find(listOfPositions, (id) => {
                return includes(content.positions, id);
              });
            }
            if (type === "subposition" && subpositionDictionary) {
              const listOfPositions = [this.currentRouteQuery?.position];

              const subPosition: any = subpositionDictionary[positionID];
              if (subPosition?.position?.id) {
                listOfPositions.push(subPosition?.position?.id);
              }
              return find(listOfPositions, (id) => {
                return includes(content.positions, id);
              });
            }
          }
          return includes(content.positions, this.currentRouteQuery?.position);
        });
      }

      if (this.currentRouteQuery.documentType) {
        contentsListFiltered = contentsList.filter((content) => {
          return content.document_type === this.currentRouteQuery?.documentType;
        });
      }

      const factoryID = this.currentRouteQuery.factory;
      if (factoryID) {
        const teamsListActives = useTeamGlobalStore().actives;
        const teamsFromFactory: TeamID[] = [];
        for (const teamActive of teamsListActives || []) {
          if (teamActive.factory_id === factoryID) {
            teamsFromFactory.push(teamActive.id);
          }
        }
        contentsListFiltered = contentsListFiltered.filter((content) => {
          const allTeamsIDsFromContent = [...(content.teams ?? [])];
          if (!content.factories || isEmpty(content.factories)) {
            return !isEmpty(
              intersection(teamsFromFactory, allTeamsIDsFromContent),
            );
          }
          for (const factoryID of content.factories) {
            const teamsIDsFromContentFactory: TeamID[] = [];
            for (const teamActive of teamsListActives || []) {
              if (teamActive.factory_id === factoryID)
                teamsIDsFromContentFactory.push(teamActive.id);
            }
            allTeamsIDsFromContent.push(...teamsIDsFromContentFactory);
          }
          return !isEmpty(
            intersection(allTeamsIDsFromContent, teamsFromFactory),
          );
        });
      }

      if (teamID) {
        const teamsListActives = useTeamGlobalStore().actives;
        contentsListFiltered = contentsListFiltered.filter((content) => {
          const allTeamsIDsFromContent = [...(content.teams ?? [])];
          if (
            (!content.teams || isEmpty(content.teams)) &&
            (content.factories || !isEmpty(content.factories))
          ) {
            for (const factoryID of content.factories ?? []) {
              const teamsIDsFromContentFactory: TeamID[] = [];
              for (const teamActive of teamsListActives || []) {
                if (teamActive.factory_id === factoryID)
                  teamsIDsFromContentFactory.push(teamActive.id);
              }
              allTeamsIDsFromContent.push(...teamsIDsFromContentFactory);
            }
          }
          return includes(allTeamsIDsFromContent, teamID);
        });
      }

      if (!documentTypeDictionary) return null;

      const contentsListDetailed: any[] = [];

      for (const item of contentsListFiltered) {
        const documentTypeObject = documentTypeDictionary[
          `${item.document_type}`
        ] || { name: "" };
        contentsListDetailed.push({
          ...item,
          document_type_object: documentTypeObject,
          teamsOrFactories: {
            factories: [...(item.factories ?? [])],
            teams: [...(item.teams ?? [])],
          },
        });
      }
      return contentsListDetailed;
    },
    contentsListFiltered(): IContentDetailed[] | null {
      const userId = useAuthentificationStore().data?.id;
      const userRightsContentRestriction = usePermissions(
        ESectionName.Enum.content_restriction,
      );
      let contentsFilteredResult = this.contentsByRouteQuery;
      contentsFilteredResult = searchForDocumentsType(
        contentsFilteredResult,
        this.selectedDocumentsType,
      );
      contentsFilteredResult = searchForPosition(
        contentsFilteredResult,
        this.selectedPositions,
      );
      contentsFilteredResult = searchForStatus(
        contentsFilteredResult,
        this.selectedStatus,
      );
      contentsFilteredResult = searchForClearances(
        contentsFilteredResult,
        this.selectedClearances,
      );
      contentsFilteredResult = searchForSkills(
        contentsFilteredResult,
        this.selectedSkills,
      );
      contentsFilteredResult = searchForArchived(
        contentsFilteredResult,
        this.showArchivedContents,
      );
      if (userRightsContentRestriction.readAllowed) {
        return contentsFilteredResult;
      }
      return (
        contentsFilteredResult?.filter((content: IContent) => {
          return content.isRestricted
            ? content.restrictedAccessUserIDS?.includes(userId || "")
            : true;
        }) || null
      );
    },
    contentsListManagementView() {
      const userRightsContentManagement = usePermissions(
        ESectionName.Enum.content_management,
      );
      const userRightsContentRestriction = usePermissions(
        ESectionName.Enum.content_restriction,
      );
      const contentGlobalStore = useContentGlobalStore();
      if (
        !contentGlobalStore.queryFetched ||
        !useContentDocumentTypeGlobalStore().queryFetched
      )
        return null;
      const authentificationStore = useAuthentificationStore();
      const currentUser = authentificationStore.data?.id;
      const { data } = authentificationStore;
      if (!contentGlobalStore || !currentUser) return;
      const filteredContent: IContent[] = [];

      const contentsListActivesAndArchives = [
        ...(contentGlobalStore.actives || []),
        ...(contentGlobalStore.archived || []),
      ];
      const favoriteTeamData = _getFavoriteTeamsData();
      if (
        !useTeamGlobalStore().queryFetched ||
        !usePositionGlobalStore().queryFetched ||
        !usePositionLevelGlobalStore().queryFetched
      )
        return null;
      //  // Enale the full list for admi
      // Enable the full list for approbators, author
      for (const content of contentsListActivesAndArchives || []) {
        if (favoriteTeamData) {
          if (content.category === EContentCategory.generic) {
            if (
              intersection(
                (content as IContentAsGeneric).teams,
                favoriteTeamData.teams,
              ).length === 0 &&
              intersection(
                (content as IContentAsGeneric).factories,
                favoriteTeamData.factories,
              ).length === 0
            ) {
              continue;
            }
          }
          if (content.category === EContentCategory.training) {
            if (
              intersection((content as IContentAsTraining).positions, [
                ...favoriteTeamData.positions,
                ...favoriteTeamData.subpositions,
              ]).length === 0
            ) {
              continue;
            }
          }
        }
        const approbatorsIDs: UserID[] = [];
        if (content.published === BasePublish.published) {
          filteredContent.push(content);
          continue;
        }
        if (
          content.need_approbation &&
          !content.isApproved &&
          !content.published
        ) {
          const usersListsToApprove = content.usersListsToApprove;
          if (!usersListsToApprove?.length) continue;
          for (const userToApprove of usersListsToApprove) {
            if (!userToApprove.usersWhoCanApprove?.length) continue;
            approbatorsIDs.push(...userToApprove.usersWhoCanApprove);
          }
        }
        if (
          currentUser === content.author ||
          // Biars YV2A7lSMthaUslGKrhUr
          (data?.site_id === "YV2A7lSMthaUslGKrhUr" &&
            userRightsContentManagement.updateAllowed.value) ||
          includes(approbatorsIDs, currentUser)
        ) {
          filteredContent.push(content);
        }
      }
      if (userRightsContentRestriction.readAllowed) {
        return filteredContent;
      }
      return filteredContent.filter((content: IContent) => {
        return content.isRestricted
          ? content.restrictedAccessUserIDS?.includes(currentUser || "")
          : true;
      });
    },
    contentsListReadOnly(): IContent[] | undefined {
      const userId = useAuthentificationStore().data?.id;
      const userRightsContentRestriction = usePermissions(
        ESectionName.Enum.content_restriction,
      );
      const contentGlobalStore = useContentGlobalStore();
      if (!contentGlobalStore) return;
      const onlyNotifyActivesContents: IContent[] = [];

      const favoriteTeamData = _getFavoriteTeamsData();

      for (const content of contentGlobalStore.actives || []) {
        if (favoriteTeamData) {
          if (content.category === EContentCategory.generic) {
            if (
              intersection(
                (content as IContentAsGeneric).teams,
                favoriteTeamData.teams,
              ).length === 0 &&
              intersection(
                (content as IContentAsGeneric).factories,
                favoriteTeamData.factories,
              ).length === 0
            ) {
              continue;
            }
          }
          if (content.category === EContentCategory.training) {
            if (
              intersection((content as IContentAsTraining).positions, [
                ...favoriteTeamData.positions,
                ...favoriteTeamData.subpositions,
              ]).length === 0
            ) {
              continue;
            }
          }
        }
        if (
          !!content.notify_employees &&
          content.published === BasePublish.published
        )
          onlyNotifyActivesContents.push(content);
      }
      if (userRightsContentRestriction.readAllowed) {
        return onlyNotifyActivesContents;
      }
      return onlyNotifyActivesContents.filter((content: IContent) => {
        return content.isRestricted
          ? content.restrictedAccessUserIDS?.includes(userId || "")
          : true;
      });
    },
    getContentCategoriesNames(): {
      generic: TranslateResult;
      training: TranslateResult;
    } {
      const authentificationStore = useAuthentificationStore();
      if (
        !authentificationStore ||
        !authentificationStore.active_site_full?.content_category_name
      ) {
        return {
          generic: i18n.tc("content.generic_information", 1),
          training: i18n.tc("content.instruction", 1),
        };
      }
      const content_category_name =
        authentificationStore.active_site_full.content_category_name;
      return {
        generic:
          content_category_name?.generic ||
          i18n.tc("content.generic_information", 1),
        training:
          content_category_name?.training || i18n.tc("content.instruction", 1),
      };
    },
    isAtLeastOneSelected(): boolean {
      const res: boolean[] = [];

      let allSelectedArray: any[][];
      if (checkRole(["ADMIN"])) {
        allSelectedArray = [
          this.selectedDocumentsType,
          this.selectedStatus,
          this.selectedPositions,
          this.selectedClearances,
          this.selectedSkills,
          [this.showArchivedContents],
        ];
      } else {
        allSelectedArray = [
          this.selectedDocumentsType,
          this.selectedStatus,
          this.selectedPositions,
          this.selectedClearances,
          this.selectedSkills,
          [this.showArchivedContents],
        ];
      }
      map(allSelectedArray, (item) => res.push(item.length > 0));
      return res.some((value: boolean) => value);
    },
    isTabletView(): boolean {
      const contentDictionary = useContentGlobalStore().dictionary;
      let isOnlyFiles = false;
      if (contentDictionary && this.currentContentViewedID) {
        isOnlyFiles =
          contentDictionary[this.currentContentViewedID]?.isOnlyFiles || false;
      }
      return this.userHasSelectedTabletView && !isOnlyFiles;
    },
    totalFiltersCount(): number {
      return (
        this.selectedDocumentsType.length +
        this.selectedStatus.length +
        this.selectedPositions.length +
        this.selectedClearances.length +
        this.selectedSkills.length +
        (this.showArchivedContents ? 1 : 0)
      );
    },
  },
  state: () => ({
    contentClientHelper: new ClientHelper<IContent>("content"),
    currentContentViewedID: null as ContentID | null,
    currentRouteQuery: null as ICurrentRouteQuery | null,
    isDynamicSearch: true,
    refetch: false,
    selectedClearances: [] as ClearanceID[],
    selectedDocumentsType: [] as ContentDocumentTypeID[],
    selectedPositions: [] as PositionID[],
    selectedSkills: [] as SkillID[],
    selectedStatus: [] as BasePublish[],
    showAllContents: false as boolean,
    showArchivedContents: false as boolean,
    tab: 0,
    userHasSelectedTabletView: false,
  }),
});

const _getFavoriteTeamsData = (): null | {
  factories: FactoryID[];
  positions: PositionID[];
  subpositions: SubpositionID[];
  teams: TeamID[];
} => {
  const currentUser = useAuthentificationStore().data;

  // let user: IUser | undefined = undefined;
  let userTeamsSelected: TeamID[] | undefined = [];

  // const userDictionnary = useUserGlobalStore().dictionary;

  // if (currentUser && userDictionnary) {
  //   user = userDictionnary[currentUser.id];
  // }
  if (!currentUser) throw new Error("Missing user");

  userTeamsSelected = currentUser.favorite_teams;
  // No teams filter
  if (!userTeamsSelected || userTeamsSelected.length === 0) {
    return null;
  }

  const TeamGlobalStoreDictionary = useTeamGlobalStore().dictionary;
  if (!TeamGlobalStoreDictionary) throw new Error("Missing team dictionary");
  const PositionGlobalStore = usePositionGlobalStore().actives;
  if (!PositionGlobalStore) throw new Error("Missing position actives");
  const SubPositionGlobalStore = usePositionLevelGlobalStore().actives;
  if (!SubPositionGlobalStore) throw new Error("Missing subposition actives");

  const factories: FactoryID[] = [];
  const positions: PositionID[] = [];
  const subpositions: SubpositionID[] = [];

  for (const teamId of userTeamsSelected) {
    // Get factory ids
    const factoryId = TeamGlobalStoreDictionary[teamId]?.factory_id;
    if (factoryId) {
      factories.push(factoryId);
    }
    // Get position ids
    for (const position of PositionGlobalStore) {
      if (position.team_ids?.includes(teamId)) {
        positions.push(position.id);
      }
    }
    // Get subposition ids
    for (const subposition of SubPositionGlobalStore) {
      if (subposition.team_ids?.includes(teamId)) {
        subpositions.push(subposition.id);
      }
    }
  }

  return {
    factories,
    positions,
    subpositions,
    teams: userTeamsSelected,
  };
};
