import { gql } from "graphql-tag";

export const CreateFactory = gql`
  mutation CreateFactory($data: FactoryCreateInput!) {
    createFactory(data: $data) {
      id
      client_id
      site_id
      status
      factory_name
      site {
        name
      }
      client {
        name
        logo_url
      }
      teams {
        id
        client_id
        site_id
        status
        site {
          name
        }
      }
    }
  }
`;

export const UpdateFactory = gql`
  mutation UpdateFactory(
    $data: FactoryUpdateInput!
    $where: FactoryWhereUniqueInput!
  ) {
    updateFactory(data: $data, where: $where) {
      id
      client_id
      site_id
      status
      factory_name
      site {
        name
      }
      client {
        name
        logo_url
      }
      teams {
        id
        client_id
        site_id
        status
        site {
          name
        }
      }
    }
  }
`;

export const DeleteFactory = gql`
  mutation DeleteFactory($where: FactoryWhereUniqueInput!) {
    delete_Factory(where: $where) {
      id
    }
  }
`;

export const DeleteManyFactory = gql`
  mutation DeleteManyFactory($where: FactoryWhereInput) {
    deleteManyFactory(where: $where) {
      count
    }
  }
`;

export const Factories = gql`
  query Factories {
    Factories {
      id
      client_id
      client {
        name
        logo_url
      }
      site_id
      status
      factory_name
      site {
        name
      }
      teams {
        id
      }
    }
  }
`;
