import { defineStore } from "pinia";

import type { Result } from "@/tscript/logic/planningBuilder";

import {
  AbsenceFilterCells,
  PlanningFilterCells,
  ShiftFilterCells,
} from "@/tscript/logic/planningFilter";

import { usePlanningAligmentStore } from "./planning-alignment";
import { usePlanningDisplayStore } from "./planning-display";
import {
  isSearchPlanningValue,
  SearchPlanningType,
  type SearchPlanningValue,
} from "./planning-search";

export const usePlanningFilterStore = defineStore("usePlanningFilter", {
  actions: {
    cleanData(): void {
      this.cells = [];
      this.shifts = [];
      this.absences = [];
    },
    cleanFiltersSearch(): void {
      usePlanningDisplayStore().setIsSearching(false);
      this.employee_ids = [];
      this.tag_ids = [];
      this.shift_ids = [];
      this.position_ids = [];
      this.subposition_ids = [];
      this.clearance_ids = [];
      this.skill_ids = [];
      this.contract_ids = [];
      this.restriction_ids = [];
      this.category_ids = [];
    },
    cleanFiltersShift(): void {
      this.shift_ids = [];
    },
    cleanState(): void {
      this.cleanFiltersSearch();
      this.cleanFiltersShift();
      this.cleanData();
    },
    setData(
      cells: Result["cells"],
      absences: Result["absences"],
      shifts: Result["shifts"],
    ): void {
      this.cleanData();
      const data = {
        absences: AbsenceFilterCells(absences, {
          employee_ids: this.employee_ids,
          reason_id: this.reason_id,
        }),
        cells: PlanningFilterCells(cells, {
          category_ids: this.category_ids,
          clearance_ids: this.clearance_ids,
          contract_ids: this.contract_ids,
          employee_ids: this.employee_ids,
          position_ids: this.position_ids,
          restriction_ids: this.restriction_ids,
          shift_ids: this.shift_ids,
          skill_ids: this.skill_ids,
          subposition_ids: this.subposition_ids,
          tag_ids: this.tag_ids,
        }),
        shifts: ShiftFilterCells(shifts, {
          shift_ids: this.shift_ids,
        }),
      };
      this.cells = data.cells;
      this.absences = data.absences;
      this.shifts = data.shifts;
      usePlanningAligmentStore().setData(
        data.cells,
        data.absences,
        data.shifts,
      );
    },
    updateFilters(searchPlanningValues: SearchPlanningValue[]) {
      usePlanningDisplayStore().setIsSearching(true);
      // Check when updateFilters comes from SearchPlanning
      this.isSearchPlanning = isSearchPlanningValue(searchPlanningValues);
      if (this.isSearchPlanning) {
        this.searchPlanningValues = searchPlanningValues;
        searchPlanningValues.forEach((v) => {
          switch (v.search_type) {
            case SearchPlanningType.category:
              this.category_ids.push(v.id);
              break;
            case SearchPlanningType.clearance:
              this.clearance_ids.push(v.id);
              break;
            case SearchPlanningType.contract:
              this.contract_ids.push(v.id);
              break;
            case SearchPlanningType.employee:
              this.employee_ids.push(v.id);
              break;
            case SearchPlanningType.position:
              this.position_ids.push(v.id);
              break;
            case SearchPlanningType.restriction:
              this.restriction_ids.push(v.id);
              break;
            case SearchPlanningType.skill:
              this.skill_ids.push(v.id);
              break;
            case SearchPlanningType.subposition:
              this.subposition_ids.push(v.id);
              break;
            case SearchPlanningType.tag:
              this.tag_ids.push(v.id);
              break;
            default:
              break;
          }
        });
      }
    },
  },
  state: () => {
    return {
      absences: [] as Result["absences"],
      category_ids: [] as string[],
      cells: [] as Result["cells"],
      clearance_ids: [] as string[],
      contract_ids: [] as string[],
      employee_ids: [] as string[],
      isSearchPlanning: false,
      position_ids: [] as string[],
      reason_id: null as null | string,
      restriction_ids: [] as string[],
      searchPlanningValues: [] as SearchPlanningValue[],
      shift_ids: [] as string[],
      shifts: [] as Result["shifts"],
      skill_ids: [] as string[],
      subposition_ids: [] as string[],
      tag_ids: [] as string[],
    };
  },
});
